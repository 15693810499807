import React, { useState, useEffect } from "react";
import AssetsImg from "images-col";
import { Link, useHistory } from "react-router-dom";
import { Col, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import Constants from "../../constants";
import { getAuthAttribute } from "../../utils/helper";

import "./Styles.scss";
import { useTranslation } from "react-i18next";

const NavigationItem = (props) => {
  const { t } = useTranslation();
  const { selected, item } = props;
  const [showSubOptions, setShowSubOptions] = useState(() =>
    Boolean(item?.options?.find((item) => item.id === selected))
  );

  const isShowStaric = (key = '') => {
    if (key === 'my_businesses' && props?.profileStatus?.has_working_hours === '0') {
      return '*'
    } else if (key === 'staff' && props?.profileStatus?.has_staff === '0') {
      return '*'
    } else if (key === 'staff' && props?.profileStatus?.has_staff_shifts === '0') {
      return '*'
    } else if (key === 'service' && props?.profileStatus?.has_services === '0') {
      return '*'
    } else {
      return ''
    }
  }

  return (
    <li className={selected === item.id ? "active text-danger" : 'text-danger'} >
      {item.hasSuboptions ? (
        <div
          className="sub-links-section"
          onClick={() => setShowSubOptions((prev) => !prev)}
        >
          <div className="setting-header">
            <OverlayTrigger
              placement="right"
              delay={{ show: 200, hide: 10 }}
              overlay={(
                <Tooltip id="button-tooltip" {...props}>
                  <span className="nav-text">{t(`${item.name}`)}</span>
                </Tooltip>
              )}
            >
              <Image
                src={selected === item.id ? item.activeIcon : item.staticIcon}
                className="setting-icon"
                alt='partners'
              />
              {/* <span className="nav-text">{t(`${item.name}`)}</span> */}
            </OverlayTrigger>
          </div>
          {showSubOptions && (
            <div className="sub-links-wrap">
              {item.options.map((item) => (
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 200, hide: 10 }}
                  overlay={(
                    <Tooltip id="button-tooltip" {...props}>
                      <span className="nav-text">{t(`${item.name}`)}</span>
                    </Tooltip>
                  )}
                >
                  <Link
                    className={selected === item.id ? "active-link" : undefined}
                    key={item.name}
                    to={item.link}
                    id={item?.id}
                  >
                    <Image
                      src={
                        selected === item.id ? item.activeIcon : item.staticIcon
                      }
                      className="nav-icon"
                    />
                    {/* <span className="nav-text">{t(`${item.name}`)}</span> */}
                  </Link>
                </OverlayTrigger>
              ))}
            </div>
          )}
        </div>
      ) : (
        <OverlayTrigger
          placement="right"
          // delay={{ show: 250, hide: 250 }}
          // trigger="click"
          overlay={(
            <Tooltip id="button-tooltip" {...props}>
              <span className="nav-text">{t(`${item.name}`)}</span>
            </Tooltip>
          )}

        >
          <Link to={item.link} id={item?.id} >
            <Image
              src={selected === item.id ? item.activeIcon : item.staticIcon}
              className="nav-icon"
            />
            {/* <span className="nav-text">{t(`${item.name}`)}</span> */}
          </Link>
        </OverlayTrigger>
      )}
      {isShowStaric(item?.id)}
    </li>
  );
};

const SideMenu = (props) => {
  const history = useHistory()
  const { selected } = props;
  const isUserAdmin = getAuthAttribute("user_type_id");

  useEffect(() => {
    const element = document.getElementById(selected);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [selected]);

  const navKey = (() => {
    if (isUserAdmin === 2) { //supplier menu
      return "Navigation";
    } else if (isUserAdmin === 3) { //supplier administrators menu
      return "Navigation";
    } else if (isUserAdmin === 4) { //super admin
      return "NavigationAdmin";
    } else if (isUserAdmin === 5) { // call center 
      return "UserNavigation";
    } else if (getAuthAttribute("sub_user_type_id") === "6") { //supplier driver menu
      return "DriverNavigation";
    } else if (isUserAdmin === 7) { //company menu
      return "CompanyNavigation";
    }
  })();

  return (
    <>

      <div className="new-sidear">
        <div className="logo-cont">
          <Image src={Logo} className="logo" onClick={() => {
            isUserAdmin === 7 ? history.replace('/companies') : history.push('/dashboard')
          }} />
        </div>
        <div className="logo-border" />
        <div className="menu-cont">
          <ul>
            {Constants[navKey].map((item, i) => {
              return <NavigationItem key={i} selected={selected} item={item} profileStatus={props?.profileStatus} />;
            })}
          </ul>
          <div className="footer-cont">
            <footer>
              <span>v1.1.1</span>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
