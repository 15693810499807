import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getSellerById, saveSeller } from "../../../core/Api/giftvouchers";
import { getCityByCountryCode, getCountryList } from "../../../core/Api/Settings";
import AssetsImg from "images-col";
import { uploadImage } from "../../../core/Api/staff";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Accordion, Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import * as _ from 'lodash';
import { useTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import { getAuthAttribute } from "../../../utils/helper";
import Hoc from "../../Hoc";
import FullPageSpinner from "../../../components/FullPageSpinner";
import UserInput from "../../../components/Inputs";
import CustomDropdown from "../../../components/Dropdown";
import SearchableDropdown from "../../../components/Dropdown/SearchableDropdown";
import PhoneNumberInput from "../../../components/Inputs/PhoneNumber";
import PrimaryButton from "../../../components/Buttons/Primary";
import './Styles.scss';
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import CustomTextArea from "../../../components/Textarea";
import MapSearch from "../../../components/MapSearch";

const intialModel = {
  seller_id: null,
  seller_name: null,
  seller_name_arb: null,
  seller_desc: null,
  seller_desc_arb: null,
  contact_person_1: null,
  contact_number_1: null,
  category: null,
  status_id: 1,
  seller_country: null,
  seller_city: null,
  logo: null,
  address: null
}

const initAddressModel = {
  address_id: null,
  location: null,
  lat_lng: '0,0',
  building_floor: null,
  street_name: null,
  neighbourhood: null,
  city: null,
  postal_zip_code: null,
  additional_numbers: null,
  landmark: null,
}

const SellerForm = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        arabic: {
          message: t("Must be arabic"),
          rule: (val) => {
            const isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
            return isArabic.test(val);
          },
          required: true,
        },
        english: {
          message: t("Must be english"),
          rule: (val) => {
            const isEnglish = /[a-zA-Z0-9]/;
            return isEnglish.test(val);
          },
          required: true,
        },
      },
    })
  );
  const [isLoading, setIsLoading] = useState(true);
  const [sellerModel, setSellerModel] = useState(_.cloneDeep(intialModel));
  const [addressModel, setAddressModel] = useState(_.cloneDeep(initAddressModel))
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [imagePreviewURL, setImagePreviewURL] = useState({
    image: null,
    src: null,
  });
  const [, forceUpdate] = useState(null);
  const [centerCords, setCenterCords] = useState({
    lat: 24.7132552,
    lng: 46.6786387,
  });
  const [markerCords, setMarkerCords] = useState({
    lat: 24.7132552,
    lng: 46.6786387,
  });
  const [isActive, setIsActive] = useState(false);
  const isUserAdmin = getAuthAttribute("user_type_id") === 4;
  const isDisabled = false;
  const mapRef = useRef(null);
  const searchFieldRef = useRef(null);

  const categories = [
    { value: null, label: 'Select Category' },
    { value: 'Beauty', label: "Beauty" }
  ]

  useEffect(() => {
    laodInitialData();
  }, []);

  const laodInitialData = async () => {
    try {
      const promises = [
        getCountryList(),
      ]
      if (match?.params?.id) {
        promises.push(getSellerById(match?.params?.id));
      }
      const results = await Promise.all([...promises]);
      setIsLoading(false)
      if (results?.length && results[0]?.code === 200) {
        const data = [];
        results?.length && results[0]?.result.forEach((row) => {
          data.push({
            label: row.name,
            value: row.code,
          });
        });
        setCountryList([...data]);
      } else {
        toast.error(results?.length && results[0]?.message || "Something went wrong");
      }
      if (match?.params?.id) {
        if (results?.length && results[1].code === 200) {
          setSellerModel({
            ...sellerModel,
            ...results[1]?.result
          })
          setAddressModel({
            ...addressModel,
            ...results[1]?.result?.address
          })
          if (results[1]?.result?.logo) {
            setImagePreviewURL({
              src: results[1]?.result?.logo,
              image: results[1]?.result?.logo_filename,
            })
          }
          if (results[1]?.result?.seller_country) {
            await fetchCityByCountryCode(results[1]?.result?.seller_country)
          }
        } else {
          toast.error(results?.length && results[1]?.message);
        }
      }
    } catch (error) {
      setIsLoading(false)
      toast.error(error.message || "Something went wrong");
    }
  }

  const fetchCityByCountryCode = async (countryCode) => {
    try {
      setIsLoading(true);
      const result = await getCityByCountryCode(countryCode);
      setIsLoading(false);
      if (result && result.code === 200) {
        const data = result.result
          ? result.result.map((row) => ({
            label: row.name,
            value: row.city_id,
          }))
          : [];
        setCityList([...data]);
      } else {
        toast.error(result.message ?? "Something went wrong")
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message ?? "Something went wrong");
    }
  };

  const onImageSelect = async (event) => {
    const image = event.target.files[0];
    if (image) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePreviewURL({
          image: image,
          src: reader.result,
        });
      });
      reader.readAsDataURL(image);
    }
  };

  const handleInputChange = (e, name) => {
    setSellerModel({
      ...sellerModel,
      [name]: e.target.value
    })
  }

  const handleDDChange = (value, name) => {
    setSellerModel({
      ...sellerModel,
      [name]: value,
    });
  };

  const handleCountryChange = (value, name) => {
    setSellerModel({
      ...sellerModel,
      [name]: value.value,
    });
    if (value?.value) {
      fetchCityByCountryCode(value?.value)
    }
  };

  const handleAddInputChange = (value, name) => {
    setAddressModel({
      ...addressModel,
      [name]: value
    })
  }

  const handlePhoneNumberChange = (value) => {
    if (!value.countryCode || !value.phoneNumber) return;
    setSellerModel({
      ...sellerModel,
      contact_number_1: `${value.countryCode}-${value.phoneNumber}`,
    });
  };

  const getPlaceValue = (lat, lng) => {
    return new Promise((resolve, reject) => {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDfxpYsb_0sfaP5dQ9Lonpd4staJ4ErH6I`
      )
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };


  const onMapLoad = (map) => {
    mapRef.current = map;
  };

  const handleMarkerDrag = async (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    try {
      const addresses = await getPlaceValue(lat, lng);
      if (addresses?.results?.length) {
        const filteredAddresses = (addresses.results || []).filter(
          (item) =>
            item.types.includes("political") && item.types.includes("sublocality")
        );
        const location = addresses.results[0].formatted_address;
        const neighbourhood =
          filteredAddresses[0].address_components[0].long_name;
        searchFieldRef.current.value = location;
        setAddressModel({
          ...addressModel,
          lat_lng: [lat, lng].toString(),
          location,
          neighbourhood
        })
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong");
    }
  };

  const onPlacesChanged = async (result) => {
    if (result) {
      const filteredAddresses = (result.address_components || []).filter(
        (item) =>
          item.types.includes("political") && item.types.includes("sublocality")
      );
      const fullAddress = result.formatted_address;
      const neighbourhood = filteredAddresses[0].long_name;
      const lat = result.geometry.location.lat();
      const lng = result.geometry.location.lng();
      setCenterCords({ lat, lng });
      setMarkerCords({ lat, lng });
      setAddressModel({
        ...addressModel,
        lat_lng: [lat, result?.lng].toString(),
        location: fullAddress,
        neighbourhood
      })
    }
  };

  const handleSaveSeller = async () => {
    toast.dismiss();
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      let data = {
        ...sellerModel
      }
      if (imagePreviewURL.image) {
        const imageData = new FormData();
        imageData.append("file", imagePreviewURL.image);
        const imageUrl = await uploadImage(imageData);
        const logo = imageUrl?.result.file_name;
        data["logo"] = logo;
      }
      data = {
        ...data,
        address: {
          ...addressModel,
        }
      }
      const result = await saveSeller(data);
      setIsLoading(false);
      if (result?.code === 200) {
        toast.success('Saved successfully');
        history.push('/sellers');
      } else {
        toast.error(result?.message || "Something went wrong.Please try again");
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      toast.error("Please enter valid details");
    }
  };


  return (
    <React.Fragment>
      <Hoc activeMenu="seller">
        <Container fluid className="seller-form">
          {isLoading && <FullPageSpinner /> || null}
          <div className="company-info">
            <Row className="">
              <Col xl="3" lg="3" md="3" sm="12" className="mt-4">
                <div className="logo-cont">
                  <label className="profile-pic-label">
                    <img
                      src={imagePreviewURL?.src || AssetsImg?.img_default}
                      className="profile-pic"
                      alt="staff-member-pi"
                      onError={(e) => {
                        e.target.src = AssetsImg?.img_default;
                      }}
                    />
                    <div className="overlay"></div>
                    <input
                      name='"profile-input'
                      visibility="hidden"
                      type="file"
                      id="profile-input"
                      accept=".png, .jpg, .jpeg"
                      onChange={onImageSelect}
                    />
                  </label>
                </div>
              </Col>
              <Col xl="9" lg="9" md="9" sm="12" className="mt-4">
                <Row className="mb-2">
                  <Col xl="6" lg="6" md="6" sm="12">
                    <UserInput
                      required
                      label={t("Name(eng)")}
                      disabled={isDisabled}
                      placeholder={t("Name(eng)")}
                      name="seller_name"
                      value={sellerModel?.seller_name}
                      onChange={(event) =>
                        handleInputChange(event, "seller_name")
                      }
                    />
                    {simpleValidator.current.message(
                      "seller_name",
                      sellerModel && sellerModel.seller_name,
                      "required"
                    )}
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12">
                    <UserInput
                      required
                      disabled={isDisabled}
                      label={t("Name(arb)")}
                      placeholder={t("Name(arb)")}
                      name="seller_name_arb"
                      value={sellerModel.seller_name_arb}
                      onChange={(event) =>
                        handleInputChange(event, "seller_name_arb")
                      }
                    />
                    {simpleValidator.current.message(
                      "seller_name_arb",
                      sellerModel && sellerModel.seller_name_arb,
                      "required"
                    )}
                  </Col>
                </Row>
              </Col>
              <Col xl="4" lg="4" md="4" sm="12" className="mt-4">
                <CustomDropdown
                  required
                  onChange={(value) =>
                    handleDDChange(value.value, "category")
                  }
                  name="category"
                  options={categories || []}
                  label={t("Category")}
                  value={
                    categories.find(
                      (row) => row.value == sellerModel?.category
                    ) || ""
                  }
                />
                {simpleValidator.current.message(
                  "category",
                  sellerModel && sellerModel.category,
                  "required"
                )}
              </Col>
              <Col xl="4" lg="4" md="4" sm="12" className="mt-4">
                <SearchableDropdown
                  onChange={(value) =>
                    handleCountryChange(value, "seller_country")
                  }
                  label={t('Country')}
                  placeholder={t("Select Country")}
                  name="country"
                  options={countryList}
                  value={countryList.find(
                    (value) => value.value === sellerModel?.seller_country || ""
                  )}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="12" className="mt-4">
                <SearchableDropdown
                  onChange={(value) =>
                    handleDDChange(value.value, "seller_city")
                  }
                  label={t('City')}
                  placeholder={t("Select City")}
                  name="city"
                  options={cityList}
                  value={cityList.find(
                    (value) => value.value === sellerModel.seller_city || ""
                  )}
                />
              </Col>
              <Col xl="4" lg="4" md="4" sm="12" className="mt-4">
                <UserInput
                  required
                  label={t("Contact Person Name")}
                  placeholder={t("Contact Person Name")}
                  name="contact_person_1"
                  disabled={isDisabled}
                  value={sellerModel.contact_person_1}
                  onChange={(event) =>
                    handleInputChange(event, "contact_person_1")
                  }
                />
                {isUserAdmin &&
                  simpleValidator.current.message(
                    "contact_person_1",
                    sellerModel && sellerModel.contact_person_1,
                    "required"
                  )}
              </Col>
              <Col xl="4" lg="4" md="4" sm="12" className="mt-3">
                <PhoneNumberInput
                  required
                  label={t("Mobile")}
                  placeholder="5xxxxxxx"
                  value={sellerModel.contact_number_1}
                  selectedValues={(values) => handlePhoneNumberChange(values)}
                  maxLength="9"
                />
                {simpleValidator.current.message(
                  "contact_number_1",
                  sellerModel.contact_number_1,
                  "required|checkMobileNumber"
                )}
              </Col>
              <Col xl="12" lg="12" md="12" sm="12" className="mt-4">
                <Accordion >
                  {/* <Card> */}
                  <Accordion.Toggle as={Card.Header} onClick={() => setIsActive(!isActive)} eventKey="0">
                    <div className='d-flex justify-content-between align-items-center'>
                      <span>Description</span> <img className={isActive && 'arrow-icons'} src={AssetsImg?.icon_arrow_down} ></img>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Row>
                        <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                          <CustomTextArea
                            label={t("Seller description (Optional)")}
                            placeholder={t("Seller description")}
                            name="seller_desc"
                            value={sellerModel.seller_desc}
                            onChange={(event) => handleInputChange(event, "seller_desc")}
                          />
                        </Col>
                        <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                          <CustomTextArea
                            customInputClass="user-input mt-2"
                            label={t("Seller description(arb) (Optional)")}
                            placeholder={t("Seller description(arb)")}
                            name="seller_desc_arb"
                            value={sellerModel.seller_desc_arb}
                            onChange={(event) => handleInputChange(event, "seller_desc_arb")}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                  {/* </Card> */}
                </Accordion>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <p className="font-bold mt-5 ">{t("Address Information")}</p>
              </Col>
              <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                <div>
                  <LoadScript
                    id="script-loader"
                    googleMapsApiKey="AIzaSyDfxpYsb_0sfaP5dQ9Lonpd4staJ4ErH6I"
                    libraries={["places", "drawing"]}
                  >
                    <GoogleMap
                      id="map_canvas"
                      mapContainerStyle={{
                        width: "100%",
                        height: "400px",
                      }}
                      center={centerCords}
                      zoom={15}
                      onLoad={onMapLoad}
                      options={{
                        mapTypeControl: false,
                        fullscreenControl: true,
                        controlSize: false,
                        scaleControl: false,
                        zoomControl: false,
                        streetView: false,
                        disableDefaultUI: false
                      }}
                    >
                      <Marker
                        position={markerCords}
                        onDragEnd={handleMarkerDrag}
                        title={t("Your current location")}
                        draggable
                      />
                      <MapSearch
                        ref={searchFieldRef}
                        handleCenterChange={onPlacesChanged}
                        styles={{ marginLeft: '10px' }}
                      />
                    </GoogleMap>
                  </LoadScript>
                </div>
              </Col>
              <Col xl="6" lg="6" md="6" sm="12" className="">
                <Col xl="12" lg="12" md="12" sm="12" className="mt-4">
                  <UserInput
                    label={t("Location")}
                    placeholder={t("Location")}
                    name="location"
                    value={addressModel.location}
                    onChange={(event) =>
                      handleAddInputChange(
                        event.target.value,
                        "location"
                      )
                    }
                  />
                </Col>
                <Col xl="12" lg="12" md="12" sm="12" className="mt-4">
                  <UserInput
                    label={t("Neighbourhood")}
                    placeholder={t("Neighbourhood")}
                    name="neighbourhood"
                    value={addressModel.neighbourhood}
                    onChange={(event) =>
                      handleAddInputChange(
                        event.target.value,
                        "neighbourhood"
                      )
                    }
                  />
                </Col>
                <Col xl="12" lg="12" md="12" sm="12" className="mt-4">
                  <UserInput
                    label={t("Street")}
                    placeholder={t("Street")}
                    name="street_name"
                    value={addressModel.street_name}
                    onChange={(event) =>
                      handleAddInputChange(
                        event.target.value,
                        "street_name"
                      )
                    }
                  />
                </Col>
                <Col xl="12" lg="12" md="12" sm="12" className="mt-4">
                  <UserInput
                    label={t("Zipcode")}
                    placeholder={t("Zipcode")}
                    name="postal_zip_code"
                    value={addressModel.postal_zip_code}
                    onChange={(event) =>
                      handleAddInputChange(
                        event.target.value,
                        "postal_zip_code"
                      )
                    }
                  />
                </Col>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="pt-5   d-flex justify-content-end">
                <PrimaryButton
                  text={t("Save")}
                  onClick={() => handleSaveSeller()}
                  style={{ width: '200px' }}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </Hoc>
    </React.Fragment >
  )
}
export default SellerForm;