import React, { useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";

import caretDown from "../../assets/images/caret-down.svg";
import caretUp from "../../assets/images/caret-up.svg";
import { Image, Button, Container, Row, Col, Modal } from "react-bootstrap";
import star from "../../assets/images/star.svg";
import filledStar from "../../assets/images/star-fill.svg";
import { approveReviews } from "../../core/Api/supplier";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import SearchInput from "../../components/Inputs/Search";
import ReviewReplyForm from "./ReviewReply";

const ReviewTable = ({ fetchReviews, isPending, reviewData }) => {
  const { t } = useTranslation();
  const [showRR, setShowRR] = useState(false);
  const [activeReviewId, setActiveReviewId] = useState(0);
  const [activeReviewReplies, setctiveReviewReplies] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [bookingInfo, setBookingInfo] = useState(null);
  const filteredData = reviewData.filter((item) =>
    `${item.first_name.toLowerCase()} ${item.title.toLowerCase()} ${item.review.toLowerCase()}`.includes(
      searchInput.toLowerCase()
    )
  );

  const handleSearchChange = (e) => setSearchInput(e.target.value);

  const sortCaret = (order) => (
    <span className="caret-icon">
      <img src={order === "asc" ? caretUp : caretDown} alt="" />
    </span>
  );

  const formatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="rating-stars">
        {Array.from({ length: 5 }).map((_, index) => (
          <Button
            size="sm"
            variant={
              index < Number(row.rating) ? "warning" : "outline-secondary"
            }
          >
            <Image src={index < Number(row.rating) ? star : filledStar} />
          </Button>
        ))}
      </div>
    );
  };

  const handleConfirm = async (review_id) => {
    toast.dismiss();
    Swal.fire({
      text: t("Are you sure you want to approve this review?"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Approve"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await approveReviews(review_id);
        if (result?.code === 200) {
          toast.success(result.message || "Approved successfully");
          fetchReviews && fetchReviews();
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
    });
  };

  const approveButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        onClick={() => handleConfirm(row.review_id)}
        size="sm"
        variant={"outline-secondary"}
      >
        Approve
      </Button>
    );
  };

  const replyButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        onClick={(e, info = row) => {
          setActiveReviewId(info.review_id);
          setctiveReviewReplies(info.replies);
          setBookingInfo(info)
          setShowRR(true);
        }}
        size="sm"
        variant={"outline-secondary"}
      >
        Replies
      </Button>
    );
  };

  const columns = [
    {
      dataField: "first_name",
      text: t("Name"),
      sort: true,
      sortCaret,
    },
    {
      dataField: "date",
      text: t("Date and Time"),
      sort: true,
      sortCaret,
    },
    {
      dataField: "rating",
      text: t("Rating"),
      sort: true,
      sortCaret,
      formatter: formatter,
    },
    {
      dataField: "title",
      text: t("Title"),
      sort: true,
      sortCaret,
    },
    {
      dataField: "review",
      text: t("Reviews"),
      sort: true,
      sortCaret,
    },
  ];

  if (isPending) {
    columns.push({
      dataField: "other",
      text: "",
      sort: false,
      formatter: approveButton,
    });
  } else {
    columns.push({
      dataField: "other",
      text: "",
      sort: false,
      formatter: replyButton,
    });
  }

  return (
    <Container className="reviews-table">
      <div className="table-wrapper">
        <Row className="search-wrap">
          <Col xl="3" lg="4" md="6" sm="12">
            <SearchInput
              classNames="pt-0"
              onChange={handleSearchChange}
              placeholder={t("Search")}
            />
          </Col>
        </Row>
        {filteredData.length ? (
          <BootstrapTable
            keyField={columns[0]}
            data={filteredData}
            columns={columns}
          />
        ) : (
          <div className="no-data-container">
            <h4>
              {searchInput
                ? t("No matching reviews found")
                : t("No reviews yet")}
            </h4>
          </div>
        )}
      </div>

      {showRR && (<Modal show={showRR} onHide={() => setShowRR(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{activeReviewId} - Review Replies</Modal.Title>
        </Modal.Header>
        <ReviewReplyForm
          handleClose={setShowRR} reviewId={activeReviewId} reviewReplies={activeReviewReplies}
          bookingInfo={bookingInfo}
        />
      </Modal>) || null
      }

    </Container>
  );
};

export default ReviewTable;
