import React, { useEffect, useState } from "react";
import Hoc from "../../Hoc";
import { Container, Accordion, Card, Button, Image } from "react-bootstrap";
import setting from "../../../assets/images/gear.svg";
import { toast } from "react-toastify";

import Generalsettings from "./Generalsettings";
import CustomerSetting from "./Customer";
import CommonSetting from "./Common";
import SupplierSetting from "./Supplier";
import { globalSettings } from "../../../core/Api/Settings";
import "./Styles.scss";

const GlobalSettings = () => {
  const [data, setData] = useState({});
  const generalSettings = { ...data.general_settings };
  const customerSettings = { ...data.customer_settings };
  const commonSettings = { ...data.common_app_settings };
  const supplierSettings = { ...data.supplier_settings };
  const [activeKey, setActiveKey] = useState(1);

  const tabs = [
    {
      id: 1,
      label: "General Settings",
      components: <Generalsettings generalSettings={generalSettings} />,
    },
    {
      id: 2,
      label: "Customer App Settings",
      components: <CustomerSetting customerSettings={customerSettings} />,
    },
    {
      id: 3,
      label: "Supplier App Settings",
      components: <SupplierSetting supplierSettings={supplierSettings} />,
    },
    {
      id: 4,
      label: "Common App Settings",
      components: <CommonSetting commonSettings={commonSettings} />,
    },
  ];

  useEffect(() => {
    getGlobalSettings();
  }, []);

  const getGlobalSettings = async () => {
    toast.dismiss();
    try {
      const result = await globalSettings();
      if (result.code === 200) {
        setData(result?.result ?? {});
        console.log("result", result.result);
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };

  return (
    <React.Fragment>
      <Hoc activeMenu="global_settings">
        <Container className="global-settings-container">
          <Card className="card-container">
            {tabs.map((tab) => (
              <Accordion
                activeKey={activeKey}
                key={tab.id}
                defaultActiveKey={activeKey}
              >
                <Card className="setting-cards">
                  <Card.Header>
                    <Accordion.Toggle
                      variant="link"
                      as={Button}
                      eventKey={tab.id}
                      onClick={() =>
                        setActiveKey((prev) => (prev === tab.id ? 0 : tab.id))
                      }
                    >
                      <Image src={setting} />
                      {tab.label}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse activeMenu={true} eventKey={tab.id}>
                    {tab.components}
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            ))}
          </Card>
        </Container>
      </Hoc>
    </React.Fragment>
  );
};

export default GlobalSettings;
