import React, { useEffect, useState, useRef } from "react";
import { Image, Modal, Col, Row, Spinner, Container, Overlay } from "react-bootstrap";
import "./Styles.scss";
import caretDown from "../../assets/images/caret-down.svg";
import caretUp from "../../assets/images/caret-up.svg";
import editIcon from "../../assets/images/Icon material-mode-edit.png";
import deactivateIcon from "../../assets/images/eye-slash-fill.svg";
import deleteIcon from "../../assets/images/trash.svg";
import offersIcon from "../../assets/images/promos.png";
import promosIcon from "../../assets/images/offers-black.png";
import dotsMenuIco from "../../assets/images/three-dots-menu-ico.svg";
import rightArrow from "../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../assets/images/Icon ionic-ios-arrow-forward-2.png";
import OfferForm from "./OfferForm";
import {
  deactivateOffer,
  deleteOffer,
  getOffers,
} from "../../core/Api/supplier";
import { getAuthAttribute } from "../../utils/helper";
import moment from "moment";
import { capitalCase } from "capital-case";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/Buttons/Primary";
import SearchInput from "../../components/Inputs/Search";


const defaultOfferState = {
  offer_id: null,
  name: "",
  coupon_code: "",
  value_type: "value",
  amount: "",
  max_amount: "",
  limit: "",
  limit_per_user: "",
  start_date: new Date(),
  expiry_date: new Date(),
  is_displayed: "0",
  is_published: "0",
  type: "offer",
  status: "Un Published",
  coupon_code: "",
  image:null,
  supplier_id: null,
  customer_id: null,
  supplier_service_id: null,
  is_continuous:'0',
  category_id:null,
};

const MyOffers = ({ match }) => {
  const { t } = useTranslation();
  const pageSize = 10;
  const supplier_id = match.params.id
    ? match.params.id
    : getAuthAttribute("supplier_id");
  const isUserAdmin = getAuthAttribute("user_type_id") === 4

  const [selectedRow, setSelectedRow] = useState({
    ...defaultOfferState,
    supplier_id,
    start_date: new Date(),
    expiry_date: new Date(),
  });
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [edit, setEdit] = useState(false);
  const [showMenuOverlay, setShowMenuOverlay] = useState(false);
  const [activeTab, setActiveTab] = useState('offer');
  const [isLoading, setLoading] = useState(false);
  const [showDisTypeModal, setShowDisTypeModal] = useState(false)
  const [selectedDiscType, setSelectedDiscType] = useState(null)
  const startPageRef = useRef(selectedPage);
  const menuOLTarget = useRef(null);
  const [offerId, setOfferId] = useState(null);

  const totalResults = data.length;
  const offset = pageSize * (selectedPage - 1);
  const filteredData = data.filter((item) =>
    `${item.name.toLowerCase()} ${item.description.toLowerCase()} ${item.coupon_code && item.coupon_code.toLowerCase()
      } `.includes(searchInput.toLowerCase())
  );
  const outputData = filteredData.slice(offset, offset + pageSize);


  useEffect(() => {
    fetchOffers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(filteredData.length / pageSize));
  }, [filteredData]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuOLTarget?.current && !menuOLTarget.current.contains(event.target)) {
        handleMenuRootClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOLTarget]);

  const fetchOffers = async () => {
    setLoading(true);
    toast.dismiss();
    try {
      const offers = await getOffers(supplier_id || 0, 1);
      if (offers.code === 200) {
        const data = offers?.result ?? [];
        setData(data);
      } else {
        throw new Error(offers?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setLoading(false);
  };

  const sortCaret = (order) => (
    <span className="caret-icon">
      <img src={order === "asc" ? caretUp : caretDown} alt="" />
    </span>
  );

  const formatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="otherWrap">
        <Image
          onClick={() => {
            setSelectedRow(row);
            setEdit(true);
          }}
          src={editIcon}
          alt="edit"
        />
        {!row.is_expired && (
          <Image
            onClick={() => handleConfirm(row.offer_id, true)}
            src={deactivateIcon}
            alt="deactivate"
          />
        )}
        <Image
          onClick={() => handleConfirm(row.offer_id, false)}
          src={deleteIcon}
          alt="delete"
        />
      </div>
    );
  };

  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    let style = (() => {
      if (row.status === "Published") {
        return { color: "#00a65a" };
      } else if (row.is_expired) {
        return { color: "#dd3333" };
      } else {
        return { color: "#ffa470" };
      }
    })();
    return (
      <div className="status-formatter" style={style}>
        {row.status}
      </div>
    );
  };

  const handleClose = () => {
    setSelectedRow(null);
    setEdit(false);
  };

  const handleConfirm = async (offer_id, isDeactivate) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        `Are you sure you want to ${isDeactivate ? "deactivate" : "delete"
        } this offer? This action cannot be undone.`
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: isDeactivate ? t("Deactivate") : t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await (isDeactivate
          ? deactivateOffer(offerId)
          : deleteOffer(offerId));
        if (result && result.code === 200) {
          await fetchOffers();
          toast.success(result.message);
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
    });
  };

  const handleSearchChange = (e) => setSearchInput(e.target.value);

  const handlePageChange = (target) => {
    let newPage = selectedPage;
    if (target === "next") {
      newPage = newPage + 1 <= pageCount ? newPage + 1 : newPage;
    } else if (target === "prev") {
      newPage = newPage - 1 > 0 ? newPage - 1 : newPage;
    } else {
      newPage = target;
    }
    setSelectedPage(newPage);
    if (pageCount > 5) {
      if (target === "next" && newPage % 5 === 1) {
        startPageRef.current += 5;
      } else if (target === "prev" && newPage % 5 === 0) {
        startPageRef.current -= 5;
      }
    }
  };

  const setMenuOLTarget = (id, offer_id) => {
    const el = document.getElementById(id);
    if (menuOLTarget.current) {
    setOfferId(null)
      menuOLTarget.current = null;
    } else {
    setOfferId(offer_id)
      menuOLTarget.current = el;
    }
    setShowMenuOverlay(!showMenuOverlay)
  }

  const handleMenuRootClose = () => {
    setShowMenuOverlay(!showMenuOverlay)
    menuOLTarget.current = null;
  }

  const handleSetSelectedRow = (row) => {
    setSelectedRow({
      ...row,
      is_published: row.status === "Published" && "1" || "0",
      // supplier_service_id: row.service.map(s=>s.supplier_service_id)
    })
  }

  const handleExportCouponCodes = (codes = []) =>{
    if (codes?.length) {
      let csv =
        "Code \r\n";
      const rows = [];
      for (let i = 0; i < codes?.length; i++) {
        const item = codes[i];
        rows.push(`${item}\r\n`)
      }
      rows.forEach((row) => {
        csv += row;
      });
      try {
        const generationDate = new Date().toLocaleDateString("en-US");
        const link = document.createElement("a");
        link.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
        link.target = "_blank";
        link.download = `coupon_codes${generationDate}.csv`;
        link.click();
      } catch (error) {
        toast.error('Something went wrong.Please try again!')
      }
    } else {
      toast.warning(t('No record found'))
    }
  }

  return (
    <Container fluid className="offers">
      <div className="table-wrapper">
        {/* offers and promotions top bar starts */}
        <div className="search-wrap">
          <div className="promo-offer-tabs">
            <div className={`offers-cont ${activeTab === 'offer' && 'active'}`} onClick={() => setActiveTab('offer')}>
              <p>{t('Offers')}</p>
            </div>
            <div className={`promo-cont ${activeTab === 'promotion' && 'active'}`} onClick={() => setActiveTab('promotion')}>
              <p>{t('Promos')}</p>
            </div>
          </div>
          <div className="cta-col">
            <PrimaryButton
              onClick={() => {
                // setSelectedRow({ ...defaultOfferState });
                // setEdit(false);
                setShowDisTypeModal(true)
              }}
              classNames="add-new-offer"
              text={t("Add New")}
            />
          </div>
        </div>
        {/* offers and promotions top bar ends */}

        {/* offers and promotions list container start */}
        {isLoading && (
          <div className="spinner-wrap">
            <Spinner animation="border" />
            <span>{t("Please wait, we're loading all offers.")}</span>
          </div>
        ) || null}
        {(!isLoading && data.length) && (
          <div className="offer-promo-list-cont">
            {data.filter(row => row.type === activeTab).map(row => (
              <div className="offer-promo-list-item">
                <div className="left-sec w-100" onClick={() => {
                  setSelectedDiscType(row.type);
                  handleSetSelectedRow(row)
                }}>
                  <div className="heading">
                    <p>{row.name}</p>
                    <div className="row">
                    <p>{row?.status || ''}</p>
                    {row?.coupon_codes?.length && <p className="ml-2 text-primary" onClick={()=>handleExportCouponCodes(row?.coupon_codes ?? [])}>{t('Export')}</p> || null}
                      </div>
                  </div>
                  <div className="content">
                    <div className="value-cont mr-3">
                      <p className="text">{t('Value')}:&nbsp;&nbsp;</p>
                      <p className="value">{`${row.amount} ${row.value_type === 'percentage' && '%' || 'SAR'}`}</p>
                    </div>
                    <div className="value-cont">
                      <p className="text">{t('Validity')}:&nbsp;&nbsp;</p>
                      <p className={`value ${row?.is_expired === 1 && 'text-danger'}`}>{`${moment(row.start_date).format('ddd, DD MMM YYYY')} - ${moment(row.expiry_date).format('ddd, DD MMM YYYY')}`}</p>
                    </div>
                  </div>
                </div>
                <div className="right-sec">
                  <>
                    <img src={dotsMenuIco} alt="dotsMenuIco" width="20px" id={`dotsMenuIco_${row.offer_id}`} onClick={() => setMenuOLTarget(`dotsMenuIco_${row.offer_id}`, row.offer_id)} />
                    <Overlay
                      trigger="click"
                      placement="bottom"
                      rootClose={true}
                      rootCloseEvent="click"
                      show={Boolean(menuOLTarget.current)}
                      target={menuOLTarget.current}
                      bsPrefix="offer-promo-menu-overlay" id="offer-promo-menu-overlay"
                      onHide={() => handleMenuRootClose(`dotsMenuIco_${row.offer_id}`)}
                    >
                      <div className="offer-promo-menu-overlay">
                        <div className="offer-promo-menu-cont">
                          {/* <p className="edit-cont">Edit</p> */}
                          <p className="delete-cont" onClick={
                            () => {
                              handleConfirm(row.offer_id, false);
                              handleMenuRootClose();
                            }}>{t('Delete')}</p>
                        </div>
                      </div>
                    </Overlay ></>
                </div>
              </div>
            ))}
          </div>
        ) || null}
        {(!isLoading && !data.filter(row => row.type === activeTab)?.length) && (
          <div className="no-data-container">
            <h4>
              {t("No data available")}
            </h4>
          </div>
        ) || null}
        {/* offers and promotions list container ends */}


        {/* Note container start */}
        <div className="note-cont">
          <div className="note-content">
            <p className="header-cont">{t('Note')}</p>
            <p className="text-cont"> 1. {t("Offers will be applied to services automatically")}</p>
            <p className="text-cont"> 2. {t("Promotions will only be applied if the coupon code is used")}</p>
          </div>

        </div>
        {/* Note container ends */}

      </div>

      {/* select discount type modal startrs */}
      <Modal
        size="lg"
        className="select-discount-type-modal"
        // dialogClassName="modal-50w"
        show={showDisTypeModal}
        onHide={() => {
          setShowDisTypeModal(false);
          setSelectedDiscType(null);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Select discount type')}</Modal.Title>
        </Modal.Header>
        <div className="select-discount-type-container">
          <div className="select-discount-type-cont">
            <div className="offers-cont" onClick={() => {
              setSelectedRow({
                ...selectedRow,
                start_date: new Date(),
                expiry_date: new Date(),
              })
              setSelectedDiscType('offer');
              setShowDisTypeModal(false);
            }}>
              <img className="ico-cont" src={offersIcon} alt="sic-im" />
              <div className="heading-cont"><p>{t('Offers')}</p></div>
              <div className="desc-cont">{t('Services booked by one client in a single visit')}</div>
            </div>
            <div className="promo-cont" onClick={() => {
              setSelectedRow({
                ...selectedRow,
                start_date: new Date(),
                expiry_date: new Date(),
              })
              setSelectedDiscType('promotion');
              setShowDisTypeModal(false);
            }}>
              <img className="ico-cont" src={promosIcon} alt="sic-im" />
              <div className="heading-cont"><p>{t('Promos')}</p></div>
              <div className="desc-cont">{t('Multiple services booked together in one appointment')}</div>
            </div>
          </div>
        </div>
      </Modal>
      {/* select discount type modal ends */}

      {/* Add/Edit Offers modal startrs */}
      {selectedDiscType && (<Modal
        size="lg"
        className="add-offer-modal"
        show={selectedDiscType || false}
        onHide={() => {
          setSelectedDiscType(null);
          setSelectedRow({
            ...defaultOfferState,
            supplier_id
          });
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedRow.offer_id && t('Edit Offer') || t('Add Offer')}</Modal.Title>
        </Modal.Header>
        <div className="add-offer-content">
          <div className="add-offer-cont">
            <OfferForm
              fetchOffers={fetchOffers}
              handleClose={() => {
                setSelectedDiscType(null);
                setSelectedRow({
                  ...defaultOfferState,
                  supplier_id
                })
              }}
              offerDetails={selectedRow}
              supplier_id={selectedRow?.supplier_id}
              discountType={selectedDiscType}
            />
          </div>
        </div>
      </Modal>) || null}
      {/*  Add/Edit Offers modal ends */}

    </Container>
  );
};

export default MyOffers;
