import React from "react";
import Hoc from "../Hoc";
import Form from "./Form";
import "./Styles.scss";

const Delivery = (props) => {
  const { match } = props;
  return (
    <Hoc activeMenu={match.params.id ? "partner" : "delivery"}>
      <Form {...props} />
    </Hoc>
  );
};

export default Delivery;
