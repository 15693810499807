import React from "react";
import Hoc from "../Hoc";
import DisputedBookingTable from "./DisputedBooking";

const DisputedBookings = (props) => {
  return (
    <Hoc activeMenu="disputes">
      <DisputedBookingTable {...props} />
    </Hoc>
  );
};

export default DisputedBookings;
