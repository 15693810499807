import React from "react";
import { Redirect, Route } from "react-router";
import { getAuthAttribute } from "../utils/helper";

const PublicRoute = ({ component: Component, ...rest }) => {
  const notLoggedIn = !getAuthAttribute("auth_token");
  return (
    <Route
      {...rest}
      render={(props) =>
        notLoggedIn ? (
          <Component exact {...props} />
        ) : (
          <Redirect to={"/dashboard"} />
        )
      }
    />
  );
};

export default PublicRoute;
