import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FullPageSpinner from '../../../components/FullPageSpinner';
import Hoc from '../../Hoc';
import './Styles.scss';
import CardContainer from './cardContainer.js';
import { getExpansionReport } from '../../../core/Api/Reports';
import { toast } from '@mobiscroll/react';
import * as _ from 'lodash';
import CustomDropdown from '../../../components/Dropdown';

const yearOptions = [
	{ label: '2015', value: '2015' },
	{ label: '2016', value: '2016' },
	{ label: '2017', value: '2017' },
	{ label: '2018', value: '2018' },
	{ label: '2019', value: '2019' },
	{ label: '2020', value: '2020' },
	{ label: '2021', value: '2021' },
	{ label: '2022', value: '2022' },
	{ label: '2023', value: '2023' },
];

const ExpansionReport = () => {
	const { t } = useTranslation();
	const [isLoading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [filterSchema, setFilterSchema] = useState({
		year: '2023',
	});
	const [activeCities, setActiveCities] = useState(0);
	const [inactiveCities, setInactiveCities] = useState(0);

	useEffect(() => {
		fetchBookings({ ...filterSchema });
	}, []);

	const fetchBookings = async (filters = {}) => {
		setLoading(true);
		const response = await getExpansionReport({
			year: filters?.year,
		});
		if (response.code === 200) {
			console.log('response', response?.result);
			if (!_.isEmpty(response?.result)) {
				setInactiveCities(response?.result?.potential_cities ?? 0);
				setActiveCities(Object.keys(response?.result?.partners ?? {}).length);
				const citiesData = [];
				const cities = response?.result?.partners ?? {};
				for (let name in cities) {
					const city = cities[name];
					console.log('city :', name, city);
					citiesData.push({
						cityName: name,
						activePartners: Number(city?.active ?? 0),
						inactivePartners: Number(city?.inactive ?? 0),
						confirmedTransactions: Number(city?.bookings?.confirmed ?? 0),
						completedTransactions: Number(city?.bookings?.completed ?? 0),
					});
				}
				setData([...citiesData]);
			}
		} else {
			toast.error(response && response.message);
		}
		setLoading(false);
	};

	const handleChange = (value, name) => {
		setFilterSchema({
			...filterSchema,
			[name]: value,
		});
		fetchBookings({
			...filterSchema,
			[name]: value,
		});
	};

	return (
		// <Hoc activeMenu="expansionReport">
			<div className="expansionReport">
				<div className="table-wrapper pr-3">
					{isLoading && <FullPageSpinner />}
					<Row className="filtersCont">
						<Col className="mt-3" lg={3} md={3} sm={4}>
							<CustomDropdown
								onChange={(value) => handleChange(value.value, 'year')}
								name="year"
								value={
									yearOptions?.find((r) => r.value === filterSchema?.year) ||
									null
								}
								options={yearOptions}
								label={t('Select Year')}
							/>
						</Col>
					</Row>
					<Container className="mainContent" fluid>
						<Row className="topRowCont">
							<Col lg={6} md={4} sm={12} className="">
								<div className="headingCont">
									<p>
										{t('Expansions')} {filterSchema?.year}
									</p>
								</div>
							</Col>
							<Col lg={3} md={4} sm={12} className="">
								<div className="totalCont">
									<p className="num">{activeCities} </p>
									<p className="text"> {t('Active Cities')}</p>
								</div>
							</Col>
							<Col lg={3} md={4} sm={12} className="">
								<div className="topCont">
									<div className="totalCont freelancer">
										<p className="num">{inactiveCities} </p>
										<p className="text">{t('Inactive Cities')}</p>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="cards-cont">
							<div className="cards-content">
								{data?.map((city) => (
									<CardContainer data={city} />
								))}
							</div>
						</Row>
					</Container>
				</div>
			</div>
		//  </Hoc>
	);
};

export default ExpansionReport;
