


import { useEffect, useState } from "react";
import './Styles.scss';
import rightArrow from "../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../assets/images/Icon ionic-ios-arrow-forward-2.png";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Pagination = ({
  totalCount = 0,
  viewCount = 0,
  pageNo=1,
  onChangePage,
}) => {
  const { t } = useTranslation();

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  useEffect(()=>{
    const pageCount = Math.ceil((totalCount || 0) / (pageSize || 0)) || 0;
    setNumberOfPages(pageCount);
    const pageNumbers = [];
    let pages = 5;
    if (!(pageCount > 8)) {
      pages = pageCount;
    }
    for (let i = 1; i <= pages; i++) {
      pageNumbers.push(i);
    }
    setPageNumbers(pageNumbers);
  },[totalCount, viewCount]);

  const handlePageNoChange = (pageNo = 0) =>{
    // setPageNo(pageNo);
    onChangePage(pageNo, pageSize)
  }

  const handlePageChange = (type)=>{
    let page = pageNo;
    page = type === "next" ? pageNo + 1 : pageNo - 1;
    if (page === 0) return;
    if (page > Math.ceil(totalCount / pageSize)) return;
    onChangePage(page, pageSize)
  }

  return (
    <Row className="pagination-component">
      <Col md="3 result-text">
        {t("Showing")} {pageSize * (pageNo - 1) + 1} {t("to")}&nbsp;
        {pageSize * (pageNo - 1) + viewCount} {t("of")} {totalCount}{" "}
        {t("Results")}
      </Col>
      <Col md="5" className="count">
        <div
          className="previous"
          onClick={() => handlePageChange("prev")}
        >
          <img src={leftArrow} alt="left-arrow"></img>
        </div>
        <div className="pages">
          {pageNumbers.map((page, index) => {
            return (
              <span
                key={index}
                className={` cursor-pointer ${pageNo === page && "active"}`}
                onClick={() => handlePageNoChange(page)}
              >
                {page}
              </span>
            );
          })}
          {numberOfPages > 8 && <span>...</span>}
          {numberOfPages > 8 && (
            <span
              className={` cursor-pointer ${pageNo === numberOfPages && "active"
                }`}
              onClick={() => handlePageNoChange(numberOfPages)}
            >
              {numberOfPages}
            </span>
          )}
        </div>
        <div
          className="next"
          onClick={() => handlePageChange("next")}
        >
          <img src={rightArrow} alt="right-arrow"></img>
        </div>
      </Col>
    </Row>
  )
}

export default Pagination;