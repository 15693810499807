import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRouteMatch } from "react-router-dom";
import { Container, Row, Image, Col, Table } from "react-bootstrap";
import { getInvoiceById } from "../../core/Api/customer";
import logo from "../../assets/images/logo.png";
import { formatDate, formatAMPM } from "../../utils/helper";
// import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

import "./Styles.scss";

const Invoice = () => {
  // const { t, i18n } = useTranslation();
  const route = useRouteMatch();
  const [invoice, setInvoice] = useState(null);
  // const [data, setData] = useState(null);

  useEffect(() => {
    const ele = document.getElementsByTagName("html");
    if (ele && ele[0]) {
      const html = ele[0];
      // html.style.width = `100%`;
      html.style.minWidth = `960px`;
      html.style.overflow = "auto";
    }
    return () => {
      if (ele && ele[0]) {
        const html = ele[0];
        html.style.minWidth = "unset";
      }
    };
  }, []);

  useEffect(() => {
    if (route && route.params && route.params.id) {
      fetchInvoiceData(route.params.id);
    }
  }, [route]);

  async function fetchInvoiceData(id) {
    const result = await getInvoiceById(id);
    if (result && result.code === 200) {
      setInvoice(result.result);
      // const address = await getSupplierAddress(result.result.supplier_id);
      // if (address && address.code === 200) {
      //   setData(address.result);
      // } else {
      //   toast.error(address && address.message);
      // }
    } else {
      toast.error(result && result.message);
    }
  }

  const qrData = `${
    "Company Name: " + invoice?.supplier?.name ?? "NA"
  }\nVAT No: 1223434\nVAT Amount: ${
    invoice?.summary?.vat_amount
      ? "SAR " + invoice?.summary?.vat_amount.toFixed(2)
      : "NA"
  }\nTotal Amount: ${
    invoice?.summary?.grand_total
      ? "SAR " + invoice?.summary?.grand_total
      : "NA"
  }`;
  return (
    <React.Fragment>
      <Container fluid="lg" className="view-invoice">
        <div className="h-line" />
        <Row className="text-start">
          <Col>
            <div>
              <Image src={logo} alt="logo" />
            </div>
          </Col>
          <Col className="center">
            <h4>فاتورة ضريبية مبسطة</h4>
            <h4>Simplified Tax Invoice</h4>
          </Col>

          <Col className="add-data">
            <div className="name">BOON Information and Tech Co.</div>
            {/* <div>شركة بون لتقنية نظم المعلومات</div> */}
            <div>٣٣٦١ أنس بن مالك- حي الملقا </div>
            <div>قطعة رقم ١٤١</div>
            <div>الرياض ١٣٥٢١- ٨٣٩٥</div>
            <div>المملكة العربية السعودية</div>
            <div>3361 Anas Bin Malek - Al Malqa Dist.</div>
            <div>Unit No 141</div>
            <div>Riyadh 13521 - 8395</div>
            <div>Kingdom of Saudi Arabia</div>
            {invoice && invoice.vat_reg_number && (
              <div>
                VAT #
                {invoice && invoice.vat_reg_number && invoice.vat_reg_number}
              </div>
            )}
          </Col>
        </Row>
        <div className="h-line" />

        <Row className="mt-4">
          <Col className="number-date">
            <div className="number">Invoice Number</div>
            <div className="value">{invoice && invoice.reference_number}</div>
          </Col>
          <Col className="number-date">
            <div>{invoice && invoice.reference_number}</div>
            <div className="number">رقم الفاتورة</div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="number-date">
            <div className="date">Invoice Issue Date</div>
            <div className="date-value">
              {invoice &&
                invoice.date &&
                formatDate("DD MMM, YYYY", invoice.date) +
                  " " +
                  formatAMPM(new Date(invoice.date))}
            </div>
          </Col>
          <Col className="number-date">
            <div>
              {invoice &&
                invoice.date &&
                formatDate("DD MMM, YYYY", invoice.date) +
                  " " +
                  formatAMPM(new Date(invoice.date))}
            </div>
            <div className="date">تاريخ إصدار الفاتورة</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <thead>
                <tr>
                  <th>
                    Nature of goods or services <br />
                    (طبيعة السلع أو الخدمات)
                  </th>
                  <th>
                    Unit Price
                    <br />
                    (سعر الوحدة)
                  </th>
                  <th>
                    Quantity
                    <br />
                    (كمية)
                  </th>
                  <th>
                    Item Subtotal (Including VAT) <br />
                    المجموع الفرعي للبند (شاملاً ضريبة القيمة المضافة)
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoice &&
                  invoice.services &&
                  Boolean(invoice.services.length) &&
                  invoice.services.map((value, index) => (
                    <tr>
                      <td>
                        {value.service_name}-{value.arb_service_name}
                      </td>
                      <td>{value.original_amount} SAR</td>
                      <td>1</td>
                      <td>{value.amount} SAR</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div className="h-line" />
          </Col>
        </Row>
        <Row className="table-2">
          <Col>
            <Table>
              <tbody>
                <tr>
                  <td>Delivery Fees</td>
                  {/* <td>{invoice?.delivery_fee ?? 0} SAR</td> */}
                  <td></td>
                  <td>{invoice?.delivery_fee ?? 0} SAR</td>
                </tr>
                <tr>
                  <td>Total Taxable Amount (Excluding VAT)</td>
                  <td>
                    إجمالي المبلغ الخاضع للضريبة (باستثناء ضريبة القيمة المضافة)
                  </td>
                  <td> {invoice && invoice.summary.sub_total} SAR</td>
                </tr>
                <tr>
                  <td>Total VAT</td>
                  <td>مجموع ضريبة القيمة المضافة</td>
                  <td>
                    {invoice && invoice.summary.vat_amount.toFixed(2)} SAR
                  </td>
                </tr>
                <tr>
                  <td>Total Amount Due</td>
                  <td> إجمالي المبلغ المستحق</td>
                  <td>{invoice && invoice.summary.grand_total} SAR</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <div className="h-line" />

        <Row className="mt-4 mb-4">
          <Col className="number-date">
            <div className="number">Payment Mode</div>
            <div className="value">{invoice && invoice.payment_mode}</div>
          </Col>
          {/* <Col className="number-date">
              <div>{invoice && invoice.payment_mode}</div>
              <div className="number">Payment Mode</div>
            </Col> */}
        </Row>

        {/* <Row className="payment-mode mb-3">
            <Col>
              <div>Payment Mode - </div>
              <div>{invoice && invoice.payment_mode}</div>
            </Col>
          </Row> */}
        <div className="h-line" />

        <Row className="justify-content-center">
          <QRCode value={qrData} size={256} />
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Invoice;
