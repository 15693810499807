import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../components/Buttons/Primary';
import DateInput from '../../../components/DateInput';
import CustomDropdown from '../../../components/Dropdown';
import FullPageSpinner from '../../../components/FullPageSpinner';
import Hoc from '../../Hoc';
import './Styles.scss';
import {
	getCumulativeCustomersReport,
	getCustomersSummaryReport,
	getPartnersSummaryReport,
} from '../../../core/Api/Reports';
import MultiTypeGraph from './multiTypeGraph';
import BarGraph from './barGraph';
import { toast } from 'react-toastify';
import * as _ from 'lodash';

const yearOptions = [
	{ label: '2015', value: '2015' },
	{ label: '2016', value: '2016' },
	{ label: '2017', value: '2017' },
	{ label: '2018', value: '2018' },
	{ label: '2019', value: '2019' },
	{ label: '2020', value: '2020' },
	{ label: '2021', value: '2021' },
	{ label: '2022', value: '2022' },
	{ label: '2023', value: '2023' },
];

const quarterOptions = [
	{ label: 'Q1', value: 'Q1' },
	{ label: 'Q2', value: 'Q2' },
	{ label: 'Q3', value: 'Q3' },
	{ label: 'Q4', value: 'Q4' },
];

const CustomersSummaryReport = () => {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [filterSchema, setFilterSchema] = useState({
		year: '2023',
		quarter: 'Q1',
	});
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		fetchBookings(filterSchema);
	}, []);

	const fetchBookings = async (filters) => {
		setLoading(true);
		const response = await getCustomersSummaryReport({
			year: filters?.year,
			quarter: filters?.quarter,
		});
		if (response.code === 200) {
			const resData = {};
			if (!_.isEmpty(response?.result)) {
				resData.activeCustomers = _.sumBy(
					response?.result?.customers_monthly ?? [],
					(r) => Number(r?.active_customers)
				);
				resData.inactiveCustomers =
					_.sumBy(response?.result?.customers_monthly ?? [], (r) =>
						Number(r?.monthly_customers)
					) -
					_.sumBy(response?.result?.customers_monthly ?? [], (r) =>
						Number(r?.active_customers)
					);
			}
			setData({
				...resData,
				registrations: {
					monthlyCustomersData: {
						labels: _.map(
							response?.result?.customers_monthly ?? [],
							(r) => r.month_name
						),
						customers: _.map(response?.result?.customers_monthly ?? [], (r) =>
							Number(r.monthly_customers)
						),
						cumulatedCustomers: _.map(
							response?.result?.customers_monthly ?? [],
							(r) => Number(r.total_customers)
						),
					},
					weeklyCustomersData: {
						labels: _.map(
							response?.result?.customers_weekly ?? [],
							(r) => r.week_name
						),
						customers: _.map(response?.result?.customers_weekly ?? [], (r) =>
							Number(r.weekly_customers)
						),
						cumulatedCustomers: _.map(
							response?.result?.customers_weekly ?? [],
							(r) => Number(r.total_customers)
						),
					},
				},
			});
		} else {
			toast.error(response && response.message);
		}
		setLoading(false);
	};

	const handleChange = (value, name) => {
		setFilterSchema({
			...filterSchema,
			[name]: value,
		});
	};
	const handleSearch = async () => {
		fetchBookings({ ...filterSchema });
	};

	return (
		// <Hoc activeMenu="customersSummaryReport">
			<div className="customersSummaryReport">
				<div className="table-wrapper pr-3">
					{isLoading && <FullPageSpinner />}
					<Row className="filtersCont">
						<Col className="mt-3" lg={3} md={3} sm={4}>
							<CustomDropdown
								onChange={(value) => handleChange(value.value, 'year')}
								name="year"
								value={
									yearOptions?.find((r) => r.value === filterSchema?.year) ||
									null
								}
								options={yearOptions}
								label={t('Select Year')}
							/>
						</Col>
						<Col className="mt-3" lg={3} md={3} sm={4}>
							<CustomDropdown
								onChange={(value) => handleChange(value.value, 'quarter')}
								name="quarter"
								value={
									quarterOptions?.find(
										(r) => r.value === filterSchema?.quarter
									) || null
								}
								options={quarterOptions}
								label={t('Select Quarter')}
							/>
						</Col>
						<Col className="mt-3" lg={3} md={3} sm={4}>
							<PrimaryButton
								disabled={isLoading}
								onClick={handleSearch}
								text={t('Search')}
							/>
						</Col>
					</Row>
					<Container className="mainContent" fluid>
						<Row className="topRowCont">
							<Col lg={4} md={4} sm={12} className="">
								<div className="headingCont">
									<p>{t('User Status')}</p>
								</div>
							</Col>
							<Col lg={4} md={4} sm={12} className="">
								<div className="totalCont">
									<p className="num">{data?.activeCustomers} </p>
									<p className="text"> {t('Active Customers')}</p>
								</div>
							</Col>
							<Col lg={4} md={4} sm={12} className="">
								<div className="topCont">
									<div className="totalCont freelancer">
										<p className="num">{data?.inactiveCustomers} </p>
										<p className="text">{t('Inactive Customers')}</p>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="graphsCont">
							<Col lg={8} md={8} sm={12}>
								<div className="multiGraphContent">
									<MultiTypeGraph
										graphsData={data?.registrations?.monthlyCustomersData ?? {}}
										barGraphLabel={t('Monthly New Customers')}
										lineGraphLabel={t('Monthly Cumulated  Customers')}
										title={t('Monthly Customers')}

									/>
								</div>
							</Col>
							<Col lg={4} md={4} sm={12}>
								<div className="barGraphContent">
									<div className="bottomSecContent">
										<p>{t('Users')}</p>
										<div className="barGraphCont">
											<BarGraph graphsData={data} />
										</div>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="graphsCont">
							<Col lg={12} md={12} sm={12}>
								<div className="multiGraphContent">
									<MultiTypeGraph
										graphsData={data?.registrations?.weeklyCustomersData ?? {}}
										barGraphLabel={t('Weekly New Customers')}
										lineGraphLabel={t('Weekly Cumulated  Customers')}
										title={t('Weekly Customers')}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		// </Hoc>
	);
};

export default CustomersSummaryReport;
