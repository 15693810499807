import React from "react";
import Hoc from "../../Hoc";
import UserTypesTable from "./UserTypesTable";
import "./Styles.scss";

const Permissions = ({ history }) => {
  return (
    <Hoc activeMenu="permissions">
      <UserTypesTable history={history} />
    </Hoc>
  );
};

export default Permissions;
