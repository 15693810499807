import React, { useState, useEffect, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Modal, Image, Row, Col, Spinner, Container } from "react-bootstrap";
import PrimaryButton from "../../../components/Buttons/Primary";
import editIcon from "../../../assets/images/Icon material-mode-edit.png";
import caretDown from "../../../assets/images/caret-down.svg";
import caretUp from "../../../assets/images/caret-up.svg";
import lock from "../../../assets/images/lock.svg";
import deleteIcon from "../../../assets/images/trash.svg";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import SearchInput from "../../../components/Inputs/Search";
import rightArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-2.png";
import UserTypeform from "./UserTypeForm";
import { getUserTypes, deleteUserType } from "../../../core/Api/Settings";

const defaultState = {
  user_type_id: "",
  type: "",
  description: "",
};

const Usertypestable = ({ history }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const pageSize = 10;
  const [pageCount, setPageCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const filteredData = data.filter((item) =>
    `${item.type.toLowerCase()}`.includes(searchInput.toLowerCase())
  );
  const offset = pageSize * (selectedPage - 1);
  const startPageRef = useRef(selectedPage);
  const [isLoading, setLoading] = useState(false);
  const outputData = filteredData.slice(offset, offset + pageSize);

  const handleSearchChange = (e) => setSearchInput(e.target.value);

  const handlePageChange = (target) => {
    let newPage = selectedPage;
    if (target === "next") {
      newPage = newPage + 1 <= pageCount ? newPage + 1 : newPage;
    } else if (target === "prev") {
      newPage = newPage - 1 > 0 ? newPage - 1 : newPage;
    } else {
      newPage = target;
    }
    setSelectedPage(newPage);
    if (pageCount > 5) {
      if (target === "next" && newPage % 5 === 1) {
        startPageRef.current += 5;
      } else if (target === "prev" && newPage % 5 === 0) {
        startPageRef.current -= 5;
      }
    }
  };

  useEffect(() => {
    fetchUserTypes();
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(filteredData.length / pageSize));
  }, [filteredData]);

  const fetchUserTypes = async () => {
    setLoading(true);
    toast.dismiss();
    try {
      const result = await getUserTypes();
      if (result.code === 200) {
        setData(result.result);
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setLoading(false);
  };

  const openPermission = (row) => {
    if (row.user_type_id) {
      const pathname = `/permissions/${row.user_type_id ?? 0}?type=${
        row.type?.toLowerCase() || ""
      }`;
      history.push(pathname);
    }
  };

  const handleConfirm = async (user_type_id) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        `Are you sure you want to delete this user type? This action cannot be undone.`
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await deleteUserType(user_type_id);
        if (result && result.code === 200) {
          await fetchUserTypes();
          toast.success(result.message);
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
    });
  };

  const sortCaret = (order) => (
    <span className="caret-icon">
      <img src={order === "asc" ? caretUp : caretDown} alt="" />
    </span>
  );

  const formatter = (_, row) => {
    return (
      <div className="otherWrap">
        <Image
          className="lock"
          onClick={() => openPermission(row)}
          src={lock}
          alt="lock"
        />
        <Image
          className="edit"
          onClick={() => setSelectedUser(row)}
          src={editIcon}
          alt="edit"
        />
        <Image
          onClick={() => handleConfirm(+row.user_type_id)}
          src={deleteIcon}
          alt="delete"
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "user_type_id",
      text: "#",
      sort: true,
      sortCaret,
    },
    {
      dataField: "type",
      text: "User Type",
      sort: true,
      sortCaret,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      sortCaret,
    },
    {
      dataField: "permissions",
      text: "Permissions",
      sort: true,
      sortCaret,
    },
    {
      dataField: "other",
      text: "",
      sort: true,
      formatter: formatter,
    },
  ];

  const handleClose = () => setSelectedUser(null);

  return (
    <Container fluid className="user-types-table">
      <div className="table-wrapper">
        <Row className="mt-0">
          <Col md="auto title">User Type</Col>
        </Row>
        <Row className="search-wrap">
          <Col xl="3" lg="5" md="6" sm="12">
            <SearchInput
              classNames="pt-0"
              onChange={handleSearchChange}
              placeholder={t("Search")}
            />
          </Col>

          <Col xl="6" lg="6" md="5" sm="12" className="cta-col">
            <PrimaryButton
              onClick={() => setSelectedUser({ ...defaultState })}
              text={t("Add New")}
            />
          </Col>
        </Row>
        {outputData.length ? (
          <BootstrapTable
            keyField={columns[0]}
            data={outputData}
            columns={columns}
          />
        ) : isLoading ? (
          <div className="spinner-wrap">
            <Spinner animation="border" />
            <span>{t("Please wait, we're loading user type")}</span>
          </div>
        ) : (
          <div className="no-data-container">
            <h4>
              {searchInput
                ? t("No matching user type found")
                : t("No user type Available")}
            </h4>
          </div>
        )}
      </div>
      {!isLoading && Boolean(outputData.length) && (
        <Row className="pagination">
          <Col md="3 result-text">
            {t("Showing")}{" "}
            {filteredData.length ? offset + 1 : filteredData.length} {t("to")}
            &nbsp;
            {offset + outputData.length} {t("of")} {filteredData.length}{" "}
            {t("Results")}
          </Col>
          <Col md="5" className="count">
            <div className="previous" onClick={() => handlePageChange("prev")}>
              <img src={leftArrow} alt="left-arrow"></img>
            </div>
            <div className="pages">
              {Array.from({ length: pageCount > 5 ? 5 : pageCount }).map(
                (_, index) => {
                  const pageNumber = startPageRef.current + index;
                  return (
                    <span
                      key={index}
                      className={` cursor-pointer ${
                        selectedPage === pageNumber && "active"
                      }`}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </span>
                  );
                }
              )}
            </div>
            <div className="next" onClick={() => handlePageChange("next")}>
              <img src={rightArrow} alt="right-arrow"></img>
            </div>
          </Col>
        </Row>
      )}
      <Modal show={selectedUser} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Manage User Type</Modal.Title>
        </Modal.Header>
        <UserTypeform
          fetchUserTypes={fetchUserTypes}
          selectedUserType={selectedUser}
          handleClose={handleClose}
        />
      </Modal>
    </Container>
  );
};

export default Usertypestable;
