import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Image, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useRouteMatch } from "react-router-dom";
import PrimaryButton from "../../../components/Buttons/Primary";
import FullPageSpinner from "../../../components/FullPageSpinner";
import CustomTableComponent from "../../../components/CustomTableComponent";
import Pagination from "../../../components/Pagination";
import { activateVoucher, deactivateVoucher, deleteVoucher, getVouchers } from "../../../core/Api/giftvouchers";
import deactivateIcon from "../../../assets/images/eye-slash-fill.svg";
import activateIcon from "../../../assets/images/eye-fill.svg";
import deleteIcon from "../../../assets/images/trash.svg";
import './Styles.scss'
import VouchersForm from "./vouchersForm";
import Icons from 'images-col';
import VouchersPurchaseForm from "../voucher-purchase-form";

const Vouchers = ({
  sellerDetails
}) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const deleteClickRef = useRef(null);
  const offset = 0;
  const pageSize = 10;

  const [pageNo, setPageNo] = useState(1);
  const [companies, setSellers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [voucherId, setVoucherId] = useState(null);
  const [openVoucherDesign, setOpenVoucherDesign] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);


  useEffect(() => {
    loadData(pageSize, offset,)
  }, []);

  const loadData = async (per_page = 10, offset = 0, search_phrase = '') => {
    setIsLoading(true);
    const result = await getVouchers({
      per_page,
      offset,
      search_phrase,
      seller_id: match?.params?.id,
    });
    if (result && result.code === 200) {
      setSellers(result?.result?.result_data || []);
      setTotalCount(result?.result?.total_records || 0);
    } else {
      toast.error(result && result.message);
    }
    setIsLoading(false);
  };

  const handleToggleActive = (id, status) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        `Are you sure you want to ${status === "2" && 'activate' || 'block'} this?`
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Ok"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        let result = {};
        if (status === "2") {
          result = await activateVoucher({
            voucher_id: id,
          })
        } else {
          result = await deactivateVoucher({
            voucher_id: id
          })
        }
        if (result && result.code === 200) {
          Swal.fire(`${status === "2" && 'Activated' || 'Blocked'} Successfully`, "", "success");
          loadData(pageSize, offset)
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
      deleteClickRef.current = false;
    });
  }

  const handlePageChange = (pageNo, pageSize) => {
    setPageNo(pageNo);
    loadData(pageSize, (pageNo - 1) * pageSize)
  };

  const onRowClick = (row, index) => {
    if (deleteClickRef.current) return
    setVoucherId(row?.voucher_id);
    setOpen(true)
  }

  const handleConfirm = async (voucher_id) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        `Are you sure you want to delete this? This action cannot be undone.`
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        const result = await deleteVoucher({
          voucher_id,
        });
        setIsLoading(false)
        if (result && result.code === 200) {
          await loadData(10, 0, '');
          setPageNo(1)
          toast.success(result.message);
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
      deleteClickRef.current = null;
    });
  };

  const statusColFormatter = (row, key) => {
    console.log('row :', row);
    return <div>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2">{t('Voucher Purchases')}</Tooltip>}
      >
        <Image
          className="delete mr-3"
          onClick={() => {
            deleteClickRef.current = true;
            setVoucherId(row.voucher_id)
            setOpenVoucherDesign(true);
            setSelectedVoucher(row)
          }}
          src={Icons.icon_add}
          alt="delete"
        />
      </OverlayTrigger>

      {row.status_id === "1" && (
        <Image
          className="delete"
          src={deactivateIcon}
          alt="delete"
          onClick={() => {
            deleteClickRef.current = true;
            handleToggleActive(row.voucher_id, row.status_id);
          }}
        />
      ) || (
          <Image
            className="delete"
            src={activateIcon}
            alt="delete"
            onClick={() => {
              deleteClickRef.current = true;
              handleToggleActive(row.voucher_id, row.status_id);
            }}
          />
        )}
      <Image
        className="delete ml-2"
        onClick={() => {
          deleteClickRef.current = true;
          handleConfirm(row.voucher_id);
        }}
        src={deleteIcon}
        alt="delete"
      />
    </div>
  }

  const columns = [
    {
      name: t("Name"),
      key: 'voucher_name',
      formatter: null,
    },
    // {
    //   name: t("Voucher Desc"),
    //   key: 'voucher_desc',
    // },
    {
      name: t("Quantity"),
      key: 'qoh',
    },
    {
      name: t("Price"),
      key: 'price',
      formatter: null,
    },
    {
      name: t("Dis. Price"),
      key: 'discounted_price',
      formatter: null,
    },
    {
      name: t("Actions"),
      key: 'application_type',
      formatter: statusColFormatter,
    },
  ]


  return (
    <React.Fragment>
      <Container fluid className="vouchers">
        <Row className="heading-row m-0">
          <div className="heading-content">
          <div xl="3" lg="4" md="6" sm="12">
            <div className="d-flex align-items-center h-100">
              <p className="seller-name"><b>{sellerDetails?.seller_name &&  `${sellerDetails?.seller_name?.toUpperCase()}-Vouchers` || ''}</b></p>
            </div>
          </div>
          <div md="auto" className="cta-col">
            <PrimaryButton text={t("Add new voucher")}
              onClick={() => {
                setOpen(true);
                setVoucherId(null);
              }}
            />
          </div>
          </div>
        </Row>
        <Row>
          <Col>
            {isLoading && (
              <div className="spinner-wrap">
                <FullPageSpinner animation="border" />
                <span>
                  {t("Please wait, we're loading companies data")}
                </span>
              </div>)
            }
            {!isLoading && totalCount === 0 && (
              <div className="no-data-text text-center mt-2">
                {t("No data available")}
              </div>
            ) || null}
          </Col>
        </Row>
        <Row className="mt-3">
          {totalCount > 0 &&
            (<CustomTableComponent
              responsive={true}
              hover={true}
              showSequence={true}
              pageSize={pageSize}
              pageNo={pageNo}
              columns={columns || []}
              data={companies || []}
              onRowClick={onRowClick}
            />) || null}
        </Row>
        <Row>
          <Pagination
            totalCount={totalCount || 0}
            viewCount={companies?.length || 0}
            pageNo={pageNo}
            onChangePage={handlePageChange}
          />
        </Row>

        {open && (
          <Modal size="xl" show={open} onHide={setOpen}>
            <Modal.Header closeButton>
              <Modal.Title>{t('Voucher')}</Modal.Title>
            </Modal.Header>
            <VouchersForm
              voucherId={voucherId}
              sellerId={match?.params?.id || null}
              onClose={() => {
                setOpen(false);
                loadData(10, 0)
              }}
            />
          </Modal>
        ) || null}

        {openVoucherDesign && (
          <Modal size="lg" show={openVoucherDesign} onHide={() => {
            setOpenVoucherDesign(false);
            deleteClickRef.current = null;
          }}>
            <Modal.Header closeButton>
              <Modal.Title>{`${selectedVoucher?.seller_name?.toUpperCase()} - ${selectedVoucher?.qoh}`}</Modal.Title>
            </Modal.Header>
            <VouchersPurchaseForm
              voucherId={voucherId}
              sellerId={match?.params?.id || null}
              onClose={() => {
                setOpenVoucherDesign(false);
                loadData(10, 0)
                deleteClickRef.current = null;
                setSelectedVoucher(null)
              }}
            />
          </Modal>
        ) || null}

      </Container>
    </React.Fragment>
  );
};
export default Vouchers;
