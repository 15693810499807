import React, { useRef, useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";

import SimpleReactValidator from "simple-react-validator";
import UserInput from "../../../components/Inputs";
import { saveUserType } from "../../../core/Api/Settings";
import { toast } from "react-toastify";

const UserTypeform = (props) => {
  const { fetchUserTypes, handleClose } = props;

  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: this })
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState(() => ({
    ...props.selectedUserType,
  }));
  const [, forceUpdate] = useState();

  const handleSave = async () => {
    setIsLoading(true);
    try {
      if (simpleValidator.current.allValid()) {
        const data = {
          user_type_id: selectedUserType.user_type_id ?? 0,
          user_type: selectedUserType.type,
          description: selectedUserType.description,
        };
        const response = await saveUserType(data);
        if (response?.code === 200) {
          toast.success(response.message);
          await fetchUserTypes();
          handleClose();
        } else {
          throw new Error(response?.message ?? "Something went wrong");
        }
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setIsLoading(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setSelectedUserType({
      ...selectedUserType,
      [name]: value,
    });
  };

  return (
    <React.Fragment>
      <Modal.Body>
        <Row>
          <Col md="12" className="mt-4">
            <UserInput
              required
              label="User Type"
              name="type"
              placeholder="User Type"
              value={selectedUserType.type}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "user_type",
              selectedUserType.type,
              "required"
            )}
          </Col>
          <Col md="12" className="mt-4">
            <UserInput
              label="Description"
              name="description"
              placeholder="Description"
              value={selectedUserType.description}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="footer">
        <Button disabled={isLoading} onClick={handleSave} className="save-btn">
          Save changes
        </Button>
        <Button onClick={handleClose} variant="white">
          Close
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
};

export default UserTypeform;
