import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as _ from 'lodash';
import SearchableDropdown from "../../../components/Dropdown/SearchableDropdown";
import { assignCustomerGroups, getGroupByCustomerId, getGroups } from "../../../core/Api/groups";
import PrimaryButton from "../../../components/Buttons/Primary";
import FullPageSpinner from "../../../components/FullPageSpinner";

const CustomerGroups = ({ customerId }) => {
  const { t } = useTranslation();
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    const [res, roupsRes] = await Promise.all([
      getGroupByCustomerId(customerId),
      getGroups()
    ]);
    setIsLoading(false);
    if (res?.code === 200) {
      setSelectedGroups([
        ...res?.result?.map(r => ({
          value: r?.grp_id,
          label: r?.grp_name
        }))
      ])
    } else {
      toast.error(res?.message);
    }
    if (roupsRes?.code === 200) {
      setGroups([
        ...roupsRes?.result?.map(r => ({
          value: r?.grp_id,
          label: `${r?.grp_name} ${r?.company_name || ''}`
        }))
      ])
    } else {
      toast.error(roupsRes?.message);
    }
  }

  const handleDDChange = (value) => {
    setSelectedGroups(value || []);
  }

  const handleAssignCustomerGroups = async () => {
    setIsLoading(true);
    const data = {
      customer_id: customerId,
      group_ids: _.map(selectedGroups, g => g?.value).toString(),
    }
    const res = await assignCustomerGroups(data);
    setIsLoading(false);
    if (res?.code === 200) {
      toast.success('Groups assigned succesfully');
    } else {
      toast.success(res?.message);
    }
  }

  return (
    <div className="assign-groups">
      {isLoading && <FullPageSpinner />}
      <div className="row">
        <SearchableDropdown
          onChange={(value) =>
            handleDDChange(value)
          }
          isMulti={true}
          label={t("Customer Groups")}
          placeholder={t("Select Groups")}
          name="groups"
          options={groups}
          value={selectedGroups || []}
        />
      </div>
      <div className="row mt-5">
        <PrimaryButton
          text={t("Assign Groups")}
          onClick={handleAssignCustomerGroups}
        />
      </div>
    </div>
  )
}

export default CustomerGroups;