import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import PrimaryButton from "../../components/Buttons/Primary";
import SearchInput from "../../components/Inputs/Search";
import FullScreenPopup from "../../components/FullScreenPopup";
import AddService from "./AddService";
import "./Styles.scss";
import CatList from "./CatList";
import { getAuthAttribute } from "../../utils/helper";
import { getServices } from "../../core/Api/services";
import { useTranslation } from "react-i18next";

const MyServices = (props) => {
  const { history, match } = props;
  const { t, i18n } = useTranslation();
  const supplierId = match.params.id
    ? match.params.id
    : getAuthAttribute("supplier_id");
  const [addServicePopup, setAddServicePopup] = useState(false);
  const [services, setServices] = useState([]);
  const [editId, setEditId] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const filteredServices = searchInput.trim()
    ? services
      ?.map((item) => ({
        ...item,
        services: filterServices(item.services),
      }))
      .filter((item) => item.services.length !== 0)
    : services;

  function filterServices(services) {
    return services?.filter((value) =>
      value?.name?.toLowerCase()?.includes(searchInput.trim().toLowerCase())
    );
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getServices(supplierId);
      if (result && result.code === 200) {
        setServices(result.result);
      } else {
        toast.error(result && result.message);
      }
      setIsLoading(false);
    })();
  }, [addServicePopup, supplierId, i18n.language]);

  useEffect(() => {
    const state = history.location.state;
    if (state?.addServicePopup) {
      setAddServicePopup(true);
    } else {
      setAddServicePopup(false);
    }
  }, [history.location.state]);

  const handleServiceClick = (supplier_service_id) => {
    setEditId(supplier_service_id);
    openOverlay();
  };

  const openOverlay = () => {
    const pathname = history.location.pathname + history.location.search;
    const state = history.location.state;
    history.push(pathname, {
      ...state,
      addServicePopup: true,
    });
  };

  const closeOverlay = () => {
    props.setUpdateProfileStatus(true);
    setEditId(null);
    history.goBack();
  };
  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRemoveService = (category_id, supplier_service_id) => {
    const updatedServices = [...services];
    let selectedIndex = -1;
    updatedServices.forEach((item, index) => {
      if (selectedIndex !== -1) {
        return;
      }
      if (item.category_id === category_id) {
        selectedIndex = index;
      }
    });
    const newServices = [...updatedServices[selectedIndex].services].filter(
      (item) => item.supplier_service_id !== supplier_service_id
    );
    updatedServices[selectedIndex].services = newServices;
    setServices(updatedServices);
    props.setUpdateProfileStatus(true);
  };

  return (
    <React.Fragment>
      <Container fluid className="service">
        <Row className="top-cta-row">
          <Col xl="3" lg="4" md="6" sm="12">
            <SearchInput
              classNames="pt-0"
              value={searchInput}
              onChange={handleSearchChange}
              placeholder={t("Search")}
            />
          </Col>
          <Col xl="6" lg="6" md="5" sm="12" className="cta-group">
            <PrimaryButton text={t("Add new")} onClick={openOverlay} />
          </Col>
        </Row>
        {filteredServices.length ? (
          <Container
            style={{ marginTop: props.supplierId ? 0 : undefined }}
            className="service-list"
          >
            <Row>
              <Col>
                <ul>
                  {filteredServices.map((row) => {
                    return (
                      <CatList
                        key={row.category_id}
                        setOpen={Boolean(searchInput.trim())}
                        catName={row.category}
                        catId={row.category_id}
                        data={row.services}
                        handleRemoveService={handleRemoveService}
                        onClick={(supplier_service_id) =>
                          handleServiceClick(supplier_service_id)
                        }
                      />
                    );
                  })}
                </ul>
              </Col>
            </Row>
          </Container>
        ) : isLoading ? (
          <div className="spinner-wrap">
            <Spinner animation="border" />
            <span>{t("Please wait, we're loading services")}</span>
          </div>
        ) : (
          <div className="no-data-container">
            <h4>{t("No Services Found")}</h4>
          </div>
        )}
      </Container>
      <FullScreenPopup open={addServicePopup} onClose={closeOverlay}>
        <AddService
          edit={editId}
          onClose={closeOverlay}
          supplierId={
            match?.params?.id
              ? match?.params?.id
              : getAuthAttribute("supplier_id")
          }
        />
      </FullScreenPopup>
    </React.Fragment>
  );
};

export default MyServices;
