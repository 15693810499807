import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserInput from "../../../components/Inputs";
import SimpleReactValidator from "simple-react-validator";
import { saveCustomerSettings } from "../../../core/Api/Settings";
import { toast } from "react-toastify";

const defaultCustomerSettings = {
  app_version: "",
  ios_app_version: "",
  is_update_required: "",
  suppliers_per_page: "",
  min_price_filter_value: "",
  max_price_filter_value: "",
  min_distance_filter_value: "",
  max_distance_filter_value: "",
  google_maps_api_key: "",
  google_maps_api_image_size: "",
  google_maps_api_zoom: "",
  guest_sms_cost: "",
  max_avatars_allowed: "",
};

const CustomerSetting = (props) => {
  const { t } = useTranslation();
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: this })
  );
  const [, forceUpdate] = useState();
  const [customerData, setCustomerData] = useState(defaultCustomerSettings);

  useEffect(() => {
    if (props.customerSettings) {
      setCustomerData(props.customerSettings);
    }
  }, [props.customerSettings]);

  const handleSave = async () => {
    toast.dismiss();
    try {
      if (simpleValidator.current.allValid()) {
        const data = {
          app_version: customerData.app_version,
          ios_app_version: customerData.ios_app_version,
          is_update_required: customerData.is_update_required,
          suppliers_per_page: customerData.suppliers_per_page,
          min_price_filter_value: customerData.min_price_filter_value,
          max_price_filter_value: customerData.max_price_filter_value,
          min_distance_filter_value: customerData.min_distance_filter_value,
          max_distance_filter_value: customerData.max_price_filter_value,
          google_maps_api_key: customerData.google_maps_api_key,
          google_maps_api_image_size: customerData.google_maps_api_image_size,
          google_maps_api_zoom: customerData.google_maps_api_zoom,
          guest_sms_cost: customerData.guest_sms_cost,
          max_avatars_allowed: customerData.max_avatars_allowed,
        };
        const result = await saveCustomerSettings(data);
        if (result?.code === 200) {
          toast.success("Customer settings saved successfully");
        } else {
          throw new Error(result?.message ?? "Something went wrong");
        }
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCustomerData({
      ...customerData,
      [name]: value,
    });
  };
  return (
    <Container>
      <Card className="cards">
        <Row className="input-row">
          <Col md="6">
            <UserInput
              required
              label={t("Suppliers Displayed Per Page")}
              placeholder={t("Suppliers Displayed Per Page")}
              name="suppliers_per_page"
              value={customerData.suppliers_per_page}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "suppliers_per_page",
              customerData.suppliers_per_page,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Minimum Price Filter (%)")}
              placeholder={t("Minimum Price Filter (%)")}
              name="min_price_filter_value"
              value={customerData.min_price_filter_value}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "min_price_filter_value",
              customerData.min_price_filter_value,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Maximum Price Filter (%)")}
              placeholder={t("Maximum Price Filter (%)")}
              name="max_price_filter_value"
              value={customerData.max_price_filter_value}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "max_price_filter_value",
              customerData.max_price_filter_value,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Minimum Distance Filter")}
              placeholder={t("Minimum Distance Filter")}
              name="min_distance_filter_value"
              value={customerData.min_distance_filter_value}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "min_distance_filter_value",
              customerData.min_distance_filter_value,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Maximum Distance Filter")}
              placeholder={t("Maximum Distance Filter")}
              name="max_distance_filter_value"
              value={customerData.max_distance_filter_value}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "max_distance_filter_value",
              customerData.max_distance_filter_value,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Google Maps API Key")}
              placeholder={t("Google Maps API Key")}
              name="google_maps_api_key"
              value={customerData.google_maps_api_key}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "google_maps_api_key",
              customerData.google_maps_api_key,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Google Maps API Image Size")}
              placeholder={t("Google Maps API Image Size")}
              name="google_maps_api_image_size"
              value={customerData.google_maps_api_image_size}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "google_maps_api_image_size",
              customerData.google_maps_api_image_size,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Google Maps API Zoom")}
              placeholder={t("Google Maps API Zoom")}
              name="google_maps_api_zoom"
              value={customerData.google_maps_api_zoom}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "google_maps_api_zoom",
              customerData.google_maps_api_zoom,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Guest Booking SMS Cost")}
              placeholder={t("Guest Booking SMS Cost")}
              name="guest_sms_cost"
              value={customerData.guest_sms_cost}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "guest_sms_cost",
              customerData.guest_sms_cost,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("How Many Avatars Displayed")}
              placeholder={t("How Many Avatars Displayed")}
              name="max_avatars_allowed"
              value={customerData.max_avatars_allowed}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "max_avatars_allowed",
              customerData.max_avatars_allowed,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("App Version")}
              placeholder={t("App Version")}
              name="app_version"
              value={customerData.app_version}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "app_version",
              customerData.app_version,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("App Version(iOS)")}
              placeholder={t("App Version(iOS)")}
              name="ios_app_version"
              value={customerData.ios_app_version}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "ios_app_version",
              customerData.ios_app_version,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Is App Update Requried")}
              placeholder={t("Is App Update Requried")}
              name="is_update_required"
              value={customerData.is_update_required}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "is_update_required",
              customerData.is_update_required,
              "required"
            )}
          </Col>
        </Row>
        <Row>
          <Col className="save-btn">
            <Button onClick={handleSave}>Save</Button>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default CustomerSetting;
