import React, { useRef, useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import UserInput from "../../components/Inputs/Search";
import ReviewRepliesList from "./ReviewRepliesList";
import { useRouteMatch } from "react-router-dom";
import { getAuthAttribute } from "../../utils/helper";
import Axios from "../../core/Api/Axios";
import FullPageSpinner from "../../components/FullPageSpinner";

const ReviewReplyForm = (props) => {
	const { fetchUserTypes, handleClose } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [replyText, setReplyText] = useState('');
	const simpleValidator = useRef(
		new SimpleReactValidator({ autoForceUpdate: this })
	);
	const [, forceUpdate] = useState();
	const match = useRouteMatch();
	
	const handleReply = async () => {
		setIsLoading(true);
		try {
		  if (simpleValidator.current.allValid()) {
				const data = {
					title: "Reply by Admin",
					supplier_id: match.params.id ? match.params.id : getAuthAttribute("supplier_id"),
          review: replyText,
          parent_review_id: props?.reviewId,
					booking_id:props?.bookingInfo?.booking_id
				};
				try {
					const response = await Axios.post("/supplier/save_review", data);
					console.log('response :', response);
					if (response?.status === 200) {
						toast.success("Saved successfully.");
						handleClose();
					} else {
						throw new Error(response?.message ?? "Something went wrong!!");
					}
				} catch (er) {
					throw er;
				}
		  } else {
		    simpleValidator.current.showMessages();
		    forceUpdate(1);
		    throw new Error("Please enter valid details" ?? "Something went wrong");
		  }
		} catch (error) {
		  toast.error(error.message ?? "Something went wrong");
		}
		setIsLoading(false);
	};

	const handleChange = (event) => {
		setReplyText(event.target.value)
	};

	return (
		<React.Fragment>
			<Modal.Body style={{paddingTop: "0px"}}>
				<ReviewRepliesList reviewReplies={props.reviewReplies} />
				<Row>
					{isLoading && <FullPageSpinner/> || null }
					<Col md="12" className="mt-4">
						<strong>Add Reply</strong>
						<textarea style={{ display: "block", width: "100%", minHeight: "90px", borderRadius: "5px", padding: "5px" }}
							required
							label="Reply Text"
							name="replyText"
							placeholder="Type your reply here..."
							onChange={handleChange}
						/>
						{simpleValidator.current.message(
							"replyText",
							replyText,
							"required"
						)}
					</Col>
				</Row>
			</Modal.Body>

			<Modal.Footer className="footer">
				<Button disabled={isLoading} onClick={handleReply} className="save-btn">
					Post Reply
        </Button>
				<Button onClick={() => props?.handleClose()} variant="white">
					Close
        </Button>
			</Modal.Footer>
		</React.Fragment>
	);
};

export default ReviewReplyForm;
