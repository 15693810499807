import React, { useEffect, useRef, useState } from "react";
import UserInput from "../../../components/Inputs";
import { Row, Col, Modal, Button, Container } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getCompanies, saveCompanyAdmin } from "../../../core/Api/company";
import * as _ from 'lodash';
import FullPageSpinner from "../../../components/FullPageSpinner";
import { saveGroup } from "../../../core/Api/groups";
import CustomDropdown from "../../../components/Dropdown";

const GroupForm = ({ getGroups, handleClose, selectedAdmin }) => {
  const { t } = useTranslation();
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: this })
  );
  const [isLoading, setIsLoading] = useState(false);
  const [adminDetails, setAdminDetails] = useState(() => ({
    ...selectedAdmin,
  }));
  const [, forceUpdate] = useState();
  const [companies, setCompanies] = useState([{
    value: null, label: 'Select Comapny'
  }]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    const res = await getCompanies({ vper_page: 1000 });
    setIsLoading(false);
    if (res?.code === 200) {
      setCompanies([
        ...companies,
        ...(res?.result?.result_data || []).map(r => ({
          label: r?.company_name,
          value: r?.company_id
        }))
      ])
    }
  }

  const handleSaveAdmin = async () => {
    setIsLoading(true);
    try {
      if (simpleValidator.current.allValid()) {
        const data = {
          company_id: adminDetails.company_id || 0,
          grp_id: adminDetails.grp_id || "0",
          grp_code: adminDetails.grp_code,
          grp_name: adminDetails.grp_name,
          grp_type: adminDetails.grp_type || "",
          status_id: adminDetails.status_id || "1",
        };
        const response = await saveGroup(data);
        if (response?.code === 200) {
          toast.success(response.message);
          await getGroups();
          handleClose();
        } else {
          throw new Error(response?.message ?? "Something went wrong");
        }
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setIsLoading(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAdminDetails({
      ...adminDetails,
      [name]: value,
    });
  };

  const handleDDChange = (value) => {
    setAdminDetails({
      ...adminDetails,
      company_id: value?.value
    })
  }

  return (
    <Container>
      <Modal.Body>
        {isLoading && <FullPageSpinner /> || null}
        <Row>
          <Col md="6" className="mb-3">
            <UserInput
              label={t("Group Code")}
              name="grp_code"
              placeholder="Enter Group Code"
              value={adminDetails.grp_code}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "grp_code",
              adminDetails.grp_code,
              "required"
            )}
          </Col>
          <Col md="6">
            <UserInput
              label={t("Group Name")}
              name="grp_name"
              placeholder="Enter Group Name"
              value={adminDetails.grp_name}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "grp_name",
              adminDetails.grp_name,
              "required"
            )}
          </Col>
          <Col>
            <CustomDropdown
              onChange={(value) =>
                handleDDChange(value, "company_id")
              }
              name="company_id"
              options={companies || []}
              label={t("Company")}
              value={
                companies.find(
                  (row) => row.value == +adminDetails?.company_id
                ) || ""
              }
            />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="footer">
        <Button
          disabled={isLoading}
          onClick={handleSaveAdmin}
          variant="primary"
          className="save-btn"
        >
          {t('Save')}
        </Button>
        <Button onClick={handleClose} variant="white">
          {t('Close')}
        </Button>
      </Modal.Footer>
    </Container >
  );
};

export default GroupForm;
