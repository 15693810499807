import React, { useState, useRef, useEffect } from "react";
import { Container, Col, Button, Row } from "react-bootstrap";
import UserInput from "../../components/Inputs";
import { saveAccountDetails, getAccountDetails } from "../../core/Api/supplier";
import { getAuthAttribute } from "../../utils/helper";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";

const accountSchema = {
  supplier_account_id: 0,
  supplier_id: "",
  account_number: "",
  account_name: "",
  branch_name: "",
  iban: "",
  extra: "",
};

const AccountForm = (props) => {
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
    })
  );
  const [, forceUpdate] = useState();
  const supplierId = props.supplierId
    ? props.supplierId
    : getAuthAttribute("supplier_id");
  const [accountData, setAccountData] = useState({ ...accountSchema });

  useEffect(() => {
    (async () => {
      toast.dismiss();
      try {
        const result = await getAccountDetails(supplierId);
        if (result.code === 200) {
          setAccountData(result?.result ?? {});
        } else {
          throw new Error(result?.message ?? "Something went wrong");
        }
      } catch (error) {
        toast.error(error.message ?? "Something went wrong");
      }
    })();
  }, [supplierId]);

  const handleSave = async () => {
    toast.dismiss();
    try {
      if (simpleValidator.current.allValid()) {
        const data = {
          supplier_account_id: accountData.supplier_account_id,
          supplier_id: supplierId,
          account_number: accountData.account_number,
          account_name: accountData.account_name,
          branch_name: accountData.branch_name,
          iban: accountData.iban,
          extra: accountData.extra,
        };
        const result = await saveAccountDetails(data);
        if (result?.code === 200) {
          toast.success("Account details saved successfully");
        } else {
          throw new Error(result?.message ?? "Something went wrong");
        }
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAccountData({
      ...accountData,
      [name]: value,
    });
  };

  return (
    <Container className="bank-account-form">
      <div className="">
        <Row className="mt">
          <Col md="6">
            <UserInput
              required
              label="Accout Number"
              name="account_number"
              placeholder="Enter account number"
              value={accountData.account_number}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "account_number",
              accountData.account_number,
              "required"
            )}
          </Col>
          <Col md="6">
            <UserInput
              required
              label="Account Name"
              name="account_name"
              placeholder="Enter account name"
              value={accountData.account_name}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "account_name",
              accountData.account_name,
              "required"
            )}
          </Col>
          <Col md="6">
            <UserInput
              required
              label="Branch Name"
              name="branch_name"
              placeholder="Enter branch name"
              value={accountData.branch_name}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "",
              accountData.branch_name,
              "required"
            )}
          </Col>
          <Col md="6">
            <UserInput
              required
              label="IBAN"
              name="iban"
              placeholder="Enter IBA Number"
              value={accountData.iban}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "iban",
              accountData.iban,
              "required"
            )}
          </Col>
          <Col md="12">
            <UserInput
              label="Extra Information"
              name="extra"
              value={accountData.extra}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="save-account">
          <Col>
            <Button onClick={handleSave}>Save changes</Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default AccountForm;
