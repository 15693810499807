import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import PrimaryButton from '../../../components/Buttons/Primary';
import CustomDropdown from '../../../components/Dropdown';
import DateInput from '../../../components/DateInput';
import { Container, Row, Col } from 'react-bootstrap';
import Hoc from '../../Hoc';
import './Styles.scss';
import { useTranslation } from 'react-i18next';
import { getEmployees } from '../../../core/Api/employee';
import { getIncompleteProfilePartnersReport } from '../../../core/Api/Reports';
import moment from 'moment';
import FullPageSpinner from '../../../components/FullPageSpinner';
import CustomTableComponent from '../../../components/CustomTableComponent';
import Pagination from '../../../components/Pagination';

const IncompleteProfilePartners = () => {
	const { t } = useTranslation();
  const offset = 0;
	const [tableData, setTableData] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [filterSchema, setFilterSchema] = useState({
		supplier_type: null,
		supplier_rep: null,
		from_date: null,
		to_date: null,
	});
	const [supplierRep, setSupplierRep] = useState([
		{ label: t('Select Customer'), value: null },
	]);
	const [pageSize, setPageSize] = useState(10);
	const [pageNo, setPageNo] = useState(1);
	const [totalCount, setTotalCont] = useState(0);

	const [csvLoading, setCsvLoading] = useState(false);

	const supplierTypes = [
		{ label: t('Select Supplier Type'), value: null },
		{ label: t('Freelancer'), value: 'freelancer' },
		{ label: t('Salon'), value: 'salon' },
	];

	useEffect(() => {
		(async () => {
      loadIncompleteProfilePartners({
        per_page: pageSize,
        offset: offset,
        supplier_type: filterSchema?.supplier_type || '',
        supplier_rep: filterSchema?.supplier_rep || '',
        from_date:
          (filterSchema?.from_date &&
            moment(filterSchema?.from_date).format('YYYY-MM-DD')) ||
          '',
        to_date:
          (filterSchema?.to_date &&
            moment(filterSchema?.to_date).format('YYYY-MM-DD')) ||
          '',
      });

			const empData = await getEmployees({
        per_page: 1000,
        offset: 0,
        status_id: '1',
      });

			if (empData && empData.code === 200) {
				if (empData.result) {
					const data = [...supplierRep];
					empData?.result?.data.forEach((r) => {
						data.push({
							value: r?.emp_id,
							label: `${r?.first_name} ${r?.last_name}`,
						});
					});
					setSupplierRep(data);
				}
			} else {
				toast.error(empData && empData.message);
			}
		})();
		// eslint-disable-next-line
	}, []);

	const loadIncompleteProfilePartners = async (params = {}) => {
		setLoading(true);
		const result = await getIncompleteProfilePartnersReport({
      per_page: params?.pageSize,
      offset: params?.offset,
      supplier_type: params?.supplier_type || '',
      supplier_rep: params?.supplier_rep || '',
      from_date:         (params?.from_date &&
          moment(params?.from_date).format('YYYY-MM-DD')) ||
        '',
      to_date:         (params?.to_date &&
          moment(params?.to_date).format('YYYY-MM-DD')) ||
        '',
		});
		setLoading(false);
		if (result && result.code === 200) {
			setTableData(result?.result?.result_data || []);
			setTotalCont(result?.result?.total_count || []);
		} else {
			toast.error(result && result.message);
		}
	};

	const handleSearch = () => {
		loadIncompleteProfilePartners({
			...filterSchema,
			offset,
			per_page: pageSize,
		});
	};

	const handleChange = (value, name) => {
		setFilterSchema({
			...filterSchema,
			[name]: value,
		});
	};

	const handleDateChange = (date, name) => {
		setFilterSchema({
			...filterSchema,
			[name]: (date && new Date(date)) || null,
		});
	};

	const getStatusValue = (status) => {
		return (status === '1' && 'YES') || 'NO';
	};

	const handleCSVExport = async () => {
		setCsvLoading(true);
		const res = await getIncompleteProfilePartnersReport({
      supplier_type: filterSchema?.supplier_type || '',
      supplier_rep: filterSchema?.supplier_rep || '',
      from_date:         (filterSchema?.from_date &&
          moment(filterSchema?.from_date).format('YYYY-MM-DD')) ||
        '',
      to_date:         (filterSchema?.to_date &&
          moment(filterSchema?.to_date).format('YYYY-MM-DD')) ||
        '',
			per_page: totalCount,
			offset: 0,
		});
		if (res && res.code === 200) {
			const result = res?.result?.result_data ?? [];
			if (result?.length) {
				let csv =
					'ID, Name, Type, Working Hours, Staff, Staff Shifts, Services, Delivery Fee, Service Area \r\n';
				const rows = [];
				for (let i = 0; i < result?.length; i++) {
					const item = result[i];
					rows.push(
						`${item?.supplier_id || ''},${item?.name || ''},${
							item?.type || ''
						},${getStatusValue(item?.has_working_hours)},${getStatusValue(
							item?.has_staff
						)},${getStatusValue(item?.has_staff_shifts)},${getStatusValue(
							item?.has_services
						)},${getStatusValue(item?.has_delivery_fee)},${getStatusValue(
							item?.has_geofence
						)}\r\n`
					);
				}
				rows.forEach((row) => {
					csv += row;
				});
				try {
					const generationDate = new Date().toLocaleDateString('en-US');
					const link = document.createElement('a');
					link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
					link.target = '_blank';
					link.download = `Incomplete_profile_partners_${generationDate}.csv`;
					link.click();
				} catch (error) {
					toast.error('Something went wrong.Please try again!');
				}
			} else {
				toast.warning(t('No record found'));
			}
		} else {
			toast.error(res && res.message);
		}
		setCsvLoading(false);
	};

	const getStatusValueTable = (status) => {
		return (status === '1' && <span>&#x2705;</span>) || <span> &#x274C; </span>;
	};

	const handlePageChange = (pageNo, pageSize) => {
		setPageNo(pageNo);
		setPageSize(pageSize);
		loadIncompleteProfilePartners({
			...filterSchema,
			per_page: pageSize,
			offset: (pageNo - 1) * pageSize,
		});
	};

	const columns = [
		{
			name: t('ID'),
			key: 'supplier_id',
			formatter: null,
		},
		{
			name: t('Name'),
			key: 'name',
			formatter: null,
		},
		{
			name: t('Type'),
			key: 'name',
			formatter: null,
		},
		{
			name: t('Working Hours'),
			key: 'has_working_hours',
			formatter: (row, key) => {
				return <span>{getStatusValueTable(row[key])}</span>;
			},
		},
		{
			name: t('Staff'),
			key: 'has_staff',
			formatter: (row, key) => {
				return <span>{getStatusValueTable(row[key])}</span>;
			},
		},
		{
			name: t('Staff Shifts'),
			key: 'has_staff_shifts',
			formatter: (row, key) => {
				return <span>{getStatusValueTable(row[key])}</span>;
			},
		},
		{
			name: t('Services'),
			key: 'has_services',
			formatter: (row, key) => {
				return <span>{getStatusValueTable(row[key])}</span>;
			},
		},
		{
			name: t('Delivery Fee'),
			key: 'has_delivery_fee',
			formatter: (row, key) => {
				return <span>{getStatusValueTable(row[key])}</span>;
			},
		},
		{
			name: t('Service Area'),
			key: 'has_geofence',
			formatter: (row, key) => {
				return <span>{getStatusValueTable(row[key])}</span>;
			},
		},
	];

	return (
		<React.Fragment>
			{/* <Hoc activeMenu="incompleteProfilePartners"> */}
				<Container fluid className="incomplete-profile-partners">
					<Row className="heading-row">
						<Col lg="3" md="6" sm="12" className="mb-3">
							<DateInput
								label={t('From date')}
								value={filterSchema.from_date}
								onChange={(date) => handleDateChange(date, 'from_date')}
								isClearable={true}
							/>
						</Col>
						<Col lg="3" md="6" sm="12" className="mb-3">
							<DateInput
								label={t('To date')}
								value={filterSchema.to_date}
								onChange={(date) => handleDateChange(date, 'to_date')}
								isClearable={true}
							/>
						</Col>
						<Col lg="3" md="6" sm="12" className="mb-3">
							<CustomDropdown
								onChange={(value) => handleChange(value.value, 'supplier_type')}
								name="supplier_type"
								value={''}
								options={supplierTypes}
								label={t('Supplier Type')}
							/>
						</Col>
						<Col lg="3" md="6" sm="12" className="mb-3">
							<CustomDropdown
								onChange={(value) => handleChange(value.value, 'supplier_rep')}
								name="supplier_rep"
								value={''}
								options={supplierRep}
								label={t('Supplier Rep')}
							/>
						</Col>
						<Col lg="3" md="6" sm="12" className="cta-col mt-3 mb-2">
							<PrimaryButton
								text={t('Search')}
								onClick={handleSearch}
								style={{ width: '100%', marginTop: '0px' }}
							/>
						</Col>
						<Col lg="3" md="3" sm="12" className="mt-3 mb-2">
							<PrimaryButton
								disabled={isLoading}
								onClick={handleCSVExport}
								text={t('Export as CSV')}
								style={{ width: '100%', marginTop: '0px' }}
								isLoading={csvLoading}
							/>
						</Col>
					</Row>
					<Row className="w-100 text-center m-0 mt-4">
						<Col>
							{isLoading && (
								<div>
									<FullPageSpinner animation="border" />
									<span>{t("Please wait, we're loading data")}</span>
								</div>
							)}
							{(!isLoading && tableData?.length === 0 && (
								<div className="no-data-text text-center mt-2">
									{t('No data available')}
								</div>
							)) ||
								null}
						</Col>
					</Row>
					<Row>
						<Col>
							{(tableData.length && (
								<div className="booking-table w-100">
									<CustomTableComponent
										responsive={true}
										hover={true}
										showSequence={true}
										pageSize={pageSize}
										pageNo={pageNo}
										columns={columns || []}
										data={tableData || []}
									/>
								</div>
							)) ||
								null}
						</Col>
					</Row>
					<Row>
						<Col>
							<Pagination
								totalCount={totalCount}
								viewCount={tableData?.length ?? 0}
								onChangePage={handlePageChange}
								pageNo={pageNo}
							/>
						</Col>
					</Row>
					{/* <IncompleteProfilePartnersList filterSchema={filterSchema} reload={reload} /> */}
				</Container>
			{/* </Hoc> */}
		</React.Fragment>
	);
};
export default IncompleteProfilePartners;
