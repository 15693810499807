import { useEffect, useRef, useState } from "react";
import { Accordion, Col, Row, Card, Image as BSImage } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import UserInput from "../../../components/Inputs";
import AssetsImg from "images-col";
import { getAuthAttribute } from "../../../utils/helper";
import CustomDropdown from "../../../components/Dropdown";
import CustomTextArea from "../../../components/Textarea";
import { getVoucherById, saveVoucher } from "../../../core/Api/giftvouchers";
import { toast } from "react-toastify";
import FullPageSpinner from "../../../components/FullPageSpinner";
import { uploadImage } from "../../../core/Api/staff";
import PrimaryButton from "../../../components/Buttons/Primary";

const defaultImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8Vg8AAlEBZ0IDjCQAAAAASUVORK5CYII=';

const initVoucherModel = {
  voucher_id: null,
  voucher_name: null,
  voucher_name_arb: null,
  voucher_desc: null,
  voucher_desc_arb: null,
  seller_id: null,
  status_id: 1,
  price: null,
  discounted_price: null,
  category: null,
  type: null,
  banner_image: null,
  voucher_image: null
}


const VouchersForm = ({
  voucherId = null,
  sellerId = null,
  onClose
}) => {
  const isDisabled = false;
  const { t } = useTranslation();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        arabic: {
          message: t("Must be arabic"),
          rule: (val) => {
            const isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
            return isArabic.test(val);
          },
          required: true,
        },
        english: {
          message: t("Must be english"),
          rule: (val) => {
            const isEnglish = /[a-zA-Z0-9]/;
            return isEnglish.test(val);
          },
          required: true,
        },
      },
    })
  );
  const [loading, setLoading] = useState(true)
  const [voucherModel, setVoucherModel] = useState({ ...initVoucherModel })
  const [isActive, setIsActive] = useState(false);
  const [imagePreview, setImagePreview] = useState({
    banner_image: null,
    voucher_image: null
  });
  const [, forceUpdate] = useState(0);

  const categories = [
    { value: null, label: t('Select Category') },
    { value: 'Beauty', label: "Beauty" }
  ]

  const types = [
    { value: null, label: t('Select Voucher Type') },
    { value: 'Boon Voucher', label: "Boon Voucher" }
  ]

  useEffect(() => {
    if (voucherId) {
      loadInitialData(voucherId);
    } else {
      setLoading(false)
    }
  }, [voucherId, sellerId])

  const loadInitialData = async (id) => {
    const res = await getVoucherById(id);
    setLoading(false)
    if (res?.code < 400) {
      setVoucherModel({
        ...res?.result
      })
    } else {
      toast.error(res?.message || t('Something wrong'))
    }
  }

  const handleInputChange = (e, name) => {
    setVoucherModel({
      ...voucherModel,
      [name]: e.target.value
    })
  }

  const handleDDChange = (value, name) => {
    setVoucherModel({
      ...voucherModel,
      [name]: value,
    });
  };

  const onImageSelect = async (event, name) => {
    if (true) {
      const image = event.target.files[0];
      if (image) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          const img = new Image();
          img.src = reader.result;
          img.onload = function () {
            var width = img.naturalWidth,
              height = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);
            // if (width >= 400 && height >= 400) {
              setImagePreview({
                ...imagePreview,
                [name]: image,
              });
              setVoucherModel({
                ...voucherModel,
                [name]: reader.result,
              });
            // } else {
            //   toast.error("Image resolution must be greater then 400X400");
            //   event.preventDefault();
            // }
          };

        });
        reader.readAsDataURL(image);
      }
    }
  };

  const handleSaveVoucher = async () => {
    toast.dismiss();
    if (simpleValidator.current.allValid()) {
      setLoading(true);
      let data = {
        ...voucherModel,
        seller_id:sellerId,
      }
      if (imagePreview?.banner_image) {
        const imageData = new FormData();
        imageData.append("file", imagePreview?.banner_image);
        const imageUrl = await uploadImage(imageData);
        const logo = imageUrl?.result.file_name;
        data["banner_image"] = logo;
      }else{
        data["banner_image"] = data?.banner_image_filename;
      }
      if (imagePreview?.voucher_image) {
        const imageData = new FormData();
        imageData.append("file", imagePreview?.voucher_image);
        const imageUrl = await uploadImage(imageData);
        const logo = imageUrl?.result.file_name;
        data["voucher_image"] = logo;
      }else{
        data["voucher_image"] = data?.voucher_image_filename;
      }
      const result = await saveVoucher(data);
      setLoading(false);
      if (result?.code === 200) {
        toast.success('Saved successfully');
        onClose && onClose(false)
      } else {
        toast.error(result?.message || "Something went wrong.Please try again");
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      toast.error("Please enter valid details");
    }
  };

  return <div className="vouchers-form p-3">
    {loading && <FullPageSpinner /> || null}
    <Row className="m-0 mb-2">
      <Col xl="4" lg="4" md="4" sm="12" className="mb-3">
        <UserInput
          required
          label={t("Name(eng)")}
          disabled={isDisabled}
          placeholder={t("Name(eng)")}
          name="voucher_name"
          value={voucherModel?.voucher_name}
          onChange={(event) =>
            handleInputChange(event, "voucher_name")
          }
        />
        {simpleValidator.current.message(
          "voucher_name",
          voucherModel && voucherModel.voucher_name,
          "required"
        )}
      </Col>
      <Col xl="4" lg="4" md="4" sm="12" className="mb-3">
        <UserInput
          required
          disabled={isDisabled}
          label={t("Name(arb)")}
          placeholder={t("Name(arb)")}
          name="voucher_name_arb"
          value={voucherModel.voucher_name_arb}
          onChange={(event) =>
            handleInputChange(event, "voucher_name_arb")
          }
        />
        {simpleValidator.current.message(
          "voucher_name_arb",
          voucherModel && voucherModel.voucher_name_arb,
          "required"
        )}
      </Col>
      <Col xl="4" lg="4" md="4" sm="12" className="mb-3">
        <CustomDropdown
          required
          onChange={(value) =>
            handleDDChange(value.value, "category")
          }
          name="category"
          options={categories || []}
          label={t("Category")}
          value={
            categories.find(
              (row) => row.value == voucherModel?.category
            ) || ""
          }
        />
        {simpleValidator.current.message(
          "category",
          voucherModel && voucherModel.category,
          "required"
        )}
      </Col>
      <Col xl="4" lg="4" md="4" sm="12" className="mb-3">
        <CustomDropdown
          required
          onChange={(value) =>
            handleDDChange(value.value, "type")
          }
          name="type"
          options={types || []}
          label={t("Type")}
          value={
            types.find(
              (row) => row.value == voucherModel?.type
            ) || ""
          }
        />
        {simpleValidator.current.message(
          "type",
          voucherModel && voucherModel.type,
          "required"
        )}
      </Col>
      <Col xl="4" lg="4" md="4" sm="12" className="mb-3">
        <UserInput
          required
          label={t("Price")}
          disabled={isDisabled}
          placeholder={t("Price")}
          name="price"
          value={voucherModel?.price}
          onChange={(event) =>
            handleInputChange(event, "price")
          }
        />
        {simpleValidator.current.message(
          "price",
          voucherModel && voucherModel.price,
          "required"
        )}
      </Col>
      <Col xl="4" lg="4" md="4" sm="12" className="mb-3">
        <UserInput
          required
          label={t("Discounted Price")}
          disabled={isDisabled}
          placeholder={t("Discounted Price")}
          name="discounted_price"
          value={voucherModel?.discounted_price}
          onChange={(event) =>
            handleInputChange(event, "discounted_price")
          }
        />
        {simpleValidator.current.message(
          "discounted_price",
          voucherModel && voucherModel.discounted_price,
          "required"
        )}
      </Col>
      <Col xl="12" lg="12" md="12" sm="12" className="mt-4">
        <Accordion >
          {/* <Card> */}
          <Accordion.Toggle as={Card.Header} onClick={() => setIsActive(!isActive)} eventKey="0">
            <div className='d-flex justify-content-between align-items-center'>
              <span>{t('Description')}</span> <img className={isActive && 'arrow-icons'} src={AssetsImg?.icon_arrow_down} ></img>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Row>
                <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                  <CustomTextArea
                    label={t("Description (Optional)")}
                    placeholder={t("description")}
                    name="voucher_desc"
                    value={voucherModel.voucher_desc}
                    onChange={(event) => handleInputChange(event, "voucher_desc")}
                  />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="mt-4">
                  <CustomTextArea
                    customInputClass="user-input mt-2"
                    label={t("Arabic description (Optional)")}
                    placeholder={t("Arabic description (Optional)")}
                    name="voucher_desc_arb"
                    value={voucherModel.voucher_desc_arb}
                    onChange={(event) => handleInputChange(event, "voucher_desc_arb")}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
          {/* </Card> */}
        </Accordion>
      </Col>

      <Col md='12'>
        <Row className="m-0 mt-5">
          <Col ml='6' lg='6' md='12' sm='12' className="image-wrapper">
            <p className="mb-2"><b>{t('pleaseSelectBannerImage')}</b></p>
            <div>
              <input
                type="file"
                name="myfile"
                accept=".png, .jpg, .jpeg"
                onChange={(e) => onImageSelect(e, 'banner_image')}
              />
            </div>
            <BSImage
              src={voucherModel?.banner_image || defaultImage}
              alt="Select banner image."
            />
            {simpleValidator.current.message(
              "banner_image",
              voucherModel?.banner_image,
              "required"
            )}
          </Col>
          <Col ml='6' lg='6' md='12' sm='12' className="image-wrapper">
          <p className="mb-2"><b>{t('pleaseSelectVoucherImage')}</b></p>

            <div>
              <input
                type="file"
                name="myfile"
                accept=".png, .jpg, .jpeg"
                onChange={(e) => onImageSelect(e, 'voucher_image')}
              />
            </div>
            <BSImage
              src={voucherModel?.voucher_image || defaultImage}
              alt="Select voucher image."
            />
            {simpleValidator.current.message(
              "voucher_image",
              voucherModel?.voucher_image,
              "required"
            )}
          </Col>
        </Row>
      </Col>
    </Row>

    <Row className="m-0 mt-3">
      <Col md="12" className="pt-5   d-flex justify-content-end">
        <PrimaryButton
          text={t("Save")}
          onClick={() => handleSaveVoucher()}
          style={{ width: '200px' }}
        />
      </Col>
    </Row>
  </div>
}

export default VouchersForm;