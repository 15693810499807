import React, { useState, useRef, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import caretDown from "../../assets/images/caret-down.svg";
import caretUp from "../../assets/images/caret-up.svg";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import SearchInput from "../../components/Inputs/Search";
import rightArrow from "../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../assets/images/Icon ionic-ios-arrow-forward-2.png";
import { getPermissions, savePermissions } from "../../core/Api/Settings";
import CheckBoxInput from "../../components/Inputs/CheckBox";
import SimpleReactValidator from "simple-react-validator";
import "./Styles.scss";
import Hoc from "../Hoc";

const PermissionsTable = ({ history, match }) => {
  const { t } = useTranslation();
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: this })
  );
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const pageSize = 10;
  const totalResults = data.length;
  const [pageCount, setPageCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [, forceUpdate] = useState();

  const userTypeId = match.params.user_type_id;

  const offset = pageSize * (selectedPage - 1);

  const startPageRef = useRef(selectedPage);
  const filteredData = data.filter((item) =>
    `${item.type.toLowerCase()} ${item.name.toLowerCase()}`.includes(
      searchInput.toLowerCase()
    )
  );
  const outputData = filteredData.slice(offset, offset + pageSize);

  const sortCaret = (order) => (
    <span className="caret-icon">
      <img src={order === "asc" ? caretUp : caretDown} alt="" />
    </span>
  );

  useEffect(() => {
    permissionsList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(filteredData.length / pageSize));
  }, [filteredData]);

  const permissionsList = async () => {
    setLoading(true);
    toast.dismiss();
    try {
      const result = await getPermissions(userTypeId);
      if (result.code === 200) {
        setData(result.result ?? []);
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setLoading(false);
  };

  const getFormattedData = () => {
    const payload = {};
    payload.user_type_id = userTypeId;
    data.forEach((permission) => {
      payload[`${permission.permission_id}_view_access`] =
        +permission.is_view_allowed;
      payload[`${permission.permission_id}_add_edit`] =
        +permission.is_edit_allowed;
      payload[`${permission.permission_id}_delete`] =
        +permission.is_delete_allowed;
    });
    return payload;
  };

  const handleSearchChange = (e) => setSearchInput(e.target.value);

  const handlePageChange = (target) => {
    let newPage = selectedPage;
    if (target === "next") {
      newPage = newPage + 1 <= pageCount ? newPage + 1 : newPage;
    } else if (target === "prev") {
      newPage = newPage - 1 > 0 ? newPage - 1 : newPage;
    } else {
      newPage = target;
    }
    setSelectedPage(newPage);
    if (pageCount > 5) {
      if (target === "next" && newPage % 5 === 1) {
        startPageRef.current += 5;
      } else if (target === "prev" && newPage % 5 === 0) {
        startPageRef.current -= 5;
      }
    }
  };
  const handleSave = async () => {
    setLoading(true);
    try {
      if (simpleValidator.current.allValid()) {
        const data = getFormattedData();
        const response = await savePermissions(data);
        if (response?.code === 200) {
          toast.success(response.message);
        } else {
          throw new Error(response?.message ?? "Something went wrong");
        }
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setLoading(false);
  };

  const handleCheckall = () => {
    const updatedData = [...data];
    outputData.forEach((item) => {
      const index = updatedData.findIndex(
        (permission) => permission.permission_id === item.permission_id
      );
      if (index !== -1) {
        updatedData[index].is_view_allowed = 1;
        updatedData[index].is_edit_allowed = 1;
        updatedData[index].is_delete_allowed = 1;
      }
    });
    setData(updatedData);
  };

  const handleUnCheckAll = () => {
    const updatedData = [...data];
    outputData.forEach((item) => {
      const index = updatedData.findIndex(
        (permission) => permission.permission_id === item.permission_id
      );
      if (index !== -1) {
        updatedData[index].is_view_allowed = 0;
        updatedData[index].is_edit_allowed = 0;
        updatedData[index].is_delete_allowed = 0;
      }
    });
    setData(updatedData);
  };

  const handleCBChange = (type, permission_id) => {
    const updatedData = [...data];
    const index = updatedData.findIndex(
      (item) => item.permission_id === permission_id
    );
    if (index === -1) {
      return;
    }
    switch (type) {
      case "view":
        updatedData[index].is_view_allowed =
          +updatedData[index].is_view_allowed === 0 ? 1 : 0;
        break;
      case "edit":
        updatedData[index].is_edit_allowed =
          +updatedData[index].is_edit_allowed === 0 ? 1 : 0;
        break;
      case "delete":
        updatedData[index].is_delete_allowed =
          +updatedData[index].is_delete_allowed === 0 ? 1 : 0;
        break;
      default:
        break;
    }
    setData(updatedData);
  };

  const accessFormatter = (cell, row, rowIndex) => {
    return (
      <div className="otherWrap">
        <CheckBoxInput
          checked={Number(row.is_view_allowed)}
          onChange={() => handleCBChange("view", row.permission_id)}
        />
      </div>
    );
  };

  const addEditformatter = (cell, row, rowIndex) => {
    return (
      <div className="otherWrap">
        <CheckBoxInput
          checked={Number(row.is_edit_allowed)}
          onChange={() => handleCBChange("edit", row.permission_id)}
        />
      </div>
    );
  };

  const deleteFormatter = (cell, row, rowIndex) => {
    return (
      <div className="otherWrap">
        <CheckBoxInput
          checked={Number(row.is_delete_allowed)}
          onChange={() => handleCBChange("delete", row.permission_id)}
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "permission_id",
      text: "#",
      sort: true,
      sortCaret,
    },
    {
      dataField: "name",
      text: "Permission",
      sort: true,
      sortCaret,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      sortCaret,
    },
    {
      dataField: "is_view_allowed",
      text: "View/Access",
      sort: true,
      formatter: accessFormatter,
    },
    {
      dataField: "is_edit_allowed",
      text: "Add/Edit",
      sort: true,
      formatter: addEditformatter,
    },
    {
      dataField: "is_delete_allowed",
      text: "Delete",
      sort: true,
      formatter: deleteFormatter,
    },
  ];

  const getUserType = () => {
    const urlParams = new URLSearchParams(history.location.search);
    return urlParams.get("type") || "N/A";
  };

  return (
    <Hoc activeMenu="permissions">
      <Container fluid className="permissions-table">
        <div className="table-wrapper">
          <Row className="mt-0">
            <Col md="auto title">
              Permissions management for <span>{getUserType()}</span>
            </Col>
          </Row>
          <Row className="search-wrap">
            <Col xl="3" lg="5" md="6" sm="12">
              <SearchInput
                classNames="pt-0"
                onChange={handleSearchChange}
                placeholder={t("Search")}
              />
            </Col>

            <Col xl="6" lg="6" md="5" sm="12" className="cta-col">
              <Button onClick={handleCheckall}>Check all</Button>
              <Button className="ml-2" onClick={handleUnCheckAll}>
                Uncheck all
              </Button>
            </Col>
          </Row>
          {outputData.length ? (
            <BootstrapTable
              keyField={columns[0]}
              data={outputData}
              columns={columns}
            />
          ) : isLoading ? (
            <div className="spinner-wrap">
              <Spinner animation="border" />
              <span>{t("Please wait, we're loading permissions")}</span>
            </div>
          ) : (
            <div className="no-data-container">
              <h4>
                {searchInput
                  ? t("No matching permissions found")
                  : t("No permissions Available")}
              </h4>
            </div>
          )}
        </div>
        {!isLoading && Boolean(outputData.length) && (
          <>
            <Row className="pagination">
              <Col md="3 result-text">
                {t("Showing")}{" "}
                {filteredData.length ? offset + 1 : filteredData.length}{" "}
                {t("to")}
                &nbsp;
                {offset + outputData.length} {t("of")} {totalResults}{" "}
                {t("Results")}
              </Col>
              <Col md="5" className="count">
                <div
                  className="previous"
                  onClick={() => handlePageChange("prev")}
                >
                  <img src={leftArrow} alt="left-arrow"></img>
                </div>
                <div className="pages">
                  {Array.from({ length: pageCount > 5 ? 5 : pageCount }).map(
                    (_, index) => {
                      const pageNumber = startPageRef.current + index;
                      return (
                        <span
                          key={index}
                          className={` cursor-pointer ${
                            selectedPage === pageNumber && "active"
                          }`}
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </span>
                      );
                    }
                  )}
                </div>
                <div className="next" onClick={() => handlePageChange("next")}>
                  <img src={rightArrow} alt="right-arrow"></img>
                </div>
              </Col>
            </Row>
          </>
        )}
        {Boolean(outputData.length) && (
          <Row className="save-col">
            <Col>
              <Button onClick={handleSave}>Save Permissions</Button>
            </Col>
          </Row>
        )}
      </Container>
    </Hoc>
  );
};

export default PermissionsTable;
