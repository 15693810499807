import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import PrimaryButton from '../../components/Buttons/Primary';
import { Container, Row, Col } from 'react-bootstrap';
import Hoc from '../Hoc';
import './Styles.scss';
import { useTranslation } from 'react-i18next';
import FullPageSpinner from '../../components/FullPageSpinner';
import CustomTableComponent from '../../components/CustomTableComponent';
import { getSupAvgSPReport } from '../../core/Api/Reports';
import Pagination from '../../components/Pagination';

const AvgServicePriceReport = () => {
	const { t } = useTranslation();
	const [isLoading, setLoading] = useState(false);
	const [transactions, setTransactions] = useState([]);
	const [currTransactions, setCurrTransactions] = useState([]);
	const [pageNo, setPageNo] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	useEffect(() => {
		loadData();
		// eslint-disable-next-line
	}, []);

	const loadData = async () => {
		setLoading(true);
		const result = await getSupAvgSPReport();
		if (result && result.code === 200) {
			setTransactions(result.result);
			setCurrTransactions(result?.result?.slice(0, 10) || []);
		} else {
			toast.error(result && result.message);
		}
		setLoading(false);
	};

	const handlePageChange = (pageNo, pageSize) => {
		setPageNo(pageNo);
		setPageSize(pageSize);
		setCurrTransactions(
			transactions?.slice(
				pageSize * (pageNo - 1),
				pageSize * (pageNo - 1) + pageSize
			)
		);
	};

	const handleCSVExport = async () => {
		setLoading(true);
		let csv =
			'Sr No, ID, Name, Arabic name, Supplier type, Avg at salon, Avg at home\r\n';
		const rows = [];
		for (let i = 0; i < transactions?.length; i++) {
			const item = transactions[i];
			rows.push(
				`${item?.supplier_id || ''},${item?.name || ''},${
					item?.arb_name || ''
				},${(item?.is_home === '1' && 'Freelancer') || 'Salon'},${parseFloat(
					item.avg_price_at_home || 0
				).toFixed(2)} SAR,${parseFloat(item.avg_price_at_salon || 0).toFixed(
					2
				)} SAR\r\n`
			);
		}
		rows.forEach((row) => {
			csv += row;
		});
		try {
			const generationDate = new Date().toLocaleDateString('en-US');
			const link = document.createElement('a');
			link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
			link.target = '_blank';
			link.download = `Suppliers_Avg_Service_Price_Report_${generationDate}.csv`;
			link.click();
		} catch (error) {
			toast.error('Something went wrong.Please try again!');
			console.log('ERROR:', error.message);
		}
		setLoading(false);
	};

	const columns = [
		{
			name: t('ID'),
			key: 'supplier_id',
			formatter: null,
		},
		{
			name: t('Name'),
			key: 'name',
			formatter: null,
		},
		{
			name: t('Arabic name'),
			key: 'arb_name',
			formatter: null,
		},
		{
			name: t('Supplier type'),
			key: 'is_home',
			formatter: (row, key) => {
				return <span>{(row[key] === '1' && 'Freelancer') || 'Salon'}</span>;
			},
		},
		{
			name: t('Avg at salon'),
			key: 'avg_price_at_home',
			formatter: (row, key) => {
				return <span>{`${parseFloat(row[key] || 0).toFixed(2)} SAR`}</span>;
			},
		},
		{
			name: t('Avg at home'),
			key: 'avg_price_at_salon',
			formatter: (row, key) => {
				return <span>{`${parseFloat(row[key] || 0).toFixed(2)} SAR`}</span>;
			},
		},
	];

	return (
		<React.Fragment>
			{/* <Hoc activeMenu="avgServicePriceReport"> */}
				<Container fluid className="bookings">
					<Row className="heading-row">
						<Col lg="3" md="3" sm="12" className="mt-3">
							<PrimaryButton
								disabled={isLoading}
								onClick={handleCSVExport}
								text={t('Export as CSV')}
								style={{ width: '100%' }}
							/>
						</Col>
					</Row>
					<Row className="w-100 text-center m-0 mt-4">
						<Col>
							{isLoading && (
								<div>
									<FullPageSpinner animation="border" />
									<span>{t("Please wait, we're loading data")}</span>
								</div>
							)}
							{(!isLoading && currTransactions?.length === 0 && (
								<div className="no-data-text text-center mt-2">
									{t('No data available')}
								</div>
							)) ||
								null}
						</Col>
					</Row>
					<Row>
						<Col>
							{(currTransactions.length && (
								<div className="booking-table w-100">
									<CustomTableComponent
										responsive={true}
										hover={true}
										showSequence={true}
										pageSize={pageSize}
										pageNo={pageNo}
										columns={columns || []}
										data={currTransactions || []}
									/>
								</div>
							)) ||
								null}
						</Col>
					</Row>
					<Row>
						<Col>
							<Pagination
								totalCount={transactions?.length ?? 0}
								viewCount={currTransactions?.length ?? 0}
								onChangePage={handlePageChange}
								pageNo={pageNo}
							/>
						</Col>
					</Row>
				</Container>
			{/* </Hoc> */}
		</React.Fragment>
	);
};

export default AvgServicePriceReport;
