import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import AdminForm from "./AdminForm";
import Swal from "sweetalert2";
import { Modal, Image, Container, Row, Col, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/Buttons/Primary";
import {
  deleteSupplierAdmin,
  getSupplierAdmins,
} from "../../core/Api/supplier";
import { getAuthAttribute } from "../../utils/helper";
import "./Styles.scss";
import editIcon from "../../assets/images/Icon material-mode-edit.png";
import caretDown from "../../assets/images/caret-down.svg";
import caretUp from "../../assets/images/caret-up.svg";
import deleteIcon from "../../assets/images/trash.svg";
import rightArrow from "../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../assets/images/Icon ionic-ios-arrow-forward-2.png";
import SearchInput from "../../components/Inputs/Search";

const SystemAdministrators = (props) => {
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const pageSize = 10;
  const [adminData, setAdminData] = useState([]);
  const supplierId = props.supplierId
    ? props.supplierId
    : getAuthAttribute("supplier_id");
  const totalResults = adminData.length;
  const [pageCount, setPageCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const filteredData = adminData.filter((item) =>
    `${item.first_name.toLowerCase()} ${item.last_name.toLowerCase()} ${item.email.toLowerCase()}`.includes(
      searchInput.toLowerCase()
    )
  );
  const offset = pageSize * (selectedPage - 1);
  const startPageRef = useRef(selectedPage);
  const { t } = useTranslation();
  const adminsState = {
    supplier_id: supplierId ? supplierId : "0",
    supplier_admin_id: "0",
    first_name: "",
    last_name: "",
    username: "",
    email: "",
  };
  const outputData = filteredData.slice(offset, offset + pageSize);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearchChange = (e) => setSearchInput(e.target.value);

  const handlePageChange = (target) => {
    let newPage = selectedPage;
    if (target === "next") {
      newPage = newPage + 1 <= pageCount ? newPage + 1 : newPage;
    } else if (target === "prev") {
      newPage = newPage - 1 > 0 ? newPage - 1 : newPage;
    } else {
      newPage = target;
    }
    setSelectedPage(newPage);
    if (pageCount > 5) {
      if (target === "next" && newPage % 5 === 1) {
        startPageRef.current += 5;
      } else if (target === "prev" && newPage % 5 === 0) {
        startPageRef.current -= 5;
      }
    }
  };

  useEffect(() => {
    getSupplierAdminslist();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(filteredData.length / pageSize));
  }, [filteredData]);

  const getSupplierAdminslist = async () => {
    setIsLoading(true);
    toast.dismiss();
    try {
      const result = await getSupplierAdmins(supplierId);
      if (result.code === 200) {
        setAdminData(result?.result);
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setIsLoading(false);
  };

  const handleConfirm = async (supplier_admin_id) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        `Are you sure you want to delete this user? This action cannot be undone.`
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await deleteSupplierAdmin(supplier_admin_id);
        if (result && result.code === 200) {
          await getSupplierAdminslist();
          toast.success(result.message);
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
    });
  };

  const sortCaret = (order) => (
    <span className="caret-icon">
      <img src={order === "asc" ? caretUp : caretDown} alt="" />
    </span>
  );

  const formatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="otherWrap">
        <Image
          className="edit"
          onClick={() => setSelectedAdmin(row)}
          src={editIcon}
          alt="edit"
        />
        <Image
          onClick={() => handleConfirm(row.supplier_admin_id)}
          src={deleteIcon}
          alt="delete"
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
      sortCaret,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
      sortCaret,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret,
    },
    {
      dataField: "other",
      text: "Action",
      sort: false,
      formatter: formatter,
    },
  ];
  const handleClose = () => setSelectedAdmin(null);

  return (
    <Container>
      <div className="table-wrapper">
        <Row className="search-wrap">
          <Col xl="3" lg="5" md="7" sm="12">
            <SearchInput
              classNames="pt-0"
              onChange={handleSearchChange}
              placeholder={t("Search")}
            />
          </Col>
          <Col
            xl="6"
            lg="6"
            md="5"
            sm="12"
            className="add-btn d-flex justify-content-end"
          >
            <PrimaryButton
              onClick={() => setSelectedAdmin({ ...adminsState })}
              classNames="add-new-Admin"
              text={t("Add New Administrator")}
            />
          </Col>
        </Row>
        {outputData.length ? (
          <BootstrapTable
            keyField={columns[0]}
            data={outputData}
            columns={columns}
          />
        ) : isLoading ? (
          <div className="spinner-wrap">
            <Spinner animation="border" />
            <span>{t("Please wait, we're loading blacklisted keywords")}</span>
          </div>
        ) : (
          <div className="no-data-container">
            <h4>
              {searchInput
                ? t("No matching administrator found")
                : t("No administrator available")}
            </h4>
          </div>
        )}
      </div>
      {!isLoading && Boolean(outputData.length) && (
        <Row className="pagination">
          <Col md="3 result-text">
            {t("Showing")}{" "}
            {filteredData.length ? offset + 1 : filteredData.length} {t("to")}
            &nbsp;
            {offset + outputData.length} {t("of")} {totalResults} {t("Results")}
          </Col>
          <Col md="5" className="count">
            <div className="previous" onClick={() => handlePageChange("prev")}>
              <img src={leftArrow} alt="left-arrow"></img>
            </div>
            <div className="pages">
              {Array.from({ length: pageCount > 5 ? 5 : pageCount }).map(
                (_, index) => {
                  const pageNumber = startPageRef.current + index;
                  return (
                    <span
                      key={index}
                      className={` cursor-pointer ${selectedPage === pageNumber && "active"
                        }`}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </span>
                  );
                }
              )}
            </div>
            <div className="next" onClick={() => handlePageChange("next")}>
              <img src={rightArrow} alt="right-arrow"></img>
            </div>
          </Col>
        </Row>
      )}
      {selectedAdmin && <Modal show={selectedAdmin} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Manage Administrator</Modal.Title>
        </Modal.Header>
        <AdminForm
          getSupplierAdminslist={getSupplierAdminslist}
          handleClose={handleClose}
          selectedAdmin={selectedAdmin}
        />
      </Modal> || null}
    </Container>
  );
};

export default SystemAdministrators;
