import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Image, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import config from "../../../config";
import Card from "../../../components/Card";
import UserInput from "../../../components/Inputs";
import PasswordInput from "../../../components/Inputs/Password/index";
import { Link, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import PrimaryButton from "../../../components/Buttons/Primary";
import CheckBoxInput from "../../../components/Inputs/CheckBox";
import CustomRadioButton from "../../../components/Inputs/RadioButton";
import PhoneNumberInput from "../../../components/Inputs/PhoneNumber";
import Logo from "../../../assets/images/logo.png";
import LeftArr from "../../../assets/images/left-arr.svg";
import "./Styles.scss";
import { registerSupplier } from "../../../core/Api/Auth";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import LanguageSelect from "../../../languageSelect";
import Img2 from '../../../assets/images/signup-2.png';
import { setLanguage } from "../../../core/Api/Settings";


const Signup = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const [, forceUpdate] = useState("");
  const [loading, setLoading] = useState(false);
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const [registerSchema, setRegisterSchema] = useState({
    first_name: "",
    last_name: "",
    password: "",
    confirmPassword: "",
    email: "",
    mobile: "+966",
    cr_number: "",
    business_name: "",
    type: "salon",
    agent_name: query?.get('agent') || '',
    signatory_name: '',
  });
  const [currLang, setCurrLang] = useState('English(USA)');

  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        customPassword: {
          message: t(
            "Must contain at least one number, one uppercase and lowercase letter,one special character and at least 5 or more characters"
          ),
          rule: (val) => {
            const isPassword =
              /^.*(?=.{5,})(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-]).*$/;
            return isPassword.test(val);
          },
          required: true,
        },
        spaces: {
          message: t("Must not contain spaces"),
          rule: (val) => {
            const isSpace = /[^\S]/;
            return !isSpace.test(val);
          },
          required: true,
        },
        arabic: {
          message: t("Must not be arabic"),
          rule: (val) => {
            const isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
            return !isArabic.test(val);
          },
          required: true,
        },
        checkMobileNumber: {
          message: "Enter a valid mobile number",
          rule: (val) => {
            if (!val) {
              return false;
            } else if (val.split("-").length !== 2) {
              return false;
            } else if (
              val.split("-")[1].length !== 9 ||
              Number.isNaN(+val.split("-")[1])
            ) {
              return false;
            }
            return true;
          },
        },
      },
    })
  );


  useEffect(() => {
    if (i18next)
      setCurrLang(i18next?.language === "ar" ? "Arabic" : "English(USA)")
  }, []);
  
  const handleChange = (event) => {
    setRegisterSchema({
      ...registerSchema,
      [event.target.name]: event.target.value,
    });
  };

  const handlePhoneNumberChange = (value) => {
    if (!value.countryCode || !value.phoneNumber) return;
    setRegisterSchema({
      ...registerSchema,
      mobile: `${value.countryCode}-${value.phoneNumber}`,
    });
  };

  const submitForm = async () => {
    toast.dismiss();
    if (simpleValidator.current.allValid()) {

      if (!acceptPolicy) {
        toast.error("Please accept Terms and Conditions");
        return;
      }
      setLoading(true);
      const data = {
        ...registerSchema,
        first_name: registerSchema?.signatory_name,
        last_name: registerSchema?.signatory_name,
      };
      const result = await registerSupplier(data);
      setLoading(false);
      if (result && result.code === 200) {
        toast.success("Account created successfully");
        // history.push("/login");
        window.open('/login', '_self')
      } else {
        setLoading(false);
        forceUpdate(1);
        toast.error((result && result.message) || "Something wrong");
      }
    } else {
      forceUpdate(1);
      simpleValidator.current.showMessages();
    }
  };

  const handleChangeSteps = () => {
    if (!registerSchema?.business_name) {
      toast.error('Please enter business name');
    } else if (!acceptPolicy) {
      toast.error('Please accept terms & conditions')
    } else {
      setCurrentStep(2);
    }
  }

  const handleLangChange = async (language) => {
    try {
      const response = await setLanguage(language);
      // toast.success(response.message);
      i18next.changeLanguage(language);
      setCurrLang(language === "ar" ? "Arabic" : "English(USA)")
    } catch (error) {
      toast.error(error.message);
    }
  }
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <p
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ cursor: 'pointer' }}
    >
      {children}
      &#x25bc;
    </p>
  ));


  return (
    <Container fluid className="signUpPage">
      <Row>
        <Col className="left-sec-content ">
          <div className="left-img-cont">
            {/* <img src={Img2} width="100%" height="100vh" /> */}
          </div>
        </Col>
        <Col className="right-sec-cont ">
          <div className="right-sec-content step-2">
            <div className="header">
              <Image src={Logo} className="logo" />
              <div className="lang-cont">
                <Dropdown >
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >
                    {currLang}
                  </Dropdown.Toggle>
                  <Dropdown.Menu >
                    <Dropdown.Item eventKey="1" onClick={(e) => handleLangChange('en')}>English (USA)</Dropdown.Item>
                    <Dropdown.Item eventKey="2" onClick={(e) => handleLangChange('ar')}>Arabic</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="heading-cont">
              <p className="heading">{t('Create your free')}</p>
              <p className="subheading-cont"><span>{t('Partner Account')}</span> {t('in just 2 steps ')}</p>
            </div>
            {currentStep === 1 &&
              (<div className="form-cont">
                <p className="sign-in-heading">{t('Create a new account')}</p>
                <Row>
                  <Col xl='12' lg='12' md='12' xm='12' className="mt-1">
                    <UserInput
                      required
                      value={registerSchema.business_name}
                      type="text"
                      label={t("Business Name")}
                      placeholder={t("Business Name")}
                      name="business_name"
                      onChange={handleChange}
                    />
                    {simpleValidator.current.message(
                      "business_name",
                      registerSchema.business_name,
                      "required"
                    )}
                  </Col>
                  <Col xl='12' lg='12' md='12' xm='12'>
                    <p className="select-business-types-header">{t('Business Type')}<span className="text-danger">*</span></p>
                    <div className="business-type-cont">
                      <p
                        className={`business-type-item mr-4 ${registerSchema.type === 'salon' && 'business-type-item-active'}`}
                        onClick={() => setRegisterSchema({
                          ...registerSchema,
                          type: 'salon'
                        })}
                      ><b>{t('Salon')}</b></p>
                      <p
                        className={`business-type-item ml-4 ${registerSchema.type === 'freelancer' && 'business-type-item-active'}`}
                        onClick={() => setRegisterSchema({
                          ...registerSchema,
                          type: 'freelancer'
                        })}
                      ><b>{t('Freelancer')}</b></p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md='12' className="mt-3">
                    <CheckBoxInput
                      classNames='m-0 mt-2'
                      checked={acceptPolicy}
                      onChange={() => setAcceptPolicy(!acceptPolicy)}
                    >
                      {t("I agree to the")}
                      <a
                        href={`/partner_terms_and_conditions.pdf`}
                        target="blank"
                      >
                        {" "}
                        {t("TERMS & CONDITIONS")}
                      </a>
                    </CheckBoxInput>
                  </Col>
                </Row>
                <Row className="m-0 mt-1 justify-content-end ">
                  <PrimaryButton
                    isLoading={loading}
                    text={t("Continue")}
                    onClick={() => handleChangeSteps()}
                    style={{ marginTop: 20, width: '100%', height: '45px' }}
                  />
                </Row>
                <Row>
                  <div className="border"></div>
                  <Col>
                    <div className="signin-link">
                      {t("Already have a professional account?")}&nbsp;
                      <Link to="/login">{t("Sign in now")} </Link>
                    </div>
                  </Col>
                </Row>
              </div>) || (
                <div className="form-cont ">
                  <p className="sign-in-heading">{t('Enter your personal and business detail')}s</p>
                  <Row className="mt-3">
                    <Col xl='6' lg='6' md='6' sm='12'>
                      <UserInput
                        required
                        value={registerSchema.signatory_name}
                        type="text"
                        label={t("Full Name")}
                        placeholder={t("Full Name")}
                        name="signatory_name"
                        onChange={handleChange}
                      />
                      {simpleValidator.current.message(
                        "full_name",
                        registerSchema.signatory_name,
                        "required"
                      )}
                    </Col>
                    <Col xl='6' lg='6' md='6' sm='12'>
                      <UserInput
                        value={registerSchema.agent_name}
                        type="text"
                        label={t("Agent Name")}
                        placeholder={t("Agent Name")}
                        name="agent_name"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xl='12' lg='12' md='12' sm='12' className="mb-2">
                      <PhoneNumberInput
                        required
                        label={t("Phone Number")}
                        placeholder="5xxxxxxx"
                        selectedValues={(values) => handlePhoneNumberChange(values)}
                        value={registerSchema.mobile}
                        maxLength="9"
                      />
                      {simpleValidator.current.message(
                        "mobile",
                        registerSchema.mobile,
                        "required|checkMobileNumber"
                      )}
                    </Col>
                    <Col xl='12' lg='12' md='12' sm='12'>
                      <UserInput
                        required
                        value={registerSchema.email}
                        type="email"
                        autoComplete="new-email"
                        label={t("Email Address")}
                        placeholder={t("Email Address")}
                        name="email"
                        onChange={handleChange}
                      />
                      {simpleValidator.current.message(
                        "email",
                        registerSchema.email,
                        "required|email"
                      )}
                    </Col>
                    <Col xl='6' lg='6' md='6' sm='12'>
                      <UserInput
                        required
                        value={registerSchema.cr_number}
                        type="text"
                        label={registerSchema?.type === 'salon' && t("CR Number") || 'License Number'}
                        placeholder={registerSchema?.type === 'salon' && t("CR Number") || 'License Number'}
                        name="cr_number"
                        onChange={handleChange}
                        autocomplete="none"
                      />
                    {simpleValidator.current.message(
                      `${registerSchema?.type === 'salon' && t("cr_number") || 'license_number'}`,
                      registerSchema.cr_number,
                      "required"
                    )}
                    </Col>
                    <Col xl='6' lg='6' md='6' sm='12'>
                      <PasswordInput
                        required
                        value={registerSchema.password}
                        autoComplete="new-password"
                        label={t("Password")}
                        placeholder="********"
                        name="password"
                        onChange={handleChange}
                      />
                      {simpleValidator.current.message(
                        "password",
                        registerSchema.password,
                        "required|arabic|spaces"
                      )}
                    </Col>
                  </Row>
                  <Row className="bottom-row">

                    <Col md='12'>
                      <div className="sigin-up-cont d-flex justify-content-between mt-4">
                        <div className="go-back-cont d-flex align-items-center">
                          <Image src={LeftArr} className="left-arr" onClick={() => setCurrentStep(1)} />
                          <p className="pl-2">{t('Go Back')}</p>
                        </div>
                        <PrimaryButton
                          isLoading={loading}
                          text={t("Sign Up")}
                          onClick={submitForm}
                          style={{ width: '30%', height: '40px' }}
                        />
                      </div>
                    </Col>
                    <Col md='12'>
                      <div className="signin-link">
                        {t("Already have a professional account?")}&nbsp;
                        <Link to="/login">{t("Sign in now")} </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Signup;