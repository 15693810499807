import React, { useState } from "react";
import Hoc from "../Hoc";
import MyStaff from "./MyStaff";
const Staff = (props) => {
  const { match } = props;
  const [updateProfileStatus, setUpdateProfileStatus] = useState(false);

  return (
    <Hoc activeMenu={match.params.id ? "partner" : "staff"} updateProfileStatus={updateProfileStatus}>
      <MyStaff {...props} setUpdateProfileStatus={setUpdateProfileStatus} />
    </Hoc>
  );
};

export default Staff;
