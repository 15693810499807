import React, { useState, useEffect, useRef } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import "./Styles.scss";
import caretDown from "../../assets/images/caret-down.svg";
import caretUp from "../../assets/images/caret-up.svg";
import editIcon from "../../assets/images/Icon material-mode-edit.png";
import deleteIcon from "../../assets/images/trash.svg";
import { Container, Image, Modal, Spinner, Row, Col, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/Buttons/Primary";
import { getBacklistedKeywords, deleteKeywords } from "../../core/Api/customer";
import SearchInput from "../../components/Inputs/Search";
import rightArrow from "../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../assets/images/Icon ionic-ios-arrow-forward-2.png";
import FeaturedSalonsForm from "./FeaturedSalonsForm";
import Pagination from "../../components/Pagination";
import { getFeaturedSalonsList, removeFeaturedSalon } from "../../core/Api/partner";
import FullPageSpinner from "../../components/FullPageSpinner";
import { deleteAdvert, getAllAdvertisements } from "../../core/Api/Asvertisements";

const model = {
  advert_id: null,
  type:null,
  title: null,
  desc:null,
  image:null,
  start_date: new Date(),
  end_date: new Date(),
};

const FeaturedSalonsList = () => {
  const { t } = useTranslation();
  const pageSize = 10;
  const offset = 0;
  const [pageNo, setPageNo] = useState(1);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    fetchFeaturedSalons(pageSize, offset);
  }, []);

  const handlePageChange = (pageNo, pageSize) => {
    setPageNo(pageNo);
    fetchFeaturedSalons(pageSize, (pageNo - 1) * pageSize)
  };

  const fetchFeaturedSalons = async (pageSize, offset) => {
    toast.dismiss();
    setLoading(true);
    try {
      const result = await getAllAdvertisements({
        per_page: pageSize,
        offset: offset,
        status_id: 1
      });
      if (result.code === 200) {
        setData(result?.result?.result_data ?? []);
        setTotalCount(result?.result?.total_count || 0)
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setLoading(false);
  };

  const handleConfirm = async (data) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        "Are you sure you want to delete this? This action cannot be undone."
      ),

      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setPageLoading(true);
        const result = await deleteAdvert(data);
        setPageLoading(false);
        if (result && result.code === 200) {
          await fetchFeaturedSalons();
          toast.success(result.message);
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
    });
  };

  const handleClose = () => {
    setSelectedSupplier(null);
    fetchFeaturedSalons(pageSize, offset);
  };

  const statusFormatter = (status) => {
    const statuses = {
      "1": "Active",
      "2": "InActive",
      "3": "Deleted",
    }
    return statuses[status];
  }

  const handleOnError = (e) => {
    e.target.src =
      "https://via.placeholder.com/140x100";
  }

  return (
		<Container className="keywords-table m-0 w-100">
			{(pageLoading && <FullPageSpinner />) || null}
			<div className="table-wrapper">
				<Row className="search-wrap m-0">
					<Col xl="4" lg="5" md="6"></Col>
					<Col xl="6" lg="6" md="5" sm="12" className="cta-col">
						<PrimaryButton
							onClick={() => setSelectedSupplier({ ...model })}
							classNames="add-new-offer"
							text={t('Add New')}
						/>
					</Col>
				</Row>
				<Table responsive className="table-cont p-3">
					<thead>
						<tr>
							<th>#</th>
							<th>{t('Image')}</th>
							<th>{t('Title')}</th>
							<th>{t('Supplier')}</th>
							<th>{t('Start Date')}</th>
							<th>{t('End Date')}</th>
							<th>{t('Status')}</th>
							<th>{t('Delete')}</th>
						</tr>
					</thead>
					<tbody>
						{Boolean(data?.length) ? (
							data.map((row, index) => (
								<tr key={index}>
									<td>{(pageNo - 1) * pageSize + (index + 1)}</td>
									<td>
										<span>
											<img
												alt="client-img"
												src={row.image}
												className="profile-pic"
												onError={handleOnError}
											/>
											{/* {`${row.first_name} ${row.last_name} `} */}
										</span>
									</td>
									<td>{row?.title || ''}</td>
									<td>{row?.name || 'N/A'}</td>
									<td>{row?.start_date || ''}</td>
									<td>{row?.end_date || ''}</td>
									<td>{statusFormatter(row?.status_id)}</td>
									<td>
										<div className="otherWrap">
											<Image
												className="delete"
												onClick={() => handleConfirm(row)}
												src={deleteIcon}
												alt="delete"
											/>
										</div>
									</td>
								</tr>
							))
						) : isLoading ? (
							<div className="spinner-wrap text-center">
								<Spinner animation="border" />
								<span>{"Please wait, we're loading data"}</span>
							</div>
						) : (
							<tr colSpan={5} className="no-data-text text-center">
								{t('No data available')}
							</tr>
						)}
					</tbody>
				</Table>
				<Row className="m-0">
					<Pagination
						totalCount={totalCount}
						viewCount={data?.length || 0}
						onChangePage={handlePageChange}
						pageNo={pageNo}
					/>
				</Row>
			</div>
			{(selectedSupplier && (
				<Modal size="lg" show={selectedSupplier} onHide={handleClose}>
					<FeaturedSalonsForm
						selectedSupplier={selectedSupplier}
						handleClose={handleClose}
					/>
				</Modal>
			)) ||
				null}
		</Container>
	);
};

export default FeaturedSalonsList;
