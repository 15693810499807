import React from "react";
import CancelIcon from "../../assets/images/cancel.png";
import "./Styles.scss";
const FullScreenPopup = (props) => {
  const { open, onClose, classNames } = props;
  if (open === true) {
    return (
      <div className={`full-screen-popup ${classNames}`}>
        <img
          src={CancelIcon}
          alt="cancel"
          className={"cancel"}
          onClick={onClose}
        />
        {props.children}
      </div>
    );
  } else {
    return <></>;
  }
};

FullScreenPopup.propTypes = {};

export default FullScreenPopup;
