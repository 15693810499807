import React, { useState, useRef } from "react";
import { Row, Col, Modal, Button, Container } from "react-bootstrap";
import UserInput from "../../components/Inputs";
import SimpleReactValidator from "simple-react-validator";
import PhoneNumberInput from "../../components/Inputs/PhoneNumber";
import PasswordInput from "../../components/Inputs/Password";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { saveSupplierDriver } from "../../core/Api/supplier";

const Driverform = (props) => {
  const { t } = useTranslation();
  const { getSupplierDrivers, handleClose } = props;
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        customPassword: {
          message: t(
            "Must contain at least one number, one uppercase and lowercase letter,one special character and at least 5 or more characters"
          ),
          rule: (val) => {
            const isPassword =
              /^.*(?=.{5,})(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-]).*$/;
            return isPassword.test(val);
          },
          required: true,
        },
        spaces: {
          message: t("Must not contain spaces"),
          rule: (val) => {
            const isSpace = /[^\S]/;
            return !isSpace.test(val);
          },
          required: true,
        },
        arabic: {
          message: t("Must not be arabic"),
          rule: (val) => {
            const isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
            return !isArabic.test(val);
          },
          required: true,
        },
        checkMobileNumber: {
          message: "Enter a valid mobile number",
          rule: (val) => {
            if (!val) {
              return false;
            } else if (val.split("-").length !== 2) {
              return false;
            } else if (
              val.split("-")[1].length !== 9 ||
              Number.isNaN(+val.split("-")[1])
            ) {
              return false;
            }
            return true;
          },
        },
      },
    })
  );
  const [isLoading, setIsLoading] = useState(false);
  const [driverDetails, setDriverDetails] = useState({
    ...props.selectedDriver,
  });
  const [, forceUpdate] = useState();

  const handleSaveDriver = async () => {
    setIsLoading(true);
    try {
      if (simpleValidator.current.allValid()) {
        const number = driverDetails.mobile.split("-");
        const data = {
          supplier_id: driverDetails.supplier_id,
          supplier_driver_id: driverDetails.supplier_driver_id,
          name: driverDetails.name,
          mobile: number[1],
          country_code: number[0],
          password: driverDetails.password,
        };
        if(!driverDetails?.supplier_driver_id){
          if(!driverDetails?.password){
           delete  data.password
          }
        }
        const response = await saveSupplierDriver(data);
        if (response?.code === 200) {
          toast.success("Driver saved successfully");
          await getSupplierDrivers();
          handleClose();
        } else {
          throw new Error(response?.message ?? "Something went wrong");
        }
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setIsLoading(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDriverDetails({
      ...driverDetails,
      [name]: value,
    });
  };

  const handlePhoneNumberChange = (value) => {
    if (!value.countryCode || !value.phoneNumber) return;
    setDriverDetails({
      ...driverDetails,
      mobile: `${value.countryCode}-${value.phoneNumber}`,
    });
  };

  console.log('driverDetails', driverDetails);
  return (
    <Container>
      <Modal.Body>
        <Row>
          <Col md="12">
            <UserInput
              required
              label={t("Name")}
              name="name"
              placeholder="Enter Your Name"
              value={driverDetails.name}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "name",
              driverDetails.name,
              "required"
            )}
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <PhoneNumberInput
              required
              label={t("Mobile")}
              placeholder="5xxxxxxx"
              value={driverDetails.mobile}
              selectedValues={handlePhoneNumberChange}
              maxLength="9"
            />
            {simpleValidator.current.message(
              "mobile",
              driverDetails.mobile,
              "required|checkMobileNumber"
            )}
          </Col>
        </Row>
        <Row>
          <Col md="12" className="mt-4">
            <PasswordInput
              required={driverDetails?.supplier_driver_id ? false : true}
              value={driverDetails.password}
              autoComplete="new-password"
              label={t("Password")}
              placeholder="********"
              name="password"
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "password",
              driverDetails.password,
              `${driverDetails?.supplier_driver_id ? '' : 'required|arabic|spaces'}`
            )}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="footer">
      <Button
          disabled={isLoading}
          onClick={handleSaveDriver}
          variant="primary"
          className="save-btn"
        >
          {t("Save")}
        </Button>
        <Button onClick={handleClose} variant="white">
          {t("Close")}
        </Button>
        
      </Modal.Footer>
    </Container>
  );
};

export default Driverform;
