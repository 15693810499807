import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import SimpleReactValidator from "simple-react-validator";
import { Container, Col, Row, Button } from "react-bootstrap";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import UserInput from "../../../components/Inputs";
import CustomDropdown from "../../../components/Dropdown";
import SearchableDropdown from "../../../components/Dropdown/SearchableDropdown";
import CheckBoxInput from "../../../components/Inputs/CheckBox";
import RoundedButton from "../../../components/Buttons/Rounded";
import PrimaryButton from "../../../components/Buttons/Primary";
import "./Styles.scss";
import moment from "moment";
import {
  saveCustomer,
  getCustomerById,
  updateAddress,
  saveCustomerAddress,
  deleteCustomerAddress,
} from "../../../core/Api/customer";
import { uploadImage } from "../../../core/Api/staff";
import { useTranslation } from "react-i18next";
import PhoneNumberInput from "../../../components/Inputs/PhoneNumber";
import AddressSection from "./AddressList";
import Swal from "sweetalert2";
import AssetsImg from "images-col";
import CustomerGroups from './groups';
import FullPageSpinner from "../../../components/FullPageSpinner";
import { getAuthAttribute } from "../../../utils/helper";
import MapSearch from "../../../components/MapSearch";

const defaultAddressSchema = {
  additional_numbers: "",
  address_id: 0,
  address_type: "",
  building_floor: "",
  city: "",
  country_id: "",
  extra: "",
  is_primary: false,
  landmark: null,
  last_updated: "",
  lat_lng: "",
  location: "",
  mobile: "",
  neighbourhood: "",
  phone: "",
  postal_zip_code: "",
  state: null,
  status_id: "",
  street_name: "",
  isEdit:false
};
const DefaultImage = AssetsImg?.img_default;
// "https://testapiv2.boon.sa/uploads/supplier_media/default_image.jpg?v=1628698285";
const schema = {
  logo: null,
  first_name: "",
  last_name: "",
  mobile: "",
  phone: "",
  email: "",
  gender: null,
  referral_source: "",
  preferred_language: "",
  month: "",
  day: "",
  year: "",
  notes: "",
  location: null,
  street_name: "",
  neighbourhood: "",
  postal_zip_code: "",
  accept_notifications: false,
  is_notes_displayed: false,
  notification_by: "0",
  date_of_birth: null,
  avatar: null,
  is_offline: 1,
  hair_type: null,
  hair_length: null,
  skin_type: null,
  skin_tone: null,
  day:'01',
  month:'01',
  year:'1970'
};
const AddClient = (props) => {
  const { updateSaved } = props;
  const { t } = useTranslation();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        birthday: {
          message: "Enter a valid birthday",
          rule: (val) => {
            const day = val[0],
              month = val[1],
              year = val[2];
            if (!day && !month && !year) {
              return true;
            } else if (!day || !month || !year) {
              console.log('check2');
              return false;
            }
            console.log('after checks', year, month, day, new Date(`${year}-${month}-${day}`).getFullYear());
            const result =
              new Date().getFullYear() -
              new Date(`${year}-${month}-${day}`).getFullYear() >=
              13;
            return result;
          },
        },
        checkMobileNumber: {
          message: "Enter a valid mobile number",
          rule: (val) => {
            if (!val) {
              return false;
            } else if (val.split("-").length !== 2) {
              return false;
            } else if (
              val.split("-")[1].length !== 9 ||
              Number.isNaN(+val.split("-")[1])
            ) {
              return false;
            }
            return true;
          },
        },
      },
    })
  );
  const isUserAdmin = getAuthAttribute("user_type_id") === 4;
  const [isLoading, setIsLoading] = useState(false);
  const [, forceUpdate] = useState();
  const [view, setView] = useState("personal");
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(defaultAddressSchema);
  const [clientModel, setClientModel] = useState({ ...schema });
  const [isEdit, setIsEdit] = useState(false);
  const [imagePreviewURL, setImagePreviewURL] = useState({
    image: null,
    src: "",
  });
  const [centerCords, setCenterCords] = useState({
    lat: 24.7132552,
    lng: 46.6786387,
  });
  const [markerCords, setMarkerCords] = useState({
    lat: 24.7132552,
    lng: 46.6786387,
  });
  const mapRef = useRef(null);
  const searchFieldRef = useRef(null);

  const languageOptions = [
    { label: t("Select Language"), value: null },
    { label: "Arabic", value: "af" },
    { label: "Bengali", value: "bn" },
    { label: "English (UK)", value: "en-GB" },
    { label: "Portuguese (Brazil)", value: "pt-BR" },
    { label: "English (US)", value: "en" },
    { label: "Filipino", value: "fil" },
    { label: "French", value: "fr" },
    { label: "German", value: "de" },
    { label: "Hindi", value: "hi" },
    { label: "Indonesian", value: "id" },
    { label: "Urdu", value: "af" },
    { label: "Afrikaans", value: "ur" },
    { label: "Afrikaans", value: "af" },
  ];

  const genderOptions = [
    { label: t("Select Option"), value: null },
    { label: t("Male"), value: "male" },
    { label: t("Female"), value: "female" },
  ];

  const refferalSourceOptions = [
    { label: t("Select Source"), value: null },
    { label: t("Walk In"), value: "walk in" },
    { label: t("Friend"), value: "friend" },
    { label: t("Others"), value: "others" },
  ];

  const notifyByOptions = [
    { label: t("Don't send notifications"), value: "0" },
    { label: t("Email"), value: "email" },
    { label: t("SMS"), value: "sms" },
  ];

  const monthOptions = [
    { label: t("Select Month"), value: null },
    { label: t("January"), value: "01" },
    { label: t("February"), value: "02" },
    { label: t("March"), value: "03" },
    { label: t("April"), value: "04" },
    { label: t("May"), value: "05" },
    { label: t("June"), value: "06" },
    { label: t("July"), value: "07" },
    { label: t("August"), value: "08" },
    { label: t("September"), value: "09" },
    { label: t("October"), value: "10" },
    { label: t("November"), value: "11" },
    { label: t("December"), value: "12" },
  ];

  const dayOptions = [
    { label: t("Select Day"), value: null },
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "09", value: "09" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" },
    { label: "29", value: "29" },
    { label: "30", value: "30" },
    { label: "31", value: "31" },
  ];

  const hairTypeOptions = [
    { label: t("Select Hair Type"), value: null },
    { label: "Straight", value: 'straight' },
    { label: "Wavy", value: 'wavy' },
    { label: "Curly", value: 'Curly' },
    { label: "Tightly Curled", value: 'tightly_curled' }
  ]

  const hairLengthOptions = [
    { label: t("Select Hair Length"), value: null },
    { label: "short", value: 'short' },
    { label: 'Medium', value: 'medium' },
    { label: "Long", value: "long" },
    { label: "Very Long", value: 'very_long' }
  ]

  const skinTypeOptions = [
    { label: t("Select Skin Type"), value: null },
    { label: "Normal", value: 'normal' },
    { label: "Dry", value: 'dry' },
    { label: "Oily", value: 'oily' },
  ];

  const skinToneOptions = [
    { label: t("Select Skin Tone"), value: null },
    { label: "Light", value: 'light' },
    { label: "Fair", value: 'fair' },
    { label: "Medium", value: "medium" },
    { label: "Mid Brown", value: 'mid_brown' },
    { label: "Dark Brown", value: 'dark_brown' },
    { label: "Very Dark Brown", value: 'very_dark_brown' }
  ]
  const year = moment().format("YYYY");
  const yearOptions = [{ label: t("Select Year"), value: null }];
  for (let i = Number(year) - 13; i >= 1950; i--) {
    yearOptions.push({ label: `${i}`, value: `${i}` });
  }

  useEffect(() => {
    (async () => {
      if (props && props.customerId && props.customerId > 0) {
        setIsLoading(true);
        const result = await getCustomerById(props.customerId);
        setIsLoading(false);
        if (result && result.code === 200) {
          const dateOfBirth = result?.result?.date_of_birth?.split("-") ?? [];
          setClientModel({
            ...result.result.address,
            ...result.result,
            referral_source: result?.result?.referral_source,
            day: dateOfBirth[2] && dateOfBirth[2] === '00' && '01' || clientModel.day,
            month: dateOfBirth[1] && dateOfBirth[1] === '00' && '01' || clientModel.month,
            year: dateOfBirth[0] && dateOfBirth[0] === '0000' && '1970' || clientModel.year,
            accept_notifications:
              result.result.accept_marketing_notification === "1",
            is_notes_displayed: result.result.is_notes_displayed === "1",
          });
        } else {
          toast.error(result && result.message);
        }
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchAddress();
    // eslint-disable-next-line
  }, [props.customerId]);

  const fetchAddress = async () => {
    if (props.customerId) {
      const result = await updateAddress({
        customer_id: props.customerId,
      });
      setAddressList(result.result);
    }
  };

  const onImageSelect = async (event) => {
    const image = event.target.files[0];
    if (image) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePreviewURL({
          image: image,
          src: reader.result,
        });
      });
      reader.readAsDataURL(image);
    }
  };

  const handleChange = (value, name) => {
    setClientModel({
      ...clientModel,
      [name]: value,
    });
    updateSaved && updateSaved();
  };

  const handleAddressChange = (value, name) => {
    setSelectedAddress({
      ...selectedAddress,
      [name]: value,
    });
  };

  const handlePhoneNumberChange = (value) => {
    if (!value.countryCode || !value.phoneNumber) return;
    setClientModel({
      ...clientModel,
      mobile: `${value.countryCode}-${value.phoneNumber}`,
    });
    updateSaved && updateSaved();
  };

  const handleSaveCustomerAddress = async () => {
    if (
      selectedAddress.location &&
      selectedAddress.neighbourhood &&
      selectedAddress.lat_lng
    ) {
      const data = {
        customer_id: props.customerId ?? 0,
        address_id: selectedAddress.address_id ? selectedAddress.address_id : 0,
        location: selectedAddress.location,
        address_type: "Home",
        lat_lng: selectedAddress.lat_lng ? selectedAddress.lat_lng : "0,0",
        city: selectedAddress.city,
        street_name: selectedAddress.street_name,
        postal_zip_code: selectedAddress.postal_zip_code,
        neighbourhood: selectedAddress.neighbourhood,
        is_primary: selectedAddress.is_primary,
      };
      setIsLoading(true);
      const result = await saveCustomerAddress(data);
      setIsLoading(false  )
      if (result && result.code === 200) {
        toast.success("Customer address saved successfully");
        setIsEdit(false);
        setSelectedAddress(defaultAddressSchema);
        fetchAddress();
      } else {
        toast.error(result && result.message);
        forceUpdate(1);
      }
    } else {
      simpleValidator.current.showMessages();
      toast.error("Please enter valid details");
      forceUpdate(1);
    }
  };

  const handleDeleteAddress = async (id) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        "Are you sure you want to delete this address? This action cannot be undone."
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#0b586d",
      cancelButtonColor: "#3085d6",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        const result = await deleteCustomerAddress(props.customerId, id);
        setIsLoading(false)
        if (result && result.code === 200) {
          Swal.fire("Deleted Successfully", "", "success");
          console.log("result", result);
          fetchAddress();
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
    });
  };

  const handleSaveCustomer = async () => {
    toast.dismiss();
    if (simpleValidator.current.allValid()) {
      let logo;
      if (imagePreviewURL?.image) {
        const imageData = new FormData();
        imageData.append("file", imagePreviewURL.image);
        logo = await uploadImage(imageData);
      }
      let data = {
        avatar:
          logo?.result && logo.result?.file_name ? logo.result?.file_name : "",
        customer_id: clientModel.customer_id ? clientModel.customer_id : "0",
        first_name: clientModel.first_name,
        last_name: clientModel.last_name,
        email: clientModel.email,
        mobile: clientModel.mobile,
        gender: clientModel.gender,
        notes: clientModel.notes,
        is_notes_displayed: clientModel.is_notes_displayed ? "1" : "0",
        notification_by: clientModel.notification_by,
        referral_source: clientModel.referral_source,
        preferred_language: clientModel.preferred_language,
        accept_marketing_notification: clientModel.accept_notifications
          ? "1"
          : "0",
        is_offline: true,
        phone: clientModel.phone,
        hair_type: clientModel?.hair_type || null,
        hair_length: clientModel?.hair_length || null,
        skin_type: clientModel?.skin_type || null,
        skin_tone: clientModel?.skin_tone || null,
      };
      if (clientModel.year && clientModel.month && clientModel.day) {
        data.date_of_birth = `${clientModel.year}-${clientModel.month}-${clientModel.day}`;
      } else {
        data.date_of_birth = '1970-01-01'
      }
      data = _.pickBy(data, _.identity);

      let address = {
        location: selectedAddress.location,
        postal_zip_code: selectedAddress.postal_zip_code,
        lat_lng: selectedAddress.lat_lng,
        street_name: selectedAddress.street_name,
        neighbourhood: selectedAddress.neighbourhood,
        is_primary: 1,
      };
      address = _.pickBy(address, _.identity);
      data.address = address;
      const result = await saveCustomer(data);
      if (result && result.code === 200) {
        toast.success("Customer saved successfully");
        updateSaved && updateSaved(false);
        props?.onClose(true);
      } else {
        toast.error(result && result.message);
        forceUpdate(1);
      }
    } else {
      simpleValidator.current.showMessages();
      toast.error("Please enter valid details");
      forceUpdate(1);
    }
  };

  const onMapLoad = (map) => {
    mapRef.current = map;
  };

  const getPlaceValue = (lat, lng) => {
    return new Promise((resolve, reject) => {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDfxpYsb_0sfaP5dQ9Lonpd4staJ4ErH6I`
      )
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleMarkerDrag = async (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    try {
      const addresses = await getPlaceValue(lat, lng);
      if (addresses?.results?.length) {
        const filteredAddresses = (addresses.results || []).filter(
          (item) =>
            item.types.includes("political") && item.types.includes("sublocality")
        );
        const location = addresses.results[0].formatted_address;
        const neighbourhood =
          filteredAddresses[0].address_components[0].long_name;
        searchFieldRef.current.value = location;
        setSelectedAddress({
          ...selectedAddress,
          lat_lng:[lat, lng].toString(),
          location,
          neighbourhood
        })
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong");
    }
  };

  const onPlacesChanged = async (result) => {
    if (result) {
      const filteredAddresses = (result.address_components || []).filter(
        (item) =>
          item.types.includes("political") && item.types.includes("sublocality")
      );
      const fullAddress = result.formatted_address;
      const neighbourhood = filteredAddresses[0].long_name;
      const lat = result.geometry.location.lat();
      const lng = result.geometry.location.lng();
      setCenterCords({ lat, lng });
      setMarkerCords({ lat, lng });
      setSelectedAddress({
        ...selectedAddress,
        lat_lng:[lat, result?.lng].toString(),
        location:fullAddress,
        neighbourhood
      })
    }
  };

  return (
    <div className="add-client">
      {isLoading && <FullPageSpinner /> || null}
      <h2>{props.customerId > 0 ? t("Edit Client") : t("New Client")}</h2>
      <Container className="form-cont">
        <Row>
          <Col md="5">
            <Row>
              <Col md="6 mb-3 d-flex justify-content-center" xl="12 d-flex justify-content-center" sm="12  d-flex justify-content-center" >
                <label for="profile-input" className="profile-pic-label">

                  <img
                    src={
                      imagePreviewURL.src ? imagePreviewURL.src : DefaultImage
                    }
                    className="profile-pic"
                    alt="client-member-pi"
                  />

                  <input
                    name='"profile-input'
                    visibility="hidden"
                    type="file"
                    id="profile-input"
                    accept=".png, .jpg, .jpeg"
                    onChange={onImageSelect}
                  />
                </label>
              </Col>
            </Row>
            <Row className="mb">
              <Col md="6">
                <UserInput
                  required
                  label={t("First name")}
                  placeholder="Ex. John"
                  name="first_name"
                  value={clientModel.first_name}
                  onChange={(event) =>
                    handleChange(event.target.value, "first_name")
                  }
                />
                {simpleValidator.current.message(
                  "first_name",
                  clientModel && clientModel.first_name,
                  "required"
                )}
              </Col>
              <Col md="6">
                <UserInput
                  required
                  label={t("Last name")}
                  placeholder={t("Last name")}
                  name="last_name"
                  value={clientModel.last_name}
                  onChange={(event) =>
                    handleChange(event.target.value, "last_name")
                  }
                />
                {simpleValidator.current.message(
                  "last_name",
                  clientModel && clientModel.last_name,
                  "required"
                )}
              </Col>
              <Col md="12">
                <PhoneNumberInput
                  required
                  label={t("Mobile")}
                  placeholder="5xxxxxxx"
                  value={clientModel.mobile}
                  selectedValues={(values) => handlePhoneNumberChange(values)}
                  maxLength="9"
                />
                {simpleValidator.current.message(
                  "mobile",
                  clientModel.mobile,
                  "required|checkMobileNumber"
                )}
              </Col>
              <Col md="6">
                <UserInput
                  label={t("Telephone")}
                  placeholder={t("Telephone")}
                  name="phone"
                  value={clientModel.phone}
                  onChange={(event) =>
                    handleChange(event.target.value, "phone")
                  }
                />
              </Col>
              <Col md="12">
                <UserInput
                  label={t("Email")}
                  placeholder={t("Email")}
                  name="email"
                  value={clientModel.email}
                  onChange={(event) =>
                    handleChange(event.target.value, "email")
                  }
                />
                {simpleValidator.current.message(
                  "email",
                  clientModel && clientModel.email,
                  "email"
                )}
              </Col>
              <Col md="12">
                <CustomDropdown
                  options={notifyByOptions}
                  label={t("Send Notifications By")}
                  placeholder={t("Send Notifications By")}
                  onChange={(value) =>
                    handleChange(value.value, "notification_by")
                  }
                  value={
                    notifyByOptions.find(
                      (row) =>
                        row.value ===
                        (clientModel && clientModel.notification_by)
                    ) || ""
                  }
                />
              </Col>
              <Col md="12">
                <SearchableDropdown
                  options={languageOptions}
                  label={t("Preferred language")}
                  onChange={(value) =>
                    handleChange(value.value, "preferred_language")
                  }
                  placeholder={t("Select Language")}
                  value={
                    languageOptions.find(
                      (row) =>
                        row.value ===
                        (clientModel && clientModel.preferred_language)
                    ) || ""
                  }
                />
                <div
                  onClick={() =>
                    handleChange(
                      !clientModel.accept_notifications,
                      "accept_notifications"
                    )
                  }
                >
                  <CheckBoxInput
                    checked={clientModel.accept_notifications}
                    onChange={(event) =>
                      handleChange(event.target.checked, "accept_notifications")
                    }
                  >
                    {t("Accepts marketing notifications")}
                  </CheckBoxInput>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md="2">
            <div className="vr"></div>
          </Col>
          <Col md="5" className="right-section">
            <Row className="mb">
              <Col md="12">
                <RoundedButton
                  label={t("Personal Info")}
                  isActive={view === "personal"}
                  onClick={() => setView("personal")}
                />
                <RoundedButton
                  label={t("Address")}
                  isActive={view === "address"}
                  onClick={() => setView("address")}
                />
                {(isUserAdmin && props?.customerId) && <RoundedButton
                  label={t("Groups")}
                  isActive={view === "groups"}
                  onClick={() => setView("groups")}
                /> || null}
                <hr />
                {view === "personal" && (
                  <Row>
                    <Col md="6">
                      <CustomDropdown
                        options={genderOptions}
                        label={t("Gender")}
                        onChange={(value) =>
                          handleChange(value.value, "gender")
                        }
                        value={
                          genderOptions.find(
                            (row) =>
                              row.value === (clientModel && clientModel.gender)
                          ) || ""
                        }
                      />
                    </Col>
                    <Col md="6">
                      <CustomDropdown
                        options={refferalSourceOptions}
                        label={t("Referral source")}
                        onChange={(value) =>
                          handleChange(value.value, "referral_source")
                        }
                        value={
                          refferalSourceOptions.find(
                            (row) =>
                              row.value ===
                              (clientModel && clientModel.referral_source)
                          ) || ""
                        }
                      />
                    </Col>
                    <Col md="6">
                      <CustomDropdown
                        options={hairTypeOptions}
                        label={t("Hair Type")}
                        onChange={(value) =>
                          handleChange(value.value, "hair_type")
                        }
                        value={
                          hairTypeOptions.find(
                            (row) =>
                              row.value === (clientModel && clientModel.hair_type)
                          ) || ""
                        }
                      />
                    </Col>
                    <Col md="6">
                      <CustomDropdown
                        options={hairLengthOptions}
                        label={t("Hair Length")}
                        onChange={(value) =>
                          handleChange(value.value, "hair_length")
                        }
                        value={
                          hairLengthOptions.find(
                            (row) =>
                              row.value === (clientModel && clientModel.hair_length)
                          ) || ""
                        }
                      />
                    </Col>
                    <Col md="6">
                      <CustomDropdown
                        options={skinTypeOptions}
                        label={t("Skin Type")}
                        onChange={(value) =>
                          handleChange(value.value, "skin_type")
                        }
                        value={
                          skinTypeOptions.find(
                            (row) =>
                              row.value === (clientModel && clientModel.skin_type)
                          ) || ""
                        }
                      />
                    </Col>
                    <Col md="6">
                      <CustomDropdown
                        options={skinToneOptions}
                        label={t("Skin Tone")}
                        onChange={(value) =>
                          handleChange(value.value, "skin_tone")
                        }
                        value={
                          skinToneOptions.find(
                            (row) =>
                              row.value === (clientModel && clientModel.skin_tone)
                          ) || ""
                        }
                      />
                    </Col>
                    <Col ml="4" lg="4" md="6" sm="6">
                      <SearchableDropdown
                        label={t("Birthday")}
                        placeholder={t("Month")}
                        options={monthOptions}
                        onChange={(value) => handleChange(value.value, "month")}
                        value={
                          monthOptions.find(
                            (row) =>
                              row.value === (clientModel && clientModel.month)
                          ) || ""
                        }
                      />
                    </Col>
                    <Col ml="4" lg="4" md="6" sm="6">
                      <SearchableDropdown
                        label="&nbsp;"
                        options={dayOptions}
                        placeholder={t("Day")}
                        onChange={(value) => handleChange(value.value, "day")}
                        value={
                          dayOptions.find(
                            (row) =>
                              row.value === (clientModel && clientModel.day)
                          ) || ""
                        }
                      />
                    </Col>
                    <Col ml="4" lg="4" md="6" sm="6">
                      <SearchableDropdown
                        label="&nbsp;"
                        options={yearOptions}
                        placeholder={t("Year")}
                        onChange={(value) => handleChange(value.value, "year")}
                        value={
                          yearOptions.find(
                            (row) =>
                              row.value === (clientModel && clientModel.year)
                          ) || ""
                        }
                      />
                    </Col>
                    <Col md="12">
                      {simpleValidator.current.message(
                        "Birthday",
                        [clientModel.day, clientModel.month, clientModel.year],
                        "birthday"
                      )}
                    </Col>
                    <Col md="12">
                      <UserInput
                        label={t("Client notes")}
                        placeholder={t("Client notes")}
                        name="notes"
                        value={clientModel.notes}
                        onChange={(event) =>
                          handleChange(event.target.value, "notes")
                        }
                      />
                    </Col>
                    <Col
                      md="12"
                      onClick={() =>
                        handleChange(
                          !clientModel.is_notes_displayed,
                          "is_notes_displayed"
                        )
                      }
                    >
                      <CheckBoxInput
                        checked={clientModel.is_notes_displayed}
                        onChange={(event) =>
                          handleChange(
                            event.target.checked,
                            "is_notes_displayed"
                          )
                        }
                      >
                        {t("Display on all bookings")}
                      </CheckBoxInput>
                    </Col>
                  </Row>
                )}
                {view === "address" && (


                  <div>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "10px",
                        justifyContent: "flex-end",
                      }}
                    >
                      {addressList.length ? (
                        <Button
                          variant="dark"
                          onClick={() => {
                            const newEdit = !isEdit;
                            setIsEdit(!isEdit);
                            if (!newEdit) {
                              setSelectedAddress(defaultAddressSchema);
                            }
                          }}
                        >
                          {isEdit ? "Back" : "Add Address"}
                        </Button>
                      ) : null}
                    </div>

                    {(!addressList.length || isEdit) && (
                      <Row className="mb">
                        <Col md="12">
                          <UserInput
                            label={t("Location")}
                            placeholder={t("Location")}
                            name="location"
                            value={selectedAddress.location}
                            onChange={(event) =>
                              handleAddressChange(
                                event.target.value,
                                "location"
                              )
                            }
                          />
                        </Col>
                        <Col md='12'>
                          <div>
                            <LoadScript
                              id="script-loader"
                              googleMapsApiKey="AIzaSyDfxpYsb_0sfaP5dQ9Lonpd4staJ4ErH6I"
                              libraries={["places", "drawing"]}
                            >
                              <GoogleMap
                                id="map_canvas"
                                mapContainerStyle={{
                                  width: "100%",
                                  height: "400px",
                                }}
                                center={centerCords}
                                zoom={15}
                                onLoad={onMapLoad}
                                options={{
                                  mapTypeControl: false,
                                  fullscreenControl: true,
                                  controlSize: false,
                                  scaleControl: false,
                                  zoomControl: false,
                                  streetView: false,
                                  disableDefaultUI: false
                                }}
                              >
                                <Marker
                                  position={markerCords}
                                  onDragEnd={handleMarkerDrag}
                                  title={t("Your current location")}
                                  draggable
                                />
                                <MapSearch
                                  ref={searchFieldRef}
                                  handleCenterChange={onPlacesChanged}
                                  styles={{marginLeft:'10px'}}
                                />
                              </GoogleMap>
                            </LoadScript>
                          </div>
                        </Col>
                        <Col md="6">
                          <UserInput
                            label={t("Street")}
                            placeholder={t("Street")}
                            name="street_name"
                            value={selectedAddress.street_name}
                            onChange={(event) =>
                              handleAddressChange(
                                event.target.value,
                                "street_name"
                              )
                            }
                          />
                        </Col>
                        <Col md="6">
                          <UserInput
                            label={t("Zipcode")}
                            placeholder={t("Zipcode")}
                            name="postal_zip_code"
                            value={selectedAddress.postal_zip_code}
                            onChange={(event) =>
                              handleAddressChange(
                                event.target.value,
                                "postal_zip_code"
                              )
                            }
                          />
                        </Col>
                        <Col md='12'>
                          <CheckBoxInput
                          classNames='m-0'
                            checked={selectedAddress.is_primary}
                            onChange={(event) => {
                              setSelectedAddress({
                                ...selectedAddress,
                                is_primary: event.target.checked,
                              })
                            }
                            }
                          >
                            {t("Set as default address")}
                          </CheckBoxInput>
                        </Col>
                      </Row>
                    )}
                    {isEdit && (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          style={{ backgroundColor: "#0b586d" }}
                          onClick={handleSaveCustomerAddress}
                        >
                          {isEdit && selectedAddress.isEdit
                            ? "Update"
                            : "Save"}
                        </Button>
                      </div>
                    )}
                    {!isEdit && props.customerId && (
                      <AddressSection
                        addressList={addressList}
                        setIsEdit={setIsEdit}
                        setSelectedAddress={setSelectedAddress}
                        handleDeleteAddress={handleDeleteAddress}
                      />
                    )}
                  </div>
                )}
                {(props?.customerId && view === "groups") && (
                  <CustomerGroups customerId={props?.customerId} />
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="save-client">
          <Col md="2">
            <PrimaryButton text={t("Save")} onClick={handleSaveCustomer} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default AddClient;
