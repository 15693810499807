import React, { useState } from "react";
import Hoc from "../Hoc";
import "./Styles.scss";
import MyServices from "./MyServices";

const Service = (props) => {
  const { match } = props;
  const [updateProfileStatus, setUpdateProfileStatus] = useState(false);

  return (
    <Hoc activeMenu={match.params.id ? "partner" : "service"} updateProfileStatus={updateProfileStatus}>
      <MyServices {...props} setUpdateProfileStatus={setUpdateProfileStatus} />
    </Hoc>
  );
};

export default Service;
