import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import Panel from "../../../../components/Panel";
import UserInput from "../../../../components/Inputs";
import CustomDropdown from "../../../../components/Dropdown";
import SearchableDropdown from "../../../../components/Dropdown/SearchableDropdown";
import PrimaryButton from "../../../../components/Buttons/Primary";
import DangerButton from "../../../../components/Buttons/Danger";
import SwitchInput from "../../../../components/SwitchInput";
import CheckBoxInput from "../../../../components/Inputs/CheckBox";
import cancel from "../../../../assets/images/ic_cancel.svg";
import Swal from "sweetalert2";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
// import Card from "../../../../components/Card";
import "./Styles.scss";
import { getAuthAttribute } from "../../../../utils/helper";
import { getStaffMembersList } from "../../../../core/Api/staff";
import FullPageSpinner from '../../../../components/FullPageSpinner';
import {
  getCategories,
  getServicesListByCategory,
  saveService,
  getServiceById,
  deleteService,
} from "../../../../core/Api/services";
import { useTranslation } from "react-i18next";
// import AdvancedPricing from "../AdvancePricing";

const AddSingleServiceForm = ({ edit, onClose, supplierId }) => {
  const { t, i18n } = useTranslation();
  const isUserAdmin = getAuthAttribute("user_type_id") === 4;
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [loader, setLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [members, setStaffMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  // const [selectedPriceOption, setSelectedPriceOption] = useState(null);
  const supplier_id = supplierId;
  const [serviceModel, setServiceModel] = useState({
    supplier_id: supplier_id,
    description: "",
    arb_description: "",
    category_id: null,
    availability_mode_1: "1",
    availability_mode_2: "1",
    supplier_staff_id: null,
    supplier_service_id: "0",
    service_id: null,
    enable_online_bookings: false,
    tax_rate: "",
    cancelation_penalty: "0",
    cancelation_period: "0",
    service_staff: "",
    status_id: 1,
    service_available_for: 0,
    options: [],
    brand_name_en:null,
    brand_name_ar:null
  });

  const options1 = [
    { label: t("Both"), value: 0 },
    { label: t("Salon"), value: 1 },
    { label: t("Home"), value: 2 },
  ];

  const priceTypeOptions = [
    // { label: t("Select Type"), value: null },
    { label: t("Fixed"), value: "fixed" },
    // { label: t("Form"), value: "form" },
    // { label: t("Free"), value: "free" },
  ];

  const durationOptions = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "15", value: 15 },
    { label: "20", value: 20 },
    { label: "25", value: 25 },
    { label: "30", value: 30 },
    { label: "35", value: 35 },
    { label: "40", value: 40 },
    { label: "45", value: 45 },
    { label: "50", value: 50 },
    { label: "55", value: 55 },
    { label: "1H", value: 60 },
    { label: "1H5m", value: 65 },
    { label: "110m", value: 70 },
    { label: "1H15m", value: 75 },
    { label: "1H20m", value: 80 },
    { label: "1H25m", value: 85 },
    { label: "1H30m", value: 90 },
    { label: "1H35m", value: 95 },
    { label: "1H40m", value: 100 },
    { label: "1H45m", value: 105 },
    { label: "1H50m", value: 110 },
    { label: "1H55m", value: 115 },
    { label: "2H", value: 120 },
    { label: "2H15", value: 135 },
    { label: "2H30", value: 150 },
    { label: "2H45", value: 165 },
    { label: "3H", value: 180 },
    { label: "4H", value: 240 },
    { label: "5H", value: 300 },
    { label: "6H", value: 360 },
  ];
  const [priceLength, setPriceLength] = useState(1);

  useEffect(() => {
    (async () => {
      setLoader(true);
      const [result, catList] = await Promise.all([
        getStaffMembersList(supplierId),
        getCategories(),
      ]);
      if (result && result.code === 200) {
        setStaffMembers(result.result);
      } else {
        toast.error(result && result.message);
      }
      if (catList && catList.code === 200) {
        const data = [...serviceCategories];
        catList.result.forEach((row) => {
          data.push({
            label: i18n.language === "ar" ? row.category_ar : row.category,
            value: row.category_id,
            service_id: row.category_id,
          });
        });
        setServiceCategories(data);
      } else {
        toast.error(catList && catList.message);
      }
      if (edit) {
        const service = await getServiceById(edit, supplier_id);
        if (service?.result.options?.length)
          setPriceLength(service.result.options.length);
        if (service && service.code === 200) {
          const servicesList = await getServicesListByCategory(
            service.result.category_id
          );
          if (servicesList && servicesList.code === 200) {
            const data = servicesList.result.map((row) => ({
              label: row.name_en,
              value: row.service_id,
              service_id: row.service_id,
            }));
            setServicesList(data);
          } else {
            toast.error(servicesList && servicesList.message);
          }

          if (service.result && service.result.staff) {
            const ids = service.result.staff.map(
              (row) => row.supplier_staff_id
            );
            setSelectedMembers(ids);
          }
          delete service.result.staff;
          let service_available_for = 0;
          if (
            service.result.availability_mode_1 === "1" &&
            service.result.availability_mode_2 === "1"
          ) {
            service_available_for = 0;
          } else if (service.result.availability_mode_1 === "1") {
            service_available_for = 1;
          } else if (service.result.availability_mode_2 === "1") {
            service_available_for = 2;
          }
          setServiceModel({
            ...serviceModel,
            ...service.result,
            enable_online_bookings: (service.result === "0" && false) || true,
            service_available_for,
          });
        } else {
          toast.error(service && service.message);
        }
      }
      setLoader(false);
    })();
    // eslint-disable-next-line
  }, []);

  const handleServiceAvailableChange = (value) => {
    if (value === 0) {
      setServiceModel({
        ...serviceModel,
        availability_mode_1: "1",
        availability_mode_2: "1",
        service_available_for: value,
      });
    } else if (value === 1) {
      setServiceModel({
        ...serviceModel,
        availability_mode_1: "1",
        availability_mode_2: "0",
        home_amount: 0,
        service_available_for: value,
      });
    } else if (value === 2) {
      setServiceModel({
        ...serviceModel,
        availability_mode_1: "0",
        availability_mode_2: "1",
        salon_amount: 0,
        service_available_for: value,
      });
    }
  };

  const handleCategoryChange = async (value) => {
    if (!value) {
      return;
    }
    const services = await getServicesListByCategory(value);
    if (services && services.code === 200) {
      const data = services.result.map((row) => ({
        label: i18n.language === "ar" ? row.name_ar : row.name_en,
        value: row.service_id,
        service_id: row.service_id,
      }));
      setServicesList([
        {
          label: t("Please Select"),
          value: null,
          service_id: 0,
        },
        ...data,
      ]);
    } else {
      toast.error(services && services.message);
    }
    setServiceModel({
      ...serviceModel,
      category_id: value,
    });
  };

  const handleServiceChange = (value) => {
    setServiceModel({
      ...serviceModel,
      service_id: value,
    });
  };

  const handlePriceChange = (value, name, index) => {
    const updatedOptions = [...serviceModel.options];
    if (!updatedOptions[index]) {
      updatedOptions[index] = {
        supplier_service_opt_id: 0,
        price_type: "fixed",
      };
    }
    updatedOptions[index][name] = value;
    setServiceModel({
      ...serviceModel,
      options: updatedOptions,
    });
  };

  const handleChange = (event, name) => {
    setServiceModel({
      ...serviceModel,
      [name]: event.target.value,
    });
  };

  const handleSelectMembers = (value, id) => {
    if (value) {
      setSelectedMembers([...selectedMembers, id]);
    } else {
      const data = selectedMembers.filter((row) => row !== id);
      setSelectedMembers(data);
    }
  };

  const handleSelectAllMembers = (value) => {
    if (value) {
      const data = members.map((row) => row.supplier_staff_id);
      setSelectedMembers(data);
    } else {
      setSelectedMembers([]);
    }
  };

  const handleSaveStaffMember = async () => {
    toast.dismiss();
    setLoader(true);
    if (simpleValidator.current.allValid()) {
      const data = { ...serviceModel };
      const updatedOptions = data.options.map((item) => ({
        ...item,
        label: item.label ? item.label : item.duration,
        label_ar: item.label_ar ? item.label_ar : item.duration,
      }));
      data.options = updatedOptions;
      data.service_staff = selectedMembers.toString();
      data.enable_online_bookings = (data.enable_online_bookings && 1) || 0;
      const result = await saveService(data);
      if (result && result.code === 200) {
        toast.success("Saved Successfully");
        onClose();
      } else {
        toast.error((result && result.message) || "Something wrong");
      }
      setLoader(false);
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      toast.error("Please enter valid details");
      setLoader(false);
    }
  };

  const handleDeleteService = async () => {
    toast.dismiss();
    Swal.fire({
      text: t(
        "Are you sure you want to delete this service? This action cannot be undone."
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeleteLoader(true);
        const result = await deleteService(
          serviceModel.supplier_id,
          serviceModel.supplier_service_id
        );
        if (result && result.code === 200) {
          Swal.fire("Deleted Successfully", "", "success");
          onClose();
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
        setDeleteLoader(false);
      }
    });
  };
  const addPrice = () => {
    setPriceLength((prev) => prev + 1);
  };
  const removePrice = (filterdIndex) => {
    let filterdOptions = [...serviceModel.options];
    filterdOptions = filterdOptions.filter(
      (_, index) => index !== filterdIndex
    );
    setServiceModel({
      ...serviceModel,
      options: filterdOptions,
    });
    setPriceLength((prev) => prev - 1);
  };
  // const handleClose = () => setSelectedPriceOption(null);
  simpleValidator.current.purgeFields();

  return (
    <div className="add-single-service-form">
      {loader && <FullPageSpinner /> || null}
      <Panel
        title={t("Basic info")}
        subtitle={t("Add a service name and choose the treatment type.")}
      >
        <Container fluid className="basic-info">
          <Row>
            <Col md="7">
              <SearchableDropdown
                required
                onChange={(value) => handleCategoryChange(value.value)}
                name="category_id"
                value={
                  serviceCategories.find(
                    (row) =>
                      row.value === (serviceModel && serviceModel.category_id)
                  ) || ""
                }
                options={serviceCategories}
                placeholder={t("Select Category")}
                label={t("Select Category")}
              />
              {simpleValidator.current.message(
                "category",
                serviceModel && serviceModel.category_id,
                "required"
              )}
              <SearchableDropdown
                required
                onChange={(value) => handleServiceChange(value.value)}
                name="service_id"
                placeholder={t("Select treatment type")}
                value={
                  servicesList.find(
                    (row) =>
                      row.value === (serviceModel && serviceModel.service_id)
                  ) || ""
                }
                options={servicesList}
                label={t("Treatment type")}
                classNames="mt-2"
              />
              {simpleValidator.current.message(
                "service_name",
                serviceModel && serviceModel.service_id,
                "required"
              )}
              
              <UserInput
              label={`${t('Brand Name')} (EN)`}
              name="brand_name_en"
              placeholder={`${t('Brand Name')} (EN)`}
              value={serviceModel.brand_name_en}
                                onChange={(event) => handleChange(event, "brand_name_en")}

            />
             <UserInput
              label={`${t('Brand Name')} (AR)`}
              name="brand_name_ar"
              placeholder={`${t('Brand Name')} (AR)`}
              value={serviceModel.brand_name_ar}
                                onChange={(event) => handleChange(event, "brand_name_ar")}

            />
              <UserInput
                customInputClass="user-input mt-2"
                label={t("Service description (Optional)")}
                placeholder={t("Service description (Optional)")}
                name="description"
                value={serviceModel.description}
                onChange={(event) => handleChange(event, "description")}
              />
                <UserInput
                  customInputClass="user-input mt-2"
                  label={t("Service arabic description (Optional)")}
                  placeholder={t("Service arabic description (Optional)")}
                  name="arb_description"
                  value={serviceModel.arb_description}
                  onChange={(event) => handleChange(event, "arb_description")}
                />
              <CustomDropdown
                onChange={(value) => handleServiceAvailableChange(value.value)}
                name="service_available_for"
                value={
                  options1.find(
                    (row) =>
                      row.value ===
                      (serviceModel && serviceModel.service_available_for)
                  ) || ""
                }
                options={options1}
                label={t("Service Available For")}
              />
              {simpleValidator.current.message(
                "service_available_for",
                serviceModel && serviceModel.service_available_for,
                "required"
              )}
            </Col>
          </Row>
        </Container>
      </Panel>

      <Panel
        title={t("Online booking")}
        subtitle={t("Choose the locations where this staff member works.")}
      >
        <Container fluid>
          <Row>
            <Col md="1">
              <SwitchInput
                onChange={(event) => {
                  setServiceModel({
                    ...serviceModel,
                    enable_online_bookings: event.target.checked,
                  });
                }}
                checked={serviceModel.enable_online_bookings}
              />
            </Col>
            <Col>{t("Enable online bookings")}</Col>
          </Row>
        </Container>
      </Panel>

      <Panel
        title={t("Staff")}
        subtitle={t("Assign staff to the service and manage staff commission")}
      >
        <Container fluid className={"staff-select"}>
          <Row>
            <Col>
              <div>
                <CheckBoxInput
                  onChange={(event) =>
                    handleSelectAllMembers(event.target.checked)
                  }
                  checked={selectedMembers.length === members.length || false}
                >
                  {t("Select All")} <small>({selectedMembers.length})</small>
                </CheckBoxInput>
              </div>
            </Col>
          </Row>
          <Row className="members-row">
            {members.map((row) => (
              <Col md="6">
                <div>
                  <CheckBoxInput
                    onChange={(event) =>
                      handleSelectMembers(
                        event.target.checked,
                        row.supplier_staff_id
                      )
                    }
                    checked={
                      selectedMembers.find(
                        (id) => id === row.supplier_staff_id
                      ) || false
                    }
                  >
                    <div className="image-object">
                      <img src={row.profile_pic_url} alt="" />
                      <div className="content">{row.staff_name}</div>
                    </div>
                  </CheckBoxInput>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Panel>
      <Panel
        title={t("Pricing and Duration")}
        subtitle={t("Add the pricing options and duration of the service.")}
      >
        <Container fluid className={"pricing-container"}>
          {Array.from({ length: priceLength }).map((_, i) => (
            <Card className="pricing-card">
              <div className="price-heading">
                <h5>{t('Pricing option')} {i + 1} </h5>
                {priceLength > 1 && i !== 0 ? (
                  <Image src={cancel} onClick={() => removePrice(i)} />
                ) : null}
              </div>
              <Row className="mt">
                <Col lg="4" md="6" sm="6" xs="12">
                  <UserInput
                    label={t('Pricing Name(eng)')}
                    placeholder={t("Pricing Name(eng)")}
                    name="label"
                    value={serviceModel.options[i]?.label ?? ""}
                    onChange={(event) =>
                      handlePriceChange(event.target.value, "label", i)
                    }
                  />
                </Col>
                <Col lg="4" md="6" sm="6" xs="12">
                  <UserInput
                    label={t('Pricing Name(arb)')}
                    placeholder={t("Pricing Name(arb)")}
                    name="label_ar"
                    value={serviceModel.options[i]?.label_ar ?? ""}
                    onChange={(event) =>
                      handlePriceChange(event.target.value, "label_ar", i)
                    }
                  />
                </Col>
              </Row>
              <Row className="mt">
                <Col
                  className="input-dropdown-button"
                  lg="4"
                  md="6"
                  sm="6"
                  xs="12"
                >
                  <SearchableDropdown
                    required
                    onChange={(value) =>
                      handlePriceChange(value.value, "duration", i)
                    }
                    name="duration"
                    value={
                      durationOptions.find(
                        (row) =>
                          row.value === +serviceModel.options[i]?.duration
                      ) || ""
                    }
                    options={durationOptions}
                    label={t("Duration")}
                    placeholder={t('Select Duration')}
                    sublabel={t("Time before the service.")}
                  />
                  {simpleValidator.current.message(
                    `option ${i + 1} duration`,
                    serviceModel.options[i]?.duration,
                    "required"
                  )}
                </Col>
                <Col lg="4" md="6" sm="6" xs="12">
                  <SearchableDropdown
                    required
                    placeholder={t('Select Extra Time')}
                    onChange={(value) =>
                      handlePriceChange(value.value, "transition_duration", i)
                    }
                    name="transition_duration"
                    value={
                      durationOptions.find(
                        (row) =>
                          row.value ===
                          +serviceModel.options[i]?.transition_duration
                      ) || ""
                    }
                    options={durationOptions}
                    label={t("Extra time")}
                    sublabel={t("Extra time after the service.")}
                  />
                  {simpleValidator.current.message(
                    `option ${i + 1} extra time`,
                    serviceModel.options[i]?.transition_duration,
                    "required"
                  )}
                </Col>
              </Row>
              <Row className="mt">
                <Col
                  className="input-dropdown-button"
                  lg="4"
                  md="6"
                  sm="6"
                  xs="12"
                >
                  <CustomDropdown
                    options={priceTypeOptions}
                    label={t("Price type")}
                    disabled={true}
                    value={priceTypeOptions.find(
                      (item) =>
                        item.value === serviceModel.options[i]?.price_type
                    )}
                    onChange={(value) =>
                      handlePriceChange(value.value, "price_type", i)
                    }
                  />
                  {simpleValidator.current.message(
                    `option ${i + 1} price type`,
                    serviceModel.options[i]?.price_type,
                    "required"
                  )}
                </Col>
                {serviceModel?.availability_mode_1 === "1" ? (
                  <Col className="user-input-wrap" lg="4" md="6" sm="6" xs="12">
                    <UserInput
                      required
                      label={t("Saloon Price")}
                      placeholder={t("Saloon Price")}
                      name="salon_amount"
                      type="number"
                      // disabled={
                      //   serviceModel.options[i]?.price_type === "free"
                      // }
                      value={serviceModel.options[i]?.salon_amount ?? ""}
                      onChange={(event) =>
                        handlePriceChange(event.target.value, "salon_amount", i)
                      }
                    />
                    {simpleValidator.current.message(
                      `option ${i + 1} salon amount`,
                      serviceModel.options[i]?.salon_amount,
                      "required"
                    )}
                  </Col>
                ) : null}
                {serviceModel?.availability_mode_2 === "1" ? (
                  <Col className="user-input-wrap" lg="4" md="6" sm="6" xs="12">
                    <UserInput
                      required
                      label={t("Home Price")}
                      placeholder={t("Home Price")}
                      type="number"
                      name="home_amount"
                      value={serviceModel.options[i]?.home_amount ?? ""}
                      // disabled={
                      //   serviceModel.options[i]?.price_type === "free"
                      // }
                      onChange={(event) =>
                        handlePriceChange(event.target.value, "home_amount", i)
                      }
                    />
                    {simpleValidator.current.message(
                      `option ${i + 1} home amount`,
                      serviceModel.options[i]?.home_amount,
                      "required"
                    )}
                  </Col>
                ) : null}
              </Row>

              {/* <Row>
                <Col>
                  <span onClick={() => setSelectedPriceOption(i)}>
                    Advanced pricing options
                  </span>
                </Col>
              </Row> */}
            </Card>
          ))}

          <Row>
            <Col>
              <span onClick={addPrice}>Add pricing option</span>
            </Col>
          </Row>
        </Container>
      </Panel>
      {/* <AdvancedPricing
        selectedPriceOption={selectedPriceOption}
        handleClose={handleClose}
        option={
          selectedPriceOption ? serviceModel.options[selectedPriceOption] : {}
        }
        availability_mode_1={serviceModel?.availability_mode_1}
        availability_mode_2={serviceModel?.availability_mode_2}
        durationOptions={durationOptions}
        members={members}
        priceTypeOptions={priceTypeOptions}
        updatePricingOption={updatePricingOption}
      /> */}
      <Panel title={t("Sales Settings")}>
        <Container fluid>
          <Row>
            <Col md="7">
              <div className="title">{t("Set the tax rate")}</div>
              <UserInput
                customInputClass="user-input"
                label={t("Tax Rate")}
                placeholder={t("Tax Rate")}
                name="tax_rate"
                value={serviceModel.tax_rate}
                onChange={(event) => handleChange(event, "tax_rate")}
              />
            </Col>
          </Row>
        </Container>
      </Panel>

      <Container fluid className="services mb-5 pb-5 pl-0">
        <PrimaryButton
          text={t("Save")}
          onClick={() => handleSaveStaffMember()}
          isLoading={loader}
        />
        {(edit && (
          <DangerButton
            classNames="ml-3"
            text={t("Delete")}
            onClick={() => handleDeleteService()}
            isLoading={deleteLoader}
          />
        )) ||
          null}
      </Container>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};
export default AddSingleServiceForm;
