import Axios from "../Axios";

export const login = async (data) => {
  try {
    const response = await Axios.post("/account/login", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const validateToken = async (data) => {
  try {
    const response = await Axios.post("/account/verify_token", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const registerSupplier = async (data) => {
  try {
    const response = await Axios.post("/account/register/supplier", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getNotifications = async () => {
  try {
    const response = await Axios.get("/account/notifications");
    return response.data;
  } catch (error) {
    return error;
  }
};

export const setNotificationsSeen = async (data = {}) => {
  try {
    const response = await Axios.post("/account/notification_is_seen",data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const sendResetLink = async (data) => {
  try {
    const response = await Axios.post("/account/reset_password", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const sendActivationEmail = async (id) => {
  try {
    const response = await Axios.get(`account/send_verification_email/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await Axios.post("/account/set_new_password", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const sendOtp = async (data) => {
  try {
    const response = await Axios.post("/login/send_otp", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const verifyOtp = async (data) => {
  try {
    const response = await Axios.post("/login/verify_otp", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getToken = async () => {
  try {
    const response = await Axios.get(
      "/app/token/10ec65960dc7dc6090cd210ec65960dc7dc6090cd2"
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
