import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { capitalCase } from "capital-case";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DateInput from "../../components/DateInput";
import SearchInput from "../../components/Inputs/Search";
import caretDown from "../../assets/images/caret-down.svg";
import caretUp from "../../assets/images/caret-up.svg";
import rightArrow from "../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../assets/images/Icon ionic-ios-arrow-forward-2.png";
import { getLogs } from "../../core/Api/Settings";
import "./Styles.scss";
import moment from "moment";

function Logs() {
  const { t } = useTranslation();
  const pageSize = 10;
  const [data, setData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filterSchema, setFilterSchema] = useState({
    date_from: null,
    date_to: null,
  });
  const filteredData = data[selectedPage - 1]?.length
    ? data[selectedPage - 1]
    : [];
  const offset = (selectedPage - 1) * 10;
  const timeoutRef = useRef(null);
  const dataLengthRef = useRef(null);
  const pageCount = dataLengthRef.current
    ? Math.ceil(dataLengthRef.current / pageSize)
    : 0;
  const startPageIndex = useRef(selectedPage);
  const pageToShow = pageCount > 10 ? 10 : pageCount;

  const debouncedFetchLogs = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      fetchLogs();
    }, 500);
  };

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line
  }, [offset, filterSchema]);

  useEffect(() => {
    debouncedFetchLogs();
    // eslint-disable-next-line
  }, [searchInput]);

  const fetchLogs = async () => {
    setIsLoading(true);
    toast.dismiss();
    try {
      const result = await getLogs(
        pageSize,
        offset,
        searchInput.trim(),
        filterSchema.date_from,
        filterSchema.date_to
      );
      if (result?.code === 200) {
        const newData = [...data];
        newData[selectedPage - 1] = result.result.result_data;
        setData(newData);
        dataLengthRef.current = result.result.total_records;
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "something went wrong");
    }
    setIsLoading(false);
  };

  const handleDateChange = (date, name) => {
    setFilterSchema({
      ...filterSchema,
      [name]: new Date(date),
    });
  };

  const handleSearchChange = (e) => setSearchInput(e.target.value);

  const sortCaret = (order) => (
    <span className="caret-icon">
      <img src={order === "asc" ? caretUp : caretDown} alt="" />
    </span>
  );

  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      sortCaret,
    },
    {
      dataField: "api_key",
      text: "Api Key",
      sort: true,
      sortCaret,
      formatter: (cell, row) => (row.api_key ? row.api_key : "NA"),
    },
    {
      dataField: "authorized",
      text: "Authorized",
      sort: true,
      sortCaret,
    },
    {
      dataField: "ip_address",
      text: "IP Address",
      sort: true,
      sortCaret,
    },
    {
      dataField: "method",
      text: "Method",
      sort: true,
      sortCaret,
      formatter: (cell, row) => capitalCase(row.method),
    },
    {
      dataField: "params",
      text: "Params",
      sort: true,
      sortCaret,
    },
    {
      dataField: "response_code",
      text: "Response Code",
      sort: true,
      sortCaret,
    },
    {
      dataField: "time",
      text: "Date",
      sort: true,
      sortCaret,
      formatter: (cell, row) =>
        moment(new Date(row.time * 1000)).format("YYYY-MM-DD"),
    },
    {
      dataField: "uri",
      text: "URI",
      sort: true,
      sortCaret,
    },
  ];

  // pagination

  const handlePageToggle = (action) => {
    let newPage = selectedPage;
    switch (action) {
      // case "first":
      //   newPage = 1;
      //   if (startPageIndex.current !== 1) {
      //     startPageIndex.current = 1;
      //   }
      //   break;
      // case "last":
      //   newPage = pageCount;
      //   startPageIndex.current = pageCount - 4;
      //   break;
      case "next":
        newPage = selectedPage + 1 < pageCount ? selectedPage + 1 : newPage;
        if (newPage > pageToShow && startPageIndex.current <= newPage - 5) {
          startPageIndex.current = newPage;
        }
        break;
      case "prev":
        newPage = selectedPage - 1 > 0 ? selectedPage - 1 : newPage;
        if (startPageIndex.current > newPage) {
          startPageIndex.current = startPageIndex.current - 5;
        }
        break;
      default:
        break;
    }
    setSelectedPage(newPage);
  };

  const renderPages = () => {
    const pages = Array.from({ length: pageToShow }).map(
      (_, index) => startPageIndex.current + index
    );
    const pageOutput = pages.map((pageNumber) => (
      <span
        className={` cursor-pointer ${selectedPage === pageNumber && "active"}`}
        key={`page-${pageNumber}`}
        active={pageNumber === selectedPage}
        onClick={() => handlePageClick(pageNumber)}
      >
        {pageNumber}
      </span>
    ));
    if (pageCount > pageToShow) {
      if (pages.includes(pageCount)) {
        pageOutput.unshift(
          <span
            className={` cursor-pointer ${1 === selectedPage && "active"}`}
            key={"first-page"}
            active={1 === selectedPage}
            onClick={() => {
              startPageIndex.current = 1;
              handlePageClick(1);
            }}
          >
            {1}
          </span>,
          <span key="ellipsis">...</span>
        );
      } else {
        pageOutput.push(
          <span key="ellipsis">...</span>,
          <span
            className={` cursor-pointer ${
              pageCount === selectedPage && "active"
            }`}
            key={"last-page"}
            active={pageCount === selectedPage}
            onClick={() => {
              startPageIndex.current = pageCount - pageToShow + 1;
              handlePageClick(pageCount);
            }}
          >
            {pageCount}
          </span>
        );
      }
    }
    return pageOutput;
  };

  const handlePageClick = (pageNumber) => {
    setSelectedPage(pageNumber);
  };

  // pagination

  return (
    <React.Fragment>
      <Container fluid className="administration">
        <Row className="heading-row">
          <Col lg="3" md="6" sm="12">
            <DateInput
              label={t("From date")}
              value={filterSchema.date_from}
              onChange={(date) => handleDateChange(date, "date_from")}
            />
          </Col>
          <Col lg="3" md="6" sm="12">
            <DateInput
              label={t("To date")}
              value={filterSchema.date_to}
              onChange={(date) => handleDateChange(date, "date_to")}
            />
          </Col>
          <Col md="12" lg="5" sm="12" className="mt-3">
            <SearchInput
              classNames="pt-0"
              onChange={handleSearchChange}
              placeholder={t("Search")}
            />
          </Col>
        </Row>

        {filteredData.length ? (
          <BootstrapTable
            keyField={columns[0]}
            data={filteredData}
            columns={columns}
          />
        ) : isLoading ? (
          <div className="spinner-wrap">
            <Spinner animation="border" />
            <span>
              {t(
                `${
                  offset
                    ? `Fecthing data for Page ${selectedPage}`
                    : t("Please wait, we're loading bookings data")
                }`
              )}
            </span>
          </div>
        ) : (
          <div className="no-data-text text-center mt-2">
            {t("No data available")}
          </div>
        )}

        {!isLoading && (data[selectedPage - 1]?.length || searchInput) && (
          <Row className="pagination">
            <Col md="3 result-text">
              {data[selectedPage - 1]?.length
                ? searchInput
                  ? data.length
                    ? `Showing ${offset + 1} to ${
                        offset + data[selectedPage - 1]?.length
                      } Results for ${searchInput}`
                    : t("No match found")
                  : t(
                      `Showing ${offset + 1} to ${
                        offset + data[selectedPage - 1]?.length
                      } Results`
                    )
                : t("No Resuts found")}
            </Col>

            {!isLoading && data[selectedPage - 1]?.length && (
              <Col md="5" className="count">
                <div
                  className="previous"
                  onClick={() => handlePageToggle("prev")}
                >
                  <img src={leftArrow} alt="left-arrow"></img>
                </div>
                <div className="pages">{renderPages()}</div>
                <div className="next" onClick={() => handlePageToggle("next")}>
                  <img src={rightArrow} alt="right-arrow"></img>
                </div>
              </Col>
            )}
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
}
export default Logs;
