import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import {
  Tabs,
  Tab,
  Image,
  PopoverContent,
  Popover,
  Overlay,
} from "react-bootstrap";
import "./Styles.scss";
import setting from "../../assets/images/menu_icons/inactive/global-settings.png";
import { getNotifications, setNotificationsSeen } from "../../core/Api/Auth";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const Notication = (props) => {
  const { t } = useTranslation();
  const {
    show,
    toggleNotification,
    updateNotificationCount,
    setUpdateNotificationsCount
  } = props;
  const history = useHistory();
  const [notificationsList, setNotificationsList] = useState([]);
  const todaysNotifications = notificationsList.filter((row) =>
    moment(row.created_date).isSameOrAfter(moment().subtract(1, "d"))
  );
  const olderNotifications = notificationsList.filter((row) =>
    moment(row.created_date).isBefore(moment().subtract(1, "d"))
  );

  useEffect(() => {
    let interval = null;
    if (show) {
      fetchNotifications();
      interval = setInterval(fetchNotifications, 15000);
    }
    return () => interval && clearInterval(interval);
    // eslint-disable-next-line
  }, [show]);

  useEffect(() => {
    let interval = null;
    function handleClickOutside(event) {
      if (!show?.contains(event.target)) {
        interval = setTimeout(() => {
          props?.setShowNotification(null);
        }, 500);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      interval && clearInterval(interval);
    };
  }, [show]);

  const fetchNotifications = async () => {
    if (show) {
      const result = await getNotifications();
      if (result && result.code === 200) {
        setNotificationsList(result.result.notifications);
      } else {
        toast.error(result && result.message);
      }
    }
  };

  const selectNotificationHandler = async (id, notification_type_id, mapping_id) => {
    if(id){
      const result = await setNotificationsSeen({
        notification_id:id
      });
      if (result && result.code === 200) {
        localStorage.setItem("unseen-notification", 0);
        setUpdateNotificationsCount(!updateNotificationCount);
      } else {
        toast.error(result && result.message);
      }
    }
    if (notification_type_id === "1") {
      const state = history.location.state;
      history.push(`/calendar?booking_id=${mapping_id}`, {
        ...state,
        viewAppointment: true,
      });
    }
  };

  const handleViewAll = async () => {
    const seenResult = await setNotificationsSeen();
    if (seenResult && seenResult.code === 200) {
      localStorage.setItem("unseen-notification", 0);
      setUpdateNotificationsCount(!updateNotificationCount);
    }
  }

  return (
    <Overlay
      show={Boolean(show)}
      target={show}
      placement="bottom"
    // container={menuRef.current}
    >
      <Popover bsPrefix="remove-selected-item notification-popup">
        <PopoverContent>
          <div className={`notication ${show ? "show" : "hide"}`} fluid>
            {/* <img src={setting} className="gear" alt="gear" /> */}
            <Tabs defaultActiveKey="notification">
              <Tab eventKey="notification" title={t("Notifications")}>
                <div className="view-all-cont">
                  <p className="view-all" onClick={() => handleViewAll()}>Read All</p>
                </div>
                <ul className="notification-list">
                  {todaysNotifications && todaysNotifications.length ? (
                    <div>
                      <li className="day">{t("Today")}</li>
                      {todaysNotifications.map((row, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => {
                              selectNotificationHandler(
                                row.notification_id,
                                row.notification_type_id,
                                row?.mapping_id
                              );
                              // toggleNotification(null);
                            }
                            }
                          >
                            <div
                              className={clsx(
                                "media-object",
                                row.is_seen !== "1" && "unseen-notification"
                              )}
                            >
                              <Image src={row.icon} />
                              <div className="details">
                                <div className="title">
                                  <h3>{row.message}</h3>
                                  <div className="time">
                                    {moment(row.created_date).fromNow()}
                                  </div>
                                </div>
                                <div className="sub-title">{row.sub_text}</div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </div>
                  ) : null}
                  {olderNotifications && olderNotifications.length ? (
                    <div>
                      <li className="day">{t("Older")}</li>
                      {olderNotifications.map((row, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => {
                              selectNotificationHandler(
                                row.notification_id,
                                row.notification_type_id
                              );
                              toggleNotification(null);
                            }
                            }
                          >
                            <div
                              className={clsx(
                                "media-object",
                                row.is_seen !== "1" && "unseen-notification"
                              )}
                            >
                              <Image src={row.icon} />
                              <div className="details">
                                <div className="title">
                                  <h3>{row.message}</h3>
                                  <div className="time">
                                    {moment(row.created_date).fromNow()}
                                  </div>
                                </div>
                                <div className="sub-title">{row.sub_text}</div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </div>
                  ) : null}
                </ul>
              </Tab>
              {/* <Tab eventKey="update" title="Updates">
                <div style={{ textAlign: "center" }}> No Updates</div>
              </Tab> */}
            </Tabs>
          </div>
        </PopoverContent>
      </Popover>
    </Overlay>
  );
};

Notication.propTypes = {};

export default Notication;
