import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import AdminForm from "./AdminForm";
import Swal from "sweetalert2";
import { Modal, Image, Container, Row, Col, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  deleteSupplierAdmin,
  getSupplierAdmins,
} from "../../../../core/Api/supplier";
import { getAuthAttribute } from "../../../../utils/helper";
import "./Styles.scss";
import editIcon from "../../../../assets/images/Icon material-mode-edit.png";
import caretDown from "../../../../assets/images/caret-down.svg";
import caretUp from "../../../../assets/images/caret-up.svg";
import deleteIcon from "../../../../assets/images/trash.svg";
import rightArrow from "../../../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../../../assets/images/Icon ionic-ios-arrow-forward-2.png";
import SearchInput from "../../../../components/Inputs/Search";
import PrimaryButton from "../../../../components/Buttons/Primary";
import Pagination from "../../../../components/Pagination";
import { deleteCompanyAdmin, getAdminsCompanyId } from "../../../../core/Api/company";
import FullPageSpinner from "../../../../components/FullPageSpinner";

const SystemAdministrators = ({ companyId }) => {
  const { t } = useTranslation();
  const pageSize = 10;
  const [adminData, setAdminData] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const [filteredData, setFilteredData] = useState([]);

  const adminsState = {
    company_id: companyId || null,
    company_admin_id: null,
    supplier_admin_id: "0",
    first_name: "",
    last_name: "",
    username: "",
    email: "",
  };

  useEffect(() => {
    getSupplierAdminslist();
    // eslint-disable-next-line
  }, []);

  const getSupplierAdminslist = async () => {
    setIsLoading(true);
    toast.dismiss();
    try {
      const result = await getAdminsCompanyId(companyId);
      if (result.code === 200) {
        setAdminData(result?.result);
        setFilteredData(result?.result);
        setTotalCount(result?.result?.length || 0);
        setPageNo(1);
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setIsLoading(false);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
    if (e.target.value) {

      const filteredData = adminData.filter((item) =>
        `${item.first_name.toLowerCase()} ${item.last_name.toLowerCase()} ${item.email.toLowerCase()}`.includes(
          searchInput.toLowerCase()
        )
      );
      setFilteredData([...filteredData])
    } else {
      setFilteredData([...adminData])
    }
    setPageNo(1);
  };

  const handlePageChange = async (pageNo, pageSize) => {
    setPageNo(pageNo);
  };

  const handleConfirm = async (company_admin_id) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        `Are you sure you want to delete this user? This action cannot be undone.`
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        const result = await deleteCompanyAdmin({
          company_admin_id,
        });
        setIsLoading(false)
        if (result && result.code === 200) {
          await getSupplierAdminslist();
          toast.success(result.message);
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
    });
  };

  const sortCaret = (order) => (
    <span className="caret-icon">
      <img src={order === "asc" ? caretUp : caretDown} alt="" />
    </span>
  );

  const formatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="otherWrap">
        <Image
          className="edit"
          onClick={() => setSelectedAdmin(row)}
          src={editIcon}
          alt="edit"
        />
        <Image
          onClick={() => handleConfirm(row.company_admin_id)}
          src={deleteIcon}
          alt="delete"
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
      sortCaret,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
      sortCaret,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret,
    },
    {
      dataField: "other",
      text: "Action",
      sort: false,
      formatter: formatter,
    },
  ];
  const handleClose = () => setSelectedAdmin(null);

  return (
    <Container>
      <div className="table-wrapper">
        {isLoading && <FullPageSpinner /> || null}
        <Row className="search-wrap">
          <Col xl="3" lg="5" md="7" sm="12">
            <SearchInput
              classNames="pt-0"
              onChange={handleSearchChange}
              placeholder={t("Search")}
            />
          </Col>
          <Col
            xl="6"
            lg="6"
            md="5"
            sm="12"
            className="add-btn d-flex justify-content-end"
          >
            <PrimaryButton
              onClick={() => setSelectedAdmin({ ...adminsState })}
              classNames="add-new-Admin"
              text={t("Add New Administrator")}
            />
          </Col>
        </Row>
        {filteredData.length ? (
          <BootstrapTable
            keyField={columns[0]}
            data={filteredData}
            columns={columns}
          />
        ) : isLoading ? (
          <div className="spinner-wrap">
            <Spinner animation="border" />
            <span>{t("Please wait, we're loading blacklisted keywords")}</span>
          </div>
        ) : (
          <div className="no-data-container">
            <h4>
              {searchInput
                ? t("No matching administrator found")
                : t("No administrator available")}
            </h4>
          </div>
        )}
      </div>
      <Row className="pagination">
        <Pagination
          totalCount={filteredData?.length || 0}
          viewCount={filteredData?.length || 0}
          pageNo={pageNo}
          onChangePage={handlePageChange}
        />
      </Row>

      {selectedAdmin && <Modal show={selectedAdmin} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Manage Administrator</Modal.Title>
        </Modal.Header>
        <AdminForm
          getSupplierAdminslist={getSupplierAdminslist}
          handleClose={handleClose}
          selectedAdmin={selectedAdmin}
        />
      </Modal> || null}
    </Container>
  );
};

export default SystemAdministrators;
