
import { useContext, useEffect, useState } from 'react';
import './ProfileCompletionStyles.scss';
import { Modal } from "react-bootstrap";
import PC1 from "../assets/images/pc-1.svg";
import PC2 from "../assets/images/pc-2.svg";
import PC3 from "../assets/images/pc-3.svg";
import PC4 from "../assets/images/pc-4.svg";
import PC5 from "../assets/images/pc-5.svg";
import PC6 from "../assets/images/pc-6.svg";
import { useHistory } from 'react-router-dom';
import BoonContext from '../context';
import { useTranslation } from 'react-i18next';

const ProfileCompletionDetails = ({
	showProfileDetailsModal = false,
	setShowProfileDetailsModal,
	profileStats
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [show, setShow] = useState(false);
	const [completedStepsCount, setCompletedStepsCount] = useState(0)
	const { setIWillDoLater } = useContext(BoonContext);

	useEffect(() => {
		setShow(showProfileDetailsModal);
		let count = 0;
		if (profileStats?.has_services === "1") {
			count += 1;
		}
		if (profileStats?.has_staff === "1") {
			count += 1;
		}
		if (profileStats?.has_staff_shifts === "1") {
			count += 1;
		}
		if (profileStats?.has_working_hours === "1") {
			count += 1;
		}
		setCompletedStepsCount(count)

	}, [showProfileDetailsModal, profileStats]);

	const currentStatusIcon = () => (
		<img
			src={PC2}
			className="current-bar-status-pic"
			alt="PC1"
			width="200px"
		/>
	);

	return (
		<>
			{show &&
				<Modal
					size="lg"
					show={show}
					onHide={() => setShow(false)}
					backdrop="static"
					keyboard={false}
					centered
				>
					<Modal.Body>
						<div className='profile-completion-details-cont'>
							<div className="profile-completion-body">
								<div className="profile-completion-body-cont">
									<div className="profile-completion-header">
										<p>{t('Boon Profile Strength')} <span></span></p>
									</div>

									<div className='top-status-bar-cont'>
										<div className="top-bar-step-1">
											<img
												src={PC2}
												className="PC1"
												alt="PC1"
												width="200px"
											/>
										</div>
										<div className='bars-container'>
											<div className={`top-bar-step-2 mr-1 ${completedStepsCount <= 0 && 'incomplete-bar-color' || ''}`}>
												{completedStepsCount === 1 && currentStatusIcon() || null}
											</div>
											<div className={`top-bar-step-3 mr-1 ${completedStepsCount <= 1 && 'incomplete-bar-color' || ''}`}>
												{completedStepsCount === 2 && currentStatusIcon() || null}
											</div>
											<div className={`top-bar-step-4 mr-1 ${completedStepsCount <= 2 && 'incomplete-bar-color' || ''}`}>
												{completedStepsCount === 3 && currentStatusIcon() || null}
											</div>
											<div className={`top-bar-step-5 mr-1 ${completedStepsCount <= 3 && 'incomplete-bar-color' || ''}`}>
											</div>
										</div>
										<div className="top-bar-step-6">
											<img
												src={PC3}
												className="PC1"
												alt="PC1"
												width="200px"
											/>
										</div>
									</div>

									{/* Step 1, Business Hours */}
									<div className='steps-cont'>
										<div className='left-sec'>
											<p className={`text-nowrap ${profileStats?.has_working_hours !== '1' && 'incompleted' || ''}`}>{t('Step 1')}</p>
										</div>
										<div className='middle-sec'>
											<div className='middle-sec-cont'>
												{profileStats?.has_working_hours === '1' && (
													<img className='desc-img' src={PC3} alt="PC4" />
												) || (
														<img className='desc-img' src={PC6} alt="PC4" />
													)}
												<div className='desc-cont'>
													<p className='desc-header'>{t('Fill Business Profile')}</p>
													<p className='desc-desc-desc'>{t('Customers prefer to look at profiles that are complete. A complete profile helps us make accurate recommendation and increase visibility')}</p>
												</div>
											</div>
										</div>
										<div className='right-sec'
											style={{
												pointerEvents: profileStats?.has_working_hours === '1' && 'none'
											}}
											onClick={() => {
												history.push('/my-businesses');
												setIWillDoLater(true);
												setShowProfileDetailsModal(false);
											}}>
											{profileStats?.has_working_hours === '1' && (
												<img src={PC4} alt="PC4" width="25px" height="25px" />
											) || (
													<img src={PC5} alt="PC4" width="25px" height="25px" />
												)}
										</div>
									</div>

									{/* Step 2, Staff Members */}
									<div className='steps-cont'>
										<div className='left-sec'>
											<p className={`text-nowrap ${profileStats?.has_staff !== '1' && 'incompleted' || ''}`}>{t('Step 2')}</p>
										</div>
										<div className='middle-sec'>
											<div className='middle-sec-cont'>
												{profileStats?.has_staff === '1' && (
													<img className='desc-img' src={PC3} alt="PC4" />
												) || (
														<img className='desc-img' src={PC6} alt="PC4" />
													)}
												<div className='desc-cont'>
													<p className='desc-header'>{t('Add Staffs')}</p>
													<p className='desc-desc-desc'>{t('Customers prefer to look at profiles that are complete. A complete profile helps us make accurate recommendation and increase visibility')}</p>
												</div>
											</div>
										</div>
										<div className='right-sec'
											style={{
												pointerEvents: profileStats?.has_staff === '1' && 'none'
											}}
											onClick={() => {
												history.push('/staff');
												setIWillDoLater(true);
												setShowProfileDetailsModal(false);
											}}>
											{profileStats?.has_staff === '1' && (
												<img src={PC4} alt="PC4" width="25px" height="25px" />
											) || (
													<img src={PC5} alt="PC4" width="25px" height="25px" />
												)}
										</div>
									</div>

									{/* Step 3, working hours */}
									<div className='steps-cont'>
										<div className='left-sec'>
											<p className={`text-nowrap ${profileStats?.has_staff_shifts !== '1' && 'incompleted' || ''}`}>{t('Step 3')}</p>
										</div>
										<div className='middle-sec'>
											<div className='middle-sec-cont'>
												{profileStats?.has_staff_shifts === '1' && (
													<img className='desc-img' src={PC3} alt="PC4" />
												) || (
														<img className='desc-img' src={PC6} alt="PC4" />
													)}
												<div className='desc-cont'>
													<p className='desc-header'>{t('Add Working Hours')}</p>
													<p className='desc-desc-desc'>{t('Customers prefer to look at profiles that are complete. A complete profile helps us make accurate recommendation and increase visibility')}</p>
												</div>
											</div>
										</div>
										<div className='right-sec'
											style={{
												pointerEvents: profileStats?.has_staff_shifts === '1' && 'none'
											}}
											onClick={() => {
												history.push('/staff');
												setIWillDoLater(true);
												setShowProfileDetailsModal(false);
											}}>
											{profileStats?.has_staff_shifts === '1' && (
												<img src={PC4} alt="PC4" width="25px" height="25px" />
											) || (
													<img src={PC5} alt="PC4" width="25px" height="25px" />
												)}
										</div>
									</div>

									{/* Step 3, services */}
									<div className='steps-cont'>
										<div className='left-sec'>
											<p className={`text-nowrap ${profileStats?.has_services !== '1' && 'incompleted' || ''}`}>{t('Step 4')}</p>
										</div>
										<div className='middle-sec'>
											<div className='middle-sec-cont'>
												{profileStats?.has_services === '1' && (
													<img className='desc-img' src={PC3} alt="PC4" />
												) || (
														<img className='desc-img' src={PC6} alt="PC4" />
													)}
												<div className='desc-cont'>
													<p className='desc-header'>{t('Add Services')}</p>
													<p className='desc-desc-desc'>{t('Customers prefer to look at profiles that are complete. A complete profile helps us make accurate recommendation and increase visibility')}</p>
												</div>
											</div>
										</div>
										<div className='right-sec'
											style={{
												pointerEvents: profileStats?.has_services === '1' && 'none'
											}}
											onClick={() => {
												history.push('/service');
												setIWillDoLater(true);
												setShowProfileDetailsModal(false);
											}}>
											{profileStats?.has_services === '1' && (
												<img src={PC4} alt="PC4" width="25px" height="25px" />
											) || (
													<img src={PC5} alt="PC4" width="25px" height="25px" />
												)}
										</div>
									</div>



								</div>
							</div>
							<div className="profile-completion-footer">
								<div className="profile-completion-footer-cont">
									<div className="profile-completion-do-later" onClick={() => {
										setIWillDoLater(true);
										setShowProfileDetailsModal(false);
										}}>
										<p >{t("I'll do later")}</p>
									</div>
									{/* <div className="profile-completion-lets-do" onClick={() => setShow(false)}>
										<p >Continue</p>
									</div> */}
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal> || null}
		</>
	)
}

export default ProfileCompletionDetails;