import React, { useState, useEffect, useRef } from "react";
import SearchInput from "../../components/Inputs/Search";
import PrimaryButton from "../../components/Buttons/Primary";
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import Hoc from "../Hoc";
import "./Styles.scss";
import { useTranslation } from "react-i18next";
import deactivateIcon from "../../assets/images/eye-slash-fill.svg";
import activateIcon from "../../assets/images/eye-fill.svg";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { activateCompany, deactivateCompany, getCompanies } from "../../core/Api/company";
import CustomTableComponent from "../../components/CustomTableComponent";
import Pagination from "../../components/Pagination";
import { useHistory } from "react-router-dom";
import FullPageSpinner from "../../components/FullPageSpinner";


const Companies = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const deleteClickRef = useRef(null);
  const offset = 0;
  const pageSize = 10;

  const [searchInput, setSearchInput] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [companies, setCompanies] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    loadData(pageSize, offset,)
  }, []);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const loadData = async (per_page = 10, offset = 0, search_phrase = '') => {
    setIsLoading(true);
    const result = await getCompanies({
      per_page,
      offset,
      search_phrase
    });
    if (result && result.code === 200) {
      setCompanies(result?.result?.result_data || []);
      setTotalCount(result?.result?.total_records || 0);
    } else {
      toast.error(result && result.message);
    }
    setIsLoading(false);
  };

  const handleToggleActive = (id, status) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        `Are you sure you want to ${status === "2" && 'activate' || 'block'} this company?`
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Ok"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        let result = {};
        if (status === "2") {
          result = await activateCompany({
            company_id: id,
          })
        } else {
          result = await deactivateCompany({
            company_id: id
          })
        }
        if (result && result.code === 200) {
          Swal.fire(`${status === "2" && 'Activated' || 'Blocked'} Successfully`, "", "success");
          loadData(pageSize, offset, searchInput)
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
      deleteClickRef.current = false;
    });
  }

  const handlePageChange = (pageNo, pageSize) => {
    setPageNo(pageNo);
    loadData(pageSize, (pageNo - 1) * pageSize, searchInput)
  };

  const onRowClick = (row, index) => {
    return !deleteClickRef.current && history.push(`/company/${row?.company_id}`)
  }

  const statusColFormatter = (row, key) => {
    if (row.status_id === "1") {
      return <Image
        className="delete"
        src={deactivateIcon}
        alt="delete"
        onClick={() => {
          deleteClickRef.current = true;
          handleToggleActive(row.company_id, row.status_id);
        }}
      />
    } else {
      return <Image
        className="delete"
        src={activateIcon}
        alt="delete"
        onClick={() => {
          deleteClickRef.current = true;
          handleToggleActive(row.company_id, row.status_id);
        }}
      />
    }
  }

  const columns = [
    {
      name: t("Company Name"),
      key: 'company_name',
      formatter: null,
    },
    {
      name: t("Contact Person"),
      key: 'contact_person_1',
      formatter: null,
    },
    {
      name: t("VAT Number"),
      key: 'vat_number',
      formatter: null,
    },
    {
      name: t("Reg. No"),
      key: 'company_cr_no',
      formatter: null,
    },
    {
      name: t("Settlement Freq."),
      key: 'settlement_frequency',
      formatter: (row, key) => {
        return <span >{row[key]?.toUpperCase()}</span>
      },
    },
    {
      name: t("Status"),
      key: 'application_type',
      formatter: statusColFormatter,
    },
  ]


  return (
    <React.Fragment>
      <Hoc activeMenu="companies">
        <Container fluid className="companies">
          <Row className="heading-row">
            <Col xl="3" lg="4" md="6" sm="12">
              {/* <SearchInput
                classNames="pt-0"
                onChange={handleSearchChange}
                placeholder={t("Search")}
              /> */}
            </Col>
            <Col md="auto" className="cta-col">
              <PrimaryButton text={t("Add new")}
                onClick={() => { history.push('/company') }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {isLoading && (
                <div className="spinner-wrap">
                  <FullPageSpinner animation="border" />
                  <span>
                    {t("Please wait, we're loading companies data")}
                  </span>
                </div>)
              }
              {!isLoading && totalCount === 0 && (
                <div className="no-data-text text-center mt-2">
                  {t("No data available")}
                </div>
              ) || null}
            </Col>
          </Row>
          <Row className="mt-3">
            {totalCount > 0 &&
              (<CustomTableComponent
                responsive={true}
                hover={true}
                showSequence={true}
                pageSize={pageSize}
                pageNo={pageNo}
                columns={columns || []}
                data={companies || []}
                onRowClick={onRowClick}
              />) || null}
          </Row>
          <Row>
            <Pagination
              totalCount={totalCount || 0}
              viewCount={companies?.length || 0}
              pageNo={pageNo}
              onChangePage={handlePageChange}
            />
          </Row>
        </Container>
      </Hoc>
    </React.Fragment>
  );
};
export default Companies;
