import StaticProfile from "../assets/images/Icon ionic-md-globe.png";

import StaticDashboard from "../assets/images/menu_icons/inactive/dashboard.svg";
import ActiveDashboard from "../assets/images/menu_icons/active/dashboard.svg";

import StaticCalendar from "../assets/images/menu_icons/inactive/calendar.svg";
import ActiveCalendar from "../assets/images/menu_icons/active/calendar.svg";

import StaticBooking from "../assets/images/menu_icons/inactive/bookings.svg";
import ActiveBooking from "../assets/images/menu_icons/active/bookings.svg";

import StaticDisptBooking from "../assets/images/menu_icons/inactive/disputed-bookings.svg";
import ActiveDisptBooking from "../assets/images/menu_icons/active/disputed-bookings.svg";


import ActiveServices from "../assets/images/menu_icons/active/services.svg";
import StaticServices from "../assets/images/menu_icons/inactive/services.svg";

import StaticOffer from "../assets/images/menu_icons/inactive/offers-and-promotions.svg";
import ActiveOffer from "../assets/images/menu_icons/active/offers-and-promotions.svg";

import StaticReview from "../assets/images/menu_icons/inactive/reviews.svg";
import ActiveReview from "../assets/images/menu_icons/active/reviews.svg";

import ActiveMyBusiness from "../assets/images/menu_icons/active/my-business.svg";
import StaticMyBusiness from "../assets/images/menu_icons/inactive/my-business.svg";

import ActiveStaff from "../assets/images/menu_icons/active/staff.svg";
import StaticStaff from "../assets/images/menu_icons/inactive/staff.svg";

import ActiveClients from "../assets/images/menu_icons/active/clients.svg";
import StaticClients from "../assets/images/menu_icons/inactive/clients.svg";

import ActiveGallery from "../assets/images/menu_icons/active/image-gallary.svg";
import StaticGallery from "../assets/images/menu_icons/inactive/image-gallary.svg";

import ActivePayouts from "../assets/images/menu_icons/active/payout.svg";
import StaticPayouts from "../assets/images/menu_icons/inactive/payout.svg";

import ActiveSupplierDriver from "../assets/images/menu_icons/active/supplier-driver.svg";
import StaticSupplierDriver from "../assets/images/menu_icons/inactive/supplier-driver.svg";

import ActiveDeliveryFee from "../assets/images/menu_icons/active/delivery-fees.svg";
import StaticDeliveryFee from "../assets/images/menu_icons/inactive/delivery-fees.svg";

import ActiveBlacklistedKeywords from "../assets/images/menu_icons/active/blocklist-keywords.svg";
import StaticBlacklistedKeywords from "../assets/images/menu_icons/inactive/blocklist-keywords.svg";

import ActiveGlobalSetting from "../assets/images/menu_icons/active/global-settings.svg";
import StaticGlobalSettings from "../assets/images/menu_icons/inactive/global-settings.svg";

import ActivePermissions from "../assets/images/menu_icons/active/permission.svg";
import StaticPermissions from "../assets/images/menu_icons/inactive/permission.svg";

import ActiveBookTrans from "../assets/images/menu_icons/active/transactions.svg";
import StaticBookTrans from "../assets/images/menu_icons/inactive/transactions.svg";

import ActiveComplains from "../assets/images/menu_icons/active/complains.svg";
import StaticComplains from "../assets/images/menu_icons/inactive/complains.svg";

import ActivePartner from "../assets/images/menu_icons/active/partners.png";
import StaticPartners from "../assets/images/menu_icons/inactive/partners.png";

import ActiveGroup from "../assets/images/menu_icons/active/add-group.svg";
import StaticGroup from "../assets/images/menu_icons/inactive/add-group.svg";

import ActiveEmployee from "../assets/images/menu_icons/active/employees.svg";
import StaticEmployee from "../assets/images/menu_icons/inactive/employees.svg";

import ActiveServiceMap from "../assets/images/menu_icons/active/service-map.svg";
import StaticServiceMap from "../assets/images/menu_icons/inactive/service-map.svg";

import ActiveCompany from "../assets/images/menu_icons/active/company.svg";
import StaticCompany from "../assets/images/menu_icons/inactive/company.svg";

import ActiveASPReport from "../assets/images/menu_icons/active/Average-Service-Price-Report.svg";
import StaticASPReport from "../assets/images/menu_icons/inactive/Average-Service-Price-Report.svg";

import ActiveSellers from "../assets/images/menu_icons/active/sellers.svg";
import StaticSellers from "../assets/images/menu_icons/inactive/sellers.svg";

import ActiveSales from "../assets/images/menu_icons/active/sales.svg";
import StaticSales from "../assets/images/menu_icons/inactive/sales.svg";

import ActiveServiceCategories from "../assets/images/menu_icons/active/service-categories.svg";
import StaticServiceCategories from "../assets/images/menu_icons/inactive/service-categories.svg";


const Constants = {
  // supplier menu
  Navigation: [
    {
      id: "dashboard",
      name: "Dashboard",
      staticIcon: StaticDashboard,
      activeIcon: ActiveDashboard,
      link: "/dashboard",
    },
    {
      id: "my_businesses",
      name: "My Business",
      staticIcon: StaticMyBusiness,
      activeIcon: ActiveMyBusiness,
      link: "/my-businesses",
    },
    {
      id: "staff",
      name: "Staff",
      staticIcon: StaticStaff,
      activeIcon: ActiveStaff,
      link: "/staff",
    },
    {
      id: "service",
      name: "Services",
      staticIcon: StaticServices,
      activeIcon: ActiveServices,
      link: "/service",
    },
    {
      id: "calender",
      name: "Calendar",
      staticIcon: StaticCalendar,
      activeIcon: ActiveCalendar,
      link: "/calendar",
    },
    {
      id: "client",
      name: "Client",
      staticIcon: StaticClients,
      activeIcon: ActiveClients,
      link: "/client",
    },
    {
      id: "images",
      name: "Image Gallery",
      staticIcon: StaticGallery,
      activeIcon: ActiveGallery,
      link: "/images",
    },
    {
      id: "offers",
      name: "Offers and Promotions",
      staticIcon: StaticOffer,
      activeIcon: ActiveOffer,
      link: "/offers",
    },
    {
      id: "bookings",
      name: "Bookings",
      staticIcon: StaticBooking,
      activeIcon: ActiveBooking,
      link: "/bookings",
    },
    {
      id: "reviews",
      name: "Reviews",
      staticIcon: StaticReview,
      activeIcon: ActiveReview,
      link: "/reviews",
    },
    {
      id: "payouts",
      name: "Payouts",
      staticIcon: StaticPayouts,
      activeIcon: ActivePayouts,
      link: "/payouts",
    },
    {
      id: "supplier_driver",
      name: "Supplier Driver",
      staticIcon: StaticSupplierDriver,
      activeIcon: ActiveSupplierDriver,
      link: "/supplier-driver",
    },
    {
      id: "delivery",
      name: "Delivery fee",
      staticIcon: StaticDeliveryFee,
      activeIcon: ActiveDeliveryFee,
      link: "/delivery-fee",
    },
    {
      id: "service_area",
      name: "Service Area",
      staticIcon: StaticServiceMap,
      activeIcon: ActiveServiceMap,
      link: "/service-area",
    },
    {
      id: "reports",
      name: "Reports",
      staticIcon: StaticASPReport,
      activeIcon: ActiveASPReport,
      link: "",
      hasSuboptions: true,
      options: [
        {
          id: "mostUsedServicesReport",
          name: "Most Used Services Report",
          staticIcon: StaticASPReport,
          activeIcon: ActiveASPReport,
          link: "/reports/most-used-services-report",
        },
      ]
    }
  ],

  //super admin menu
  NavigationAdmin: [
    {
      id: "dashboard",
      name: "Dashboard",
      staticIcon: StaticDashboard,
      activeIcon: ActiveDashboard,
      link: "/dashboard",
    },
    {
      id: "partner",
      name: "Partners",
      staticIcon: StaticPartners,
      activeIcon: ActivePartner,
      link: "/partners",
    },
    {
      id: "companies",
      name: "Company",
      staticIcon: StaticCompany,
      activeIcon: ActiveCompany,
      link: "/companies",
    },
    {
      id: "groups",
      name: "Groups",
      staticIcon: StaticGroup,
      activeIcon: ActiveGroup,
      link: "/groups",
    },
    {
      id: "seller",
      name: "Sellers and Gift Vouchers",
      staticIcon: StaticSellers,
      activeIcon: ActiveSellers,
      link: "/sellers",
    },
    {
      id: "voucherSales",
      name: "Voucher Sales",
      staticIcon: StaticSales,
      activeIcon: ActiveSales,
      link: "/voucher-sales",
    },
    {
      id: "client",
      name: "Client",
      staticIcon: StaticClients,
      activeIcon: ActiveClients,
      link: "/client",
    },
    {
      id: "complains",
      name: "Complains",
      staticIcon: StaticComplains,
      activeIcon: ActiveComplains,
      link: "/complains",
    },
    {
      id: "bookings",
      name: "Bookings",
      staticIcon: StaticBooking,
      activeIcon: ActiveBooking,
      link: "/bookings",
    },
    {
      id: "disputes",
      name: "Disputed Bookings",
      staticIcon: StaticDisptBooking,
      activeIcon: ActiveDisptBooking,
      link: "/disputed-bookings",
    },
    {
      id: "bookingTransactions",
      name: "Booking Transactions",
      staticIcon: StaticBookTrans,
      activeIcon: ActiveBookTrans,
      link: "/booking-transactions",
    },
    {
      id: "offers",
      name: "Our Offers",
      staticIcon: StaticOffer,
      activeIcon: ActiveOffer,
      link: "/offers",
    },
    {
      id: "logs",
      name: "Logs",
      staticIcon: StaticProfile,
      activeIcon: StaticProfile,
      link: "/logs",
    },
    {
      id: "reports",
      name: "Reports",
      staticIcon: StaticASPReport,
      activeIcon: ActiveASPReport,
      link: "/reports",
      hasSuboptions: false,
      // options: [
      //   {
      //     id: "transactionsPerDay",
      //     name: "Transactions Per Day",
      //     staticIcon: StaticBookTrans,
      //     activeIcon: ActiveBookTrans,
      //     link: "/reports/transactions-per-day",
      //   },
      //   {
      //     id: "transactionsPerWeek",
      //     name: "Transactions Per Week",
      //     staticIcon: StaticBookTrans,
      //     activeIcon: ActiveBookTrans,
      //     link: "/reports/transactions-per-week",
      //   },
      //   {
      //     id: "transactionsPerMonth",
      //     name: "Transactions Per Month",
      //     staticIcon: StaticBookTrans,
      //     activeIcon: ActiveBookTrans,
      //     link: "/reports/transactions-per-month",
      //   },
      //   {
      //     id: "transactionsServicesPerDay",
      //     name: "Transactions Services Per Day",
      //     staticIcon: StaticBookTrans,
      //     activeIcon: ActiveBookTrans,
      //     link: "/reports/transactions-services-per-day",
      //   }, {
      //     id: "transactionsServicesPerMonth",
      //     name: "Transactions Services Per Month",
      //     staticIcon: StaticBookTrans,
      //     activeIcon: ActiveBookTrans,
      //     link: "/reports/transactions-services-per-month",
      //   },
      //   {
      //     id: "transactionsByPartners",
      //     name: "Transactions By Partners",
      //     staticIcon: StaticBookTrans,
      //     activeIcon: ActiveBookTrans,
      //     link: "/reports/transactions-by-partners",
      //   },
      //   {
      //     id: "transactionsByPartnerType",
      //     name: "Transactions By Partner Type",
      //     staticIcon: StaticBookTrans,
      //     activeIcon: ActiveBookTrans,
      //     link: "/reports/transactions-by-partner-type",
      //   },

      //   {
      //     id: "mostUsedServicesReport",
      //     name: "Most Used Services Report",
      //     staticIcon: StaticASPReport,
      //     activeIcon: ActiveASPReport,
      //     link: "/reports/most-used-services-report",
      //   },
      //   {
      //     id: "avgServicePriceReport",
      //     name: "Avg Service Price Report",
      //     staticIcon: StaticASPReport,
      //     activeIcon: ActiveASPReport,
      //     link: "/avg-service-price-report",
      //   },
      //   {
      //     id: "incompleteProfilePartners",
      //     name: "Incomplete Profile Partners",
      //     staticIcon: StaticASPReport,
      //     activeIcon: ActiveASPReport,
      //     link: "/reports/incomplete-profile-partners",
      //   },
      //   {
      //     id: "servicesPricingReport",
      //     name: "Services Pricing Report",
      //     staticIcon: StaticASPReport,
      //     activeIcon: ActiveASPReport,
      //     link: "/reports/services-pricing",
      //   },
      //   {
      //     id: "cumulativeCustomers",
      //     name: "Cumulative Customers Report",
      //     staticIcon: StaticASPReport,
      //     activeIcon: ActiveASPReport,
      //     link: "/reports/cumulative-customers",
      //   },
      //   {
      //     id: "topCustomersReport",
      //     name: "Top Customers Report",
      //     staticIcon: StaticASPReport,
      //     activeIcon: ActiveASPReport,
      //     link: "/reports/top-customers-report",
      //   },
      //   {
      //     id: "bookingsIncomeReport",
      //     name: "Bookings Income Report",
      //     staticIcon: StaticASPReport,
      //     activeIcon: ActiveASPReport,
      //     link: "/reports/bookings-income-report",
      //   },
      //   {
      //     id: "graphReports",
      //     name: "Reports",
      //     staticIcon: StaticASPReport,
      //     activeIcon: ActiveASPReport,
      //     link: "/reports/graph-reports",
      //   },
      //   {
      //     id: "partnersSummaryReport",
      //     name: "Partners Summary Report",
      //     staticIcon: StaticASPReport,
      //     activeIcon: ActiveASPReport,
      //     link: "/reports/partners-summary-report",
      //   },
      //   {
      //     id: "customersSummaryReport",
      //     name: "Customers Summary Report",
      //     staticIcon: StaticASPReport,
      //     activeIcon: ActiveASPReport,
      //     link: "/reports/customers-summary-report",
      //   },
      //   {
      //     id: "expansionReport",
      //     name: "Expansion Report",
      //     staticIcon: StaticASPReport,
      //     activeIcon: ActiveASPReport,
      //     link: "/reports/expansion-report",
      //   },
      //   {
      //     id: "salesReport",
      //     name: "Sales Report",
      //     staticIcon: StaticASPReport,
      //     activeIcon: ActiveASPReport,
      //     link: "/reports/sales-report",
      //   },
      //   {
      //     id: "grossTransactionsReport",
      //     name: "Gross Transactions Value Report",
      //     staticIcon: StaticASPReport,
      //     activeIcon: ActiveASPReport,
      //     link: "/reports/gross-transactions-report",
      //   },
      // ],
    },
    {
      id: "settings",
      name: "Settings",
      staticIcon: StaticGlobalSettings,
      activeIcon: ActiveGlobalSetting,
      link: "",
      hasSuboptions: true,
      options: [
        {
          id: "services",
          name: "Services",
          staticIcon: StaticServices,
          activeIcon: ActiveServices,
          link: "/services",
        },
        {
          id: "featured_salons",
          name: "Featured Salons",
          staticIcon: StaticPartners,
          activeIcon: ActivePartner,
          link: "/featured-salons",
        },
        {
          id: "advertisements",
          name: "Advertisements",
          staticIcon: StaticOffer,
          activeIcon: ActiveOffer,
          link: "/advertisements",
        },
        {
          id: "categories",
          name: "Categories",
          staticIcon: StaticServiceCategories,
          activeIcon: ActiveServiceCategories,
          link: "/categories",
        },
        {
          id: "permissions",
          name: "Permissions",
          staticIcon: StaticPermissions,
          activeIcon: ActivePermissions,
          link: "/user-types",
        },
        {
          id: "global_settings",
          name: "Global Settings",
          staticIcon: StaticGlobalSettings,
          activeIcon: ActiveGlobalSetting,
          link: "/global-settings",
        },
        {
          id: "blacklisted_keywords",
          name: "Blacklisted Keywords",
          staticIcon: StaticBlacklistedKeywords,
          activeIcon: ActiveBlacklistedKeywords,
          link: "/blacklisted-keywords",
        },
        {
          id: "push_notifications",
          name: "Push Notifications",
          staticIcon: StaticOffer,
          activeIcon: ActiveOffer,
          link: "/push-notifications",
        },
        {
          id: "employees",
          name: "Employees",
          staticIcon: StaticEmployee,
          activeIcon: ActiveEmployee,
          link: "/employees",
        },
      ],
    },
  ],

  UserNavigation: [
    {
      id: "bookingTransactions",
      name: "Booking Transactions",
      staticIcon: StaticBookTrans,
      activeIcon: ActiveBookTrans,
      link: "/booking-transactions",
    },
    {
      id: "bookings",
      name: "Bookings",
      staticIcon: StaticBooking,
      activeIcon: ActiveBooking,
      link: "/bookings",
    },
    {
      id: "complains",
      name: "Complains",
      staticIcon: StaticComplains,
      activeIcon: ActiveComplains,
      link: "/complains",
    },
  ],

  //driver menu
  DriverNavigation: [
    {
      id: "bookings",
      name: "Bookings",
      staticIcon: StaticBooking,
      activeIcon: ActiveBooking,
      link: "/bookings",
    },
  ],

  CountriesPhoneCode: [
    {
      code: "+7 840",
      name: "Abkhazia",
    },
    {
      code: "+93",
      name: "Afghanistan",
    },
    {
      code: "+355",
      name: "Albania",
    },
    {
      code: "+213",
      name: "Algeria",
    },
    {
      code: "+1 684",
      name: "American Samoa",
    },
    {
      code: "+376",
      name: "Andorra",
    },
    {
      code: "+244",
      name: "Angola",
    },
    {
      code: "+1 264",
      name: "Anguilla",
    },
    {
      code: "+1 268",
      name: "Antigua and Barbuda",
    },
    {
      code: "+54",
      name: "Argentina",
    },
    {
      code: "+374",
      name: "Armenia",
    },
    {
      code: "+297",
      name: "Aruba",
    },
    {
      code: "+247",
      name: "Ascension",
    },
    {
      code: "+61",
      name: "Australia",
    },
    {
      code: "+672",
      name: "Australian External Territories",
    },
    {
      code: "+43",
      name: "Austria",
    },
    {
      code: "+994",
      name: "Azerbaijan",
    },
    {
      code: "+1 242",
      name: "Bahamas",
    },
    {
      code: "+973",
      name: "Bahrain",
    },
    {
      code: "+880",
      name: "Bangladesh",
    },
    {
      code: "+1 246",
      name: "Barbados",
    },
    {
      code: "+1 268",
      name: "Barbuda",
    },
    {
      code: "+375",
      name: "Belarus",
    },
    {
      code: "+32",
      name: "Belgium",
    },
    {
      code: "+501",
      name: "Belize",
    },
    {
      code: "+229",
      name: "Benin",
    },
    {
      code: "+1 441",
      name: "Bermuda",
    },
    {
      code: "+975",
      name: "Bhutan",
    },
    {
      code: "+591",
      name: "Bolivia",
    },
    {
      code: "+387",
      name: "Bosnia and Herzegovina",
    },
    {
      code: "+267",
      name: "Botswana",
    },
    {
      code: "+55",
      name: "Brazil",
    },
    {
      code: "+246",
      name: "British Indian Ocean Territory",
    },
    {
      code: "+1 284",
      name: "British Virgin Islands",
    },
    {
      code: "+673",
      name: "Brunei",
    },
    {
      code: "+359",
      name: "Bulgaria",
    },
    {
      code: "+226",
      name: "Burkina Faso",
    },
    {
      code: "+257",
      name: "Burundi",
    },
    {
      code: "+855",
      name: "Cambodia",
    },
    {
      code: "+237",
      name: "Cameroon",
    },
    {
      code: "+1",
      name: "Canada",
    },
    {
      code: "+238",
      name: "Cape Verde",
    },
    {
      code: "+ 345",
      name: "Cayman Islands",
    },
    {
      code: "+236",
      name: "Central African Republic",
    },
    {
      code: "+235",
      name: "Chad",
    },
    {
      code: "+56",
      name: "Chile",
    },
    {
      code: "+86",
      name: "China",
    },
    {
      code: "+61",
      name: "Christmas Island",
    },
    {
      code: "+61",
      name: "Cocos-Keeling Islands",
    },
    {
      code: "+57",
      name: "Colombia",
    },
    {
      code: "+269",
      name: "Comoros",
    },
    {
      code: "+242",
      name: "Congo",
    },
    {
      code: "+243",
      name: "Congo, Dem. Rep. of (Zaire)",
    },
    {
      code: "+682",
      name: "Cook Islands",
    },
    {
      code: "+506",
      name: "Costa Rica",
    },
    {
      code: "+385",
      name: "Croatia",
    },
    {
      code: "+53",
      name: "Cuba",
    },
    {
      code: "+599",
      name: "Curacao",
    },
    {
      code: "+537",
      name: "Cyprus",
    },
    {
      code: "+420",
      name: "Czech Republic",
    },
    {
      code: "+45",
      name: "Denmark",
    },
    {
      code: "+246",
      name: "Diego Garcia",
    },
    {
      code: "+253",
      name: "Djibouti",
    },
    {
      code: "+1 767",
      name: "Dominica",
    },
    {
      code: "+1 809",
      name: "Dominican Republic",
    },
    {
      code: "+670",
      name: "East Timor",
    },
    {
      code: "+56",
      name: "Easter Island",
    },
    {
      code: "+593",
      name: "Ecuador",
    },
    {
      code: "+20",
      name: "Egypt",
    },
    {
      code: "+503",
      name: "El Salvador",
    },
    {
      code: "+240",
      name: "Equatorial Guinea",
    },
    {
      code: "+291",
      name: "Eritrea",
    },
    {
      code: "+372",
      name: "Estonia",
    },
    {
      code: "+251",
      name: "Ethiopia",
    },
    {
      code: "+500",
      name: "Falkland Islands",
    },
    {
      code: "+298",
      name: "Faroe Islands",
    },
    {
      code: "+679",
      name: "Fiji",
    },
    {
      code: "+358",
      name: "Finland",
    },
    {
      code: "+33",
      name: "France",
    },
    {
      code: "+596",
      name: "French Antilles",
    },
    {
      code: "+594",
      name: "French Guiana",
    },
    {
      code: "+689",
      name: "French Polynesia",
    },
    {
      code: "+241",
      name: "Gabon",
    },
    {
      code: "+220",
      name: "Gambia",
    },
    {
      code: "+995",
      name: "Georgia",
    },
    {
      code: "+49",
      name: "Germany",
    },
    {
      code: "+233",
      name: "Ghana",
    },
    {
      code: "+350",
      name: "Gibraltar",
    },
    {
      code: "+30",
      name: "Greece",
    },
    {
      code: "+299",
      name: "Greenland",
    },
    {
      code: "+1 473",
      name: "Grenada",
    },
    {
      code: "+590",
      name: "Guadeloupe",
    },
    {
      code: "+1 671",
      name: "Guam",
    },
    {
      code: "+502",
      name: "Guatemala",
    },
    {
      code: "+224",
      name: "Guinea",
    },
    {
      code: "+245",
      name: "Guinea-Bissau",
    },
    {
      code: "+595",
      name: "Guyana",
    },
    {
      code: "+509",
      name: "Haiti",
    },
    {
      code: "+504",
      name: "Honduras",
    },
    {
      code: "+852",
      name: "Hong Kong SAR China",
    },
    {
      code: "+36",
      name: "Hungary",
    },
    {
      code: "+354",
      name: "Iceland",
    },
    {
      code: "+91",
      name: "India",
    },
    {
      code: "+62",
      name: "Indonesia",
    },
    {
      code: "+98",
      name: "Iran",
    },
    {
      code: "+964",
      name: "Iraq",
    },
    {
      code: "+353",
      name: "Ireland",
    },
    {
      code: "+972",
      name: "Israel",
    },
    {
      code: "+39",
      name: "Italy",
    },
    {
      code: "+225",
      name: "Ivory Coast",
    },
    {
      code: "+1 876",
      name: "Jamaica",
    },
    {
      code: "+81",
      name: "Japan",
    },
    {
      code: "+962",
      name: "Jordan",
    },
    {
      code: "+7 7",
      name: "Kazakhstan",
    },
    {
      code: "+254",
      name: "Kenya",
    },
    {
      code: "+686",
      name: "Kiribati",
    },
    {
      code: "+965",
      name: "Kuwait",
    },
    {
      code: "+996",
      name: "Kyrgyzstan",
    },
    {
      code: "+856",
      name: "Laos",
    },
    {
      code: "+371",
      name: "Latvia",
    },
    {
      code: "+961",
      name: "Lebanon",
    },
    {
      code: "+266",
      name: "Lesotho",
    },
    {
      code: "+231",
      name: "Liberia",
    },
    {
      code: "+218",
      name: "Libya",
    },
    {
      code: "+423",
      name: "Liechtenstein",
    },
    {
      code: "+370",
      name: "Lithuania",
    },
    {
      code: "+352",
      name: "Luxembourg",
    },
    {
      code: "+853",
      name: "Macau SAR China",
    },
    {
      code: "+389",
      name: "Macedonia",
    },
    {
      code: "+261",
      name: "Madagascar",
    },
    {
      code: "+265",
      name: "Malawi",
    },
    {
      code: "+60",
      name: "Malaysia",
    },
    {
      code: "+960",
      name: "Maldives",
    },
    {
      code: "+223",
      name: "Mali",
    },
    {
      code: "+356",
      name: "Malta",
    },
    {
      code: "+692",
      name: "Marshall Islands",
    },
    {
      code: "+596",
      name: "Martinique",
    },
    {
      code: "+222",
      name: "Mauritania",
    },
    {
      code: "+230",
      name: "Mauritius",
    },
    {
      code: "+262",
      name: "Mayotte",
    },
    {
      code: "+52",
      name: "Mexico",
    },
    {
      code: "+691",
      name: "Micronesia",
    },
    {
      code: "+1 808",
      name: "Midway Island",
    },
    {
      code: "+373",
      name: "Moldova",
    },
    {
      code: "+377",
      name: "Monaco",
    },
    {
      code: "+976",
      name: "Mongolia",
    },
    {
      code: "+382",
      name: "Montenegro",
    },
    {
      code: "+1664",
      name: "Montserrat",
    },
    {
      code: "+212",
      name: "Morocco",
    },
    {
      code: "+95",
      name: "Myanmar",
    },
    {
      code: "+264",
      name: "Namibia",
    },
    {
      code: "+674",
      name: "Nauru",
    },
    {
      code: "+977",
      name: "Nepal",
    },
    {
      code: "+31",
      name: "Netherlands",
    },
    {
      code: "+599",
      name: "Netherlands Antilles",
    },
    {
      code: "+1 869",
      name: "Nevis",
    },
    {
      code: "+687",
      name: "New Caledonia",
    },
    {
      code: "+64",
      name: "New Zealand",
    },
    {
      code: "+505",
      name: "Nicaragua",
    },
    {
      code: "+227",
      name: "Niger",
    },
    {
      code: "+234",
      name: "Nigeria",
    },
    {
      code: "+683",
      name: "Niue",
    },
    {
      code: "+672",
      name: "Norfolk Island",
    },
    {
      code: "+850",
      name: "North Korea",
    },
    {
      code: "+1 670",
      name: "Northern Mariana Islands",
    },
    {
      code: "+47",
      name: "Norway",
    },
    {
      code: "+968",
      name: "Oman",
    },
    {
      code: "+92",
      name: "Pakistan",
    },
    {
      code: "+680",
      name: "Palau",
    },
    {
      code: "+970",
      name: "Palestinian Territory",
    },
    {
      code: "+507",
      name: "Panama",
    },
    {
      code: "+675",
      name: "Papua New Guinea",
    },
    {
      code: "+595",
      name: "Paraguay",
    },
    {
      code: "+51",
      name: "Peru",
    },
    {
      code: "+63",
      name: "Philippines",
    },
    {
      code: "+48",
      name: "Poland",
    },
    {
      code: "+351",
      name: "Portugal",
    },
    {
      code: "+1 787",
      name: "Puerto Rico",
    },
    {
      code: "+974",
      name: "Qatar",
    },
    {
      code: "+262",
      name: "Reunion",
    },
    {
      code: "+40",
      name: "Romania",
    },
    {
      code: "+7",
      name: "Russia",
    },
    {
      code: "+250",
      name: "Rwanda",
    },
    {
      code: "+685",
      name: "Samoa",
    },
    {
      code: "+378",
      name: "San Marino",
    },
    {
      code: "+966",
      name: "Saudi Arabia",
    },
    {
      code: "+221",
      name: "Senegal",
    },
    {
      code: "+381",
      name: "Serbia",
    },
    {
      code: "+248",
      name: "Seychelles",
    },
    {
      code: "+232",
      name: "Sierra Leone",
    },
    {
      code: "+65",
      name: "Singapore",
    },
    {
      code: "+421",
      name: "Slovakia",
    },
    {
      code: "+386",
      name: "Slovenia",
    },
    {
      code: "+677",
      name: "Solomon Islands",
    },
    {
      code: "+27",
      name: "South Africa",
    },
    {
      code: "+500",
      name: "South Georgia and the South Sandwich Islands",
    },
    {
      code: "+82",
      name: "South Korea",
    },
    {
      code: "+34",
      name: "Spain",
    },
    {
      code: "+94",
      name: "Sri Lanka",
    },
    {
      code: "+249",
      name: "Sudan",
    },
    {
      code: "+597",
      name: "Suriname",
    },
    {
      code: "+268",
      name: "Swaziland",
    },
    {
      code: "+46",
      name: "Sweden",
    },
    {
      code: "+41",
      name: "Switzerland",
    },
    {
      code: "+963",
      name: "Syria",
    },
    {
      code: "+886",
      name: "Taiwan",
    },
    {
      code: "+992",
      name: "Tajikistan",
    },
    {
      code: "+255",
      name: "Tanzania",
    },
    {
      code: "+66",
      name: "Thailand",
    },
    {
      code: "+670",
      name: "Timor Leste",
    },
    {
      code: "+228",
      name: "Togo",
    },
    {
      code: "+690",
      name: "Tokelau",
    },
    {
      code: "+676",
      name: "Tonga",
    },
    {
      code: "+1 868",
      name: "Trinidad and Tobago",
    },
    {
      code: "+216",
      name: "Tunisia",
    },
    {
      code: "+90",
      name: "Turkey",
    },
    {
      code: "+993",
      name: "Turkmenistan",
    },
    {
      code: "+1 649",
      name: "Turks and Caicos Islands",
    },
    {
      code: "+688",
      name: "Tuvalu",
    },
    {
      code: "+1 340",
      name: "U.S. Virgin Islands",
    },
    {
      code: "+256",
      name: "Uganda",
    },
    {
      code: "+380",
      name: "Ukraine",
    },
    {
      code: "+971",
      name: "United Arab Emirates",
    },
    {
      code: "+44",
      name: "United Kingdom",
    },
    {
      code: "+1",
      name: "United States",
    },
    {
      code: "+598",
      name: "Uruguay",
    },
    {
      code: "+998",
      name: "Uzbekistan",
    },
    {
      code: "+678",
      name: "Vanuatu",
    },
    {
      code: "+58",
      name: "Venezuela",
    },
    {
      code: "+84",
      name: "Vietnam",
    },
    {
      code: "+1 808",
      name: "Wake Island",
    },
    {
      code: "+681",
      name: "Wallis and Futuna",
    },
    {
      code: "+967",
      name: "Yemen",
    },
    {
      code: "+260",
      name: "Zambia",
    },
    {
      code: "+255",
      name: "Zanzibar",
    },
    {
      code: "+263",
      name: "Zimbabwe",
    },
  ],

  //Company menu
  CompanyNavigation: [
    {
      id: "companies",
      name: "Company",
      staticIcon: StaticCompany,
      activeIcon: ActiveCompany,
      link: "/companies",
    },
  ]
};

export default Constants;
