import React, { useEffect, useState } from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import PrimaryButton from "../../../components/Buttons/Primary";
import FullPageSpinner from "../../../components/FullPageSpinner";
import { exportDataToExcel, getAuthAttribute } from "../../../utils/helper";
import Hoc from "../../Hoc";
import "./Styles.scss";
import { getMostBookedServices } from "../../../core/Api/dashboard";
import CustomTableComponent from "../../../components/CustomTableComponent";
import DateInput from "../../../components/DateInput";
import { getPartnersList } from "../../../core/Api/partner";
import CustomDropdown from "../../../components/Dropdown";
import moment from "moment";

const MostUsedServicesReport = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const supplier_id = match?.params?.id || getAuthAttribute("supplier_id");
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [csvLoader, setCSVLoader] = useState(false);
  const [filterSchema, setFilterSchema] = useState({
    from_date: null,
    to_date: null,
    supplier_id: supplier_id || null,
  });
  const [partner, setPartners] = useState([
    { label: t("Select Partner"), value: null },
  ]);

  useEffect(() => {
    fetchData(filterSchema);
  }, []);

  const fetchData = async (params = {}) => {
    setLoading(true);
    const [response, partners] = await Promise.all([
      getMostBookedServices({
        from_date: params?.from_date || null,
        to_date: params?.to_date || null,
        supplier_id: params?.supplier_id || 0
      }),
      getPartnersList(),
    ]);
    if (response.code === 200) {
      setData(response?.result || []);
    } else {
      toast.error(response && response.message);
    }
    if (partners && partners.code === 200) {
      if (partners.result) {
        const data = [...partner];
        partners.result.forEach((row) => {
          data.push({
            label: row.name,
            value: row.supplier_id,
          });
        });
        setPartners(data);
      }
    } else {
      toast.error(partners && partners.message);
    }
    setLoading(false);
  };

  const handleDateChange = (date, name) => {
    setFilterSchema({
      ...filterSchema,
      [name]: date && new Date(date) || null,
    });
  };

  const handleChange = (value, name) => {
    setFilterSchema({
      ...filterSchema,
      [name]: value,
    });
  };

  const handleSearch = async () => {
    setLoading(true)
    const response = await getMostBookedServices({
      from_date: filterSchema?.from_date && moment(filterSchema?.from_date).format('YYYY-MM-DD') || null,
      to_date: filterSchema?.to_date && moment(filterSchema?.to_date).format('YYYY-MM-DD') || null,
      supplier_id: filterSchema?.supplier_id || 0
    });
    setLoading(false)
    if (response.code === 200) {
      setData(response?.result || []);
    } else {
      toast.error(response && response.message);
    }
  };

  const handleCSVExport = async () => {
    try {
      setCSVLoader(true);

      await exportDataToExcel({
        fileName: `Most_Booked_Services${new Date().toLocaleDateString("en-US")}`,
        header: ['Name', 'Count'],
        data: data?.map(r => ({
          name_en: r?.name_en,
          booked_count: r?.booked_count
        }))
      })
      // const bookings = data || [];
      // let csv = "";
      // const rows = [];
      // csv = "Name, Count\r\n";
      // for (let i = 0; i < bookings?.length; i++) {
      //   const item = bookings[i];
      //   rows.push(`${item.name_en},${item.booked_count}\r\n`)
      // }
      // rows.forEach((row) => {
      //   csv += row;
      // });
      // const generationDate = new Date().toLocaleDateString("en-US");
      // const link = document.createElement("a");
      // link.href = "data:text/xls;charset=utf-8," + encodeURI(csv);
      // link.target = "_blank";
      // link.download = `Most_Booked_Services${generationDate}.xls`;
      // link.click();
      setCSVLoader(false);
    } catch (error) {
      toast.error(error && error.message);
      setCSVLoader(false);
    }
  };

  const columns = [
    {
      name: t("Name"),
      key: 'name_en',
      formatter: null,
    },
    {
      name: t("Count"),
      key: 'booked_count',
      formatter: null,
    },
  ]

  return (
    // <Hoc activeMenu="mostUsedServicesReport">
      <div className="mostUsedServicesReport">
        {csvLoader && <FullPageSpinner /> || null}
        <div className="table-wrapper pr-3">
          <Row className="m-0">
            <Col className="filter-item" lg="2" md="6" sm="12">
              <DateInput
                label={t("From date")}
                value={filterSchema.from_date}
                onChange={(date) => handleDateChange(date, "from_date")}
                isClearable={true}
              />
            </Col>
            <Col className="filter-item" lg="2" md="6" sm="12">
              <DateInput
                label={t("To date")}
                value={filterSchema.to_date}
                onChange={(date) => handleDateChange(date, "to_date")}
                isClearable={true}
              />
            </Col>
            {!supplier_id && <Col className="filter-item" lg="3" md="6" sm="12">
              <CustomDropdown
                onChange={(value) => handleChange(value.value, "supplier_id")}
                name="supplier_id"
                value={""}
                options={partner}
                label={t("Partner/Salon")}
              />
            </Col> || null}
            <Col className="filter-item" lg="2" md="6" sm="12">
              <PrimaryButton
                text={t("Search")}
                onClick={handleSearch}
                style={{ width: "100%" }}
              />
            </Col>
            <Col className="filter-item" lg="2" md="6" sm="12">
              <PrimaryButton
                disabled={isLoading || csvLoader}
                onClick={handleCSVExport}
                text={t('exportAsCSV')}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <Row className="w-100 text-center m-0 mt-4">
            <Col>
              {isLoading && (
                <div>
                  <FullPageSpinner animation="border" />
                  <span>
                    {t("Please wait, we're loading data")}
                  </span>
                </div>)
              }
              {!isLoading && data?.length === 0 && (
                <div className="no-data-text text-center mt-2">
                  {t("No data available")}
                </div>
              ) || null}
            </Col>
          </Row>
          <Row className="w-100 m-0">
            {
              data.length && (
                <div className="booking-table w-100">
                  <CustomTableComponent
                    responsive={true}
                    hover={true}
                    showSequence={true}
                    pageSize={10}
                    pageNo={1}
                    columns={columns || []}
                    data={data || []}
                  />
                </div>
              ) || null
            }
          </Row>
          <Row>
            {/* <Pagination
              totalCount={totalCount}
              viewCount={data?.length || 0}
              onChangePage={handlePageChange}
              pageNo={pageNo}
            /> */}
          </Row>
        </div>

      </div>
    // </Hoc>
  );
};

export default MostUsedServicesReport;
