import React, { useEffect, } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { toast } from "react-toastify";
import Navigation from "../../navigation";
import BoonProvider from "../../context/provider";
import Login from "../Auth/Login";
import SignUp from "../Auth/SignUp";
import PublicRoute from "../../navigation/PublicRoute";

const MainApp = () => {
  // localStorage.removeItem('boon-react-token')//for testing purpose only
  useEffect(() => {
    const isInvoice = window.location.pathname.includes("invoice");
    if (navigator.userAgent.indexOf("Chrome") === -1 && !isInvoice) {
      toast.info(
        "You are not using Google Chrome.we recommend you to switch to Google Chrome browser"
      );
    }
  }, []);

  return (
      <BoonProvider>
        <BrowserRouter>
          <Switch>
            {/* <Redirect exact from="/" to={"/login"} />
            <PublicRoute exact path="/login" component={Login} />
            <PublicRoute exact path="/signup" component={SignUp} /> */}
            <Route path="/" component={Navigation} />
          </Switch>
        </BrowserRouter>
      </BoonProvider>
  );
};

export default MainApp;
