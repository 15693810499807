import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { Modal, Image, Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/Buttons/Primary";
import { getAuthAttribute } from "../../utils/helper";
import Driverform from "./DriverForm";
import editIcon from "../../assets/images/edit.png";
import caretDown from "../../assets/images/caret-down.svg";
import caretUp from "../../assets/images/caret-up.svg";
import deleteIcon from "../../assets/images/trash1.svg";
import {
  getSupplierDriverlist,
  deleteSupplierDriver,
} from "../../core/Api/supplier";

const driversState = {
  supplier_id: "",
  supplier_driver_id: "0",
  name: "",
  mobile: "",
  country_code: "",
  password: "",
};

const SupplierDrivertable = (props) => {
  const { match } = props;
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [driverData, setDriverData] = useState([]);
  const supplierId = match.params.id
    ? match.params.id
    : getAuthAttribute("supplier_id");
  const { t } = useTranslation();
  driversState.supplier_id = supplierId;

  useEffect(() => {
    getSupplierDrivers();
    // eslint-disable-next-line
  }, []);

  const getSupplierDrivers = async () => {
    toast.dismiss();
    try {
      const result = await getSupplierDriverlist(supplierId);
      if (result.code === 200) {
        setDriverData(result?.result);
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };

  const handleConfirm = async (supplier_driver_id) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        `Are you sure you want to delete this driver? This action cannot be undone.`
      ),

      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await deleteSupplierDriver(supplier_driver_id);
        if (result && result.code === 200) {
          await getSupplierDrivers();
          toast.success(result.message);
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
    });
  };

  const sortCaret = (order) => (
    <span className="caret-icon">
      <img src={order === "asc" ? caretUp : caretDown} alt="" />
    </span>
  );

  const formatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="otherWrap">
        <Image
          className="edit"
          onClick={() => setSelectedDriver(row)}
          src={editIcon}
          alt="edit"
        />
        <Image
          className="delete"
          onClick={() => handleConfirm(row.supplier_driver_id)}
          src={deleteIcon}
          alt="delete"
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: t("Name"),
      sort: true,
      sortCaret,
    },
    {
      dataField: "mobile",
      text: t("Mobile/Username"),
      sort: true,
      sortCaret,
    },
    {
      dataField: "other",
      text: t("Action"),
      sort: false,
      formatter: formatter,
    },
  ];

  const handleClose = () => setSelectedDriver(null);

  return (
    <>
      <Container fluid className="driver-bt">
        <div className="add-new-driver">
          <PrimaryButton
            onClick={() => setSelectedDriver({ ...driversState })}
            text={t("Add New Driver")}
          />
        </div>
      </Container>
      <Container className="supplier-driver-container">
        <div className="table-wrapper">
          <BootstrapTable
            bordered={false}
            keyField={columns[0]}
            data={driverData}
            columns={columns}
          />
        </div>
        <Modal show={selectedDriver} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Manage Driver</Modal.Title>
          </Modal.Header>
          <Driverform
            getSupplierDrivers={getSupplierDrivers}
            handleClose={handleClose}
            selectedDriver={selectedDriver}
          />
        </Modal>
      </Container>
    </>
  );
};

export default SupplierDrivertable;
