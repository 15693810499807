import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import PrimaryButton from '../../../components/Buttons/Primary';
import { Container, Row, Col } from 'react-bootstrap';
import Hoc from '../../Hoc';
import './Styles.scss';
import { useTranslation } from 'react-i18next';
import { getServicesPricingReport } from '../../../core/Api/Reports';
import TableData from './DataTable';
import FullPageSpinner from '../../../components/FullPageSpinner';
import CustomTableComponent from '../../../components/CustomTableComponent';
import Pagination from '../../../components/Pagination';

const ServicesPricingReport = () => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [reportData, setReportData] = useState([]);
	const [totalCount, setTotalCont] = useState(0);
	const [pageNo, setPageNo] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [isCSVLoading, setIsCSVLoading] = useState(false);

	useEffect(() => {
		getReportData();
	}, []);

	const getReportData = async (per_page = 10, offset = 0) => {
		setIsLoading(true);
		const res = await getServicesPricingReport({
			per_page,
			offset,
		});
		if (res && res.code === 200) {
			setReportData(res?.result?.result_data ?? []);
			setTotalCont(res?.result?.total_count ?? 0);
		} else {
			toast.error(res?.message);
		}
		setIsLoading(false);
	};

	const getPrice = (type, row) => {
		if (type == 'home') {
			if (row?.is_home === '1') {
				return row?.opt_home_price;
			} else {
				return '---';
			}
		} else if (type == 'salon') {
			if (row?.is_salon === '1') {
				return row?.opt_salon_price;
			} else {
				return '---';
			}
		}
	};

	const handleCSVExport = async () => {
		setIsCSVLoading(true);
		const res = await getServicesPricingReport({
			per_page: totalCount,
			offset: 0,
		});

		if (res?.result?.result_data?.length) {
			let csv =
				'Supplier, Category, Name(eng), Name (arb), Home Price, Salon Price, Is Home, Is Salon \r\n';
			const rows = [];
			for (let i = 0; i < res?.result?.result_data?.length; i++) {
				const item = res?.result?.result_data[i];
				rows.push(
					`"${item?.name || ''}","${item?.category || ''}","${
						item?.name_en || ''
					}","${item?.name_ar}",${getPrice('home', item)},${getPrice(
						'salon',
						item
					)},${item?.is_home},${item?.is_home}\r\n`
				);
			}
			rows.forEach((row) => {
				csv += row;
			});
			try {
				const generationDate = new Date().toLocaleDateString('en-US');
				const link = document.createElement('a');
				link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
				link.target = '_blank';
				link.download = `Services_Pricing_Report_${generationDate}.csv`;
				link.click();
			} catch (error) {
				toast.error('Something went wrong.Please try again!');
			}
		} else {
			toast.warning(t('No record found'));
		}
		setIsCSVLoading(false);
	};

	const getStatusValue = (status) => {
		return (status === '1' && <span>&#x2705;</span>) || <span> &#x274C; </span>;
	};

	const handlePageChange = (pageNo, pageSize) => {
		setPageNo(pageNo);
		setPageSize(pageSize);
		getReportData(pageSize, (pageNo - 1) * pageSize);
	};

	const columns = [
		{
			name: t('Supplier'),
			key: 'name',
			formatter: null,
		},
		{
			name: t('Category'),
			key: 'category',
			formatter: null,
		},
		{
			name: t('Name(eng)'),
			key: 'name_en',
			formatter: null,
		},
		{
			name: t('Name(arb)'),
			key: 'name_ar',
			formatter: null,
		},
		{
			name: t('Home Price'),
			key: 'name',
			formatter: (row, key) => {
				return <span>{getPrice('home', row)}</span>;
			},
		},
		{
			name: t('Salon Price'),
			key: 'name',
			formatter: (row, key) => {
				return <span>{getPrice('salon', row)}</span>;
			},
		},
		{
			name: t('Home'),
			key: 'is_home',
			formatter: (row, key) => {
				return <span>{getStatusValue(row[key])}</span>;
			},
		},
		{
			name: t('Salon'),
			key: 'is_home',
			formatter: (row, key) => {
				return <span>{getStatusValue(row[key])}</span>;
			},
		},
	];

	return (
		<React.Fragment>
			{/* <Hoc activeMenu="servicesPricingReport"> */}
				<Container fluid className="incomplete-profile-partners">
					{(isLoading && <FullPageSpinner />) || null}
					<Row className="heading-row">
						<Col lg="3" md="3" sm="12" className="mt-3 mb-2">
							<PrimaryButton
								disabled={isCSVLoading}
								onClick={handleCSVExport}
								text={t('Export as CSV')}
								style={{ width: '100%', marginTop: '0px' }}
								isLoading={isCSVLoading}
							/>
						</Col>
					</Row>
					<Row className="w-100 text-center m-0 mt-4">
						<Col>
							{isLoading && (
								<div>
									<FullPageSpinner animation="border" />
									<span>{t("Please wait, we're loading data")}</span>
								</div>
							)}
							{(!isLoading && reportData?.length === 0 && (
								<div className="no-data-text text-center mt-2">
									{t('No data available')}
								</div>
							)) ||
								null}
						</Col>
					</Row>
					<Row>
						<Col>
							{(reportData.length && (
								<div className="booking-table w-100">
									<CustomTableComponent
										responsive={true}
										hover={true}
										showSequence={true}
										pageSize={pageSize}
										pageNo={pageNo}
										columns={columns || []}
										data={reportData || []}
									/>
								</div>
							)) ||
								null}
						</Col>
					</Row>
					<Row>
						<Col>
							<Pagination
								totalCount={totalCount}
								viewCount={reportData?.length ?? 0}
								onChangePage={handlePageChange}
								pageNo={pageNo}
							/>
						</Col>
					</Row>
				</Container>
			{/* </Hoc> */}
		</React.Fragment>
	);
};
export default ServicesPricingReport;
