import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserInput from "../../../components/Inputs";
import SimpleReactValidator from "simple-react-validator";
import { saveCommonSettings } from "../../../core/Api/Settings";
import { toast } from "react-toastify";

const defaultCommonSettings = {
  maximum_months_allowed: "",
  volley_timeout: "",
  feedback_popup_timeout: "",
  simple_date_format: "",
  full_simple_date_format: "",
  encoding_type: "",
};
const CommonSetting = (props) => {
  const { t } = useTranslation();
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: this })
  );
  const [, forceUpdate] = useState();
  const [commonAppData, setCommonAppData] = useState(defaultCommonSettings);

  useEffect(() => {
    if (props.commonSettings) {
      setCommonAppData(props.commonSettings);
    }
  }, [props.commonSettings]);

  const handleSave = async () => {
    toast.dismiss();
    try {
      if (simpleValidator.current.allValid()) {
        const data = {
          maximum_months_allowed: commonAppData.maximum_months_allowed,
          volley_timeout: commonAppData.volley_timeout,
          feedback_popup_timeout: commonAppData.feedback_popup_timeout,
          simple_date_format: commonAppData.simple_date_format,
          full_simple_date_format: commonAppData.full_simple_date_format,
          encoding_type: commonAppData.encoding_type,
        };
        const result = await saveCommonSettings(data);
        if (result?.code === 200) {
          toast.success("Common app settings saved successfully");
        } else {
          throw new Error(result?.message ?? "Something went wrong");
        }
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCommonAppData({ ...commonAppData, [name]: value });
  };

  return (
    <Container>
      <Card className="cards">
        <Row className="input-row">
          <Col md="6">
            <UserInput
              required
              label={t("Maximum Months Allowed")}
              placeholder={t("Maximum Months Allowed")}
              name="maximum_months_allowed"
              value={commonAppData.maximum_months_allowed}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "maximum_months_allowed",
              commonAppData.maximum_months_allowed,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Volley Timeout")}
              placeholder={t("Volley Timeout")}
              name="volley_timeout"
              value={commonAppData.volley_timeout}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "volley_timeout",
              commonAppData.volley_timeout,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Feedback Popup Timeout")}
              placeholder={t("Feedback Popup Timeout")}
              name="feedback_popup_timeout"
              value={commonAppData.feedback_popup_timeout}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "feedback_popup_timeout",
              commonAppData.feedback_popup_timeout,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Simple Date Format")}
              placeholder={t("Simple Date Format")}
              name="simple_date_format"
              value={commonAppData.simple_date_format}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "simple_date_format",
              commonAppData.simple_date_format,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Full Simple Date Format")}
              placeholder={t("Full Simple Date Format")}
              name="full_simple_date_format"
              value={commonAppData.full_simple_date_format}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "full_simple_date_format",
              commonAppData.full_simple_date_format,
              "required"
            )}
          </Col>

          <Col md="6">
            <UserInput
              required
              label={t("Encoding Type")}
              placeholder={t("Encoding Type")}
              name="encoding_type"
              value={commonAppData.encoding_type}
              onChange={handleChange}
            />
            {simpleValidator.current.message(
              "encoding_type",
              commonAppData.encoding_type,
              "required"
            )}
          </Col>
        </Row>
        <Row>
          <Col className="save-btn">
            <Button onClick={handleSave}>Save</Button>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default CommonSetting;
