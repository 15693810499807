/* global google */
import Hoc from "../Hoc";
import React, { useEffect, useState } from "react";
import * as _ from 'lodash';
import { getAuthAttribute } from "../../utils/helper";
import { useRouteMatch } from "react-router-dom";
import { LoadScript, GoogleMap } from "@react-google-maps/api";
import MapDrawingComponent from "./MapDrawingComponent";
import "./index.scss";
import DrawPolygon from "./DrawPolygon";
import DrawOverLayView from "./DrawOverlayView";

const Map = ({
  pathCoordinates = [],
  setPathCoordinates,
  deleteFence,
  centerCoordinates = {},
}) => {
  const match = useRouteMatch();
  const [overlay, setOverlay] = useState(false);
  const [overlayIndex, setOverlayIndex] = useState(null);
  const [overlayPosition, setOverlayPosition] = useState(null);
  const [, forceUpdate] = useState();

  useEffect(() => {
    forceUpdate(1);
  }, [pathCoordinates])


  const addFence = fence => {
    let arr = [..._.cloneDeep(pathCoordinates)];
    arr.push(fence);
    setPathCoordinates(arr);
  };

  const updateFence = (fenceIndex, fence) => {
    let arr = [..._.cloneDeep(pathCoordinates)];
    arr.splice(fenceIndex, 1, fence);
    setPathCoordinates(_.cloneDeep(arr));
  };

  const removeFence = async (fenceIndex) => {
    let arr = [..._.cloneDeep(pathCoordinates)];
    arr.splice(fenceIndex, 1);
    deleteFence((_.cloneDeep(arr)))
  };

  const removeOverlay = () => {
    setOverlay(false);
    setOverlayPosition(null);
    setOverlayIndex(null);
  };

  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey="AIzaSyDfxpYsb_0sfaP5dQ9Lonpd4staJ4ErH6I"
      language="en"
      region="us"
      libraries={["drawing"]}
    >
      <GoogleMap
        id="map_canvas"
        mapContainerClassName="App-map"
        center={centerCoordinates}
        zoom={12}
        version="weekly"
        on
        key="service-area-map"
      >
        {pathCoordinates?.map((fence, i) => (
          <div key={`div-${i}`}>
            <DrawPolygon
              key={i}
              index={i}
              fence={fence}
              updateFence={updateFence}
              setOverlay={setOverlay}
              setOverlayPosition={setOverlayPosition}
              setOverlayIndex={setOverlayIndex}
            />
            {overlay && overlayPosition && overlayIndex === i ? (
              <DrawOverLayView
                key={`custom-overylay-${i}`}
                position={overlayPosition}
                index={i}
                removeFence={removeFence}
                removeOverlay={removeOverlay}
              />
            ) : null}
          </div>
        ))}
        <MapDrawingComponent addFence={addFence} pathCoordinates={pathCoordinates}/>
      </GoogleMap>
    </LoadScript>
  )
}

export default Map;