import React, { useState, useEffect } from "react";
import PartnersTable from "./partnerList";
import { useRouteMatch } from "react-router-dom";
import AddBussines from "../MyBusinesses/AddMyBusiness/index";
import FullScreenPopup from "../../components/FullScreenPopup";
import SearchInput from "../../components/Inputs/Search";
import PrimaryButton from "../../components/Buttons/Primary";
import { Container, Row, Col } from "react-bootstrap";
import Hoc from "../Hoc";
import "./Styles.scss";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { getAllPartners, getPartners } from "../../core/Api/partner";
import FullPageSpinner from "../../components/FullPageSpinner";
import moment from "moment";
import { exportDataToExcel } from "../../utils/helper";

const Partners = ({ history }) => {
  const { t } = useTranslation();
  const route = useRouteMatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [partnerId, setPartnerId] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [reloadPartners, setReloadPartners] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [csvLoading, setCSVLoading] = useState(false)


  useEffect(() => {
    const state = history.location.state;
    if (state?.openPopup) {
      setOpenPopup(true);
    } else {
      setOpenPopup(false);
    }
  }, [history.location.state]);

  useEffect(() => {
    if (route && route.params && route.params.id) {
      setOpenPopup(true);
      setPartnerId(route.params.id);
      openOverlay();
    }
    // eslint-disable-next-line
  }, []);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const openOverlay = () => {
    setReloadPartners(false);
    const pathname = history.location.pathname + history.location.search;
    const state = history.location.state;
    history.push(pathname, {
      ...state,
      openPopup: true,
    });
  };

  const closeOverlay = (saved) => {
    if (saved === true || !isSaved) {
      history.goBack();
      setReloadPartners(true);
      setIsSaved(false);
    } else {
      confirmClose();
    }
  };

  const confirmClose = async () => {
    toast.dismiss();
    Swal.fire({
      text: t(
        "Are you sure you want to discard the changes? This action cannot be undone."
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Discard"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        history.goBack();
        setReloadPartners(true);
        setIsSaved(false);
      }
    });
  };

  const updateIsSavedStatus = () => !isSaved && setIsSaved(true);

  const getCity = (code) => {
    switch (code) {
      case '1':
        return 'Riyadh';
      case '2':
        return 'Dubai';
      case '3':
        return 'Jeddah';
      case '4':
        return 'Makkah';
      case '5':
        return 'Khobar';
      default:
        return 'N/A'
    }
  }

  const getAvailability = (key, isAvailability) => {
    if (!key) {
      return;
    }
    if (isAvailability) {
      if (key.includes("1") && key.includes("2")) {
        return "Home & Salon";
      } else if (key.includes("1")) {
        return "Salon";
      } else if (key.includes("2")) {
        return "Home";
      } else {
        return "Not Available";
      }
    } else {
      if (key.includes("1") && key.includes("2")) {
        return "Card & Cash";
      } else if (key.includes("1")) {
        return "Card";
      } else if (key.includes("2")) {
        return "Cash";
      } else {
        return "Not Available";
      }
    }
  };

  const getStatus = (statusId) =>{
    switch (statusId) {
      case "1":
        return "Active";
      case "2":
        return "Inactive";
      case "3":
        return "Deleted";
      default:
        return "NA";
    }
  }
  const handleCSVExport = async () => {
    try {
      if (csvLoading) return
      setCSVLoading(true);

      let bookings = [];
      const result = await getPartners(0,'',0,1000);
      if (result && result.code === 200) {
        bookings = result?.result ?? [];
      } else {
        toast.error(result && result.message);
      }

      await exportDataToExcel({
        fileName: `Partners_${new Date().toLocaleDateString("en-US")}`,
        header: ['Supplier Id',' Supplier',' Registration No',' Payout Threshold',' Availability','Type',' Payment Methods',' Date Joined','City',' status'],
        data: bookings?.map(r => ({
          supplier_id:`${r?.supplier_id}`,
          name:`${r?.name}`,
          registration_number:`${r?.registration_number}`,
          payout_threshold:`${r?.payout_threshold}`,
          availability:`${getAvailability(r.availability, true) || ''}`,
          type:`${r?.type}`,
          payment_method:`${getAvailability(r.payment_method, false)}`,
          date:`${moment(r.date).format('YYYY-MM-DD hh:ss A')}`,
          supplier_city:`${getCity(r?.supplier_city)}`,
          status_id:`${getStatus(r?.status_id)}`,
        }))
      })

      // let csv =
      //   "Supplier Id, Supplier, Registration No, Payout Threshold, Availability,Type, Payment Methods, Date Joined,City, status\r\n";
      // const rows = [];
      // for (let i = 0; i < bookings?.length; i++) {
      //   const item = bookings[i];
      //   rows.push(`"${item.supplier_id}","${item.name}","${item.registration_number
      //     }","${item?.payout_threshold || ''}","${getAvailability(item.availability, true) || ''}"," ${item.type}","${(
      //       getAvailability(item.payment_method, false)
      //     )}","${moment(item.date).format('YYYY-MM-DD hh:ss A')}","${getCity(item?.supplier_city)}","${getStatus(item?.status_id)}"\r\n`)
      // }
      // rows.forEach((row) => {
      //   csv += row;
      // });
      // const generationDate = new Date().toLocaleDateString("en-US");
      // const link = document.createElement("a");
      // link.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      // link.target = "_blank";
      // link.download = `Suppliers_${generationDate}.csv`;
      // link.click();
      setCSVLoading(false);
    } catch (error) {
      toast.error(error && error.message);
    }
  };

  return (
    <React.Fragment>
      <Hoc activeMenu="partner">
        <Container fluid className="partner">
          {csvLoading && <FullPageSpinner />}
          <Row className="heading-row">
            <Col xl="3" lg="4" md="6" sm="12">
              <SearchInput
                classNames="pt-0"
                onChange={handleSearchChange}
                placeholder={t("Search")}
              />
            </Col>
            <Col md="auto" className="cta-col">
              <PrimaryButton
                onClick={() => handleCSVExport(suppliers)}
                text={t('Export as CSV')}
                style={{ margin: '5px 10px' }}
              />
              <PrimaryButton 
              text={t("Add new")} 
              onClick={openOverlay} 
              style={{ margin: '5px 10px' }}
              />
            </Col>
          </Row>
          <PartnersTable
            searchInput={searchInput}
            reloadPartners={reloadPartners}
            setSuppliers={setSuppliers}
            getCity={getCity}
            getAvailability={getAvailability}
          />
        </Container>
      </Hoc>
      {(openPopup && (
        <FullScreenPopup open={openPopup} onClose={closeOverlay}>
          <AddBussines
            history={history}
            onClose={closeOverlay}
            partnerId={partnerId}
            updateIsSavedStatus={updateIsSavedStatus}
          />
        </FullScreenPopup>
      )) ||
        null}
    </React.Fragment>
  );
};
export default Partners;
