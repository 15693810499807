import React, { useState, useEffect } from "react";
import { Form, InputGroup, FormControl } from "react-bootstrap";
import Constants from "../../../constants";
import "./Styles.scss";
import * as _ from "lodash";
import Select from "react-select";

const PhoneNumberInput = (props) => {
  const {
    label,
    selectedValues,
    value,
    placeholder,
    disabled,
    maxLength,
    required,
  } = props;
  const codeOptionList = _.uniqBy(
    Constants.CountriesPhoneCode.map((item) => {
      return {
        label: `${item.code}`,
        value: item.code,
      };
    }).sort((a, b) => +a.label.replace(" ", "") - +b.label.replace(" ", "")),
    (e) => {
      return e.value;
    }
  );

  const [countryCode, setCountryCode] = useState("+966");
  const [phoneNumber, setPhoneNumber] = useState(null);

  useEffect(() => {
    if (value) {
      const number = value.split("-");
      setCountryCode(number && number.length && number[0]);
      setPhoneNumber(number && number.length > 1 && number[1]);
    }
  }, [value]);

  const customStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: state.hasValue ? "none" : "unset",
    }),
    control: (provided) => ({
      ...provided,
      minWidth: 70,
      height: "100%",
    }),
  };

  const handleCountryCodeChange = (value) => {
    setCountryCode(value);
    selectedValues({ countryCode: value, phoneNumber: phoneNumber });
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    selectedValues({ countryCode: countryCode, phoneNumber: value });
  };

  return (
    <div className="phone-number-input">
      {label && (
        <Form.Label>
          {label}
          {required && <span style={{ color: "#943434" }}>*</span>}
        </Form.Label>
      )}
      <InputGroup>
        <Select
          isDisabled={disabled}
          styles={customStyles}
          classNamePrefix="country-code-select"
          value={codeOptionList.find((row) => row.value === countryCode)}
          isClearable={false}
          isSearchable={true}
          onChange={(selectedValue) =>
            handleCountryCodeChange(selectedValue.value)
          }
          name="country-code"
          placeholder=""
          options={codeOptionList}
        />
        <FormControl
          disabled={disabled}
          type="text"
          placeholder={placeholder}
          onChange={(e) => {
            handlePhoneNumberChange(e.target.value);
          }}
          value={phoneNumber || ""}
          maxLength={maxLength}
        />
      </InputGroup>
    </div>
  );
};

PhoneNumberInput.propTypes = {};

export default PhoneNumberInput;
