import React, { useEffect } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import { validateToken } from "../core/Api/Auth";
import { getAuthAttribute } from "../utils/helper";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  const token = getAuthAttribute("auth_token");

  useEffect(() => {
    (async () => {
      const token = getAuthAttribute("auth_token");
      if (token) {
        const payload = {
          token: token,
        };
        const result = await validateToken(payload);
        if (result && result.code === 200) {
          if (result.result && result.result.is_valid) {
          } else {
            localStorage.removeItem("boon-react-auth-result");
            // history.push("/login");
            window.open('/login',  '_self')
          }
        } else {
          localStorage.removeItem("boon-react-auth-result");
          window.open('/login',  '_self')
          toast.error((result && result.message) || "Something wrong");
        }
      } else {
        if (
          location.pathname === "/signup" ||
          location.pathname.indexOf("reset_password") >= 0
        ) {
          history.push(location.pathname);
        } else {
          // history.push("/login");
          window.open('/login',  '_self')
        }
      }
    })();
  }, [location.pathname, history]);

  const toRender = (props, pathname) => {
    switch (pathname) {
      case "/complains":
        if (
          getAuthAttribute("user_type_id") === 4 ||
          getAuthAttribute("user_type_id") === 5
        ) {
          return <Component exact {...props} />;
        } else {
          return (
            <Redirect
              to={
                getAuthAttribute("user_type_id") === 5
                  ? "/complains"
                  : "/dashboard"
              }
            />
          );
        }
      case "/calendar":
        if(getAuthAttribute("user_type_id") === 5){
          // return <Redirect to={"/booking-transactions"} />
          return <Component exact {...props} />
        }else if(getAuthAttribute("user_type_id") !== 4){
          return <Component exact {...props} />
        }else{
          return <Redirect to={"/dashboard"} />
        }
      case "/dashboard":
        if (getAuthAttribute("user_type_id") === 5) {
          return <Redirect to={"/calendar"} />;
        } else if (getAuthAttribute("sub_user_type_id") === "6") {
          return <Redirect to={"/bookings"} />;
        } else {
          return <Component exact {...props} />;
        }
      case "/booking-transactions":
      case "/partners":
      case "/partners/:supplier_id":
      case "/booking-transactions":
      case "/blacklisted-keywords":
      case "/services":
      case "/user-types":
      case "/service/:id":
      case "/offers/:id":
      case "/staff/:id":
      case "/permissions/:user_type_id":
      case "/global-settings":
      case "/images/:id":
      case "/calendar/:id":
      case "/delivery-fee/:id":
      case "/supplier-driver/:id":
      case "/reviews/:id":
      case "/payouts/:id":
      case "/disputed-bookings":
      case "/logs":
      if(getAuthAttribute("user_type_id") === 4){
       return <Component exact {...props} />
      }else if(getAuthAttribute("user_type_id") === 5){
        return<Component exact {...props} />
      }else{
        return <Redirect to={"/dashboard"} />
      }
      case "/bookings":
        return getAuthAttribute("user_type_id") === 2 ||
          getAuthAttribute("user_type_id") === 4 ||
          getAuthAttribute("user_type_id") === 5 ||
          getAuthAttribute("sub_user_type_id") === "6" ? (
          <Component exact {...props} />
        ) : (
          <Redirect to={"/dashboard"} />
        );
      case "/staff":
      case "/service":
      case "/my-businesses":
      case "/my-businesses/add":
      case "/images":
        return getAuthAttribute("user_type_id") === 4 ? (
          <Redirect to={"/dashboard"} />
        ) : (
          <Component exact {...props} />
        );
      default:
        return <Component exact {...props} />;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) => (token ? toRender(props, path) : <Redirect to={"/login"} />)}
    />
  );
};

export default PrivateRoute;
