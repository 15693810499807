/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState, forwardRef } from "react";
import ViewAppointment from "../../Client/Profile/ViewAppointment";
import {
  Container,
  Row,
  Col,
  Image,
  Overlay,
  Popover,
  Modal,
  PopoverContent,
  Button,
  OverlayTrigger,
} from "react-bootstrap";
import FullScreenPopup from "../../../components/FullScreenPopup";
import NewAppointment from "../NewAppointment";
import moment from "moment";
import CustomDropdown from "../../../components/Dropdown";
import SearchableDropdown from "../../../components/Dropdown/SearchableDropdown";
import SecondaryButton from "../../../components/Buttons/Secondary";
import PrimaryButton from "../../../components/Buttons/Primary";
import BlockSlotModal from "./BlockSlotModel";
import "./Styles.scss";
import { getCalendarScheduleByStaffId } from "../../../core/Api/bookings";
import { formatAMPM, getAuthAttribute } from "../../../utils/helper";
import { getStaffMembersList } from "../../../core/Api/staff";
import rightArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-2.png";
import downArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-3.png";
import upArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-3.png";
import filterRight from "../../../assets/images/filter-right.svg";
import plusCircle from "../../../assets/images/plus-circle.svg";
import filtersIcon from "../../../assets/images/filters-icon.svg";
import cancelIcon from "../../../assets/images/cancel.png";
import DatePicker from "react-datepicker";
import clock from "../../../assets/images/clock.svg";
import calendarIcon2 from "../../../assets/images/calendar-white.svg";
import cancel from "../../../assets/images/ic_cancel.svg";
import Hoc from "../../Hoc";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "@fullcalendar/daygrid/main.css";
import { getSupplierAddress } from "../../../core/Api/supplier";
import { toast } from "react-toastify";
import { rescheduleBooking, getBookingById } from "../../../core/Api/bookings";
import MobiscrollScheduler from "./mobiscroll";
import FullPageSpinner from "../../../components/FullPageSpinner";
const DefaultImage =
  "https://testapiv2.boon.sa/uploads/supplier_media/default_image.jpg";


const CalenderGrid = ({ history, match }) => {
  const { t, i18n } = useTranslation();
  const calendarRef = useRef(null);
  const [viewAppointment, setViewAppointment] = useState(false);
  const [addAppointmentPopupShow, setAddAppointmentPopupShow] = useState(false);
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [businessHours, setBusinessHours] = useState([]);
  const [selectedStaffId, setSelectedStaffId] = useState(0);
  const calendarApi = useRef(null);
  const [title, setTitle] = useState("");
  const [selectedView, setSelectedView] = useState("day");
  const [pickedDate, setPickedDate] = useState();
  const [staffList, setStaffList] = useState([]);
  const [workMin, setWorkMin] = useState([]);
  const [workMax, setWorkMax] = useState([]);
  const [workDays, setWorkDays] = useState([]);
  const [sendByOptions, setSendByOptions] = useState([]);
  const [blockSlot, setBlockSlot] = useState("");
  const [blockSlotData, setBlockSlotData] = useState({});
  const supplier_id = match.params.id
    ? match.params.id
    : getAuthAttribute("supplier_id");
  const userID = getAuthAttribute("user_type_id");
  const [isLoading, setIsLoading] = useState(false);
  const applicationTypeOptions = [
    { label: t("All Selected"), value: null },
    { label: t("Customer/Android"), value: "customer/android" },
    { label: t("Customer/iOS"), value: "customer/ios" },
    { label: t("Backoffice"), value: "backoffice" },
  ];
  const [status, setStatus] = useState(0);
  const statusOptions = [
    { label: t("All"), value: 0 },
    { label: t("Confirmed"), value: 10 },
    { label: t("On The Way"), value: 19 },
    { label: t("In Progress"), value: 13 },
    { label: t("Completed"), value: 6 },
    { label: t("Pending"), value: 5 },
  ];
  const [selectedApplicationType, setSelectedApplicationType] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [anchorEL, setAnchorEL] = useState(null);
  const [supplierData, setSupplierData] = useState({});
  const [showFiltersOverlay, setShowFiltersOverlay] = useState(false);
  const [addApttOverlay, setAddApptOverlay] = useState(false)
  const [calendarLoading, setCalendarLoading] = useState(true)
  const [startdate, setStartDate] = useState(moment()._d);
  const [enddate, setEndDate] = useState(moment()._d);
  const [isOpenRightDrawer, setIsOpenRightDrawer] = useState(false);
  const [businessHrs, setBusinessHrs] = useState([]);
  const [enableBlockSlotSelect, setEnablelockSlotSelect] = useState(false);

  const notifyByOptions = sendByOptions?.length
    ? [{ label: t("All"), value: 0 }, ...sendByOptions]
    : [{ label: t("All"), value: 0 }];

  const calendaViewsTypeList = [
    { label: t("Day"), value: 'day' },
    { label: t("Week"), value: 'week' },
    { label: t("Month"), value: 'month' },
  ]

  useEffect(() => {
    const state = history.location.state;
    if (state?.addAppointmentPopupShow) {
      !addAppointmentPopupShow && setAddAppointmentPopupShow(true);
    } else if (addAppointmentPopupShow) {
      setAddAppointmentPopupShow(false);
    }
    if (state?.viewAppointment) {
      !viewAppointment && setViewAppointment(true);
    } else if (viewAppointment) {
      setViewAppointment(false);
    }
  }, [history.location.state]);

  const updateDayColor = (day) => {
    const element = document.getElementsByClassName(
      `fc-col-header-cell fc-day fc-day-${day}`
    )[0];
    if (!element) {
      return new Promise((resolve) =>
        setTimeout(() => {
          resolve(updateDayColor(day));
        }, 100)
      );
    } else {
      element.classList.add("fc-day-today");
      return new Promise((resolve) => resolve(true));
    }
  };

  useEffect(() => {
    fetchSupplier();
  }, [supplier_id]);

  const fetchSupplier = async () => {
    toast.dismiss();
    try {
      const result = await getSupplierAddress(supplier_id);
      if (result.code === 200) {
        setSupplierData(result.result ?? {});
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };

  useEffect(() => {
    handleDateClick();
  }, []);

  const getDayAbbreviations = (index) => {
    switch (index) {
      case 0:
        return "sun";
      case 1:
        return "mon";
      case 2:
        return "tue";
      case 3:
        return "wed";
      case 4:
        return "thu";
      case 5:
        return "fri";
      case 6:
        return "sat";
      default:
        return "";
    }
  };

  useEffect(() => {
    getStaffMembers();
  }, [supplier_id]);

  const getStaffMembers = async () => {
    const result = await getStaffMembersList(supplier_id);
    if (result && result.code === 200) {
      if (result.result) {
        const data = (result?.result ?? []).map((row) => ({
          label: row.staff_name,
          value: row.supplier_staff_id,
          profile_pic_url: row.profile_pic_url
        }));
        setSendByOptions(data);
      }
    } else {
      toast.error(result && result.message);
    }
  };

  const handleDateClick = async () => {
    setCalendarLoading(true);
    const calenderSchedule = await getCalendarScheduleByStaffId(
      supplier_id,
      selectedStaffId,
      selectedApplicationType ?? "",
      status
    );
    setCalendarData(calenderSchedule, selectedView);
    setCalendarLoading(false);
  };

  const setCalendarData = (calenderSchedule, activeView = 'day') => {
    console.log('calenderSchedule :', calenderSchedule);
    if (calenderSchedule?.result) {
      const result = calenderSchedule?.result?.supplier?.length
        ? calenderSchedule.result.supplier
        : calenderSchedule?.result?.staff;
      const staffList = (result ?? []).map((value) => ({
        label: value.title,
        value: value.id,
        html: value?.profile_pic_url ? `<img
              class="staff-icon"
              src=${value?.profile_pic_url}
              alt=${"picture"}
            />`
          : `<div class="profile-text">${value.title[0]}</div>`,
        ...value,
      }));
      setStaffList(staffList);
    }
    if (calenderSchedule?.result) {
      const eventsTemp = calenderSchedule.result?.calendar_events
        ?.map((value) => ({
          id: `mbsc_${value.bookingId}`,
          booking_id: value.bookingId || value.supplierUnavailabilityId,
          start: value.start,
          end: value.end,
          title: value.title,
          className: value.className,
          resourceId: value.resourceId,
          resource: value.resourceId,
          staffColor: value.staffColor,
          color: value.staffColor,
          supplierUnavailabilityId: value.supplierUnavailabilityId,
          ...value
        }))
        ?.filter((value) => value.booking_id || value.supplierUnavailabilityId);
      setBusinessHrs(calenderSchedule?.result?.business_hours || [])
      const businessHoursTemp = calenderSchedule?.result?.business_hours?.map(
        (value) => ({
          daysOfWeek: [...value.dow],
          start: value.start,
          end: value.end,
        })
      );
      const workMinArray = businessHoursTemp
        ?.map((item) => item.start)
        .sort()
        .shift();
      const workMaxArray = businessHoursTemp
        ?.map((item) => item.end)
        .sort()
        .pop();
      const workDaysArray = [
        ...new Set(businessHoursTemp.flatMap((item) => item.daysOfWeek)),
      ]?.map((val) => +val);
      const hideDays = [...Array(7).keys()].filter(
        (day) => !workDaysArray.includes(day)
      );
      setWorkMin(workMinArray);
      setWorkMax(workMaxArray);
      setWorkDays(hideDays);
      setEvents(eventsTemp);
      setBusinessHours(businessHoursTemp);
      if (activeView === 'week' || activeView === 'month') {
        setFilteredEvents(eventsTemp.map(e => {
          delete e.resource;
          return e
        }))
      } else {
        setFilteredEvents([...eventsTemp])
      }
    }
  }

  const handleStaffChange = async (staffId) => {
    if (staffId) {
      setSelectedStaffId(staffId);
      setCalendarLoading(true);
      const calenderSchedule = await getCalendarScheduleByStaffId(
        supplier_id,
        staffId,
        selectedApplicationType ?? "",
        status
      );
      setCalendarData(calenderSchedule, selectedView);
      setCalendarLoading(false);
    } else {
      handleViewChange({
        value: 'day'
      })
    }
  }

  const onEventClick = (eventInfo) => {
    const eventId = eventInfo?.booking_id;
    const selectedEventCur = events.find(
      (value) => value.booking_id === eventId
    );
    openOverlay("viewAppointment", selectedEventCur);
  };

  const handleViewChange = async (view) => {
    setSelectedView(view?.value);
    setTarget(null);
    if (view?.value === 'week') {
      calendarApi.current?.today();
      setStartDate(moment().startOf("week")._d);
      setEndDate(moment().endOf("week")._d);
    } else if (view?.value === 'day') {
      setStartDate(moment()._d);
      setEndDate(moment()._d);
    }

    let staffId = (!selectedStaffId && (notifyByOptions && notifyByOptions.length > 0 && notifyByOptions[1].value || 0) || selectedStaffId);
    if (view?.value === 'week' || view?.value === 'month') {
      setSelectedStaffId(staffId)
    } else {
      setSelectedStaffId(0);
      staffId = 0;
    }
    setCalendarLoading(true);
    const calenderSchedule = await getCalendarScheduleByStaffId(
      supplier_id,
      staffId,
      selectedApplicationType ?? "",
      status
    );
    setCalendarData(calenderSchedule, view?.value);
    setCalendarLoading(false);
  };

  const renderEventContent = (eventInfo) => {
    const classNames = [
      "event-content",
      ...eventInfo.event?._def?.ui?.classNames,
    ];
    const staffColor = eventInfo.event?._def?.extendedProps?.staffColor;

    return (
      <div
        style={{ color: staffColor ?? undefined }}
        className={classNames.join(" ")}
      >
        <div className={staffColor ? "title" : "title2"}>
          {eventInfo.event.title}
        </div>
        <div className={staffColor ? "time" : "time2"}>
          {formatAMPM(new Date(eventInfo.event.start)) +
            " - " +
            formatAMPM(new Date(eventInfo.event.end))}
        </div>
      </div>
    );
  };

  const openOverlay = (key, selectedEvent) => {
    let pathname = history.location.pathname + history.location.search;
    if (key === "viewAppointment" && selectedEvent?.booking_id) {
      pathname += `?booking_id=${selectedEvent.booking_id}`;
    }
    const state = history.location.state;
    history.push(pathname, {
      ...state,
      [key]: true,
    });
  };

  const closeOverlay = async () => {
    setAddAppointmentPopupShow(false)
    setViewAppointment(false)
    await handleStaffChange(0);
    if(userID === 5){
      history.push(`/booking-transactions`)
    }else{
      history.push(match.params.id && `/calendar/${match.params.id}` || '/calendar', {})
    }
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    await handleDateClick();
    setIsLoading(false);
  };

  const eventReschedule = async (event) => {
    const { booking_date = moment().format("YYYY-MM-DD"), booking_id, service_date, supplier_staff_id, supplierUnavailabilityId } = event;
    const booking = await getBookingById(booking_id);
    let services;
    if (booking && booking.code === 200) {
      services = booking?.result?.services.map((value) => ({
        amount: value.amount,
        duration: value.duration,
        booking_service_id: value.booking_service_id,
        name: value.name,
        offers: value.offers,
        original_amount: value.original_amount,
        service_date: moment(service_date).format("YYYY-MM-DD"),
        service_time: moment(service_date).format(
          "hh:mm A"
        ),
        supplier_service_id: value.supplier_service_id,
        supplier_staff_id: supplier_staff_id,
        supplier_service_opt_id: value.supplier_service_opt_id,
      }));
    } else {
      toast.error(booking && booking.message);
    }

    const payload = {
      booking_date: booking_date,
      booking_id: booking_id,
      services: services ?? [],
    };
    if (!supplierUnavailabilityId) {
      const response = await rescheduleBooking(payload);
      if (response && response.code === 200) {
        toast.success("Successfully Reschedule Appointment!");
      } else {
        toast.error(response.message);
      }
    }
    handleRefresh()
  };

  const handleOptionPopup = (id) => {
    const ele = document.getElementById(id)
    setAnchorEL((anchorEL && null) || ele);
    setShowFiltersOverlay(!showFiltersOverlay);
  };

  const handleSetAnchorEL = (id) => {
    const ele = document.getElementById(id)
    setAnchorEL((anchorEL && null) || ele);
  }

  const handleClose = () => {
    setModalShow(false);
    setEnablelockSlotSelect(false);
    handleRefresh();
  };

  const renderPopoverContent = () => {
    return (
      <PopoverContent>
        <div className="filter-staff">
          <CustomDropdown
            label="Status"
            options={statusOptions}
            name="application_type"
            value={statusOptions.find((item) => item.value === status)}
            onChange={(value) => {
              setStatus(value.value);
            }}
            classNames="from-calendar"
          />
          <PrimaryButton
            text="Apply"
            className="filter-apply"
            onClick={() => {
              setShowFiltersOverlay(false);
              handleDateClick();
            }}
          />
        </div>
      </PopoverContent>
    );
  };

  const handleDateChange = (type = '', date = null) => {
    setCalendarLoading(true);
    if (type === 'next') {
      if (selectedView === 'week') {
        setStartDate(moment(startdate).add(1, 'week').startOf("week")._d);
        setEndDate(moment(startdate).add(1, 'week').endOf("week")._d);
      } else if (selectedView === 'day') {
        setStartDate(moment(startdate).add(1, 'day')._d);
        setEndDate(moment(startdate).add(1, 'day')._d);
      } else {
        setStartDate(moment(startdate).add(1, 'month')._d);
      }
    } else if (type === 'prev') {
      if (selectedView === 'week') {
        setStartDate(moment(startdate).subtract(1, 'week').startOf("week")._d);
        setEndDate(moment(startdate).subtract(1, 'week').endOf("week")._d);
      } else if (selectedView === 'day') {
        setStartDate(moment(startdate).subtract(1, 'day')._d);
        setEndDate(moment(startdate).subtract(1, 'day')._d);
      } else {
        setStartDate(moment(startdate).subtract(1, 'month')._d);
      }
    } else if (type === 'today') {
      if (selectedView === 'week') {
        setStartDate(moment().startOf("week")._d);
        setEndDate(moment().endOf("week")._d);
      } else if (selectedView === 'day') {
        setStartDate(moment()._d);
        setEndDate(moment()._d);
      } else {
        setStartDate(moment()._d);
      }
    } else if (type === 'specficDate') {
      if (selectedView === 'week') {
        setStartDate(moment(date).startOf("week")._d);
        setEndDate(moment(date).endOf("week")._d);
      } else if (selectedView === 'day') {
        setStartDate(moment(date)._d);
        setEndDate(moment(date)._d);
      } else {
        setStartDate(date);
      }
    }
    setCalendarLoading(false);
  }

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {(selectedView === 'month' && moment(value).format('MMMM')) || ((selectedView === 'week' && value) || (`${moment(value).format("dddd ,")} ${value}`))}
    </button>
  ));

  const renderRightDrawerContent = () => {
    return (
      <PopoverContent>
        <div className="right-drawer-cont">
          <div className="right-drawer-header">
            <img
              src={rightArrow} alt="drawer_close"
              onClick={() => {
                setIsOpenRightDrawer(!isOpenRightDrawer);
              }}
            />
          </div>
          <div className="right-drawer-content">
            <div className="select-view-cont">
              <p className="select-view-heading">Calendar View</p>
              <div className="views-list-cont">
                <div
                  className={`view ${selectedView === 'day' && 'view-active'}`}
                  onClick={() => {
                    handleViewChange({
                      value: 'day'
                    });
                    setIsOpenRightDrawer(false);
                  }}>Day</div>
                <div
                  className={`view ${selectedView === 'week' && 'view-active'}`}
                  onClick={() => {
                    handleViewChange({
                      value: 'week'
                    });
                    setIsOpenRightDrawer(false);
                  }}>Week</div>
                <div
                  className={`view ${selectedView === 'month' && 'view-active'}`}
                  onClick={() => {
                    handleViewChange({
                      value: 'month'
                    });
                    setIsOpenRightDrawer(false);
                  }}>Month</div>
              </div>
            </div>
            <div className="members-cont">
              <p className="members-heading">Team Memberes</p>
              <div className="members-list-cont">
                <ul className="members-list">
                  {notifyByOptions.map(row => (
                    <li
                      className={`members-list-item ${selectedStaffId === row.value && 'members-list-item-active'}`}
                      key={row.value}
                      onClick={() => {
                        handleStaffChange(row.value);
                        setIsOpenRightDrawer(false);
                      }}>
                      <div className="member-img-container">
                        {row.profile_pic_url &&
                          (<img
                            src={row?.profile_pic_url}
                            alt={row.label[0]}
                          />) || (
                            <div class="profile-text">{row.label[0]}</div>
                          )}
                      </div>
                      <p>{row.label}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </PopoverContent>
    )
  }

  const handleAddAppFromSlot = (data) => {
    if (data) {
      setPickedDate(data.dateTime);
      setSelectedStaffId(data?.resource);
      setAddAppointmentPopupShow(true);
      openOverlay('addAppointmentPopupShow')
    }
  }

  const renderAddAppointmentContent = () => {
    return (
      <PopoverContent>
        <div className="add-appointment-content">
          <div className="add-appointment-items">
            <div className="add-appointment-item">
              <p onClick={() => {
                openOverlay("addAppointmentPopupShow");
                setTarget(null);
                setAddApptOverlay(false)
              }}>New Appointment</p>
            </div>
            <div className="add-appointment-item">
              <p onClick={() => {
                // openOverlay("addAppointmentPopupShow");
                setTarget(null);
                setAddApptOverlay(false)
                setEnablelockSlotSelect(true)
              }}>New Block Time</p>
            </div>
          </div>
        </div>
      </PopoverContent>
    );
  };
  const handleAddBlockSlot = (data) => {
    setBlockSlotData({
      ...data,
      supplier_id,
    });
    setModalShow(true);
  }

  return (
    <Hoc
      activeMenu="calender"
      selectedView={selectedView}
      handleViewChange={handleViewChange}
    >
      <Container fluid className="calender-grid">
        {enableBlockSlotSelect && (
          <div className='cal-block-slot-header'>
            <div className="cal-block-slot-header-cont">
              <div></div>
              <div>
                <p>Select time slot to block</p>
              </div>
              <div>
                <img src={cancelIcon} width="20px" height="20px" onClick={() => setEnablelockSlotSelect(false)} />
              </div>
            </div>
          </div>) || null}

        {/* Calendar toolbar starts */}
        <div className="calendar-toolbar">

          {/* Employee List dropdown starts*/}
          <div className="staff-list">
            {supplier_id && (
              <SearchableDropdown
                label=""
                value={
                  selectedStaffId
                    ? notifyByOptions.find(
                      (item) => item.value === selectedStaffId
                    )
                    : notifyByOptions[0]
                }
                onChange={(value) =>
                  handleStaffChange(
                    selectedView === "day"
                      ? value.value?.resource?.id ?? value.value
                      : value.value
                  )
                }
                name="notify_by"
                options={notifyByOptions}
              />
            )}
          </div>
          {/* Employee List dropdown ends*/}

          {/* middle section starts */}
          <div className="calendar-toolbar-col upper-col">
            <div className="calendar-controller-col ml-1">
              <div
                className="calendar-controller"
                onClick={() => {
                  setAnchorEL(null);
                }}
                id="calendar-controller"
              >
                <SecondaryButton
                  className="arrow-button b1"
                  onClick={() => handleDateChange('prev')}
                  src={leftArrow}
                />
                <button
                  className="today-btn"
                  onClick={() => handleDateChange('today')}
                >{t("Today")}</button>
                <DatePicker
                  selected={startdate}
                  onChange={(date) => handleDateChange('specficDate', date)}
                  customInput={<CustomInput />}
                  // closeOnScroll={(e) => e.target === document}
                  dateFormat="d MMM yyyy"
                  showMonthYearPicker={selectedView === 'month' && true || false}
                  showFullMonthYearPicker={selectedView === 'month' && true || false}
                  startDate={startdate}
                  endDate={enddate}
                  selectsRange={selectedView === 'week' && true || false}
                  calendarStartDay={1}
                  disabled={selectedView === 'week' && true || false}
                />
                <SecondaryButton
                  className="arrow-button b2"
                  onClick={() => handleDateChange('next')}
                  src={rightArrow}
                />
              </div>
            </div>
          </div>
          {/* middle section ends */}

          {/* right section starts */}
          <div
            className="calendar-toolbar-col"
            onClick={() => {
              setAnchorEL(null);
            }}
          >
            <div className="new-appointment-col">
              {/* <SecondaryButton
                isLoading={isLoading}
                onClick={handleRefresh}
                src={refresh}
              /> */}
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                rootClose
                show={showFiltersOverlay}
                overlay={(
                  <Popover bsPrefix="staff-profile-filter" id="staff-profile-filter">
                    {renderPopoverContent()}
                  </Popover>
                )}>
                <Button
                  // id="filter-button"
                  className="filter-icon"
                  onClick={() => handleOptionPopup('filter-button')}
                >
                  <Image src={filterRight} />
                </Button>
              </OverlayTrigger>
              <div className="calendar-views-list-cont">
                <SearchableDropdown
                  label=""
                  value={
                    calendaViewsTypeList.find(
                      (item) => item.value === selectedView
                    ) || calendaViewsTypeList[0]
                  }
                  onChange={(value) => handleViewChange(value)}
                  name="notify_by"
                  options={calendaViewsTypeList}
                />
              </div>
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                rootClose
                show={addApttOverlay}
                overlay={(
                  <Popover bsPrefix="add-appt-overlay" id="add-appt-overlay">
                    {renderAddAppointmentContent()}
                  </Popover>
                )}>
                <PrimaryButton
                  id="add-appointment-btn"
                  style={{ padding: "15px", width: '90px' }}
                  text={t("Add ")}
                  onClick={() => {
                    setAddApptOverlay(!addApttOverlay)
                  }}
                  src={addApttOverlay && upArrow || downArrow}
                />
              </OverlayTrigger>

            </div>
            {/* smallscreen filters icon container start */}
            <div className="small-scr-filters-cont">
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                rootClose
                show={isOpenRightDrawer}
                overlay={(
                  <Popover bsPrefix="small-scr-filters-overlay" id="small-scr-filters-overlay">
                    {renderRightDrawerContent()}
                  </Popover>
                )}>
                <SecondaryButton
                  onClick={() => {
                    setIsOpenRightDrawer(!isOpenRightDrawer);
                  }}
                  src={filtersIcon}
                />
              </OverlayTrigger>
            </div>
            {/* smallscreen filters icon container start */}
          </div>
          {/* right section ends */}
        </div>
        {/* Calendar toolbar ends */}
        {/* add appointment button on small screens container start */}
        <div className="small-add-appt-btn">
          <SecondaryButton
            // onClick={handleRefresh}
            src={plusCircle}
            onClick={() => {
              openOverlay("addAppointmentPopupShow");
              setTarget(null);
            }}
          />
        </div>
        {/* add appointment button on small screens container ends */}

        <Row className="calendar-row mt-0">
          <Col className="calender-cont">
            {calendarLoading && <FullPageSpinner /> || null}
            <div className={`${enableBlockSlotSelect && 'block-slot-columns'}`}>
              <MobiscrollScheduler
                selectedView={selectedView}
                staffList={staffList}
                events={filteredEvents}
                startDate={startdate}
                endDate={enddate}
                businessHrs={businessHrs}
                handleAddAppFromSlot={handleAddAppFromSlot}
                handleEventClick={onEventClick}
                eventReschedule={eventReschedule}
                handleAddBlockSlot={handleAddBlockSlot}
                enableBlockSlotSelect={enableBlockSlotSelect}
              />
            </div>
          </Col>
        </Row>
        <FullScreenPopup
          open={addAppointmentPopupShow}
          onClose={closeOverlay}
          classNames="add-app"
        >
          <NewAppointment
            supplierData={supplierData}
            startDate={pickedDate}
            selectedPartner={supplier_id}
            onCancel={() => {
              closeOverlay();
            }}
            selectedStaffId={selectedStaffId}
            history={history}
          />
        </FullScreenPopup>
        <FullScreenPopup
          open={viewAppointment}
          classNames="view-app"
          onClose={closeOverlay}
        >
          <ViewAppointment history={history} onClose={closeOverlay} />
        </FullScreenPopup>
        <Modal show={modalShow} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Block Slot</Modal.Title>
          </Modal.Header>
          <BlockSlotModal
            handleClose={handleClose}
            blockSlotData={blockSlotData}
          />
        </Modal>
      </Container>

      {/* previous cal addapt and add block slot */}
      <Overlay show={Boolean(target)} target={target} placement="bottom">
        <Popover className="calendar-popup">
          <div onClick={() => setTarget(null)} className="circle">
            <Image src={cancel} alt="cancel" />
          </div>
          <Popover.Content>
            <div
              className="d-flex align-items-center"
              onClick={() => {
                openOverlay("addAppointmentPopupShow");
                setTarget(null);
              }}
            >
              <div className="mr-1">
                <Image src={calendarIcon2} alt="calendar" />
              </div>
              {t("New Appointment")}
            </div>
            <div
              className="d-flex align-items-center"
              onClick={() => {
                setModalShow(true);
                setBlockSlot("Block Slot");
                setTarget(null);
              }}
            >
              <div className="mr-1">
                <Image src={clock} alt="clock" />
              </div>
              {t("Add Busy Time")}
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>

    </Hoc>
  );
};
export default CalenderGrid;
