import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import SearchInput from "../../../components/Inputs/Search";
import PrimaryButton from "../../../components/Buttons/Primary";
import Card from "../../../components/Card";
import FullScreenPopup from "../../../components/FullScreenPopup";
import MemberEditCard from "./EditCard";
import AddMember from "../AddMember";
import "./Styles.scss";
import { getStaffMembersList } from "../../../core/Api/staff";
import { useTranslation } from "react-i18next";
import { getAuthAttribute } from "../../../utils/helper";

const Members = ({ history, supplierId, setUpdateProfileStatus }) => {
  const { t } = useTranslation();
  const [addMemberPopup, setAddMemberPopup] = useState(false);
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);
  const filteredMembers = searchInput.trim()
    ? members.filter((item) => item?.staff_name?.toLowerCase()?.includes(searchInput.trim()?.toLowerCase()))
    : members;
  const supplierId2 = supplierId ? supplierId : getAuthAttribute("supplier_id");

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getStaffMembersList(supplierId2);
      if (result && result.code === 200) {        
        setMembers(result.result);
      } else {
        toast.error(result && result.message);
      }
      setIsLoading(false);
    })();
  }, [supplierId2]);

  const updateMembers = (supplier_staff_id) => {
    const updatedMembers = [...members].filter(
      (member) => member.supplier_staff_id !== supplier_staff_id
    );
    setMembers(updatedMembers);
    setUpdateProfileStatus && setUpdateProfileStatus(true);
  };

  useEffect(() => {
    const state = history.location.state;
    if (state?.addMemberPopup) {
      setAddMemberPopup(true);
    } else {
      setAddMemberPopup(false);
    }
  }, [history.location.state]);

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const openOverlay = (row, viewOnly) => {
    const pathname = history.location.pathname + history.location.search;
    const state = history.location.state;
    if (viewOnly === true) {
      setViewOnly(true);
    }
    row && setSelectedMember(row);
    history.push(pathname, {
      ...state,
      addMemberPopup: true,
    });
  };

  const closeOverlay = async () => {
    setUpdateProfileStatus && setUpdateProfileStatus(true)
    selectedMember && setSelectedMember(null);
    setAddMemberPopup(false);
    history.goBack();
    viewOnly && setViewOnly(false);
    //update staff member list after adding/updating staff member
    const result = await getStaffMembersList(supplierId2);
    if (result && result.code === 200) {
      setMembers(result.result);
    } else {
      toast.error(result && result.message);
    }
  };

  return (
    <React.Fragment>
      <Container className="staff-member">
        <Row className="top-cta-row">
          <Col xl="3" lg="4" md="6" sm="12">
            <SearchInput
              classNames="pt-0"
              value={searchInput}
              onChange={handleSearchChange}
              placeholder={t("Search")}
            />
          </Col>
          {/* <Col xl="6" lg="6" md="3" sm="6" className="cta-group">
                <CustomDropdown
                  options={[{ label: "Options", value: 1 }]}
                  onChange={() => false}
                />
              </Col> */}
          <Col xl="6" lg="6" md="6" sm="6" className="cta-staff">
            <PrimaryButton
              text={t("Add Staff")}
              onClick={() => openOverlay()}
            />
          </Col>
        </Row>

        <Row className="listing-card-row">
          {filteredMembers.length ? (
            filteredMembers.map((row, index) => (
              <Col md="6" sm="3" lg="4" xl="3">
                <Card>
                  <MemberEditCard
                    member={row}
                    key={index}
                    onClick={(viewOnly) => openOverlay(row, viewOnly)}
                    updateMembers={updateMembers}
                  />
                </Card>
              </Col>
            ))
          ) : isLoading ? (
            <div className="spinner-wrap">
              <Spinner animation="border" />
              <span>{t("Please wait, we're loading members data")}</span>
            </div>
          ) : (
            <div className="no-members-container">
              <h4>{t("No members to display")}</h4>
            </div>
          )}
        </Row>
      </Container>
      <FullScreenPopup open={addMemberPopup} onClose={closeOverlay}>
        <AddMember
          selectedMember={selectedMember}
          viewOnly={viewOnly}
          onClose={closeOverlay}
          supplierId={supplierId2}
        />
      </FullScreenPopup>
    </React.Fragment>
  );
};

Members.propTypes = {};

export default Members;
