import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./Styles.scss";

import Hoc from "../Hoc";
import MyOffers from "./MyOffers";

const Offers = (props) => {
  const { match } = props;
  return (
    <Hoc activeMenu={match.params.id ? "partner" : "offers"}>
      <MyOffers {...props} />
    </Hoc>
  );
};

export default Offers;
