import React, { useContext, useEffect, useRef, useState } from "react";
import { Container, Image, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import AssetsImg from "images-col";
import Constants from "../../constants";
import "./Styles.scss";
import { getAuthAttribute } from "../../utils/helper";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../../languageSelect";
import { getNotifications } from "../../core/Api/Auth";
import { toast } from "react-toastify";
import cameraIcon from "../../assets/images/camera.svg";
import pristineSound from "../../assets/audios/pristine.mp3";
import { getProfileCompletionStatus } from "../../core/Api/partner";
import ProfileStatus from "../../navigation/ProfileStatus";
import BoonContext from "../../context";
import Logo from "../../assets/images/desk-notif-logo.png";


const TopMenu = (props) => {

  const { t } = useTranslation();
  const notificationBtnRef = React.useRef(null)
  const { showNotification, show, updateProfileStatus = false, setCustProfileStats, updateNotificationCount } = props;
  const isUserAdmin = getAuthAttribute("user_type_id");
  const notificatonSoundRef = useRef(null);
  let unseenNotificationCount = localStorage.getItem("unseen-notification")
    ? localStorage.getItem("unseen-notification")
    : 0;
  const initialUnseenCount = !Number.isNaN(+unseenNotificationCount)
    ? +unseenNotificationCount
    : 0;
  const [unseen, setUnseen] = useState(initialUnseenCount);
  const unseenRef = useRef(unseen);
  const [loading, setLoading] = useState(false);
  const [profileStats, setProfileStats] = useState(null);
  const [showProfileStatus, setShowProfileStatus] = useState(false);
  const [completedStepsCount, setCompletedStepsCount] = useState(0)
  const { iWillDoLater, profileStatusInfo, setProfileStatusInfo } = useContext(BoonContext);

  useEffect(() => {
    if(isUserAdmin !== 7){
      checkProfileStatus();
    }
  }, [updateProfileStatus]);

  const updatedUnseen = (notificationsResult) => {
    localStorage.setItem("unseen-notification", notificationsResult?.unseen);
    unseenRef.current = notificationsResult?.unseen;
    setUnseen(notificationsResult?.unseen);
    notificationBtnRef?.current && notificationBtnRef.current.click();
    showDesktopNotification(notificationsResult?.notifications);
  };

  function playSound() {
    const audio = new Audio(pristineSound);
    audio.play();
  }

  const showDesktopNotification = (notifications=[]) => {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        createNotification(notifications);
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            createNotification(notifications);
          }
        });
      }
    }
  };

  const createNotification = (notifications = []) => {
		try {
			const unSeen = notifications.filter((r) => r.is_seen === '0')[0];
			const notification = new Notification('BOON BACKOFFICE', {
				body:
					(unSeen && `${unSeen?.message} \n ${unSeen?.sub_text}`) ||
					`You have a new notification from BOON`,
				icon: Logo,
			});

			notification.onclick = () => {
				// Handle notification click event if needed
			};
		} catch (error) {
			console.log(error);
		}
	};


  useEffect(() => {
    getNotificationsCount();
    const interval = setInterval(getNotificationsCount, 15000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [updateNotificationCount]);

  const getNotificationsCount = async () => {
    const result = await getNotifications();
    if (result && result.code === 200) {
      // updatedUnseen(result.result);
      if (result.result.unseen !== unseenRef.current) {
        if (result.result.unseen) {
          notificatonSoundRef.current?.click();
        }
        updatedUnseen(result.result);
      }
    } else {
      toast.error(result && result.message);
    }
  };

  const handleLogout = () => {
    Swal.fire({
      text: t("Are you sure you want to logout?"),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#0b586d",
      cancelButtonColor: "#fff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Yes"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("boon-react-auth-result");
        window.open('/login', '_self')
        // history.push("/login");
      }
    });
  };
  const navKey = () => {
    if (isUserAdmin === 4) {
      return "NavigationAdmin";
    } else if (isUserAdmin === 5) {
      return "UserNavigation";
    } else if (getAuthAttribute("sub_user_type_id") === "6") {
      return "DriverNavigation";
    } else {
      return "Navigation";
    }
  };

  const handleNotificationIconClick = async (e) => {
    showNotification(show ? null : e.target);
  };

  const checkProfileStatus = async () => {
    setLoading(true);
    if (getAuthAttribute('user_type_id') !== 4 && getAuthAttribute('user_type_id') !== 5 ) {
      const profileStatsRes = await getProfileCompletionStatus(getAuthAttribute('supplier_id'));
      setLoading(false);
      if (profileStatsRes && profileStatsRes.code === 200) {
        setCustProfileStats && setCustProfileStats(profileStatsRes.result)
        setProfileStats(profileStatsRes.result);
        getCompletedProfileCount(profileStatsRes?.result);
        setProfileStatusInfo(profileStatsRes.result)
        if (!iWillDoLater) {
          setShowProfileStatus(!profileStatsRes?.result?.is_profile_complete);
        }
      } else {
        toast.error(profileStatsRes && profileStatsRes.message);
      }
    } else {
      setLoading(false);
    }
  }

  const getCompletedProfileCount = (profileStats) => {
    let count = 0;
    if (profileStats?.has_services === "1") {
      count += 1;
    }
    if (profileStats?.has_staff === "1") {
      count += 1;
    }
    if (profileStats?.has_staff_shifts === "1") {
      count += 1;
    }
    if (profileStats?.has_working_hours === "1") {
      count += 1;
    }
    setCompletedStepsCount(count)
  }
  return (
    <Container fluid className="top-menu">
      {/* {loading && <FullPageSpinner /> || null} */}
      <div className="row m-0 flex-row">
        <div className="heading">
          {Constants[navKey()].map((item) => {
            if (props.selected === item.id) {
              return (
                <React.Fragment key={item.id}>
                  <Image src={item.staticIcon} /> {t(`${item.name}`)}
                </React.Fragment>
              );
            } else if (item.hasSuboptions) {
              const selectedSuboption = item.options.find(
                (value) => value.id === props.selected
              );
              if (selectedSuboption) {
                return (
                  <React.Fragment key={selectedSuboption.id}>
                    <Image src={selectedSuboption.staticIcon} />{" "}
                    {t(`${selectedSuboption.name}`)}
                  </React.Fragment>
                );
              } else {
                return null;
              }
            } else {
              return null;
            }
          })}
        </div>
        <div className="right-block" md="auto">
          <div className="d-none" ref={notificationBtnRef} onClick={playSound}>Sound</div>
          {props.handleImageUpload && (
            <div className="upload-btn-wrapper">
              <Image
                src={cameraIcon}
                alt="upload-image"
                classNames="text-nowrap"
              />
              <input
                type="file"
                name="myfile"
                accept=".png, .jpg, .jpeg"
                onChange={props.handleImageUpload}
              />
            </div>
          )}
          {(!profileStats?.is_profile_complete && (getAuthAttribute('user_type_id') !== 4 && getAuthAttribute('user_type_id') !== 5) && getAuthAttribute('user_type_id') !== 7) && (
            loading && (<Spinner animation="border" />) ||
            (<button
              className="profile-status-btn"
              ref={notificatonSoundRef}
              onClick={() => setShowProfileStatus(true)}
            >
              Complete Profile
              <p>{completedStepsCount}</p>
            </button>)
          )
          }

          <LanguageSelect />
          <div className="notification-wrap">
            <Image
              className="notification"
              src={AssetsImg.ic_feather_bell}
              onClick={(e) => handleNotificationIconClick(e)}
            />
            {unseen ? <span>{unseen}</span> : null}
          </div>
          <Image
            className="notification"
            src={AssetsImg.ic_logout_icon}
            onClick={handleLogout}
            alt="logout"
          />
        </div>
      </div>
      {showProfileStatus &&
        <ProfileStatus
          profileStats={profileStats}
          showProfileStatus={showProfileStatus}
          setShowProfileStatus={setShowProfileStatus} />
        || null}
    </Container>
  );
};

export default TopMenu;
