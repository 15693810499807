import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Container, Row, Col, Table, Image, Spinner } from "react-bootstrap";
import "./Styles.scss";
import rightArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-1.png";
import leftArrow from "../../../assets/images/Icon ionic-ios-arrow-forward-2.png";
import deactivateIcon from "../../../assets/images/eye-slash-fill.svg";
import activateIcon from "../../../assets/images/eye-fill.svg";
import trash from "../../../assets/images/trash.svg";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { getAuthAttribute } from "../../../utils/helper";
import { deleteEmployee, getEmployees, updateEmployeeStatus } from "../../../core/Api/employee";
import FullPageSpinner from "../../../components/FullPageSpinner";

const EmployeeListing = ({
  searchInput,
  handleReload,
  history,
  reloadClients,
  openOverlay
}) => {
  const { t } = useTranslation();
  const pageSize = 10;
  const isUserAdmin = getAuthAttribute("user_type_id") === 4;
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [count, setCount] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const deleteClickRef = useRef(false);
  const timeoutRef = useRef(null);

  const debouncedLoadData = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      loadData(searchInput);
    }, 500);
  };

  useEffect(() => {
    if (searchInput?.trim()) {
      debouncedLoadData(searchInput);
    } else {
      loadData(searchInput);
    }
    // eslint-disable-next-line
  }, [searchInput]);

  useEffect(() => {
    if (reloadClients) loadData(searchInput);
    // eslint-disable-next-line
  }, [reloadClients]);

  const loadData = async (value = null) => {
    setIsLoading(true);
    const result = await getEmployees({
      per_page:pageSize,
      offset:  (pageSize * (pageNo - 1)),
      search_phrase: value,
    });
    if (result && result.code === 200) {
      setCustomers(result.result.data);
      setCount(result?.result?.total_count || 0);
      setNumberOfPages(
        Math.ceil(
          Math.floor((result?.result?.total_count) || 0) / pageSize
        )
      );
    } else {
      toast.error(result && result.message);
    }
    reloadClients && handleReload(false);
    setIsLoading(false);
  };

  const handlePageChange = async (type, value = null) => {
    const page = type === "next" ? pageNo + 1 : pageNo - 1;
    if (page === 0) return;
    if (page > count / pageSize) return;
    setPageNo(page);
    setIsLoading(true);
    const result = await getEmployees({
      per_page:pageSize,
      offset:  (pageSize * (pageNo - 1)),
      search_phrase: value,
    });
    if (result && result.code === 200) {
      setCustomers(result.result.data);
      setCount(result?.result?.total_count || 0);
      setNumberOfPages(
        Math.ceil(
          Math.floor((result?.result?.total_count) || 0) / pageSize
        )
      );
    } else {
      toast.error(result && result.message);
    }
    setIsLoading(false);
  };

  const handlePageNoChange = async (page, value = null) => {
    setPageNo(page);
    setIsLoading(true);
    const result = await getEmployees({
      per_page:pageSize,
      offset:  (pageSize * (pageNo - 1)),
      search_phrase: value,
    });
    if (result && result.code === 200) {
      setCustomers(result.result.data);
      setCount(result?.result?.total_count || 0);
      setNumberOfPages(
        Math.ceil(
          Math.floor((result?.result?.total_count) || 0) / pageSize
        )
      );
    } else {
      toast.error(result && result.message);
    }
    setIsLoading(false);
  };

  const pageNumbers = [];
  let pages = 5;
  if (!(numberOfPages > 8)) {
    pages = numberOfPages;
  }
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }

  const handleDeleteService = async (emp_id) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        "Are you sure you want to delete this employee? This action cannot be undone."
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const result = await deleteEmployee(emp_id);
        setLoading(false);
        if (result && result.code === 200) {
          Swal.fire("Deleted Successfully", "", "success");
          console.log("result", result);
          handleReload(true);
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
      deleteClickRef.current = false;
    });
  };

  const handleToggleActive = (id, status) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        `Are you sure you want to ${status === "1" && 'block' || 'activate'} this employee?`
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#D11A2A",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Ok"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          emp_id:id,
          status_id:status === "1" && "2" || "1",
        };
        setLoading(true);
        const res = await updateEmployeeStatus(data);
        setLoading(false)
        if (res && res.code === 200) {
          Swal.fire(`${status === "1" && 'Blocked' || 'Activated'} Successfully`, "", "success");
          handleReload(true);
        } else {
          Swal.fire(
            (res && res.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
      deleteClickRef.current = false;
    });
  }

  return customers.length ? (
    <Container fluid className="employee-table">
      {loading && <FullPageSpinner /> || null }
      <Row>
        <Col>
          <Table responsive>
            <thead>
              <tr>
                <th>{t("Code")}</th>
                <th>{t("First Name")}</th>
                <th>{t("Last Name")}</th>
                <th>{t("Email")}</th>
                <th>{t("Phone")}</th>
                <th>{t("Role")}</th>
                <th>{t("Department")}</th>
                <th>{t("Designation")}</th>
                <th>{t("Gender")}</th>
                <th>{t("DOB")}</th>
                <th>{t("Joining Date")}</th>
                <th>{t("Contract End Date")}</th>
                <th>{t("Employment End Date")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {customers &&
                customers.length &&
                customers.map((row, index) => (
                  <tr
                    onClick={() =>
                      !deleteClickRef.current &&
                      openOverlay(false, row?.emp_id)
                    }
                    key={index}
                  >
                    <td>{row?.emp_code || ''}</td>
                    <td>{row?.first_name || ''}</td>
                    <td>
                      {row?.last_name || ''}
                    </td>
                    <td>{row.emp_email || 'N/A'}</td>
                    <td>{row.emp_phone || 'N/A'}</td>
                    <td>{row.emp_role || 'N/A'}</td>
                    <td>{row.dept_name || 'N/A'}</td>
                    <td>{row.desg_name || 'N/A'}</td>
                    <td>
                      {row?.emp_gender ? t(`${row.emp_gender.toUpperCase()}`) : "N/A"}
                    </td>
                    <td className="text-nowrap">{row.birth_date || 'N/A'}</td>
                    <td className="text-nowrap">{row.joining_date || 'N/A'}</td>
                    <td className="text-nowrap">{row.contract_end_date || 'N/A'}</td>
                    <td className="text-nowrap">{row.employment_end_date || 'N/A'}</td>
                    <td>
                      <div className="actions-cont">
                        <Image
                          className="delete"
                          src={trash}
                          alt="delete"
                          onClick={() => {
                            deleteClickRef.current = true;
                            handleDeleteService(row.emp_id);
                          }}
                        />
                        {isUserAdmin && (
                          row.status_id === "2" && (
                            <Image
                              className="delete"
                              src={deactivateIcon}
                              alt="delete"
                              onClick={() => {
                                deleteClickRef.current = true;
                                handleToggleActive(row.emp_id, row.status_id);
                              }}
                            />
                          ) || (
                            <Image
                              className="delete"
                              src={activateIcon}
                              alt="delete"
                              onClick={() => {
                                deleteClickRef.current = true;
                                handleToggleActive(row.emp_id, row.status_id);
                              }}
                            />
                          )
                        ) || null}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="pagination">
        <Col md="3 result-text">
          {t("Showing")} {pageSize * (pageNo - 1) + 1} {t("to")}&nbsp;
          {pageSize * (pageNo - 1) + customers.length} {t("of")} {count}{" "}
          {t("Results")}
        </Col>

        <Col md="5" className="count">
          <div
            className="previous"
            onClick={() => handlePageChange("prev", searchInput)}
          >
            <img src={leftArrow} alt="left-arrow"></img>
          </div>
          <div className="pages">
            {pageNumbers.map((page, index) => {
              return (
                <span
                  key={index}
                  className={` cursor-pointer ${pageNo === page && "active"}`}
                  onClick={() => handlePageNoChange(page, searchInput)}
                >
                  {page}
                </span>
              );
            })}
            {numberOfPages > 8 && <span>...</span>}
            {numberOfPages > 8 && (
              <span
                className={` cursor-pointer ${pageNo === numberOfPages && "active"
                  }`}
                onClick={() => handlePageNoChange(numberOfPages, searchInput)}
              >
                {numberOfPages}
              </span>
            )}
          </div>
          <div
            className="next"
            onClick={() => handlePageChange("next", searchInput)}
          >
            <img src={rightArrow} alt="right-arrow"></img>
          </div>
        </Col>
      </Row>
    </Container>
  ) : isLoading ? (
    <div className="spinner-wrap">
      <Spinner animation="border" />
      <span>
        {t(
          `${pageNo > 1
            ? `Fecthing data for Page ${pageNo}`
            : t("Please wait, we're loading employees data")
          }`
        )}
      </span>
    </div>
  ) : (
    <div className="no-data-container mt-4">
      <h4>{t("No Employees Available")}</h4>
    </div>
  );
};
export default EmployeeListing;
