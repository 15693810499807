import Axios from "../Axios";

export const saveSupplier = async (data) => {
  try {
    const response = await Axios.post("/supplier/save", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveUnavailability = async (data) => {
  try {
    const response = await Axios.post("/supplier/save_unavailability", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteMedia = async (supplier_media_id) => {
  const data = {
    supplier_media_id,
  };
  try {
    const response = await Axios.post("/supplier/delete_media", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteFeatureMedia = async (supplier_media_id) => {
  try {
    const response = await Axios.get(
      `/supplier/delete_feature_image/${supplier_media_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getOffers = async (supplier_id, exclude_services = 0) => {
  try {
    const response = await Axios.get(
      `/supplier/offers?supplier_id=${supplier_id}&exclude_services=${exclude_services}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getOfferByd = async (params={}) => {
  try {
    const {supplier_id=0, exclude_services = 0, offer_id=0} = params;
    const response = await Axios.get(
      `/supplier/offers?offer_id=${offer_id}&supplier_id=${supplier_id}&exclude_services=${exclude_services}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSearchPhrases = async (records_limit = -1) => {
  try {
    const response = await Axios.get(
      `/supplier/popular_search_phrases?records_limit=${records_limit}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteSearchPhrase = async (search_phrase) => {
  const data = { search_phrase };

  try {
    const response = await Axios.post("/supplier/delete_search_phrase", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deactivateOffer = async (offer_id) => {
  try {
    const response = await Axios.get(
      `/supplier/deactivate_offer?offer_id=${offer_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteOffer = async (offer_id) => {
  try {
    const response = await Axios.get(
      `/supplier/delete_offer?offer_id=${offer_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveOffer = async (data) => {
  try {
    const response = await Axios.post("/supplier/save_offer", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getReviews = async (id) => {
  try {
    const response = await Axios.get(
      `/supplier/supplier_reviews_by_status/${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getVerifiedReviews = async (supplier_id, customer_id = 0) => {
  try {
    const response = await Axios.get(
      `/supplier/supplier_reviews?supplier_id=${supplier_id}&customer_id=${customer_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const approveReviews = async (review_id) => {
  const data = {
    review_id,
  };
  try {
    const response = await Axios.post("/supplier/approve_review", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSupplierAdmins = async (id) => {
  try {
    const response = await Axios.get(`/supplier/administrators/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveSupplierAdmin = async (data) => {
  try {
    const response = await Axios.post("/supplier/save_administrator", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteSupplierAdmin = async (supplier_admin_id) => {
  const data = {
    supplier_admin_id,
  };
  try {
    const response = await Axios.post("/supplier/delete_administrator", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveDeliveryFee = async (data) => {
  try {
    const response = await Axios.post("/supplier/save_delivery_fee", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getDeliveryFee = async (id) => {
  try {
    const response = await Axios.get(`/supplier/delivery_fees/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveAccountDetails = async (data) => {
  try {
    const response = await Axios.post("/supplier/save_bank_account", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAccountDetails = async (id) => {
  try {
    const response = await Axios.get(
      `/supplier/bank_account?supplier_id=${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSupplierDriverlist = async (id) => {
  try {
    const response = await Axios.get(`/supplier/drivers/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveSupplierDriver = async (data) => {
  try {
    const response = await Axios.post("/supplier/save_driver", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteSupplierDriver = async (supplier_driver_id) => {
  const data = {
    supplier_driver_id,
  };
  try {
    const response = await Axios.post("/supplier/delete_driver", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSupplierDriver = async (supplier_id) => {
  try {
    const response = await Axios.get(`/supplier/drivers/${supplier_id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSupplierPayouts = async (supplier_id, per_page, offset) => {
  try {
    const response = await Axios.get(
      `/supplier/payouts?supplier_id=${supplier_id}&per_page=${per_page}&offset=${offset}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const confirmSupplierPayout = async (supplier_payout_id) => {
  const data = {
    supplier_payout_id,
  };
  try {
    const response = await Axios.post("/supplier/confirm_payout", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSupplierAddress = async (id) => {
  try {
    const response = await Axios.get(`/supplier/index/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
