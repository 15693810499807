import React, { useState, useRef, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../components/Buttons/Primary";
import UserInput from "../../components/Inputs";
import { saveDeliveryFee, getDeliveryFee } from "../../core/Api/supplier";
import { getAuthAttribute } from "../../utils/helper";
import SimpleReactValidator from "simple-react-validator";

import { toast } from "react-toastify";

const data = {
  "0 to 7": "",
  "7 to 12": "",
  "12 to 20": "",
  "20 or more": "",
};

const From = ({ match }) => {
  const { t } = useTranslation();
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: this })
  );
  const [, forceUpdate] = useState();
  const supplierId = match.params.id
    ? match.params.id
    : getAuthAttribute("supplier_id");
  const [deliveryData, setDeliveryData] = useState({ ...data });

  useEffect(() => {
    getDeliveryData();
    // eslint-disable-next-line
  }, []);

  const getDeliveryData = async () => {
    toast.dismiss();
    try {
      const result = await getDeliveryFee(supplierId);
      if (result.code === 200) {
        const data = result?.result[0] ?? {};
        if (data.fee_details?.length) {
          const updatedDeliveryData = { ...deliveryData };
          const labels = Object.keys(deliveryData);
          data.fee_details.forEach((key) => {
            const label = `${key.km_from} ${
              key.km_to > 0 ? "to " + key.km_to : "or more"
            }`;
            if (labels.includes(label)) {
              updatedDeliveryData[label] = key.delivery_price;
            }
          });
          setDeliveryData(updatedDeliveryData);
        }
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };

  const handleSave = async () => {
    toast.dismiss();
    try {
      if (simpleValidator.current.allValid()) {
        const fee_details = [
          {
            km_from: "0",
            km_to: "7",
            delivery_price: deliveryData["0 to 7"],
          },
          {
            km_from: "7",
            km_to: "12",
            delivery_price: deliveryData["7 to 12"],
          },
          {
            km_from: "12",
            km_to: "20",
            delivery_price: deliveryData["12 to 20"],
          },
          {
            km_from: "20",
            km_to: "-1",
            delivery_price: deliveryData["20 or more"],
          },
        ];
        const data = {
          supplier_id: supplierId,
          fee_details: fee_details,
        };
        const result = await saveDeliveryFee(data);
        if (result?.code === 200) {
          toast.success("Delivery price saved successfully");
        } else {
          throw new Error(result?.message ?? "Something went wrong");
        }
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedFeeDetailes = { ...deliveryData };
    updatedFeeDetailes[name] = value;
    setDeliveryData(updatedFeeDetailes);
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <div className="delivery-form-container">
          <Row>
            <Col md="6">
              <h5 className="heading">{t("Distance Range")} </h5>
            </Col>
            <Col md="6">
              <h5 className="heading">{t("Fee(SAR)")}</h5>
            </Col>
            <hr style={{ width: "100%" }}></hr>
          </Row>

          {Object.keys(deliveryData).map((label) => {
            const value = deliveryData[label];
            return (
              <Row key={label}>
                <Col md="6">
                  <h5>{t(`${label}`)}</h5>
                </Col>
                <Col md="6">
                  <UserInput
                    name={label}
                    value={value}
                    onChange={handleChange}
                  />
                  {simpleValidator.current.message(
                    label,
                    value,
                    "required|numeric"
                  )}
                </Col>
              </Row>
            );
          })}

          <Row className="save-btn">
            <PrimaryButton text={t("Save")} onClick={handleSave} />
          </Row>
        </div>
      </Row>
    </Container>
  );
};

export default From;
