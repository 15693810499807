import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Hoc from "../../Hoc";
import { Container, Row, Col, Image, Table } from "react-bootstrap";
import backIcon from "../../../assets/images/Icon ionic-ios-arrow-back.png";
import Card from "../../../components/Card";
import PrimaryButton from "../../../components/Buttons/Primary";
import RoundedButton from "../../../components/Buttons/Rounded";
import NewAppointment from "../../Calender/NewAppointment";
import FullScreenPopup from "../../../components/FullScreenPopup";
import "./Styles.scss";
import {
  getCustomerById,
  getInvoicesByCustomerId,
  updateAddress,
} from "../../../core/Api/customer";
import {
  getAllBookingsByCustomerId,
  getBookingSummaryByCustomerId,
} from "../../../core/Api/bookings";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ViewAppointment from "./ViewAppointment";
import { getAuthAttribute } from "../../../utils/helper";
import AssetsImg from "images-col";
const DefaultImage =
  "https://testapiv2.boon.sa/uploads/supplier_media/default_image.jpg";

const Profile = ({ history, match }) => {
  const { t } = useTranslation();
  const [openPopup, setOpenPopup] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [bookingSummary, setBookingSummary] = useState(null);
  const [isActive, setActive] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [viewAppointment, setViewAppointment] = useState(false);
  const [addressList, setAddressList] = useState(null);
  const isUserAdmin =
    getAuthAttribute("user_type_id") === 4 ||
    getAuthAttribute("user_type_id") === 5;
  const supplierId = isUserAdmin && 0 || getAuthAttribute("supplier_id");

  useEffect(() => {
    (async () => {
      if (match && match.params && match.params.id) {
        setCustomerId(match.params.id);
        const [result, invoicesResult, bookingsResult, summary] =
          await Promise.all([
            getCustomerById(match.params.id),
            getInvoicesByCustomerId(match.params.id),
            getAllBookingsByCustomerId(supplierId, match.params.id),
            getBookingSummaryByCustomerId(match.params.id),
          ]);
        if (result && result.code === 200) {
          setCustomer(result.result);
        } else {
          toast.error(result && result.message);
        }
        if (invoicesResult && invoicesResult.code === 200) {
          setInvoices(invoicesResult.result);
        } else {
          toast.error(invoicesResult && invoicesResult.message);
        }
        if (bookingsResult && bookingsResult.code === 200) {
          setAppointments(bookingsResult.result);
        } else {
          toast.error(bookingsResult && bookingsResult.message);
        }
        if (summary && summary.code === 200) {
          setBookingSummary(summary.result);
        } else {
          toast.error(summary && summary.message);
        }
      }
    })();
  }, [match]);

  useEffect(() => {
    (async () => {
      if (customerId) {
        const result = await updateAddress({
          customer_id: customerId,
        });
        setAddressList(result.result);
      }
    })();
  }, [customerId]);

  const handleInvoiceRedirection = (bookingId) => {
    window.open(`/invoice/${bookingId}`, "_blank");
  };

  useEffect(() => {
    const state = history.location.state;
    if (state?.newAppointment) {
      setOpenPopup(true);
    } else {
      setOpenPopup(false);
    }
    if (state?.viewAppointment) {
      setViewAppointment(true);
    } else {
      setViewAppointment(false);
    }
  }, [history.location.state]);

  const openOverlay = (key, bookingId) => {
    let pathname = history.location.pathname + history.location.search;
    if (bookingId) {
      pathname += `?booking_id=${bookingId}`;
    }
    const state = history.location.state;
    history.push(pathname, {
      ...state,
      [key]: true,
    });
  };

  const closeOverlay = () => {
    history.goBack();
  };
  return (
    <React.Fragment>
      <Hoc activeMenu="profile">
        <Container className="client-profile">
          <Row>
            <Col md="5" className="personal-detail">
              <Card>
                <div>
                  {/* <div className="back-arrow">
                    <Image
                      className="back"
                      src={backIcon}
                      onClick={() => history.goBack()}
                    />
                  </div> */}
                  <Image
                    src={(customer && customer.profile_pic) || AssetsImg?.img_default}
                  />
                </div>
                <div className="name">{customer && customer.first_name}</div>

                <Row>
                  <PrimaryButton
                    text={t("Edit")}
                    onClick={() =>
                      history.push(`/client/${customerId ?? 0}`, {
                        addressList,
                      })
                    }
                    style={{
                      width: "30%",
                    }}
                  />
                  <PrimaryButton
                    text={t("New Appointment")}
                    onClick={() => openOverlay("newAppointment")}
                    style={{
                      width: "60%",
                      padding: "inherit",
                    }}
                  />
                </Row>
                <hr />
                <div className="filled-data">
                  <label>{t("Email")}</label>
                  <div className="response">
                    {customer && customer.email ? customer.email : "NA"}
                  </div>
                  <label>{t("Mobile")}</label>
                  <div className="response">
                    {customer && customer.mobile ? customer.mobile : "NA"}
                  </div>
                  <label>{t("Gender")} </label>
                  <div className="response">
                    {t(
                      `${
                        customer && customer.gender
                          ? customer.gender.toUpperCase()
                          : "NA"
                      }`
                    )}
                  </div>
                  <label>{t("Marketing notifications")} </label>
                  <div className="response">
                    {customer && customer.accept_marketing_notification === "1"
                      ? t("Accepts marketing notifications")
                      : null}
                  </div>
                  <label>{t("Birthday")} </label>
                  <div className="response">
                    {customer && customer.date_of_birth
                      ? customer.date_of_birth
                      : "NA"}
                  </div>
                </div>
              </Card>
            </Col>
            <Col md="7">
              <Card className={"custom-card sar-section"}>
                <Row>
                  <Col>
                    <div className="title">
                      {t("SAR")} {bookingSummary && bookingSummary.total_sales}
                    </div>
                    <div className="value">{t("Total Sales")}</div>
                  </Col>
                  <Col>
                    <div className="title">
                      {t("SAR")}{" "}
                      {bookingSummary && bookingSummary.total_outstanding}
                    </div>
                    <div className="value">{t("Outstanding")}</div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <div className="title">
                      {bookingSummary &&
                        bookingSummary.completed +
                          bookingSummary.no_show +
                          bookingSummary.cancelled +
                          bookingSummary.pending}
                    </div>
                    <div className="value text-nowrap">{t("All bookings")}</div>
                  </Col>
                  <Col>
                    <div className="title">
                      {bookingSummary && bookingSummary.completed}
                    </div>
                    <div className="value">{t("Completed")}</div>
                  </Col>
                  <Col>
                    <div className="title">
                      {bookingSummary && bookingSummary.cancelled}
                    </div>
                    <div className="value">{t("Cancelled")}</div>
                  </Col>
                  <Col>
                    <div className="title">
                      {bookingSummary && bookingSummary.no_show}
                    </div>
                    <div className="value">{t("No Shows")}</div>
                  </Col>
                  <Col>
                    <div className="title">
                      {bookingSummary && bookingSummary.pending}
                    </div>
                    <div className="value">{t("Pending")}</div>
                  </Col>
                </Row>
              </Card>

              <Card className={"custom-card past-section"}>
                <div className="cta">
                  <RoundedButton
                    onClick={() => setActive(true)}
                    isActive={isActive}
                    label={t("Appointments")}
                  />
                  <RoundedButton
                    onClick={() => setActive(false)}
                    isActive={!isActive}
                    label={t("Invoices")}
                  />
                </div>
                {isActive ? (
                  !appointments?.status_list?.length ? (
                    <div style={{ textAlign: "center" }} className="heading">
                      {"No bookings found"}
                    </div>
                  ) : (
                    Object.keys(appointments).map(
                      (key) =>
                        appointments[key].bookings &&
                        appointments[key].bookings.length && (
                          <React.Fragment>
                            <div className="heading">
                              {`${t(key)} (${
                                appointments[key].bookings.length
                              })`}
                            </div>
                            <ul>
                              {appointments[key].bookings.map((booking, i) => (
                                <li
                                  key={i}
                                  onClick={() =>
                                    openOverlay(
                                      "viewAppointment",
                                      booking.booking_id
                                    )
                                  }
                                >
                                  <div className="timing">
                                    <div className="date">
                                      {moment(booking.appointment_date).format(
                                        "DD"
                                      )}
                                    </div>
                                    <div className="month">
                                      {moment(booking.appointment_date).format(
                                        "MMM"
                                      )}
                                    </div>
                                  </div>
                                  <div className="name">
                                    <div className="slot">
                                      {`${moment(
                                        booking.appointment_date
                                      ).format("ddd")} ${moment(
                                        booking.appointment_date
                                      ).format("hh:mm A")} `}
                                      <span className="status confirmed">
                                        {t(`${booking.status}`)}
                                      </span>
                                    </div>
                                    <div className="what">
                                      {booking.services &&
                                        booking.services.length &&
                                        booking.services[0].service_name}
                                    </div>
                                    <div className="with">
                                      {booking.services &&
                                        booking.services.length &&
                                        `${booking.services[0].duration} with ${booking.services[0].staff_name}`}
                                    </div>
                                  </div>
                                  <div className="sar">
                                    {t("SAR")} {booking.total_amount}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </React.Fragment>
                        )
                    )
                  )
                ) : (
                  <>
                    <Table>
                      <thead>
                        <tr className="invoice-row">
                          <th>{t("Status")}</th>
                          <th>{t("Invoice#")}</th>
                          <th>{t("Invoice date")}</th>
                          <th>{t("Payment Mode")}</th>
                          <th>{t("Total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoices.map((key, index) => (
                          <tr
                            key={index}
                            className="invoice-row"
                            onClick={() =>
                              handleInvoiceRedirection(key.booking_id)
                            }
                          >
                            <td>{key.status_id}</td>
                            <td>{key.reference_number}</td>
                            <td>
                              {moment(key.date).format("DD")}
                              {moment(key.date).format("MMM")}
                            </td>
                            <td>{t(`${key.payment_mode}`)}</td>
                            <td>{t("SAR ") + key.total_amount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="invoice-total">
                      {t("Showing")} {invoices.length} {t("of")}{" "}
                      {invoices.length} {t("results")}
                    </div>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </Hoc>
      <FullScreenPopup open={openPopup} onClose={closeOverlay}>
        <NewAppointment history={history} customerId={customerId} />
      </FullScreenPopup>
      <FullScreenPopup open={viewAppointment} onClose={closeOverlay}>
        <ViewAppointment history={history} />
      </FullScreenPopup>
    </React.Fragment>
  );
};

export default Profile;
