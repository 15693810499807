import React from "react";
import Hoc from "../Hoc";
import PayoutsTable from "./Payouts";

const Payouts = (props) => {
  const { match } = props;
  return (
    <Hoc activeMenu={match.params.id ? "partner" : "payouts"}>
      <PayoutsTable {...props} />
    </Hoc>
  );
};

export default Payouts;
