import React from "react";
import Hoc from "../Hoc";
import ServicesTable from "./ServicesTable";
import "./Styles.scss";
const Services = (props) => {
  return (
    <Hoc activeMenu="services">
      <ServicesTable {...props} />
    </Hoc>
  );
};

export default Services;
