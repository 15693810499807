import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Modal,
  Image,
  Button,
  Row,
  Col,
  Spinner,
  Container,
} from "react-bootstrap";
import PrimaryButton from "../../components/Buttons/Primary";
import editIcon from "../../assets/images/Icon material-mode-edit.png";
import deleteIcon from "../../assets/images/trash.svg";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ServiceForm from "./ServiceForm";
import {
  getCategories,
  getServicesByStatusId,
  serviceDelete,
  serviceSave,
} from "../../core/Api/services";
import { useTranslation } from "react-i18next";
import SearchInput from "../../components/Inputs/Search";
import { getAuthAttribute } from "../../utils/helper";
import TableData from "./TableData";
import Pagination from "../../components/Pagination";

const defaultState = {
  service_id: 0,
  category_id: "",
  service_name_en: "",
  service_name_ar: "",
  new_service_id: 0,
  is_active: 0,
};

const ServicesTable = () => {
  const { t, i18n } = useTranslation();

  const [serviceData, setServiceData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [categoriesOptions, setCategoryOPtions] = useState([]);

  const pageSize = 10;
  const offset = pageSize * (selectedPage - 1);
  const userName = getAuthAttribute("name");
  const userEmail = getAuthAttribute("username");
  const isMasterEdit =  userEmail === "noufadmin@boon.sa" || userName === "Nouf Administrator";

  useEffect(() => {
    fetchServices();
  }, []);

  useEffect(() => {
    setSelectedPage(1);
    handleFilterData();
  }, [searchInput]);

  useEffect(() => {
    handleFilterData();
  }, [selectedPage]);

  const handleFilterData = () => {
    if (searchInput?.length) {
      const newData = serviceData.filter((item) =>
        `${item.category.toLowerCase()} ${item.name_ar.toLowerCase()} ${item.name_en.toLowerCase()}`.includes(
          searchInput.toLowerCase()
        )
      );
      setFilteredData(newData || []);
      setTotalCount(newData?.length || 0)
    } else {
      setFilteredData(serviceData || []);
      setTotalCount(serviceData?.length || 0)
    }
  }

  const handlePageChange = (target) => {
    setSelectedPage(target);
  };

  const fetchServices = async () => {
    setLoading(true);
    toast.dismiss();
    try {
      const [result, catRes] = await Promise.all([
        getServicesByStatusId(),
        getCategories()
      ]);
      if(catRes?.code === 200){
        const catList = catRes?.result.map((row) => ({
          label: i18n.language === "ar" ? row.category_ar : row.category,
          value: row.category_id,
        }))
        setCategoryOPtions(catList)
      }
      if (result.code === 200) {
        setServiceData(result.result);
        setFilteredData(result?.result);
        setTotalCount(result?.result?.length);
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
    setLoading(false);
  };

  const statusFormatter = (status_id) => {
    const status = ((status_id) => {
      switch (status_id) {
        case "1":
          return "Active";
        case "2":
          return "Inactive";
        case "3":
          return "Deleted";
        default:
          break;
      }
    })(status_id);
    let style = ((status_id) => {
      switch (status_id) {
        case "1":
          return { color: "#00a65a" };
        case "2":
          return { color: "#ffa470" };
        case "3":
          return { color: "#dd3333" };
        default:
          break;
      }
    })(status_id);
    return (
      <div className="status-formatter" style={style}>
        {status}
      </div>
    );
  };

  const toggleActive = async (row) => {
    try {
      const data = {
        service_id: row.service_id ?? 0,
        category_id: row.category_id,
        service_name_en: row.name_en,
        service_name_ar: row.name_ar,
        new_service_id: row.new_service_id ?? 0,
        is_active: 1,
      };
      const response = await serviceSave(data);
      if (response?.code === 200) {
        toast.success(response.message);
        await fetchServices();
        handleClose();
      } else {
        throw new Error(response?.message ?? "Something went wrong");
      }
    } catch (error) { }
  };

  const handleConfirm = async (service_id) => {
    toast.dismiss();
    Swal.fire({
      text: t(
        `Are you sure you want to delete this service? This action cannot be undone.`
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#0b586d",
      cancelButtonColor: "#ffffff",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Delete"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await serviceDelete(service_id);
        if (result && result.code === 200) {
          await getServicesByStatusId();
          toast.success(result.message);
        } else {
          Swal.fire(
            (result && result.message) || "Something wrong",
            "",
            "error"
          );
        }
      }
    });
  };

  const formatter = (row) => {
    return (
      <div className="otherWrap">
        {+row.status_id === 2 && (
          <Button onClick={() => toggleActive(row)} variant="dark">
            {t('Activate')}
          </Button>
        )}
        <Image
          className="edit"
          onClick={() => setSelectedCategory(row)}
          src={editIcon}
          alt="edit"
        />
        <Image
          onClick={() => handleConfirm(row.service_id)}
          src={deleteIcon}
          alt="delete"
        />
      </div>
    );
  };

  const handleClose = () => setSelectedCategory(null);

  return (
    <Container
      fluid
      className={clsx(
        "services-container",
        isMasterEdit && "edit-service-container"
      )}
    >
      <div className="table-wrapper">
        <Row className="search-wrap">
          <Col xl="3" lg="6" md="6" sm="12">
            <SearchInput
              classNames="pt-0"
              onChange={(e) => setSearchInput(e?.target?.value)}
              placeholder={t("Search")}
            />
          </Col>
          {isMasterEdit && (
            <Col xl="6" lg="6" md="5" sm="12" className="cta-col">
              <PrimaryButton
                onClick={() => setSelectedCategory({ ...defaultState })}
                text={t("Add New")}
              />
            </Col>
          )}
        </Row>

        {isLoading && (
          <div className="spinner-wrap">
            <Spinner animation="border" />
            <span>{t(`${t("Please wait, we're loading data")}`)}</span>
          </div>
        ) || (
            filteredData?.length && (
              <TableData
                pageSize={pageSize}
                pageNo={selectedPage}
                data={filteredData.slice((pageSize * (selectedPage - 1)), ((pageSize * (selectedPage - 1)) + pageSize)) || []}
                isMasterEdit={isMasterEdit}
                statusFormatter={statusFormatter}
                formatter={formatter}
              />
            ) || (
              <div className="no-data-text text-center mt-2">
                {t("No data available")}
              </div>
            )
          )}
      </div>

      {/* Pagination Start */}
      <Row>
        <Pagination
          totalCount={totalCount || 0}
          viewCount={filteredData?.slice((pageSize * (selectedPage - 1)), ((pageSize * (selectedPage - 1)) + pageSize))?.length ?? []}
          onChangePage={handlePageChange}
          pageNo={selectedPage}
        />
      </Row>
      {/* Pagination ends */}

      {/* Service Form */}
      <Modal show={selectedCategory} onHide={handleClose} scrollable={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Manage Service')}</Modal.Title>
        </Modal.Header>
        <ServiceForm
          fetchServices={fetchServices}
          serviceData={serviceData}
          categoryDetail={selectedCategory}
          handleClose={handleClose}
          categoriesOptions={categoriesOptions}
        />
      </Modal>
    </Container>
  );
};

export default ServicesTable;
