import React, { useState, useRef, useEffect } from "react";
import { saveKeywords } from "../../core/Api/customer";
import { toast } from "react-toastify";
import UserInput from "../../components/Inputs";
import CheckBoxInput from "../../components/Inputs/CheckBox";
import { Button, Col, Modal, Row, Image as BSImage } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { getAllPartners, getPartners, getPartnersList, setFeaturedSalon } from "../../core/Api/partner";
import { useTranslation } from "react-i18next";
import CustomDropdown from "../../components/Dropdown";
import FullPageSpinner from "../../components/FullPageSpinner";
import DateInput from "../../components/DateInput";
import moment from "moment";
import CustomTextArea from "../../components/Textarea";
import { saveAdvert } from "../../core/Api/Asvertisements";
import { uploadImage } from "../../core/Api/staff";

const FeaturedSalonsForm = ({ handleClose, selectedSupplier }) => {
  const { t } = useTranslation();
  const simpleValidator = useRef(new SimpleReactValidator({
    forceUpdate: true,
    validators: {
      notPastDate: {
        message: t("Must not contains past date"),
        rule: (val) => {
          if (moment(val).startOf('day') < moment().startOf('day')) return false;
          else return true
        },
        required: true,
      },
    }
  }));

  const [supplierDetails, setSupplierDetails] = useState({ ...selectedSupplier });
  const [isLoading, setLoading] = useState(false);
  const [, forceUpdate] = useState(0);
  const [imagePreview, setImagePreview] = useState(null);
  const [suppliers, setSuppliers] = useState([
    { value: null, label: 'Select Supplier' }
  ]);

  const options = [
    { value: null, label: t('Please select type') },
    { value: '1', label: t('Add') },
    { value: '2', label: t('Event') }
  ]

  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    setLoading(true);
    const res = await getPartnersList({
      per_page: 1000,
      offset: 0
    });
    setLoading(false);
    if (res?.code === 200) {
      setSuppliers([
        ...suppliers,
        ...(res?.result || []).map(r => ({
          label: r?.name,
          value: r?.supplier_id
        }))
      ])
    }
  }

  const handleDDChange = (value, name) => {
    setSupplierDetails({
      ...supplierDetails,
      [name]: value?.value
    })
  }

  const handleDateChange = (date, name) => {
    setSupplierDetails({
      ...supplierDetails,
      [name]: new Date(date),
    });
  };

  const handleChange = (e) => {
    setSupplierDetails({
      ...supplierDetails,
      [e.target.name]: e.target.value
    })
  }

  const handleSaveOffer = async () => {
    toast.dismiss();
    try {
      if (simpleValidator.current.allValid()) {
        if (moment(supplierDetails?.end_date).startOf('day') < moment(supplierDetails?.start_date).startOf('day')) {
          toast.error(t('End date of must be greater than or equals to start date'));
          return
        }
        setLoading(true);
        let img_name = null;
        if (imagePreview?.image) {
          const imageData = new FormData();
          imageData.append("file", imagePreview.image);
          const offerImage = await uploadImage(imageData);
          img_name = offerImage?.result?.file_name;
        }
        const result = await saveAdvert({
          advert_id: supplierDetails?.advert_id || null,
          type: supplierDetails?.type || '',
          title: supplierDetails?.title || '',
          desc: supplierDetails?.desc || '',
          image: supplierDetails?.image || '',
          start_date: moment(supplierDetails?.start_date).format('YYYY-MM-DD'),
          end_date: moment(supplierDetails?.end_date).format('YYYY-MM-DD'),
          status_id: 1,
          image: img_name,
          supplier_id:supplierDetails?.supplier_id || null
        });
        setLoading(false);
        if (result.code === 200) {
          handleClose();
        } else {
          throw new Error(result?.message ?? "Something went wrong");
        }
      } else {
        forceUpdate(1);
        simpleValidator.current.showMessages();
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message ?? "Something went wrong");
    }
  };

  const onImageSelect = async (event) => {
    if (true) {
      const image = event.target.files[0];
      if (image) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          const img = new Image();
          img.src = reader.result;
          img.onload = function () {
            var width = img.naturalWidth,
              height = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);
            if (width >= 400 && height >= 400) {
              setImagePreview({
                image: image,
              });
              setSupplierDetails({
                ...supplierDetails,
                image: reader.result,
              });
            } else {
              toast.error("Image resolution must be greater then 400X400");
              event.preventDefault();
            }
          };

        });
        reader.readAsDataURL(image);
      }
    }
  };

  return (
    <React.Fragment>
      <Modal.Body>
        {isLoading && <FullPageSpinner /> || null}
        <div className="add-fav-salon-cont">
          <Row>
            <Col md={6} sm={12}>
              <CustomDropdown
                onChange={(value) =>
                  handleDDChange(value, "type")
                }
                name="type"
                options={options || []}
                label={t("Type")}
                value={
                  options.find(
                    (row) => row.value == supplierDetails?.type
                  ) || ""
                }
              />
              {simpleValidator.current.message(
                "Type",
                supplierDetails?.type,
                "required"
              )}
            </Col>
            <Col md={6} sm={12}>
              <UserInput
                required
                label={t("Title")}
                name="title"
                placeholder={t("Title")}
                value={supplierDetails.title}
                onChange={handleChange}
              />
              {simpleValidator.current.message("Title", supplierDetails.title, "required")}
            </Col>
            <Col md={6} sm={12}>
              <DateInput
                label={t("From date")}
                value={new Date(moment(supplierDetails.start_date).format('YYYY/MM/DD'))}
                onChange={(date) => handleDateChange(date, "start_date")}
              />
              {simpleValidator.current.message(
                "start_date",
                supplierDetails?.start_date,
                "required|notPastDate"
              )}
            </Col>
            <Col md={6} sm={12}>
              <DateInput
                label={t("To date")}
                value={new Date(moment(supplierDetails.end_date).format('YYYY/MM/DD'))}
                onChange={(date) => handleDateChange(date, "end_date")}
              />
            </Col>
            <Col md={6} sm={12}>
              <CustomDropdown
                onChange={(value) =>
                  handleDDChange(value, "supplier_id")
                }
                name="supplier_id"
                options={suppliers || []}
                label={t("Supplier")}
                value={
                  suppliers.find(
                    (row) => row.value == +supplierDetails?.supplier_id
                  ) || ""
                }
                classNames='mt-3'
              />
            </Col>
            <Col md={12} sm={12} className='mt-3'>
              <CustomTextArea
                label={t("Description (Optional)")}
                name="desc"
                value={supplierDetails.desc}
                onChange={(event) =>
                  handleChange(event)
                }
              >
              </CustomTextArea>
            </Col>
          </Row>
          <Row className="m-0">
            <Col md="12" className="image-wrapper">
              <div>
                <input
                  type="file"
                  name="myfile"
                  accept=".png, .jpg, .jpeg"
                  onChange={onImageSelect}
                />
              </div>
              <BSImage
                src={supplierDetails?.image || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8Vg8AAlEBZ0IDjCQAAAAASUVORK5CYII='}
                alt="Select banner image."
              />
            </Col>
            {simpleValidator.current.message(
              "Image",
              supplierDetails?.image,
              "required"
            )}
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer className="footer">
        <Button
          disabled={isLoading}
          onClick={handleSaveOffer}
          className="save-btn"
        >
          {t('Save changes')}
        </Button>
        <Button onClick={handleClose} variant="white">
          {t('Close')}
        </Button>
      </Modal.Footer>
    </React.Fragment >
  );
};

export default FeaturedSalonsForm;
