import React, { useState, useRef, useEffect } from "react";
import { saveKeywords } from "../../core/Api/customer";
import { toast } from "react-toastify";
import UserInput from "../../components/Inputs";
import { Button, Col, Modal, Row, Image as BSImage } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { useTranslation } from "react-i18next";
import FullPageSpinner from "../../components/FullPageSpinner";
import CustomTextArea from "../../components/Textarea";
import { uploadImage } from "../../core/Api/staff";
import { defaultImage } from "../../utils/image";
import { saveCategory } from "../../core/Api/category";

const FeaturedSalonsForm = ({ handleClose, selectedCategory }) => {
  const { t } = useTranslation();
  const simpleValidator = useRef(new SimpleReactValidator({
    forceUpdate: true,
    validators: {
      notPastDate: {
        message: t("Must be only number"),
        rule: (val) => {
          if (false) return false;
          else return true
        },
        required: true,
      },
    }
  }));

  const [categoryDetails, setCategoryDetails] = useState({
    ...selectedCategory,
    image: selectedCategory?.image_name || null
  });
  const [isLoading, setLoading] = useState(false);
  const [, forceUpdate] = useState(0);
  const [imagePreview, setImagePreview] = useState(null);


  useEffect(() => {
  }, [])

  const handleChange = (e) => {
    setCategoryDetails({
      ...categoryDetails,
      [e.target.name]: e.target.value
    })
  }

  const handleSaveCategory = async () => {
    toast.dismiss();
    try {
      if (simpleValidator.current.allValid()) {

        setLoading(true);
        let img_name = null;
        if (imagePreview?.image) {
          const imageData = new FormData();
          imageData.append("file", imagePreview.image);
          const offerImage = await uploadImage(imageData);
          img_name = offerImage?.result?.file_name;
        }
        const result = await saveCategory({
          category_id: categoryDetails?.category_id || null,
          category: categoryDetails?.category || '',
          category_ar: categoryDetails?.category_ar || '',
          description: categoryDetails?.description || '',
          priority: categoryDetails?.priority || 0,
          status_id: 1,
          image: img_name || selectedCategory?.image,
        });
        setLoading(false);
        if (result.code === 200) {
          handleClose();
        } else {
          throw new Error(result?.message ?? "Something went wrong");
        }
      } else {
        forceUpdate(1);
        simpleValidator.current.showMessages();
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message ?? "Something went wrong");
    }
  };

  const onImageSelect = async (event) => {
    if (true) {
      const image = event.target.files[0];
      if (image) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          const img = new Image();
          img.src = reader.result;
          img.onload = function () {
            var width = img.naturalWidth,
              height = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);
            // if (width === 256 && height === 256) {
            if (true) {
              setImagePreview({
                image: image,
              });
              setCategoryDetails({
                ...categoryDetails,
                image: reader.result,
              });
            } else {
              toast.error(t('Image resolution must be equals to 256 X 256'));
              event.preventDefault();
            }
          };

        });
        reader.readAsDataURL(image);
      }
    }
  };

  return (
    <React.Fragment>
      <Modal.Body>
        {isLoading && <FullPageSpinner /> || null}
        <div className="add-fav-salon-cont">
          <Row>
            <Col md={6} sm={12}>
              <UserInput
                required
                label={t("Name")}
                name="category"
                placeholder={t("Name")}
                value={categoryDetails.category}
                onChange={handleChange}
              />
              {simpleValidator.current.message("Name", categoryDetails.category, "required")}
            </Col>
            <Col md={6} sm={12}>
              <UserInput
                required
                label={t("Name(arb)")}
                name="category_ar"
                placeholder={t("Name(arb)")}
                value={categoryDetails.category_ar}
                onChange={handleChange}
              />
              {simpleValidator.current.message("Name(arb)", categoryDetails.category_ar, "required")}
            </Col>
            <Col md={6} sm={12}>
              <UserInput
                required
                label={t("Priority")}
                name="priority"
                placeholder={t("Priority")}
                value={categoryDetails.priority}
                onChange={handleChange}
              />
              {simpleValidator.current.message("Priority", categoryDetails.priority, "required")}
            </Col>
            <Col md={12} sm={12} className='mt-3'>
              <CustomTextArea
                label={t("Description (Optional)")}
                name="desc"
                value={categoryDetails.description}
                onChange={(event) =>
                  handleChange(event)
                }
              >
              </CustomTextArea>
            </Col>
          </Row>
          <Row className="m-0">
            <Col md="12" className="image-wrapper">
              <p>{t('Please choose category image')}</p>
              <p>{t('Image resolution must be (256 X 256)')}</p>
              <div className="mt-3">
                <input
                  type="file"
                  name="myfile"
                  accept=".png, .jpg, .jpeg"
                  onChange={onImageSelect}
                />
              </div>
              <BSImage
                src={categoryDetails?.image || defaultImage}
                alt="Select banner image."
              />
            </Col>
            {simpleValidator.current.message(
              "Image",
              categoryDetails?.image,
              "required"
            )}
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer className="footer">
        <Button
          disabled={isLoading}
          onClick={handleSaveCategory}
          className="save-btn"
        >
          {t('Save changes')}
        </Button>
        <Button onClick={handleClose} variant="white">
          {t('Close')}
        </Button>
      </Modal.Footer>
    </React.Fragment >
  );
};

export default FeaturedSalonsForm;
