import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import CustomDropdown from "../../../../components/Dropdown";
import "./Styles.scss";
import moment from "moment";
import {
  getBookingById,
  getBookingHistoryByBookingId,
  updateStatus,
  setNoShow,
  cancelBooking,
} from "../../../../core/Api/bookings";
import { getInvoiceById } from "../../../../core/Api/customer";
import { getSupplierDriver } from "../../../../core/Api/supplier";
import BillIcon from "../../../../assets/images/bill.png";
import locationIcon from "../../../../assets/images/geo-alt-fill.svg";
import NewAppointment from "../../../Calender/NewAppointment";
import FullScreenPopup from "../../../../components/FullScreenPopup";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { getSupplierAddress } from "../../../../core/Api/supplier";
import FullPageSpinner from "../../../../components/FullPageSpinner";
import { getAuthAttribute } from "../../../../utils/helper";
import AssetsImg from "images-col";
import PrimaryButton from "../../../../components/Buttons/Primary";
import * as _ from 'lodash'

const ViewAppointment = ({ history, onClose, from }) => {
  const { t } = useTranslation();
  const [booking, setBooking] = useState({});
  const [driverData, setDriverData] = useState({});
  const [bookingHistory, setBookingisory] = useState([]);
  const [invoicePresent, setInvoicePresent] = useState(false);
  const [editAppointment, setEditAppointment] = useState(false);
  const [totalDuration, setDuration] = useState(0);
  const [status, setStatus] = useState(0);
  const urlParams = new URLSearchParams(history.location.search);
  const [supplierData, setSupplierData] = useState({});
  const booking_id = urlParams.get("booking_id");
  const userTypeId = getAuthAttribute("user_type_id");
  const [selectedOption, setSelectedOption] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const menuOptions = useRef([
    { label: t("Reschedule"), value: "Reschedule" },
    { label: t("Cancel"), value: "Cancel" },
    { label: t("No show"), value: "No show" },
  ]);

  const statusOptions = [
    // { label: t("Status"), value: 0 },
    { label: t("Created"), value: 14 },
    { label: t("Confirmed"), value: 10 },
    { label: t("On The Way"), value: 19 },
    { label: t("In Progress"), value: 13 },
    { label: t("Pending"), value: 5 },
    { label: t("Completed"), value: 6 },
    { label: t("No show"), value: 21 },
    { label: t("Cancelled"), value: 4 },
  ];

  useEffect(() => {
    if (selectedOption) {
      switch (selectedOption) {
        case "Reschedule":
          openOverlay();
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [selectedOption]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [history.location.search]);

  const handleCancelBooking = async () => {
    setIsLoading(true)
    const result = await cancelBooking(booking_id, userTypeId);
    if (result && result.code === 200) {
      toast.success(result.message);
    } else {
      toast.error(result && result.message);
    }
    setIsLoading(false)
  };

  const handleSetNoShow = async () => {
    setIsLoading(true)
    const result = await setNoShow(booking_id);
    if (result && result.code === 200) {
    } else {
      toast.error(result && result.message);
    }
    setIsLoading(false)
  };

  const updateBookingStatus = async (bookingStatus) => {
    if (!booking_id || !bookingStatus?.value) {
      return;
    }
    const optionsInfo = {
      isConfirmationReq: bookingStatus?.value == 21 || bookingStatus?.value == 4 || bookingStatus?.value == 6,
    };
    if (optionsInfo?.isConfirmationReq) {
      if (bookingStatus?.value == '21') {
        Swal.fire({
          text: t("Are you sure to mark the booking as No-Show?"),
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          cancelButtonText: t("Cancel"),
          confirmButtonText: t("Yes"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            handleSetNoShow(booking_id);
            setStatus(bookingStatus)
          } else {
            setStatus(_.clone(status))
          }
        });
      } else if (bookingStatus?.value == '4') {
        Swal.fire({
          text: t("Are you sure you want to cancel the booking?"),
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          cancelButtonText: t("Cancel"),
          confirmButtonText: t("Yes"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            handleCancelBooking(booking_id);
            setStatus(bookingStatus)
          } else {
            setStatus(_.clone(status))
          }
        });
      } else if (bookingStatus?.value == '6') {
        Swal.fire({
          text: t("Are you sure you want to complete the booking?"),
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          cancelButtonText: t("Cancel"),
          confirmButtonText: t("Yes"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            changeBookingStatus(booking_id, bookingStatus?.value);
            setStatus(bookingStatus)
          } else {
            setStatus(_.clone(status))
          }
        });
      }
    } else {
      changeBookingStatus(booking_id, bookingStatus?.value);
      setStatus(bookingStatus)
    };

  }

  const changeBookingStatus = async (booking_id, status_id) => {
    setIsLoading(true);
    const result = await updateStatus({
      booking_id: Number(booking_id),
      status_id,
    });
    setIsLoading(false)
    if (result && result.code === 200) {
      toast.success(result.message ?? "Status updated successfully");
      onClose && onClose();
    } else {
      toast.error(result.message ?? "Something went wrong");
    }
  }

  const fetchSupplier = async (supplier_id) => {
    toast.dismiss();
    try {
      const result = await getSupplierAddress(supplier_id);
      if (result.code === 200) {
        setSupplierData(result.result ?? {});
      } else {
        throw new Error(result?.message ?? "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message ?? "Something went wrong");
    }
  };

  const loadData = async () => {
    setIsLoading(true)
    if (booking_id) {
      const [result, historyResult, invoiceResult] = await Promise.all([
        getBookingById(booking_id),
        getBookingHistoryByBookingId(booking_id),
        getInvoiceById(booking_id),
      ]);
      if (result && result.code === 200) {
        const status = t(result.result.status_id);
        setStatus(statusOptions.find((item) => item.value == status));
        setBooking(result.result);
        await fetchSupplier(result.result.supplier_id);
      } else {
        toast.error(result && result.message);
      }
      let drivers = await getSupplierDriver(result.result.supplier_id);
      drivers = drivers?.result ?? [];
      if (drivers.length) {
        setDriverData(
          drivers
            ? drivers.find(
              (driver) =>
                driver.supplier_driver_id === result.result.supplier_driver_id
            )
            : {}
        );
      } else {
        toast.error(drivers && drivers.message);
      }
      if (historyResult && historyResult.code === 200) {
        setBookingisory(historyResult.result);
      } else {
        toast.error(historyResult && historyResult.message);
      }
      if (invoiceResult && invoiceResult.code === 200) {
        setInvoicePresent(true);
      } else {
        setInvoicePresent(false);
      }
    }
    setIsLoading(false)
  };

  useEffect(() => {
    const state = history.location.state;
    if (state?.editAppointment) {
      setEditAppointment(true);
    } else {
      setEditAppointment(false);
    }
  }, [history.location.state]);

  const openOverlay = () => {
    const pathname = history.location.pathname + history.location.search;
    const state = history.location.state;
    history.push(pathname, {
      ...state,
      editAppointment: true,
    });
  };

  const closeOverlay = () => {
    history.go(-2);
  };

  const handleRedirectToLocation = () => {
    if (booking.customer_address?.lat_lng) {
      const lat_lng = booking.customer_address.lat_lng
        .split(",")
        .map((val) => val?.trim());
      window.open(
        `https://maps.google.com/maps?z=12&t=m&q=${lat_lng[0]}+${lat_lng[1]}`,
        "_blank"
      );
    }
  };

  const getActionBtnText = () => {
    if (booking.status &&
      (booking.status?.toLowerCase() === "created" ||
        booking.status?.toLowerCase() === "confirmed") ||
      (booking.status?.toLowerCase() === "pending")) {
      return 'Reschedule'
    } else {
      return null
    }
  }

  useEffect(() => {
    let tempDuration = 0;
    if (booking && booking.services && booking.services.length) {
      booking.services.forEach((service) => {
        tempDuration = tempDuration + Number(service.duration);
      });
      setDuration(tempDuration);
    }
  }, [booking]);


  const statusFormatter = (status) => {
    const style = ((status) => {
      switch (status) {
        case "In Progress":
          return {
            color: "#7effd7",
            fontFamily: "Nunito Sans-extrabols",
            fontSize: "16px",
          };
        case "Cancelled":
          return {
            color: "#dd3333",
            fontFamily: "Nunito Sans-extrabold",
            fontSize: "16px",
          };
        case "Completed":
          return {
            color: "#097969",
            fontFamily: "Nunito Sans-extrabold",
            fontSize: "16px",
          };
        default:
          return {
            color: "#ffa470",
            fontFamily: "Nunito Sans-extrabold",
            fontSize: "16px",
          };
      }
    })(status);
    return (
      <span style={style}>
        {t(status)}
      </span>
    );
  };

  const handleOpenCustomerProfile = (customer_id) => {
    console.log('customer_id ', customer_id);
    if (customer_id != '1') {
      window.open(
        window.location.origin +
        `/profile/${customer_id}`,
        "_blank"
      )
    }
  }

  const journeyStatus = (() => {
    switch (booking.journey_status) {
      case "0":
        return "Not started";
      case "1":
        return "Started";
      case "19":
        return "On the way";
      case "2":
        return "Ended";
      default:
        return "N/A";
    }
  })();

  const getPromoText = (offer_details) =>{
    if(!offer_details) return false
    if(offer_details?.value_type === 'value'){
      return <span>{`${offer_details?.coupon_code} - ${offer_details?.amount}${t('SAR')}`}</span>
    }else if(offer_details?.value_type === 'percentage'){
      return <span>{`${offer_details?.offer_id} - ${offer_details?.coupon_code} - ${offer_details?.amount}%`}</span>
    }
  }


  return (
    <div className="view-appointment">
      {isLoading && <FullPageSpinner /> || null}
      <h2>
        {" "}
        {t("View Appointment")}
        <h5> {booking?.name} </h5>
      </h2>
      <Container fluid className="main-container">
        <Row className="h-100">
          <Col lg={{ span: 5, offset: 2 }} className="left mt-5">
            <Container fluid className="history-block">
              <div className="client-status">
                <Row
                  className="client-details px-3"
                  onClick={() => handleOpenCustomerProfile(booking?.customer?.customer_id)}
                >
                  <Image src="https://images.unsplash.com/photo-1557296387-5358ad7997bb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=694&q=80" />
                  <div className="email-cont">
                    <label>
                      {booking &&
                        booking.customer &&
                        `${booking.customer.first_name} ${booking.customer.last_name}`}
                    </label>
                    <div className="value">
                      {booking &&
                        booking.customer &&
                        `${booking.customer.mobile}`}
                    </div>
                  </div>
                </Row>
                <Row className="status-options justify-content-center align-items-center">
                  <Col md="4" sm="4" xs="5">
                    {invoicePresent ? (
                      <Link
                        className="invoice-link"
                        to={`/invoice/${booking_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("View Invoice")}
                      </Link>
                    ) : (
                      <div className="no-invoice-wrap">
                        <div className="no-invoice">
                          {t("No invoice issued yet")}
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col md="4" sm="4" xs="6" className="status">
                    <CustomDropdown
                      options={statusOptions}
                      value={status}
                      onChange={(value) => updateBookingStatus(value)}
                      disabled={status?.label === 'Completed' && true || false}
                      highlightedOptions={["Cancelled", "No show"]}
                    />
                  </Col>
                </Row>
              </div>
              <div className="name">
                {booking &&
                  booking.customer &&
                  `${booking.customer.first_name} ${booking.customer.last_name}`}
              </div>
              <div className="product-name">
                {booking &&
                  (booking.customer_address?.location ?? booking.location)}
                {booking.customer_address?.lat_lng && (
                  <Image
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRedirectToLocation()}
                    src={locationIcon}
                    className="mx-2 mb-2"
                    alt="location"
                  />
                )}
              </div>
              {booking?.is_guest_booking === "1" && (
                    <div className="appt-date-cont mt-2">
                      <table class="table table-sm">
                        <tr><th class="table-secondary">{t('Created Date')}</th><td class="table-primary"><span>{moment(booking?.date).format('MM-DD-YYYY ddd hh:mm A')}</span></td></tr>
                        <tr><th class="table-secondary">{t('Appointment Date')}</th><td class="table-primary"><span>{booking?.services?.length && moment(booking?.services?.[0]?.appointment_date).format('MM-DD-YYYY ddd hh:mm A')}</span></td></tr>
                        <tr><th class="table-secondary">{t('Status')}</th><td class="table-primary"><span>{booking?.status}</span></td></tr>
                      </table>
                      <table class="table">
                        <tr><th colSpan="3" rowSpan="4" class="table-secondary">{t('Guest Details')}</th></tr>
                        <tr><th style={{ padding: "0px 5px" }} class="table-primary">{t('Name')}</th><td   class="table-primary  p-0" ><span>{booking?.guest_name}</span></td></tr>
                        <tr><th style={{ padding: "0px 5px" }} class="table-primary">{t('Phone')}</th><td   class="table-primary p-0"><span>{booking?.guest_mobile}</span></td></tr>
                        {/* <tr><th style={{ padding: "0px 5px" }} class="table-primary">{t('Email')}</th><td   class="table-primary p-0"><span>{booking?.guest_email}</span></td></tr> */}
                      </table>
                    </div>
                  ) || null}

                  {booking?.offer_details && (
                    <div className="appt-date-cont mt-2">
                      <table class="table table-sm">
                      <tr><th class="table-secondary">{t('Coupon Info')}</th><td class="table-primary">{getPromoText(booking?.offer_details)}</td></tr>

                      </table>
                    </div>
                  )}
                  <div className="name mt-5">
                    Services
                  </div>
              {booking?.services && booking?.services?.length
                ? booking.services.map((service, index) => {
                  return (
                    <Row className="calculation">
                      <Col md="3" className="time">
                        {`${moment(
                          service && service.appointment_date
                        ).format("ddd")} ${moment(
                          service && service.appointment_date
                        ).format("hh:mm A")} `}
                      </Col>
                      <Col md="6" className="product-name">
                        {service?.service_label}
                        <div className="duration">
                          {`${service.duration} mins with ${service.staff_name}`}
                        </div>
                      </Col>
                      <Col md="3" className="product-amount text-right">
                        {t("SAR")} {service && service.amount}
                      </Col>
                    </Row>
                  );
                })
                : null}
                <div className="charges-details-cont">
                  
              <Row className="total">
                <Col md={{ span: 6, offset: 0 }} className="duration p-0">
                  {t('Duration')}
                </Col>
                <Col md="6" className="amount p-0">
                  {`${totalDuration || 0} mins`}
                </Col>
              </Row>
              {booking?.summary?.services_original_amount > 0 && <Row className="total">
                <Col md={{ span: 6, offset: 0 }} className="duration">
                  {t('Services Total')}
                </Col>
                <Col md="6" className="amount">
                  {`SAR ${parseFloat(booking?.summary?.services_original_amount ?? 0).toFixed(2) ?? 0}`}
                </Col>
              </Row>}
              {booking?.summary?.discount_total > 0 && <Row className="total">
                <Col md={{ span: 6, offset: 0 }} className="duration">
                  {t('Discount')}
                </Col>
                <Col md="6" className="amount">
                  {`SAR ${parseFloat(booking?.summary?.discount_total??0).toFixed(2) ?? 0}`}
                </Col>
              </Row>}
              {booking?.summary?.services_amount > 0 && <Row className="total">
                <Col md={{ span: 6, offset: 0 }} className="duration">
                  {t('Subtotal')}
                </Col>
                <Col md="6" className="amount">
                  {`SAR ${parseFloat(booking?.summary?.services_amount ?? 0).toFixed(2) ?? 0}`}
                </Col>
              </Row>}
              {(booking.location_type === "home" && booking?.summary?.delivery_fee) > 0 && <Row className="total">
                <Col md={{ span: 6, offset: 0 }} className="duration">
                  {t('Delivery Charges')}
                </Col>
                <Col md="6" className="amount">
                  {`SAR ${parseFloat(booking?.summary?.delivery_fee).toFixed(2) ?? 0}`}
                </Col>
              </Row>}
              {booking?.summary?.sub_total > 0 && <Row className="total">
                <Col md={{ span: 6, offset: 0 }} className="duration">
                  {t('Amount w/o VAT')}
                </Col>
                <Col md="6" className="amount">
                  {`SAR ${parseFloat(booking?.summary?.sub_total??0).toFixed(2) ?? 0}`}
                </Col>
              </Row>}
              {booking?.summary?.vat_amount > 0 && <Row className="total">
                <Col md={{ span: 6, offset: 0 }} className="duration">
                  {`${t('VAT')}(${booking?.summary?.vat}%)`}
                </Col>
                <Col md="6" className="amount">
                  {`SAR ${booking?.summary?.vat_amount?.toFixed(2)}`}
                </Col>
              </Row>}
              {booking?.summary?.grand_total > 0 && <Row className="total">
                <Col md={{ span: 6, offset: 0 }} className="duration">
                  <span>{t('Total')}</span>
                </Col>
                <Col md="6" className="amount">
                  {`SAR ${parseFloat(booking?.summary?.grand_total ?? 0).toFixed(2) ?? 0}`}
                </Col>
              </Row>}
                </div>

              {driverData?.name ? (
                <Row>
                  <Col md="12">
                    <div className="driver">
                      <strong>{t("Driver")}</strong>
                      <p>
                        {driverData?.name}
                      </p>
                      <p>{driverData?.mobile}</p>
                    </div>
                    <br />
                    <div className="journey-status">
                      <span>Journey Status </span>
                      <br /> {journeyStatus}
                    </div>
                  </Col>
                </Row>
              ) : null}

              <Row className="appointment-timeline">
                <Col md="12">
                  <div className="heading">{t("Appointment history")}</div>
                  {bookingHistory.map((row) => (
                    <React.Fragment key={row.service_label}>
                      <p>
                        <strong>{row.service_label}</strong>
                        {booking?.status === 'Cancelled' && <small className="text-danger pl-2">({t('Cancelled')})</small> || null}
                      </p>
                      <ul>
                        {row.flow.map((flow) => (
                          <li key={flow.description}>
                            &nbsp;{statusFormatter(flow?.status)}&nbsp;
                            {` - ${flow.description} at ${moment(flow.date).format(
                              "ddd"
                            )}, ${moment(flow.date).format(
                              "DD  MMM YYYY"
                            )} at ${moment(flow.date).format("HH:mm A")}`}
                          </li>
                        ))}
                      </ul>
                    </React.Fragment>
                  ))}

                </Col>
              </Row>
            </Container>
            <div className="service-menu-options">
              <Row className="justify-content-center ">
                <Col md="4" sm="6" xs="10" className="service-menu">
                  {getActionBtnText() && <PrimaryButton
                    onClick={() => setSelectedOption('Reschedule')}
                    text={getActionBtnText()}
                  /> || null}
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={{ span: 3, offset: 2 }} className="invoice-panel">
            <div
              className="client-details"
              onClick={() => handleOpenCustomerProfile(booking?.customer?.customer_id)}
            >
              {/* <Image src="https://images.unsplash.com/photo-1557296387-5358ad7997bb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=694&q=80" /> */}
              <Image
                src={AssetsImg?.img_default}
              />
              <div className="email-cont">
                <label>
                  {booking &&
                    booking.customer &&
                    `${booking.customer.first_name} ${booking.customer.last_name}`}
                </label>
                <div className="value">
                  {booking && booking.customer && `${booking.customer.mobile}`}
                </div>
              </div>
            </div>
           {from !== 'bookingTrans'  && 
            <div className="status">
              <CustomDropdown
                options={statusOptions}
                value={status}
                onChange={(value) => updateBookingStatus(value)}
                disabled={status?.label === 'Completed' && true || false}
                highlightedOptions={["Cancelled", "No show"]}
              />
            </div>}
            <div className="invoice">
              <Container fluid>
                <Row>
                  {
                    <Col>
                      {invoicePresent ? (
                        <Link
                          className="invoice-link"
                          to={`/invoice/${booking_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("View Invoice")}
                        </Link>
                      ) : (
                        <div className="no-invoice-wrap">
                          <Image
                            className="bill-image"
                            src={BillIcon}
                            alt="No Invoice"
                          />
                          <div className="no-invoice">
                            {t("No invoice issued yet")}
                          </div>
                        </div>
                      )}
                    </Col>
                  }
                 {from !== 'bookingTrans' && 
                  <div className="service-menu">
                    {getActionBtnText() && <PrimaryButton
                      onClick={() => setSelectedOption('Reschedule')}
                      text={getActionBtnText()}
                    /> || null}
                  </div>}
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
       {editAppointment &&  <FullScreenPopup open={editAppointment} onClose={closeOverlay}>
          <NewAppointment
            supplierData={supplierData}
            isEdit={true}
            onCancel={closeOverlay}
            booking_id={booking_id}
            customerId={booking?.customer?.customer_id}
            status={status}
            history={history}
            selectedPartner={booking.supplier_id}
          />
        </FullScreenPopup>}
      </Container>
    </div>
  );
};

export default ViewAppointment;
