import React, { useState } from "react";
import BoonContext from "./index";

const BoonProvider = (props) => {
  const [supplierName, setSupplierName] = useState("");
  const [iWillDoLater, setIWillDoLater] = useState(false);
  const [profileStatusInfo, setProfileStatusInfo] = useState(null);
  return (
    <BoonContext.Provider
      value={{
        supplierName,
        setSupplierNameContext: (name) => {
          setSupplierName(name);
        },
        iWillDoLater,
        setIWillDoLater: (value) => {
          setIWillDoLater(value)
        },
        profileStatusInfo,
        setProfileStatusInfo: (value) => {
          setProfileStatusInfo(value)
        }
      }}
    >
      {props.children}
    </BoonContext.Provider >
  );
};

export default BoonProvider;
